<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <yan-guang-dan :is-customer="3"/>
        </template>
      </page-header>
      <search-condition>
        <template #default>
          <div style="display: inline-block;" class="customer-select">
            <customer-select :cus-title="'请输入客户名称'" v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" border>
          <el-table-column align="center" label="序号" type="index" width="60" fixed/>
          <el-table-column align="center" label="验光单号" prop="id" min-width="120" show-overflow-tooltip fixed/>
          <el-table-column align="center" label="验光时间" prop="createdTime" show-overflow-tooltip fixed/>
          <el-table-column align="center" label="客户名称" prop="customerName"/>
          <el-table-column align="center" label="客户手机号">
            <template v-slot="{row}">
              <div>{{row.customerPhone ? row.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') : '空'}}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="球面度数(右)" prop="leftSphericalDegree"/>
          <el-table-column align="center" label="球面度数(左)" prop="rightSphericalDegree"/>
          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{row}">
              <yan-guang-dan :ygd-id="row.id" :is-customer="2" :customer-id="row.customerId" :customer-info="row.customerDomain" />
              <table-button @click="toDelete(row)" el-type="danger" type="delete" message="您确定要删除该条验光数据吗?">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" />
    </list-page>
  </div>
</template>

<script>

import PageHeader from "@/components/h5/layout/PageHeader.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import YanGuangDanApi from "@/api/main/order/yanguangdan/YanGuangDanApi";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import YanGuangDan from "@/views/main/sale/order/businessComponent/YanGuangDan.vue";

export default {
  name: "MemberYgd",
  components: {
    CustomerSelect,
    FormPage,
    SearchCondition, PageHeader, PageButton, ListPage, TableList, YanGuangDan
  },
  data() {
    return {
      // 搜索条件
      searchInfo: {},
      // 数据
      dataList: [],
      // 客户对应的验光单数据
      customerYgdList: []
    };
  },
  created() {
  },
  methods: {
    // 筛选客户
    setCustomer(data) {
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    // 重置
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询
    queryList(){
      YanGuangDanApi.queryList(this.searchInfo).then(res=>{
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 删除
    toDelete(row){
      YanGuangDanApi.deleteById(row.id);
    },
  }
};
</script>

<style lang="scss" scoped>

</style>