<template>
  <!-- -->
  <div style="height: 100%">
    <separate-page>
      <template #category>
        <el-empty description="请先添加角色" v-if="roleList.length === 0"/>
        <div v-else :class="currentRole.id === role.id? 'role-list on': 'role-list'" v-for="role in roleList" @click="selectRole(role)">{{role.roleName}}</div>
      </template>
      <template #default>
        <list-page>
          <table-list>
            <separate-page>
              <template #category>
                <el-tree :data="roleModules" :props="{children: 'children', label: 'moduleName'}" @node-click="clickModule" node-key="id" default-expand-all :expand-on-click-node="false" style="height: 100%;" >
                  <template #default="{node, data}">
                    <span class="custom-tree-node">
                      <span class="node-name">{{ node.label }}</span>
                      <span v-if="data.selected" style="color: #1EBD43"><el-icon><el-icon-check/></el-icon></span>
                    </span>
                  </template>
                </el-tree>
              </template>
              <template #default>
                <access-module-component ref="moduleAccessComponent" v-model="currentModule.accessModule" :right-list="rightList" :module-access-right="currentModule.moduleAccess"/>
              </template>
            </separate-page>
          </table-list>
          <form-page :show-page="false">
            <page-button el-type="success" type="write" @click="toEditRole({})">新建角色</page-button>
            <page-button el-type="primary" :disabled="!currentRole.id" @click="toEditRole(currentRole)">编辑角色</page-button>
            <page-button el-type="danger" :disabled="!currentRole.id" message="是否确认删除这个角色" @click="deleteRole">删除角色</page-button>
            <page-button el-type="success" :disabled="!currentRole.id" @click="toShowGrantAllModule">全部授权</page-button>
            <page-button el-type="success" :disabled="!currentRole.id" @click="showShowGrantCopy">复制权限</page-button>
            <page-button el-type="primary" :disabled="!currentModule.id" @click="saveGrant">保存授权</page-button>
            <page-button el-type="danger" :disabled="!currentModule.accessId" @click="deleteGrant">删除授权</page-button>
          </form-page>
        </list-page>
      </template>
    </separate-page>
    <el-dialog v-model="showEditRole" width="400px" title="角色编辑" :close-on-click-modal="false">
      <el-form label-width="100px">
        <el-form-item label="角色名称">
          <el-input v-model="editRole.roleName" placeholder="请输入角色名称"/>
        </el-form-item>
        <el-form-item label="角色分类">
          <el-select v-model="editRole.roleType" placeholder="请选择角色分类">
            <el-option :value="1" label="超级管理员"/>
            <el-option :value="2" label="普通角色"/>
          </el-select>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" v-model="editRole.roleDescription"/>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <page-button @click="showEditRole = false">取消</page-button>
        <page-button el-type="primary" @click="saveRole">保存</page-button>
      </div>
    </el-dialog>
    <el-dialog v-model="showCopyRight" title="复制授权" width="30%">
      <el-form label-width="70px">
        <el-form-item label="来源角色">
          <el-select v-model="copyRight.from">
            <el-option v-for="role in roleList" :value="role.id" :label="role.roleName"/>
          </el-select>
        </el-form-item>
        <el-form-item label="目标角色">
          <el-select v-model="copyRight.to">
            <el-option v-for="role in roleList" :value="role.id" :label="role.roleName"/>
          </el-select>
        </el-form-item>
        <div style="text-align: right">
          <page-button @click="showCopyRight = false">取消</page-button>
          <page-button el-type="primary" type="write" @click="saveCopyRight">保存</page-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog v-model="showAllGrantModule" title="全部授权" width="30%">
      <el-form label-width="70px">
        <el-form-item label="授权角色">
          <el-select v-model="copyRight.roleId">
            <el-option v-for="role in roleList" :value="role.id" :label="role.roleName"/>
          </el-select>
        </el-form-item>
        <el-form-item label="数据权限">
          <el-select v-model="copyRight.right">
            <el-option label="自己的" :value="1"/>
            <el-option label="部门及下级" :value="2"/>
            <el-option label="全部" :value="3"/>
          </el-select>
        </el-form-item>
        <div style="text-align: right">
          <page-button @click="showAllGrantModule = false">取消</page-button>
          <page-button el-type="primary" type="write" @click="grantAllModule">保存</page-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SeparatePage from "@/components/h5/layout/SeparatePage";
import roleApi from "@/api/main/merchant/roleApi";
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";

import AccessModuleComponent from "@/views/main/plat/system/role/AccessModuleComponent";
import PageButton from "@/components/h5/button/PageButton";
export default {
  props: {
    merchantId: {default: ""}
  },
  name: "",
  components: {
    PageButton,
    SeparatePage, AccessModuleComponent, ListPage, TableList, FormPage
  },
  data() {
    return {
      roleList: [],
      currentRole: {
        roleName: "",
      },
      // 全部的功能
      roleModules: [],
      allModules: [],
      showEditRole: false,
      editRole: {
        roleName: "",
        roleDescription: "",
        roleType: 2,
      },
      // 系统支持的全部的权限
      rightList: [],
      // 当前选中编辑的权限
      currentModule: {

      },
      copyRight: {
        from: "",
        to: "",
        right: 3,
        roleId: "",
      },
      // 复制授权
      showCopyRight: false,
      // 全部授权
      showAllGrantModule: false,
    };
  },
  created() {
    // 查询全部的功能
    roleApi.queryAllModules(this.merchantId).then(list => {
      this.allModules = list;
      this.queryAllRoles();
    });
    roleApi.queryAccessRight().then(list => this.rightList = list);
  },
  methods: {
    deleteRole() {
      roleApi.delRole(this.currentRole.id).then(() => {
        this.queryAllRoles();
      })
    },
    // 查询角色列表
    queryAllRoles() {
      roleApi.queryAllRoles(this.merchantId).then(list => {
        this.roleList = list;
        // 选中第一个
        if (this.roleList.length > 0) {
          this.selectRole(this.roleList[0]);
        }
      });
    },
    // 保存角色基本信息
    saveRole() {
      let data = {...this.editRole};
      if (this.merchantId) {
        data.merchantId = this.merchantId;
      }
      roleApi.saveRole(data).then(() => {
        this.queryAllRoles();
        this.showEditRole = false;
      });
    },
    // 删除授权
    deleteGrant() {
      let id = this.currentModule.accessId;
      if (!id) {
        this.$message.error("尚未添加此功能的授权，不需要删除")
        return;
      }
      roleApi.deleteGrant(id).then(() => {
        this.selectRole(this.currentRole);
      });
    },
    // 保存授权
    saveGrant() {
      // 获取到所有的权限
      let data = {
        id: this.currentModule.accessId,
        moduleId: this.currentModule.id,
        roleId: this.currentRole.id,
        accessModule: this.currentModule.accessModule,
      };
      roleApi.saveGrant(data).then(() => {
        this.selectRole(this.currentRole);
      });
    },

    getAllGrantInfo(list, result) {
      for (let i = 0; i < list.length; i++) {
        // 如果有授权，就添加
        if (list[i].accessModule) {
          result.push({moduleId: list[i].id, accessModule: list[i].accessModule, moduleName: list[i].moduleName});
        }
        if (list[i].children && list[i].children.length > 0) {
          this.getAllGrantInfo(list[i].children, result);
        }
      }
    },
    // 选中某个角色
    selectRole(role) {
      this.currentRole = role;
      // 选中某个角色，查询这个角色被授权的数据
      roleApi.queryGrantModules(role.id).then(list => {
        // 查询到的list，添加权限
        this.roleModules = this.getModuleAccess(this.allModules, list);
      });
    },
    // 角色授权功能的信息
    getModuleAccess(modules, list) {
      let arr = [];
      for (let i = 0; i < modules.length; i++) {
        let contains = false;
        let accessModule = "";
        let accessId = "";
        // 是否被授权
        for (let j = 0; j < list.length; j++) {
          if (modules[i].id === list[j].moduleId) {
            contains = true;
            accessModule = list[j].accessModule;
            accessId = list[j].id;
            break;
          }
        }
        let children = [];
        // 半选中
        let indeterminate = false;
        if (modules[i].children && modules[i].children.length > 0) {
          children = this.getModuleAccess(modules[i].children, list);
          if (children.length > 0) {
            for (let j = 0; j < children.length - 1; j++) {
              indeterminate = !!children[j].accessModule !== !!children[j+1].accessModule;
              if (indeterminate) {
                break;
              }
            }
          }
        }
        arr.push({
          ...modules[i],
          accessModule: accessModule,
          children: children,
          indeterminate: indeterminate, // 半选中状态
          selected: contains, // 是否选中
          accessId: accessId,
        })
      }
      return arr;
    },

    toEditRole(data) {
      this.editRole = {...data};
      this.showEditRole = true;
    },
    clickModule(data) {
      if (data.children && data.children.length > 0) {
        return;
      }
      this.currentModule = {...data}
      this.$refs.moduleAccessComponent.initData();
    },
    showShowGrantCopy() {
      this.copyRight.from = this.currentRole.id;
      this.copyRight.to = "";
      this.showCopyRight = true;
    },
    saveCopyRight() {
      if (!this.copyRight.from || !this.copyRight.to) {
        this.$message.error("请选择要复制的来源和目标角色");
        return;
      }
      roleApi.copyGrant(this.copyRight.from, this.copyRight.to).then(() => {
        this.$message.success("保存成功")
        this.showCopyRight = false;
      });
    },
    toShowGrantAllModule() {
      this.copyRight.roleId = this.currentRole.id;
      this.copyRight.right = 3;
      this.showAllGrantModule = true;
    },
    // 全部授权
    grantAllModule() {
      roleApi.allGrant(this.copyRight.roleId, this.copyRight.right, this.merchantId).then(() => {
        this.$message.success("保存成功");
        this.showAllGrantModule = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/table.scss";
@import "@/assets/css/components/tree.scss";
.role-list{
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
}
.role-list.on{
  color: #409EFF;
}
.role-info{
  text-align: right;
}
.role-grant{
  height: calc(100% - 60px);
  overflow: auto;
}
.module{
  border-bottom: 1px solid #eaeaea;
  padding: 20px;
  .mo-info{
    line-height: 28px;
    .mo-title{
      display: inline-block;
      width: 220px;
    }
    .mo-content{
      display: inline-block;
    }
    .weight{
      font-weight: 600;
    }
  }
  .mo-info:nth-child(2n+1){
    background: #fafafa;
    border-bottom: 1px solid #fafafa;
  }
}
</style>