<template>
  <page-button el-type="primary" v-if="isCustomer === 1" @click="toShowDetail" :disabled="disabled">验光单</page-button>
  <table-button el-type="primary" v-else-if="isCustomer === 2" @click="toEdit">编辑</table-button>
  <page-button el-type="success" v-else-if="isCustomer === 3" @click="toEdit">新建</page-button>
  <!-- 泰康业务组件，验光单 -->

    <el-dialog title="验光单" v-model="showYanGuangDan" width="60%" append-to-body>
      <dialog-table>
        <div style="height: 100%;position: relative;padding-top: 133px;box-sizing: border-box">
          <div style="position: absolute;left: 0;top: 0;width: 100%">
            <el-descriptions :column="2" border style="margin: 0" class="desc-info">
              <el-descriptions-item v-if="isCustomer !== 3" label="客户名称"><div class="position-abs">{{detailInfo.customerName || customerInfo.customerName}}</div></el-descriptions-item>
              <el-descriptions-item v-if="isCustomer === 3" label="客户名称">
                <customer-select :cus-title="'请输入客户名称'" v-model="detailInfo.customerId" :name="detailInfo.customerName" @selectOne="setCustomer"/>
              </el-descriptions-item>
              <el-descriptions-item label="联系电话" v-if="isCustomer !== 3">
                {{detailInfo.customerPhone ? detailInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') : '空'
                    ||
                customerInfo.customerPhone ? customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') : '空'}}
              </el-descriptions-item>
              <el-descriptions-item label="联系电话" v-if="isCustomer === 3"><div class="position-abs" style="top: 13px">{{detailInfo.customerPhone ? detailInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') : '空'}}</div></el-descriptions-item>
              <el-descriptions-item label="验光单号"><div class="position-abs">{{detailInfo.id}}</div></el-descriptions-item>
              <el-descriptions-item label="验光时间">
                <div class="position-abs">{{detailInfo.createdTime}}</div>
              </el-descriptions-item>
              <el-descriptions-item label-class-name="desc-left-right" class-name="desc-left-right" label="右眼(R)"/>
              <el-descriptions-item label-class-name="desc-left-right" class-name="desc-left-right" label="左眼(L)"/>

            </el-descriptions>

          </div>
          <!--        style="height: 100%;overflow: auto"-->
          <div class="hide-scrollbar">
            <el-descriptions :column="2" border>
              <el-descriptions-item label="球面度数">
                <el-input v-model="detailInfo.leftSphericalDegree"/>
              </el-descriptions-item>
              <el-descriptions-item label="球面度数">
                <el-input v-model="detailInfo.rightSphericalDegree"/>
              </el-descriptions-item>
              <el-descriptions-item label="柱面度数">
                <el-input v-model="detailInfo.leftCylinderDegree"/>
              </el-descriptions-item>
              <el-descriptions-item label="柱面度数">
                <el-input v-model="detailInfo.rightCylinderDegree"/>
              </el-descriptions-item>
              <el-descriptions-item label="轴向">
                <el-input v-model="detailInfo.leftAxial"/>
              </el-descriptions-item>
              <el-descriptions-item label="轴向">
                <el-input v-model="detailInfo.rightAxial"/>
              </el-descriptions-item>
              <el-descriptions-item label="近瞳距">
                <el-input v-model="detailInfo.leftClosePupilDis"/>
              </el-descriptions-item>
              <el-descriptions-item label="近瞳距">
                <el-input v-model="detailInfo.rightClosePupilDis"/>
              </el-descriptions-item>
              <el-descriptions-item label="远瞳距">
                <el-input v-model="detailInfo.leftRemotePupilDis"/>
              </el-descriptions-item>
              <el-descriptions-item label="远瞳距">
                <el-input v-model="detailInfo.rightRemotePupilDis"/>
              </el-descriptions-item>
              <el-descriptions-item label="棱镜">
                <el-input v-model="detailInfo.leftPrism"/>
              </el-descriptions-item>
              <el-descriptions-item label="棱镜">
                <el-input v-model="detailInfo.rightPrism"/>
              </el-descriptions-item>
              <el-descriptions-item label="基底">
                <el-input v-model="detailInfo.leftBasement"/>
              </el-descriptions-item>
              <el-descriptions-item label="基底">
                <el-input v-model="detailInfo.rightBasement"/>
              </el-descriptions-item>
              <el-descriptions-item label="原光度">
                <el-input v-model="detailInfo.leftOriginalLum"/>
              </el-descriptions-item>
              <el-descriptions-item label="原光度">
                <el-input v-model="detailInfo.rightOriginalLum"/>
              </el-descriptions-item>
              <el-descriptions-item label="全矫光度">
                <el-input v-model="detailInfo.leftFullCorRange"/>
              </el-descriptions-item>
              <el-descriptions-item label="全矫光度">
                <el-input v-model="detailInfo.rightFullCorRange"/>
              </el-descriptions-item>
              <el-descriptions-item label="曲率">
                <el-input v-model="detailInfo.c"/>
              </el-descriptions-item>
              <el-descriptions-item label="曲率">
                <el-input v-model="detailInfo.rightCurvature"/>
              </el-descriptions-item>
              <el-descriptions-item label="裸视力">
                <el-input v-model="detailInfo.leftNakedVision"/>
              </el-descriptions-item>
              <el-descriptions-item label="裸视力">
                <el-input v-model="detailInfo.rightNakedVision"/>
              </el-descriptions-item>
              <el-descriptions-item label="矫正视力">
                <el-input v-model="detailInfo.leftCorVision"/>
              </el-descriptions-item>
              <el-descriptions-item label="矫正视力">
                <el-input v-model="detailInfo.rightCorVision"/>
              </el-descriptions-item>
              <el-descriptions-item label="ADD">
                <el-input v-model="detailInfo.c"/>
              </el-descriptions-item>
              <el-descriptions-item label="ADD">
                <el-input v-model="detailInfo.rightAdd"/>
              </el-descriptions-item>
              <el-descriptions-item label="瞳高">
                <el-input v-model="detailInfo.leftPupilHeight"/>
              </el-descriptions-item>
              <el-descriptions-item label="瞳高">
                <el-input v-model="detailInfo.rightPupilHeight"/>
              </el-descriptions-item>
              <el-descriptions-item label="隐斜">
                <el-input v-model="detailInfo.leftHiddenOblique"/>
              </el-descriptions-item>
              <el-descriptions-item label="隐斜">
                <el-input v-model="detailInfo.rightHiddenOblique"/>
              </el-descriptions-item>

            </el-descriptions>
          </div>
        </div>
      </dialog-table>
      <div style="text-align: right;padding-top: 10px" v-if="!disabled">
        <!--      <page-button el-type="danger" @click="toRemove">移除</page-button>-->
        <page-button el-type="info" @click="showYanGuangDan = false">取消</page-button>
        <page-button el-type="success" @click="copyAndCreateNew">复制新建</page-button>
        <page-button el-type="success" type="write" @click="toAddNew">新建</page-button>
        <page-button el-type="primary" type="write" @click="toSetPrint">打印</page-button>
        <page-button el-type="primary" type="write" @click="save">保存</page-button>
      </div>
    </el-dialog>
    <el-dialog title="验光单列表" v-model="showSelect" width="60%" append-to-body>
      <dialog-table>
        <el-table stripe height="100%" :data="sheetList">
          <el-table-column label="序号"  type="index" width="60px" :align="'center'"/>
          <el-table-column label="客户名称" show-overflow-tooltip :align="'center'" min-width="150px">
            <template v-slot="{row}">{{row.customerName}}</template>
          </el-table-column>
          <el-table-column label="验光单号" prop="id" show-overflow-tooltip :align="'center'" min-width="150px">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="selectDetail2Show(row)">{{row.id}}</table-button>
            </template>
          </el-table-column>
          <el-table-column label="验光时间" prop="createdTime" show-overflow-tooltip :align="'center'" min-width="150px"/>
        </el-table>
      </dialog-table>
      <div style="padding-top: 10px;text-align: right">
        <page-button el-type="info" @click="showSelect = false">取消</page-button>
        <page-button el-type="success" @click="toAddNew">新建</page-button>
      </div>
    </el-dialog>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import YanGuangDanApi from "@/api/main/order/yanguangdan/YanGuangDanApi";
import DialogTable from "@/components/h5/layout/DialogTable";
import TableButton from "@/components/h5/button/TableButton";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import memberApi from "@/api/main/customer/memberApi";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";

export default {
  emits: ["removeYGDid", "addYGDid"],
  props: {
    ygdId: {default: ""},
    customerId: {default: ""},  //用户id
    customerInfo: {default: () => {}},
    disabled: {default: false},
    // 1：订单   2：表格编辑   3：页头添加
    isCustomer: {default: 1},
  },
  name: "YanGuangDan",
  components: {
    CustomerSelect,
    TableButton,
    PageButton, DialogTable
  },
  data() {
    return {
      showSelect: false,
      showYanGuangDan: false,
      // 验光单列表
      sheetList: [],
      // 一条验光单
      detailInfo: {},
    };
  },
  created() {

  },
  methods: {
    setCustomer(data){
      this.detailInfo.customerId = data.id;
      this.detailInfo.customerName = data.customerName;
      this.detailInfo.customerPhone = data.customerPhone;
    },
    toEdit(){
      if(this.isCustomer === 2){
        // 表格添加时做的回显操作
        YanGuangDanApi.findById(this.ygdId).then(res=>{
          this.detailInfo = {...res,...this.detailInfo};
          this.showYanGuangDan = true;
        })
      }else if(this.isCustomer === 3){
        // 页头添加时不需要回显
        this.showYanGuangDan = true;
      }
    },
    toRemove() {
      this.showYanGuangDan = false;
      this.$emit("removeYGDid", this.detailInfo.id);
    },
    toAddNew() {
      this.detailInfo = {customerId: this.customerId,customerName : this.detailInfo.customerName, customerPhone: this.detailInfo.customerPhone}
      this.showSelect = false;
      this.showYanGuangDan = true;
    },
    copyAndCreateNew() {
      this.detailInfo = {
        ...this.detailInfo,
        id: "",
        createdTime: ""
      }
    },
    //保存
    save(){
      this.handleCustomerId();
      let data = {
        ...this.detailInfo,
        customerId: this.customerId ? this.customerId : this.detailInfo.customerId,
      };
      YanGuangDanApi.save(data).then((d)=>{
        this.detailInfo = d;
        this.selectYanGuangDan();
      });
    },
    selectDetail2Show(data) {
      this.detailInfo = data;
      this.showSelect = false;
      this.showYanGuangDan = true;
    },
    // 从列表选中一条数据
    selectDetail(data) {
      this.$emit("addYGDid", data.id);
      this.showSelect = false;
    },
    selectYanGuangDan() {
      this.showYanGuangDan = false;
      this.$emit("addYGDid", this.detailInfo.id);
    },
    //加入客户id
    handleCustomerId(){
      this.sheetList.forEach(item => {
        item.customerId = this.customerId;
      })
    },
    toShowDetail() {
      if (!this.customerId) {
        this.$message.error("请先选择客户");
        return;
      }
      // 查询验光单
      YanGuangDanApi.queryByCustomer(this.customerId).then(res => {
        this.sheetList = res;
        if (this.sheetList.length === 1) {
          // 只有一条数据，直接显示
          this.detailInfo = this.sheetList[0];
          this.showYanGuangDan = true;
        } else if (this.sheetList.length > 1) {
          // 多条数据，选择
          this.showSelect = true;
        } else {
          this.detailInfo = {customerId: this.customerId}
          this.showYanGuangDan = true;
        }
      });
    },
    showYanGuangDanDetail(id) {
      YanGuangDanApi.findById(id).then(data => {
        this.detailInfo = data;
        this.showYanGuangDan = true;
      })
    },


    async toSetPrint(){
      const customerInfo = await memberApi.findById(this.detailInfo.customerId);
      let data = this.getPrintData("design",customerInfo.customerName + customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    async toPrint(){
      const customerInfo = await memberApi.findById(this.detailInfo.customerId);
      let data = this.getPrintData("print",customerInfo.customerName + customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    getPrintData(type,customerName){
      let data = {
        ...this.detailInfo,
        ygdId: this.detailInfo.id,
        ygdTime: this.detailInfo.createdTime,
        reportName: "验光单",
        customerName: customerName,
        excuteType: type,
      }
      let arr = [];
      data.detail = arr;
      return data;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description.scss";
@import "@/assets/css/el-tables";
.el-input__inner{
  text-align: center;
}

.seperator-container{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .left{
    width: 50%;
    box-sizing: border-box;
    /* border-right: 1px solid #ccd3e2; */
    background: #FFF;
    color: #000;
    font-weight: 600;
    padding-left: 10px;
  }

  .right{
    width: 50%;
    box-sizing: border-box;
    /* border-right: 1px solid #ccd3e2; */
    background: #FFF;
    color: #000;
    font-weight: 600;
    padding-left: 10px;
  }
}

.hide-scrollbar{
  height: 100%;
  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { /* WebKit浏览器（如Chrome和Safari） */
    display: none;
  }
  overflow-y: scroll; /* 保持内容可垂直滚动 */
}
.desc-info{
  :deep(.el-descriptions__table) {
    .desc-left-right{
      background: #FFF;
      border: none !important;
      padding-left: 0 !important;
      line-height: 36px;
    }
  }
}
.position-abs{
  position: absolute;
  top: 8px;
}
</style>