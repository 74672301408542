<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header title="销售退单">
        <template #default>
          <page-button v-if="showEditBtn" @click="editDataInfo" el-type="primary">{{ editWord }}</page-button>
          <page-button el-type="success" @click="reloading" v-if="!isDraftUpdate">新建</page-button>
        </template>
      </page-header>

      <table-list>
        <div :class="showAll
                        ? (dataDetail.status === 99 || isDraftUpdate) ? 'top-all-suc' : 'top-all'
                        : (dataDetail.status === 99 || isDraftUpdate) ? 'top-part-suc' : 'top-part'">
          <el-descriptions :column="3" border size="small">
            <el-descriptions-item label="客户">
              <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.customerName }}</span>
              <customer-select v-else :disabled="dataDetail.status > 0" v-model="dataDetail.customerId" :name="dataDetail.customerName" @selectOne="setCustomer"/>
            </el-descriptions-item>

            <el-descriptions-item label="客户电话" label-class-name="require">
              <span>{{ dataDetail.customerPhone ? dataDetail.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2') : '空' }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="经办人">
              <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.employeeName }}</span>
              <employee-select v-else :disabled="dataDetail.status > 0" v-model="dataDetail.employeeId"
                               @selectOne="setEmployee"/>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="部门">
              <dictionary :disabled="dataDetail.status > 0" v-model="dataDetail.departCode" @change-data="changeDepart"
                          :dictionaries="departList"
                          dict-code="departCode"
                          dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="仓库">
              <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.stockName }}</span>
              <el-select v-else :disabled="dataDetail.status > 0" v-model="dataDetail.stockId" style="width: 100%">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="单据日期">
              <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.orderTime }}</span>
              <el-date-picker v-else style="width: 100%"
                              v-model="dataDetail.orderTime"
                              type="datetime"
                              placeholder="选择时间"
              />
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="订单编号">
              {{ dataDetail.id }}
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="创建人">
              {{ dataDetail.createdName }}
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="退款人">
              {{ dataDetail.payeeName }}
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="备注">
              <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.remark }}</span>
              <el-input v-else :disabled="dataDetail.status > 0" v-model="dataDetail.remark" type="textarea"
                        class="el-input-text"/>
            </el-descriptions-item>

          </el-descriptions>
          <div style="display: flex;justify-content: space-between;">
            <table-button @click="showAll = !showAll" style="margin-top: 8px">
              <el-icon :style="{transform: showAll? 'rotateZ(0deg)': 'rotateZ(180deg)'}">
                <el-icon-arrow-down/>
              </el-icon>
              {{ showAll ? "收起" : "展开" }}
            </table-button>
            <div v-if="dataDetail.status !== 99 && !isDraftUpdate"
                 style="display: inline-block;width: 500px;padding-bottom: 10px">
              <product-list-select @select="selectProduct" @selectOne="selectOneProduct"
                                   :selected-product-list="dataDetail.productList" :show-price="true" :is-depart="true"
                                   :show-cost="true"/>
            </div>
          </div>
        </div>

        <div :class="showAll
                        ? (dataDetail.status === 99 || isDraftUpdate) ? 'table-all-suc' : 'table-all'
                        : (dataDetail.status === 99 || isDraftUpdate) ? 'table-part-suc' : 'table-part'">

          <el-table stripe :data="dataDetail.productList" height="100%" show-summary :summary-method="getSummaries">
            <el-table-column label="序号" width="60px" type="index" fixed/>
            <el-table-column align="center" show-overflow-tooltip min-width="150px" prop="skuName" label="商品名称"
                             fixed/>
            <el-table-column align="center" show-overflow-tooltip prop="barCode" label="条码"/>
            <el-table-column prop="skuUnitName" label="单位" :align="'center'" width="60px"/>

            <el-table-column label="销售价" :align="'center'" width="100px">
              <template v-slot="{row}">
                {{ row.skuSalePrice }}
              </template>
            </el-table-column>

            <el-table-column label="订单数量" :align="'center'" width="100px">
              <template v-slot="{row}">
                {{ row.skuAllQty }}
              </template>
            </el-table-column>

            <el-table-column label="退货价" :align="'center'" width="100px">
              <template v-slot="{row}">
                <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ row.skuRefundPrice }}</span>
                <el-input v-else v-model="row.skuRefundPrice" :disabled="dataDetail.payStatus >= 50"
                          @input="updateOrderAmount"/> <!--:min="0" :max="row.skuSalePrice" type="number"-->
              </template>
            </el-table-column>

            <el-table-column label="退货数量" :align="'center'" width="150px">
              <template v-slot="{row}">
                <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ row.qty }}</span>
                <el-input-number style="width: 120px" v-else v-model="row.qty" :disabled="dataDetail.payStatus >= 50"
                                 :min="0" :max="row.skuAllQty ?row.skuAllQty - row.refundQty : 999999999999999 "
                                 @change="updateOrderAmount"/>
              </template>
            </el-table-column>

            <el-table-column label="已退数量" :align="'center'" width="100px"
                             v-if="dataDetail.status === 0 && !isDraftUpdate">
              <template v-slot="{row}">
                <el-input v-model="row.refundQty" disabled type="number"/>
              </template>
            </el-table-column>

            <el-table-column label="退货金额" :align="'right'" width="120px">
              <template v-slot="{row}">
                <money :value="row.skuRefundPrice * row.qty"/>
              </template>
            </el-table-column>

            <el-table-column v-if="dataDetail.status === 0 && !isDraftUpdate" label="操作" :align="'center'"
                             width="80px" fixed="right">
              <template #default="scope">
                <table-button el-type="danger" :message="'确定移除该条订单商品？'" @click="deleteRow(scope.row)">删除
                </table-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <template #left>
          <div class="custom-info">
            <div>
              <span style="margin-right: 20px">已退款金额:<money :value="alreadyRefundAmount"
                                                                 :show-color="true"/></span>
              <span>应退合计:  </span>
              <money v-if="isDraftUpdate" :value="dataDetail.amount" :show-color="true"/>
              <el-input v-if="!isDraftUpdate" type="number" style="width: 100px;height: 25px"
                        :max="originalDetail.amountCollect" :disabled="dataDetail.payStatus >= 50"
                        v-model="dataDetail.amount" @input="updateOrderData"/>
            </div>
          </div>
        </template>
        <template #default>
          <div style="display: flex;flex-direction: row;line-height: 32px;font-size: 14px;">
<!--            <page-button v-if="!isDraftUpdate" type="write" el-type="primary" @click="toShowOrder"-->
<!--                         :disabled="dataDetail.status === 99">结算-->
<!--            </page-button>-->
            <page-button v-if="!isDraftUpdate" type="write" el-type="success" @click="toSaveDraft"
                         :disabled="dataDetail.status === 99">提交
            </page-button>
            <page-button v-if="!isDraftUpdate" el-type="success" @click="toShowAllOrder" style="padding-bottom: 10px"
                         :disabled="dataDetail.status === 99">调入订单
            </page-button>
            <el-dropdown split-button type="primary" @click="toPrint" @command="handlerSelect" style="margin-top: 1px">
              打印
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :icon="Edit" command="setPrint">打印设置</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </form-page>
    </list-page>

    <el-dialog v-model="showAllOrder" title="订单列表">
      <search-condition @search="resetSearch" :show-search="true">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
        </template>
        <template #moreCondition>
          <el-input style="width: 240px" ref="orderNumberInput" v-model="searchInfo.orderId"
                    placeholder="请输入订单编号"
                    @change="resetSearch"/>
          <div style="display: inline-block; width: 240px" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>
        </template>
      </search-condition>
      <dialog-table>
        <el-table stripe :data="dataList" height="100%">
          <el-table-column align="center" label="序号" type="index" width="60px"/>
          <el-table-column align="center" label="订单编号" prop="id" show-overflow-tooltip/>
          <el-table-column align="center" label="客户名称" prop="customerName"/>
          <el-table-column align="center" label="订单状态">
            <template v-slot="{row}">
              <el-tag v-if="row.refundQty === 0" type="info">未退款</el-tag>
              <el-tag v-else-if="row.refundQty < row.qty && row.refundQty !== 0" el-type="primary">部分退款</el-tag>
              <el-tag v-else-if="row.refundQty === row.qty" type="success">退款完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单金额" prop="amount"/>
          <el-table-column align="center" label="下单时间" prop="createdTime" show-overflow-tooltip/>
          <el-table-column align="center" label="支付时间" show-overflow-tooltip>
            <template v-slot="{row}">
              <span>{{ row.payTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="addRefund(row)" :disabled="row.refundQty === row.qty"> 选中
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" :default-load="false" @onLoad="queryAllOrder"></form-page>
    </el-dialog>

    <el-dialog v-model="showOrder" :close-on-click-modal="false" width="40%" height="390px"
               title="保存单据">
      <dialog-table :auto-height="true">
        <el-form label-width="70px">
          <div style="height: 100%">
            <el-form-item label="成交金额">
              <el-input disabled v-model="allAmount"/>
            </el-form-item>
            <el-form-item label="应退合计">
              <el-input @input="updateOrderData" v-model="dataDetail.amount"/>
            </el-form-item>
            <el-form-item label="数量合计">
              <el-input disabled v-model="dataDetail.qty"/>
            </el-form-item>
            <div>
              <el-form-item :label="index === 0 ? '退款方式' : ''" v-for="(item,index) in accountRefundList"
                            :key="item.payTypeId + new Date()">
                <div style="display: flex;flex-direction: row;width: 100%;">
                  <el-select style="width: 47%" v-model="item.payTypeId" placeholder="请选择支付方式"
                             clearable :disabled="dataDetail.payStatus >= 50">
                    <el-option v-for="(st, i) in payTypeList" :key="i + 'p'" :label="st.payName" :value="st.id"/>
                  </el-select>
                  <el-input style="width: 47%" v-model="item.refundCurrent" :disabled="dataDetail.payStatus >= 50"/>
                  <page-button el-type="success" v-if="index === this.accountRefundList.length - 1" @click="addAccount"
                               style="width: 6%">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                  </page-button>
                  <page-button el-type="danger" v-else @click="reduceAccount(index)" style="width: 6%">
                    <el-icon>
                      <Minus/>
                    </el-icon>
                  </page-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </dialog-table>
      <div style="display: flex;justify-content: space-between">
        <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large"/>
        <div>
          <page-button @click="showOrder = false">取消</page-button>
          <page-button el-type="primary" :loading="preparePay" @click="submitRefund" :disabled="disableToClickPay">
            确认
          </page-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="showDraft" :close-on-click-modal="false" width="40%" height="310px"
               title="提交单据">
      <dialog-table :auto-height="true">
        <el-form label-width="70px">
          <div style="height: 100%">
            <el-form-item label="成交金额">
              <el-input disabled v-model="allAmount"/>
            </el-form-item>
            <el-form-item label="应退合计">
              <el-input @input="updateOrderData" v-model="dataDetail.amount"/>
            </el-form-item>
            <el-form-item label="数量合计">
              <el-input disabled v-model="dataDetail.qty"/>
            </el-form-item>
            <div>
              <el-form-item :label="index === 0 ? '退款方式' : ''" v-for="(item,index) in accountRefundList"
                            :key="item.payTypeId + new Date()">
                <div style="display: flex;flex-direction: row;width: 100%">
                  <el-select style="width: 47%" v-model="item.payTypeId" placeholder="请选择支付方式"
                             clearable>
                    <el-option v-for="(st, i) in payTypeList" :key="i + 'p'" :label="st.payName" :value="st.id"/>
                  </el-select>
                  <el-input style="width: 47%" v-model="item.refundCurrent"/>
                  <page-button el-type="success" v-if="index === this.accountRefundList.length - 1" @click="addAccount"
                               style="width: 6%">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                  </page-button>
                  <page-button el-type="danger" v-else @click="reduceAccount(index)" style="width: 6%">
                    <el-icon>
                      <Minus/>
                    </el-icon>
                  </page-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </dialog-table>
      <div style="display: flex;justify-content: space-between;margin-top: -5px">
        <el-checkbox v-model="isAutoPrint" label="打印退单凭证" size="large"/>
        <div>
          <page-button @click="showDraft = false">取消</page-button>
          <page-button el-type="primary" :loading="preparePay" @click="saveDraft" :disabled="disableToClickPay">
            确认
          </page-button>
        </div>
      </div>
    </el-dialog>


  </div>


</template>

<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import Money from "@/components/h5/Money.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import RefundOrderApi from "@/api/main/order/online/RefundOrderApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import util from "@/util/util";
import {h} from "vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import {Edit, Minus, Plus} from "@element-plus/icons-vue";
import memberApi from "@/api/main/customer/memberApi";
import localPrint from "@/util/new/LocalPrint";

export default {
  name: "offlineOrder",
  components: {
    Minus,
    Plus,
    PayTypeSelect,
    PageHeader,
    ProductListSelect,
    TableButton,
    PageButton,
    ListPage,
    TableList,
    FormPage,
    Dictionary,
    Money,
    CustomerSelect,
    EmployeeSelect,
    DateRange,
    DialogTable,
    SearchCondition
  },
  data() {
    return {
      isAutoPrint: true,
      showOrder: false,
      showAllOrder: false,
      searchInfo: {},
      showAll: false,
      dataDetail: {
        // payType: ,
        productList: [],
        attachmentList: [],
        orderTime: new Date().format("yyyy-MM-dd hh:mm:ss"),
        ygdId: "",
        echoStockId: "",//回显仓库时使用的id
        payList: [],
      },
      // 来源单据
      originalDetail: {},
      stockList: [],
      departList: [],
      payTypeList: [], //支付方式集合
      employeeList: [],
      dataList: [], //需要修改的数据
      currentAmount: null, //记录已退款金额和数量
      currentCount: null,
      isRefund: false,
      preparePay: false,
      disableToClickPay: false,
      isDraftUpdate: true, // 销售跳转过来，用来控制，页面修改权限
      showDraft: false,
      // 控制草稿修改按钮
      showEditBtn: true,
      editWord: "修改",
      isActual: false, // 判断是否是直接退款
      checkAmountTotal: 0, // 判断聚合是否超额
      alreadyRefundAmount: 0, // 订单已退款金额，部分退款成功时，使用该值做校验
      // 选择的退款账户集合
      accountRefundList: [],
    };
  },
  computed: {
    Edit() {
      return Edit
    },
    allAmount() {
      let price = 0; // 初始化 price 为 0
      this.dataDetail.productList.forEach(item => {
        price += item.skuSalePrice * item.skuAllQty;
      });
      // 减去抹零金额
      price = price - this.dataDetail.amountDiscountZero;
      return isNaN(price) ? 0 : price;
    }
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  created() {
    // 默认一个退款
    this.dataDetail.orderTime = new Date().format("yyyy-MM-dd hh:mm:ss");
    systemPayApi.queryAllList().then(res => this.payType = res);
    systemPayApi.queryAllList().then(res => this.payTypeList = res);
    //当前为新建
    if (!this.$routerUtil.query.id && !this.$routerUtil.query.isDraftUpdate) {
      this.isDraftUpdate = false;
      this.showEditBtn = false;
    }
    // 从订单收款页面跳转过来、限定无法修改数据
    if (this.$routerUtil.query.isDraftUpdate) {
      this.isDraftUpdate = true;
      this.showEditBtn = false;
    }

    // 从订单页面跳转过来、此时订单应该走调入订单的逻辑
    if (this.$routerUtil.query.id && this.$routerUtil.query.isFinish === "1") {
      let row = {
        id: this.$routerUtil.query.id
      };
      this.isDraftUpdate = false;
      this.showEditBtn = false;
      this.addRefund(row);
    }

    // 从退单页面跳转过来、此时订单应该走回显数据的逻辑
    if (this.$routerUtil.query.id && this.$routerUtil.query.isFinish === "2") {
      this.findById(this.$routerUtil.query.id);
    }
    // 查询仓库
    this.queryAllStockList();
    // 部门
    commonApi.queryDepartList().then(list => this.departList = list);
    //职员
    commonApi.queryAllEmployee().then(list => {
      this.employeeList = list;
    });
    //初始化时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  methods: {
    handlerSelect(command) {
      if (command === "setPrint") {
        this.toSetPrint();
      }
    },
    // openToRefund() {
    //   if (this.accountRefundList.length === 0) {
    //     // 获取原订单的最后一次支付方式
    //     offlineOrderApi.findFinalPayType(this.originalDetail.payId).then(res => {
    //       this.accountRefundList.push({payTypeId: res, refundCurrent: 0});
    //     })
    //   }
    // },
    // 添加一条退款方式
    addAccount() {
      this.accountRefundList.push({payTypeId: "", refundCurrent: 0});
    },
    // 删除一条退款方式
    reduceAccount(index) {
      this.accountRefundList.splice(index, 1)
    },
    changePay(data) {
      this.dataDetail.payTypeId = data.id;
      this.dataDetail.payType = data.payCode;
    },
    editDataInfo() {
      if (this.editWord === "修改") {
        this.isDraftUpdate = false;
        this.editWord = "取消";
        this.getSummaries();
      } else {
        this.findById(this.dataDetail.id).then(() => {
          this.isDraftUpdate = true;
          this.editWord = "修改";
          this.getSummaries();
        })
      }
    },
    toShowOrder() {
      if (this.accountRefundList.length === 0) {
        // 获取原订单的最后一次支付方式
        offlineOrderApi.findFinalPayType(this.originalDetail.payId).then(res => {
          this.accountRefundList.push({payTypeId: res, refundCurrent: 0});
          this.showOrder = true;
        }).catch(() => {
          systemPayApi.queryAllList().then(res => {
            this.$message.error("未查询到对应支付,请手动选择")
            this.accountRefundList.push({payTypeId: res[0].id, refundCurrent: 0});
            this.showOrder = true;
          })
        })
      } else {
        this.showOrder = true;
      }
    },

    toSaveDraft() {
      if (this.accountRefundList.length === 0) {
        // 获取原订单的最后一次支付方式
        offlineOrderApi.findFinalPayType(this.originalDetail.payId).then(res => {
          this.accountRefundList.push({payTypeId: res, refundCurrent: 0});
          this.showDraft = true;
        })
      } else {
        this.showDraft = true;
      }
    },
    reloading() {
      this.$routerUtil.push({path: "/report/refund-order/detail"});
    },
    changeDepart(data) {
      // 变更部门时，清空仓库
      this.dataDetail.stockId = "";
      // 根据code获得部门id、防止异步出现部门数据尚未返回变更为同步处理
      if (!this.departList || this.departList.length === 0) {
        commonApi.queryDepartList().then(res => {
          this.departList = res;
          let dep = this.findDepartByCode(data, this.departList);
          if (dep.id) {
            // 部门有效
            this.queryAllStockList2(dep.id)
          }
        })
      } else {
        let dep = this.findDepartByCode(data, this.departList);
        if (dep.id) {
          // 部门有效
          this.queryAllStockList2(dep.id)
        }
      }
    },
    findDepartByCode(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].departCode) {
          return list[i];
        }
        if (list[i].children && list[i].children.length > 0) {
          let dep = this.findDepartByCode(code, list[i].children);
          if (dep.id) {
            /// 找到数据
            return dep;
          }
        }
      }
      return {};
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.createdName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.customerId = "10000";
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2(user.mainDepartId)
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      offlineOrderApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 默认选中一个
        if (this.dataDetail.echoStockId !== null && this.dataDetail.echoStockId !== '') {
          this.dataDetail.stockId = this.dataDetail.echoStockId;
          this.dataDetail.echoStockId = "";
          return;
        }
        if (list.length > 0) {
          this.dataDetail.stockId = list[0].id;
        }
      });
    },
    createOrderDetail(data) {
      return {
        skuId: data.id,
        skuName: data.skuName,
        skuCode: data.skuCode,
        barCode: data.barCode,
        skuUnitId: data.skuUnitId,
        qty: 1,
        skuUnitName: data.skuUnitName,
        skuSalePrice: data.skuSalePrice,
        skuBuyPrice: data.skuBuyPrice,
        skuOriginalPrice: data.skuSalePrice,
        rate: 100,
      }
    },
    selectOneProduct(data) {
      // this.dataDetail.productList = [];
      // 商品列表是否有数据，没有已添加，有就数量+1
      let contains = false;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (this.dataDetail.productList[i].skuId === data.id) {
          this.dataDetail.productList[i].qty += 1;
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.productList.push(this.createOrderDetail(data));
      }
    },
    selectProduct(list) {
      // this.dataDetail.productList = [];
      let arr = [...this.dataDetail.productList];
      // 有哪些商品，看是否存在，不存在就添加
      for (let i = 0; i < list.length; i++) {
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === list[i].id) {
            // 存在，更新数量
            contains = true
            break;
          }
        }
        // 不存在，添加
        if (!contains) {
          arr.push(this.createOrderDetail(list[i]));
        }
      }
      this.dataDetail.productList = arr;
      // 更新数据
      this.updateOrderData();
      // 更新数量
      this.updateOrderAmount();
    },
    openBefore() {
      this.queryAllOrder();
    },
    // 编辑打印格式
    async toSetPrint() {
      localPrint.print("销售退单", this.getFieldPrint());
    },
    // 设置打印字段
    getFieldPrint() {
      return {
        reportName: "销售退单",
        amount: "合计金额",
        customerName: "客户名称",
        customerPhone: "客户手机号",
        orderNumber: "订单编号",
        orderTime: "订单时间",
        employeeName: "经办职员",
        createdName: "操作人员",
        countQty: "合计数量",
        departName: "部门名称",
        stockName: "仓库名称",
        payStatus: "支付状态",
        payType: "支付方式",
        amountTotal: "优惠前金额",
        payeeName: "收费人员",
        refundActual: "实退金额",
        discountAmount: "合计优惠",
        status: "订单状态",
        payTypeName: "账户名称",
        productList: {
          productIndex: "行号",
          productName: "商品名称",
          productNumber: "商品编号",
          productPrice: "商品原价",
          productCount: "商品数量",
          productTotalPrice: "小计",
          productDisCount: "商品折扣",
          productUnitName: "商品单位",
          productSalePrice: "商品单价",
        },
      };
    },
    setEmployee(data) {
      this.dataDetail.employeeName = data.employeeName;
    },
    findById(id, isEdit = false) {
      return RefundOrderApi.findRefundById(id).then(data => {
        this.dataDetail = data;
        this.dataDetail.echoStockId = data.stockId;
        // 过滤掉支付记录未成功的记录
        this.dataDetail.payList = data.payList.filter(item => item.payStatus >= 50);
        this.changeDepart(this.dataDetail.departCode);
        if (data.status === 99) {
          this.showEditBtn = false;
        }
        // 计算已退款金额
        if (!isEdit) {
          this.calcAlreadyRefundAmount();
        }
        //初始化退款数量;
        this.updateOrderData();
        // // 初始化账户
        this.accountRefundList = [];
        this.handlerRefundAccount();
      });
    },
    updateOrderData() {
      // 草稿状态，可以修改数据，此时限定可退款的商品数量
      if (this.dataDetail && !this.dataDetail.status) {
        // 有来源订单
        if (this.originalDetail && this.originalDetail.id) {
          for (let i = 0; i < this.dataDetail.productList.length; i++) {
            let skuId = this.dataDetail.productList[i].skuId;
            for (let j = 0; j < this.originalDetail.productList.length; j++) {
              if (skuId === this.originalDetail.productList[j].skuId) {
                this.dataDetail.productList[i].skuAllQty = this.originalDetail.productList[j].skuAllQty;
                break;
              }
            }
          }
        }
      }
      if (this.dataDetail.amount > (Number(this.originalDetail.amountCollect) - Number(this.alreadyRefundAmount))) {
        // this.$message.error("退单金额不能大于原订单已支付金额！！！")
        this.dataDetail.amount = (Number(this.originalDetail.amountCollect) - Number(this.alreadyRefundAmount));
      }
    },
    // 价格发生变更
    updateOrderAmount() {
      let amount = 0, qty = 0, originalAmount = 0;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        amount += this.dataDetail.productList[i].qty * this.dataDetail.productList[i].skuRefundPrice;
        // 避免string参与计算
        qty += this.dataDetail.productList[i].qty * 1;
        originalAmount += this.dataDetail.productList[i].qty * this.dataDetail.productList[i].skuOriginalPrice;
      }
      this.dataDetail.qty = qty;
      this.dataDetail.amountTotal = originalAmount;
      this.dataDetail.amount = amount - this.dataDetail.amountDiscountZero;
      if (this.dataDetail.amount > this.originalDetail.amountCollect) {
        // this.$message.error("退单金额不能大于原订单已支付金额！！！")
        this.dataDetail.amount = this.originalDetail.amountCollect;
      }
    },
    // 计算已退款金额
    calcAlreadyRefundAmount() {
      for (let i = 0; i < this.dataDetail.payList.length; i++) {
        // 已退款金额
        this.alreadyRefundAmount += this.dataDetail.payList[i].refundTotal;
        // 累加聚合金额
        if (this.dataDetail.payList[i].payType !== 1) {
          this.checkAmountTotal += Number(this.dataDetail.payList[i].currentAmount);
        }
      }
    },
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      this.dataDetail.customerPhone = data.customerPhone;
      this.dataDetail.customerAmount = data.customerAmount;
      this.dataDetail.customerAddress = data.address;
      // 备份一个customerInfo用于其它组件
      this.dataDetail.customerInfo = {...data};
    },
    // 组装打印数据
    async toPrint() {
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'));
      localPrint.print("销售退单", data);
    },
    //查询所有已完成订单
    queryAllOrder() {
      //获取客户id
      this.searchInfo.orderStatus = 99;  //查询已完成支付的订单
      this.searchInfo.payStatus = 99;
      this.searchInfo.orderType = 1;
      offlineOrderApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    //显示所有订单
    toShowAllOrder() {
      this.showAllOrder = true;
    },
    // 处理退货款账户
    handlerRefundAccount() {
      this.$nextTick(() => {
        let list = this.dataDetail.payList;
        for (let i = 0; i < list.length; i++) {
          if (list[i].refundCurrent && list[i].refundCurrent > 0) {
            this.accountRefundList.push({...list[i]});
          }
        }
      })
    },
    checkHasRefund(id) {
      offlineOrderApi.findHasRefundOrder(id).then(res => {
        if (res) {
          this.$routerUtil.push(`/report/refund-order/detail?id=${res}&isFinish=2`);
        }
      })
    },
    // 选择要退货的订单
    addRefund(row) {
      this.checkHasRefund(row.id);
      RefundOrderApi.findRefundById(row.id).then(data => {
        // 调入订单并初始化当前订单为一个草稿状态
        this.originalDetail = data;
        //初始化退款数量;
        let arr = [];
        for (let i = 0; i < data.productList.length; i++) {
          let p = data.productList[i];
          if (p.qty === p.refundQty) continue;
          arr.push({
            ...p,
            payStatus: 0,
            qty: p.skuAllQty - p.refundQty,
            allQty: p.qty - p.refundQty,
            // 初始退款价、默认为销售价格
            skuRefundPrice: p.skuSalePrice,
            id: "",
            orderId: "",
            payType: data.payType,
            payTypeId: data.payTypeId,
          })
        }
        this.dataDetail = {
          productList: arr,
          customerId: data.customerId,
          customerName: data.customerName,
          employeeId: data.employeeId,
          stockId: data.stockId,
          departCode: data.departCode,
          payTypeId: data.payTypeId,
          payType: data.payType,
          originalId: data.id,
          amount: data.amount,
          amountDiscountZero: data.amountDiscountZero,
          status: 0,
        }
        // 处理支付记录数据，排除掉支付未成功的支付记录
        // payList: data.payList,
        this.dataDetail.payList = data.payList.filter(item => item.payStatus >= 50);
        // 设置默认的经手人和制单人
        util.getUserInfo().then(res => {
          this.dataDetail.employeeName = res.employeeName
          this.dataDetail.createdName = res.employeeName
        })

        // 设置订单时间为当前时间
        this.dataDetail.orderTime = new Date().format("yyyy-MM-dd hh:mm:ss")

        // 变更订单金额
        this.updateOrderAmount();

        // 计算已退款金额
        this.calcAlreadyRefundAmount();

        this.dataDetail.echoStockId = data.stockId;
        this.changeDepart(this.dataDetail.departCode);

        // // 处理账户
        // this.handlerRefundAccount();
      });
      this.showAllOrder = false;
    },
    // 变更退货数量
    //全部退款
    deleteRow(row) {
      this.dataDetail.productList.splice(this.dataDetail.productList.indexOf(row), 1);
      this.updateOrderAmount();
      this.$message.success("移除成功！")
    },
    //提交退款
    submitRefund() {
      // 判断是否有0的
      for (let i = 0; i < this.accountRefundList.length; i++) {
        if (Number(this.accountRefundList[i].refundCurrent) === 0) {
          this.$message.error("已选择退款方式，不能包含0的退款金额");
          return;
        }
      }
      this.handlerAccountDraft();
      let isTrue = true;
      this.dataDetail.amount = Number(this.dataDetail.amount);
      // 检查退款金额
      let total = 0;
      let jhTotal = 0; // 聚合支付金额
      for (let i = 0; i < this.dataDetail.payList.length; i++) {
        total += Number(this.dataDetail.payList[i].refundCurrent);
        if (this.dataDetail.payList[i].payType !== 1) {
          jhTotal += Number(this.dataDetail.payList[i].refundCurrent);
        }
      }
      if (total === 0) {
        this.$message.error("退款金额不能为0");
        isTrue = false;
      }
      if (jhTotal > this.checkAmountTotal) {
        this.$message.error("聚合退款的金额不能大于支付金额, 聚合支付金额为：" + this.checkAmountTotal);
        isTrue = false;
      }
      // if (total > this.dataDetail.amount) {
      //   this.$message.error("请检查退款金额！！！");
      //   return;
      // }
      //检查是否选择了商品
      if (this.dataDetail.productList.length === 0) {
        this.$message.error("请选择商品");
        isTrue = false;
      }
      if (this.dataDetail.qty === 0) {
        this.$message.error("请添加退货商品");
        isTrue = false;
      }
      if (this.dataDetail.employeeId == null) {
        this.$message.error("请添加经办职员");
        isTrue = false;
      }
      if (!isTrue) {
        return;
      }
      this.preparePay = true;
      this.disableToClickPay = true;
      this.handleData();
      this.isActual = false;
      RefundOrderApi.saveDraft(this.dataDetail).then(data => {
        this.findById(data.id, true).then(() => {
          this.toSubmit();
        })
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      })
    },
    handlerAccountDraft() {
      // 最终上传后端的数据
      let acutalAccount = this.dataDetail.payList;
      // 客户id
      const customerId = acutalAccount[0].customerId;
      // 商户id
      const merchantId = acutalAccount[0].merchantId;
      // 订单id
      const orderId = this.dataDetail.originalId;
      // 退款的支付方式及金额
      let refundAccount = this.accountRefundList;
      // 将相同的支付方式的数据合并，放入到map中，保证payTypeId唯一
      let myAccountMap = new Map();
      // 过滤掉退款金额等于0的数据
      // refundAccount = refundAccount.filter(item => {
      //   return Number(item.refundCurrent) !== 0 && item.payTypeId;
      // })
      for (let i = 0; i < refundAccount.length; i++) {
        // 校验map中是否已存在当前支付方式
        if (myAccountMap.has(refundAccount[i].payTypeId)) {
          let account = myAccountMap.get(refundAccount[i].payTypeId);
          account.refundCurrent = Number(account.refundCurrent) + Number(refundAccount[i].refundCurrent);
          myAccountMap.set(refundAccount[i].payTypeId, account);
          // 移除账户数据中的重复账户
          refundAccount.splice(i, 1);
          // i--;
        } else {
          myAccountMap.set(refundAccount[i].payTypeId, refundAccount[i]);
        }
      }
      // 将账户数据与原始数据进行整合
      for (let i = 0; i < acutalAccount.length; i++) {
        let account = myAccountMap.get(acutalAccount[i].payTypeId);
        if (account) {
          acutalAccount[i].refundCurrent = account.refundCurrent;
          // 移除
          myAccountMap.delete(acutalAccount[i].payTypeId)
        } else {
          acutalAccount.splice(i, 1);
          i--;
        }
      }
      // 将未找到原始数据的账户直接添加到原始数据中
      for (const value of myAccountMap.values()) {
        let data = {
          payTypeId: value.payTypeId,
          refundCurrent: value.refundCurrent,
          orderId: orderId,
          payTime: new Date(),
          payStatus: 99,
          amount: 0,
          currentAmount: 0,
        }
        data.customerId = customerId;
        data.merchantId = merchantId;
        this.payTypeList.forEach(item => {
          if (value.payTypeId === item.id) {
            data.payType = item.payCode;
          }
        })
        acutalAccount.push(data)
      }
      this.dataDetail.payList = acutalAccount;
    },
    toSubmit() {
      this.isActual = true;
      RefundOrderApi.refund(this.dataDetail.id).then(res => {

        this.isRefund = true;
        this.preparePay = false;
        this.disableToClickPay = false;
        if (res === "退款失败") {
          this.$message.error(res);
        } else {
          this.$message.success(res);
          if (this.isAutoPrint) {
            this.toPrint();
            this.$routerUtil.push(`/report/refund-order`);
          } else {
            this.$routerUtil.push(`/report/refund-order`);
          }
        }
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      });
    },
    //数据处理
    handleData() {
      //设置退货单的来源单据
      if (this.dataDetail.orderType === 1) {
        this.dataDetail.originalId = this.dataDetail.id;
      }
      this.handlerAccountDraft();
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryAllOrder();
    },
    saveDraft() {
      this.handlerAccountDraft();
      this.dataDetail.amount = Number(this.dataDetail.amount);
      // 检查退款金额
      let total = 0;
      let jhTotal = 0; // 聚合支付金额
      for (let i = 0; i < this.dataDetail.payList.length; i++) {
        total += Number(this.dataDetail.payList[i].refundCurrent);
        if (this.dataDetail.payList[i].payType !== 1) {
          jhTotal += Number(this.dataDetail.payList[i].refundCurrent);
        }
      }
      if (total === 0) {
        this.$message.error("退款金额不能为0");
        return;
      }
      if (jhTotal > this.checkAmountTotal) {
        this.$message.error("聚合退款的金额不能大于支付金额, 聚合支付金额为：" + this.checkAmountTotal);
        return;
      }
      // if (total > this.dataDetail.amount) {
      //   this.$message.error("请检查退款金额！！！");
      //   return;
      // }
      //检查是否选择了商品
      if (this.dataDetail.productList.length === 0) {
        this.$message.error("请选择商品");
        return;
      }
      if (this.dataDetail.qty === 0) {
        this.$message.error("请添加退货商品");
        return;
      }
      if (this.dataDetail.employeeId == null) {
        this.$message.error("请添加经办职员");
        return;
      }
      this.handleData();
      RefundOrderApi.saveDraft(this.dataDetail).then((res) => {
        this.showEditBtn = true;
        this.isDraftUpdate = true;
        this.editWord = "修改";
        if (this.isAutoPrint) {
          this.toPrint();
          // 当打印获取完数据后刷新当前页面
          this.$routerUtil.push({path: "/report/refund-order/detail"});
        }
        this.$message.success("保存成功");
        this.showDraft = false;
      })
    },
    getPrintData(type, customerName, customerPhone) {
      let data = {
        reportName: "销售退单",
        amount: this.dataDetail.amount,
        refundActual: this.isActual ? this.dataDetail.amount : 0,
        discountAmount: this.dataDetail.amountDiscount,
        customerName: customerName,
        customerPhone: customerPhone,
        orderNumber: this.dataDetail.id,
        orderTime: this.dataDetail.orderTime,
        // employeeName: this.dataDetail.employeeName,
        employeeId: this.dataDetail.employeeId,
        createdName: this.dataDetail.createdName,
        countQty: this.dataDetail.qty,
        amountTotal: this.dataDetail.amountTotal,
        status: this.dataDetail.status === 0 ? '草稿' : '已支付',
        excuteType: type,
      }

      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      // 支付状态
      if (this.dataDetail.payStatus === 0) {
        data.payStatus = '未退款';
      } else {
        data.payStatus = '已退款';
      }
      //仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.dataDetail.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.dataDetail.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }

      let payMap = new Map();
      this.payTypeList.forEach(item => payMap.set(item.id, item.payName));
      let payStr = '';
      this.dataDetail.payList.forEach(item => {
        if ((item.refundCurrent === 0 || !item.refundCurrent)) {
          if (item.refundTotal > 0) {
            payStr += payMap.get(item.payTypeId) + ':         ' + item.refundTotal + '元' + '\n';
          }
        } else {
          payStr += payMap.get(item.payTypeId) + ':         ' + item.refundCurrent + '元' + '\n';
        }
      })
      data.payTypeName = payStr;
      let arr = [];
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        let p = this.dataDetail.productList[i];
        arr.push({
          productIndex: i + 1,
          productName: p.skuName,
          productNumber: p.barCode,
          productPrice: p.skuOriginalPrice, //商品原价
          productCount: p.qty,
          productTotalPrice: p.qty * p.skuSalePrice,
          productDisCount: p.rate,
          productUnitName: p.skuUnitName,
          productSalePrice: p.skuSalePrice, //售价
        })
      }

      data.productList = arr;
      return data;
    },

    getSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }

        if (this.dataDetail.status !== 0) {
          if (index === 7) {
            let totalQty = 0;
            for (let i = 0; i < data.length; i++) {
              totalQty += data[i].qty;
            }
            sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
              totalQty.toString(),
            ])
          }
        } else {
          if (index === 7) {
            let totalQty = 0;
            for (let i = 0; i < data.length; i++) {
              totalQty += data[i].qty;
            }
            sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
              totalQty.toString(),
            ])
          }
        }

        if (this.dataDetail.status !== 0 || this.isDraftUpdate) {
          if (index === 8) {
            let totalAmount = 0;
            for (let i = 0; i < data.length; i++) {
              totalAmount += data[i].skuRefundPrice * data[i].qty;
            }
            sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
              "￥" + totalAmount.toFixed(2).toString(),
            ])
          }
        } else {
          if (index === 9) {
            let totalAmount = 0;
            for (let i = 0; i < data.length; i++) {
              totalAmount += data[i].skuRefundPrice * data[i].qty;
            }
            sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
              "￥" + totalAmount.toFixed().toString(),
            ])
          }
        }
        //   Number(sums[2].substring(1, sums.length)) +   - Number(sums[4].substring(1, sums.length))
      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>

@import "@/assets/css/components/dialog";
@import "@/assets/css/el-tables";
.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}

.customer-select {
  width: 23%;
  margin-right: 10px;

  :deep(.el-input) {
    width: 100% !important;
  }
}

.el-input-text {
  :deep(textarea) {
    padding: 10px;
    box-sizing: border-box;
  }
}

.table-all {
  height: calc(100% - 243px);
}

.table-all-suc {
  height: calc(100% - 187px);
}

.table-part {
  height: calc(100% - 97px);
}

.table-part-suc {
  height: calc(100% - 91px);
}

.top-all {
  height: 243px;
}

.top-all-suc {
  height: 187px;
}

.top-part {
  height: 97px;
}

.top-part-suc {
  height: 91px;
}


</style>