<template>
  <!-- -->
  <div class="short-cut-setting">
    <el-form label-width="90px">
      <el-form-item label="菜单名称">
        <div class="form-width">
          <el-input v-model="menu.cutName" placeholder="请输入菜单名称"/>
        </div>
      </el-form-item>
      <el-form-item label="快捷键类型">
        <div class="form-width">
          <el-select v-model="menu.settingType"
                     @change="()=>{this.queryMainList();}"
                     placeholder="请选择快捷键类型">
            <el-option label="商户" :value="1" />
            <el-option label="部门" :value="2" />
            <el-option label="个人" :value="3" />
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <search-condition @search="MainResetSearch" :show-search="true">
      <template #default>
        <el-select v-model="searchInfoMain.dataType" placeholder="请选择类型" clearable>
          <el-option label="商品分类" :value="1"/>
          <el-option label="商品" :value="2"/>
          <el-option label="SKU" :value="3"/>
        </el-select>
      </template>
    </search-condition>
    <dialog-table>
      <el-table  stripe class="tables" :highlight-current-row="true" :data="menu.detail" :row-key="getRowKey" border height="100%">
        <!--          <el-table-column align="center" type="index" prop="key" width="60px" fixed>-->
        <!--            <template #default>-->
        <!--              <el-icon size="15px" class="handle">-->
        <!--                <Rank/>-->
        <!--              </el-icon>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <el-table-column label="序号" align="center" type="index" prop="key" width="60px" fixed/>
        <el-table-column label="类型" align="center" prop="key" min-width="100px">
          <template v-slot="{row}">
            <div v-if="row.isSave">
              <el-select v-model="row.dataType" placeholder="数据类型">
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </div>
            <div v-else>
                <span v-for="item in typeList" :key="item.value">
                  <span v-if="row.dataType === item.value">{{ item.label }}</span>
                </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数据" align="center" prop="key" min-width="120px" show-overflow-tooltip>
          <template v-slot="{row,$index}">
            <div v-if="row.isSave">

              <span v-if="!row.dataType">请选择类型</span>

              <!-- 商品分类 -->
              <div v-else-if="row.dataType === 1">
                <dictionary v-model="row.dataId" :dictionaries="productCategoryList" dict-code="dictCode"
                            dict-name="dictName" :place-holder="'商品分类'"
                            @clear-data="clearCode" style="width: 100%"/>
              </div>

              <!-- 商品选择 -->
              <div v-else-if="row.dataType === 2">
                <table-button el-type="primary" v-if="row.productDetail"
                              @click="()=>{showProduct = true;rowIndex = $index;searchInfo.searchKey = row.productDetail.productName}">
                  {{ row.productDetail.productName }}
                </table-button>
                <table-button el-type="primary" v-else @click="()=>{showProduct = true;rowIndex = $index}">筛选商品
                </table-button>
              </div>

              <!-- SKU选择 -->
              <div v-else-if="row.dataType === 3">
                <table-button el-type="primary" v-if="row.productSkuDetail"
                              @click="()=>{showSku = true;rowIndex = $index;searchInfo.searchKey = row.productSkuDetail.skuName}">
                  {{ row.productSkuDetail.skuName }}
                </table-button>
                <table-button el-type="primary" v-else @click="()=>{showSku = true;rowIndex = $index}">筛选SKU
                </table-button>
              </div>
            </div>

            <div v-else>

              <!-- 商品分类 -->
              <dictionary v-if="row.dataType === 1" :disabled="true" v-model="row.dataId"
                          :dictionaries="productCategoryList" dict-code="dictCode" dict-name="dictName"
                          :place-holder="'商品分类'"
                          @clear-data="clearCode" style="width: 100%"/>

              <!-- 商品选择 -->
              <table-button el-type="info" v-if="row.dataType === 2"
                            @click="()=>{rowProductDetail = row.productDetail; showDetail = true}">
                {{ row.productDetail.productName }}
              </table-button>

              <!-- SKU选择 -->
              <table-button el-type="info" v-if="row.dataType === 3"
                            @click="()=>{rowProductDetail = row.productSkuDetail; showSkuDetail = true}">
                {{ row.productSkuDetail.skuName }}
              </table-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="显示" align="center" min-width="100px">
          <template v-slot="{row}">
            <div v-if="row.dataType === 1">
              <div v-if="row.isSave">
                <el-select v-model="row.showType" placeholder="显示方式">
                  <el-option v-for="item in showTypeList.category" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </div>
              <div v-else>
                <span v-for="item in showTypeList.category" :key="item.value">
                  <span v-if="row.showType === item.value">{{ item.label }}</span>
                </span>
              </div>
            </div>
            <div v-if="row.dataType === 2">
              <div v-if="row.isSave">
                <el-select v-model="row.showType" placeholder="显示方式">
                  <el-option v-for="item in showTypeList.product" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </div>
              <div v-else>
                <span v-for="item in showTypeList.product" :key="item.value">
                  <span v-if="row.showType === item.value">{{ item.label }}</span>
                </span>
              </div>
            </div>
            <div v-if="!row.dataType || row.dataType === 3">
              <span>默认展示sku</span>
            </div>
          </template>
        </el-table-column>


        <el-table-column label="排序" align="center" prop="key" min-width="100px">
          <template v-slot="{row}">
            <el-input v-if="row.isSave" v-model="row.index" placeholder="请输入排序序号"/>
            <span v-else>{{ row.index }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" prop="key" min-width="70px" fixed="right">
          <template #header>
            <a href="javascript:" @click="toAdd" style="text-decoration: none">
              <el-icon style="border: 1px solid #ccc8c8;border-radius: 3px;padding: 5px;">
                <Plus/>
              </el-icon>
            </a>
          </template>
          <template v-slot="{row}">
            <table-button el-type="danger" type="delete" message="您确定删除该条快捷方式吗？"
                          @click="deleteRow(row)">
              删除
            </table-button>
            <table-button :el-type="row.isSave ? 'success' : 'primary'" @click="editAndSure(row)">{{
                row.isSave ? '确认' : '编辑'
              }}
            </table-button>
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
    <form-page v-model:page-number="searchInfoMain.pageNumber" v-model:page-size="searchInfoMain.pageSize"
               :total-count="searchInfoMain.totalCount" @onLoad="queryMainList" :default-load="true">
      <div style="display: flex;justify-content: flex-end;margin-top: 10px">
        <page-button el-type="info" @click="cancel">取消</page-button>
        <page-button el-type="success" @click="saveSubmit">保存</page-button>
      </div>
    </form-page>

    <el-dialog title="选择商品" @open="queryList(true)" @close="this.pageMap.clear()" v-model="showProduct"
               width="1100px">
      <search-condition @search="resetSearch(true)" :show-search="true">
        <template #default>
          <el-input v-model="searchInfo.searchKey" placeholder="请输入商品名称" @change="resetSearch(true)" clearable/>
        </template>
      </search-condition>
      <dialog-table>
        <el-table stripe :data="productList" height="100%" ref="multipleTable" @selection-change="handleSelectionChange"
                  @row-click="rowSelected">
          <el-table-column type="selection" width="55" :align="'center'" fixed/>
          <el-table-column :align="'center'" type="index" label="序号" width="60px" fixed/>
          <el-table-column :align="'center'" prop="productCode" label="商品编号" min-width="120px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productName" label="商品名称" min-width="180px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="barCode" label="商品条码" min-width="120px" show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productUnitName" label="基本单位" width="100px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productStandard" label="商品规格" width="100px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productType" label="商品型号" width="100px" show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productBrade" label="商品品牌" width="100px" show-overflow-tooltip>
            <template v-slot="{row}">
              <dictionary :disabled="true" v-model="row.productBrade"
                          :dictionaries="productBrades"
                          dict-code="dictCode"
                          dict-name="dictName"/>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="销售单价" width="100px" show-overflow-tooltip>
            <template v-slot="{row}">
              <money :value="row.basePrice"/>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList(true)" :default-load="true">
        <template #default>
          <page-button @click="showProduct = false">取消</page-button>
          <page-button el-type="primary" @click="saveProduct(true)">选中</page-button>
        </template>
      </form-page>
    </el-dialog>
    <el-dialog title="详情信息" v-model="showDetail" width="30%">
      <el-form label-width="70px">
        <div style="display: flex;flex-direction: row;justify-content: space-between">
          <div>
            <el-form-item label="编号:">
              <span>{{ rowProductDetail.productCode }}</span>
            </el-form-item>
            <el-form-item label="名称:">
              <span>{{ rowProductDetail.productName }}</span>
            </el-form-item>
            <el-form-item label="条码:">
              <span>{{ rowProductDetail.barCode }}</span>
            </el-form-item>
            <el-form-item label="品牌:">
              <dictionary :disabled="true" v-model="rowProductDetail.productBrade"
                          :dictionaries="productBrades"
                          dict-code="dictCode"
                          dict-name="dictName"/>
            </el-form-item>
          </div>
          <div style="margin-right: 80px">
            <el-form-item label="单位:">
              <span>{{ rowProductDetail.productUnitName }}</span>
            </el-form-item>
            <el-form-item label="规格:">
              <span>{{ rowProductDetail.productStandard }}</span>
            </el-form-item>
            <el-form-item label="型号:">
              <span>{{ rowProductDetail.productType }}</span>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="SKU列表" @open="queryList(false)" @close="this.pageMap.clear()" v-model="showSku" width="1100px">
      <search-condition @search="resetSearch(false)" :show-search="true">
        <template #default>
          <el-input v-model="searchInfo.searchKey" placeholder="请输入商品名称" @change="resetSearch(false)" clearable/>
        </template>
      </search-condition>
      <dialog-table>
        <el-table stripe @selection-change="handleSelectionChange" :data="productList" height="100%" ref="multipleTable"
                  @row-click="rowSelected">
          <el-table-column type="selection" width="55" :align="'center'" fixed/>
          <el-table-column :align="'center'" type="index" label="序号" width="60px" fixed/>
          <el-table-column :align="'center'" prop="skuCode" label="商品编号" min-width="120px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="skuName" label="商品名称" min-width="180px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="barCode" label="商品条码" min-width="120px" show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="skuUnitName" label="基本单位" width="100px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="skuStandard" label="商品规格" width="100px"
                           show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="skuType" label="商品型号" width="100px" show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productBrade" label="商品品牌" width="100px" show-overflow-tooltip>
            <template v-slot="{row}">
              <dictionary :disabled="true" v-model="row.productBrade"
                          :dictionaries="productBrades"
                          dict-code="dictCode"
                          dict-name="dictName"/>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="销售单价" width="100px" show-overflow-tooltip>
            <template v-slot="{row}">
              <money :value="row.skuSalePrice"/>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList(false)" :default-load="false">
        <template #default>
          <page-button @click="showProduct = false">取消</page-button>
          <page-button el-type="primary" @click="saveProduct(false)">选中</page-button>
        </template>
      </form-page>
    </el-dialog>
    <el-dialog title="详情信息" v-model="showSkuDetail" width="30%">
      <el-form label-width="70px">
        <div style="display: flex;flex-direction: row;justify-content: space-between">
          <div>
            <el-form-item label="编号:">
              <span>{{ rowProductDetail.skuCode }}</span>
            </el-form-item>
            <el-form-item label="名称:">
              <span>{{ rowProductDetail.skuName }}</span>
            </el-form-item>
            <el-form-item label="条码:">
              <span>{{ rowProductDetail.barCode }}</span>
            </el-form-item>
            <el-form-item label="品牌:">
              <dictionary :disabled="true" v-model="rowProductDetail.productBrade"
                          :dictionaries="productBrades"
                          dict-code="dictCode"
                          dict-name="dictName"/>
            </el-form-item>
          </div>
          <div style="margin-right: 80px">
            <el-form-item label="单位:">
              <span>{{ rowProductDetail.skuUnitName }}</span>
            </el-form-item>
            <el-form-item label="规格:">
              <span>{{ rowProductDetail.skuStandard }}</span>
            </el-form-item>
            <el-form-item label="型号:">
              <span>{{ rowProductDetail.skuType }}</span>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import TableButton from "@/components/h5/button/TableButton.vue";
import {Plus} from "@element-plus/icons-vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import config from "@/config/config";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import Money from "@/components/h5/Money.vue";
import productApi from "@/api/main/product/productApi";
import shortCutApi from "@/api/main/shortCut/shortCutApi";

export default {
  emits: ["cancel","selectData"],
  name: "AddShortCutSetting",
  components: {
    Money,
    SearchCondition, PageButton, FormPage,
    Dictionary,
    Plus,
    TableButton, DialogTable,
  },
  computed: {
    shortCutApi() {
      return shortCutApi
    },
    getRowKey() {
      return new Date().getTime();
    },
  },
  data() {
    return {
      // 菜单
      menu: {
        cutName: "", // 菜单名称
        settingType: 3, // 快捷键类型
        detail: [], // 菜单详情
      },
      // 子菜单
      menuDetail: {
        isSave: true,
      },
      // 数据类型集合
      typeList: [],
      // 商品字典分类
      productCategoryList: [],
      // 选择商品
      showProduct: false,
      // 展示详情
      showDetail: false,
      // 展示SKU
      showSku: false,
      // 展示sku详情
      showSkuDetail: false,
      // 商品筛选条件
      searchInfo: {
        pageNumber: 1,
        pageSize: 20,
      },
      // 列表筛选
      searchInfoMain: {},
      // 选择商品列表
      productList: [],
      // 品牌字典
      productBrades: [],
      // 列表当前行索引
      rowIndex: {},
      // 当前查看的商品详情
      rowProductDetail: {},
      // 存储每一页选中的数据,key: 当前页码 , value: 当前页选中的数据
      pageMap: new Map(),
      // 展示方式
      showTypeList:{
        category: [
            {
              label: "sku展示",
              value: 1
            },
            {
              label: "商品展示",
              value: 2
            }
        ],
        product: [
            {
              label: "列表展示",
              value: 1
            },
            {
              label: "弹窗展示",
              value: 2
            }
        ],
      }
    };
  },
  created() {
    // 商品品牌
    commonApi.queryDictionaryList("PRODUCT_BRADE").then(list => this.productBrades = list);
    // 详情分类
    this.typeList = config.type;
    // 商品字典分类-树形
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.productCategoryList = list);
  },
  mounted() {
    // 判断当前的detail集合中是否有数据，无数据，则默认让它添加一条
    if (this.menu.detail.length <= 0) {
      this.menu.detail.push({...this.menuDetail, index: 1});
    }
    // 拖拽排序功能
    const el = document.querySelector('.tables .el-table__body-wrapper  table tbody')
    Sortable.create(el, {
      animation: 150,
      ghostClass: 'blue-background-class',
      handle: '.handle', // 如果需要点击某个图标拖拽的话需要吧那个图标的class写在这里
      onEnd: ({newIndex, oldIndex}) => {

        // 修改数据实际位置
        const targetRow = this.menu.detail[oldIndex]
        this.menu.detail.splice(oldIndex, 1)
        this.menu.detail.splice(newIndex, 0, targetRow);

        if (newIndex === 0) {
          // 如果拖拽到了第一个位置
          this.menu.detail[newIndex].index = 1;
          this.sortTable();
          return;
        }

        if (newIndex === this.menu.detail.length - 1) {
          this.menu.detail[newIndex].index = this.menu.detail.length;
          this.sortTable();
          return;
        }

        // 获取当前拖拽元素的上一个元素
        const previousRow = this.menu.detail[newIndex - 1];
        // 获取当前拖拽元素的下一个元素
        const nextRow = this.menu.detail[newIndex + 1];

        if (previousRow.index === nextRow.index) {
          // 如果它们的排序索引相同，则直接赋值
          this.menu.detail[newIndex].index = previousRow.index;
        } else {
          // 如果不同，则赋值为后一个元素的索引排序值
          this.menu.detail[newIndex].index = nextRow.index;
        }
        // 数据总排序
        this.sortTable();
      },
    })
  },
  methods: {
    // 删除
    deleteRow(row){
      if(!row.id){
        for (let i = 0; i < this.menu.detail.length; i++) {
          if(row.id === this.menu.detail[i].id){
            this.menu.detail.splice(i, 1);
          }
        }
      }else{
        shortCutApi.delete(row.id).then(()=>{this.queryMainList()});
      }
    },
    // 主要列表重置
    MainResetSearch() {
      this.searchInfoMain.pageNumber = 1;
      this.queryMainList();
    },
    // 主要列表
    queryMainList() {
      this.searchInfoMain.searchType = this.menu.settingType;
      return shortCutApi.queryList(this.searchInfoMain).then(res => {
        this.searchInfoMain.totalCount = res.totalCount;
        if (res.records && res.records.length > 0) {
          this.menu.detail = this.handlerMenuList(res.records);
        }else{
          this.menu.detail = [];
          this.menu.cutName = "";
          this.menu.id = "";
          this.menu.merchantId = "";
          this.menu.departId = "";
          this.menu.employeeId = "";
          this.toAdd();
        }
        this.$emit('selectData',this.menu.settingType);
      })
    },
    // 保存提交数据
    async saveSubmit() {
      // 将未保存的数据进行保存
      for (let i = 0; i < this.menu.detail.length; i++) {
        if (this.menu.detail[i].isSave) {
          this.menu.detail[i].isSave = !this.menu.detail[i].isSave;
        }
      }
      await shortCutApi.saveSubmit(this.menu);
      // this.queryMainList();
      this.cancel();
    },
    // 处理返回的数据
    handlerMenuList(data) {
      this.menu.cutName = data[0].cutName;
      this.menu.id = data[0].shortCutId;
      this.menu.merchantId = data[0].merchantId;
      this.menu.departId = data[0].departId;
      this.menu.employeeId = data[0].employeeId;
      for (let i = 0; i < data.length; i++) {
        data[i].isSave = false;
        data[i].dataType = Number(data[i].dataType);
        if (data[i].productDto) {
          data[i].productDetail = data[i].productDto;
        } else if (data[i].productSkuDto) {
          data[i].productSkuDetail = data[i].productSkuDto;
        }
      }
      return data;
    },

    // 新增一行详情数据
    toAdd() {
      // 校验是否还存在未确认的详情数据
      for (let i = 0; i < this.menu.detail.length; i++) {
        if (this.menu.detail[i].isSave) {
          this.menu.detail[i].isSave = !this.menu.detail[i].isSave;
        }
      }
      // 排序
      this.sortTable();
      // 在数组头部新增一行
      this.menu.detail.unshift({...this.menuDetail, index: this.menu.detail.length + 1});
    },
    // 保存或修改
    editAndSure(row) {
      row.isSave = !row.isSave;
      if (!row.isSave) {
        this.sortTable();
      }
    },
    // 排序
    sortTable() {
      // 分离出isSave为true和为false的项
      const savedItems = this.menu.detail.filter(item => item.isSave);
      const unsavedItems = this.menu.detail.filter(item => !item.isSave);
      // 只对确定的进行排序
      unsavedItems.sort((a, b) => a.index - b.index);
      // 合并两个数组，保持savedItems的原有顺序
      this.menu.detail = savedItems.concat(unsavedItems);
    },
    // 移除分类编码
    clearCode() {
      this.searchInfo.categoryCode = "";
    },
    // 商品查询
    queryList(isProduct) {
      if (isProduct) {
        commonApi.queryProductList(this.searchInfo).then(res => {
          this.productList = res.records;
          this.searchInfo.totalCount = res.totalCount;
          // 回选数据
          this.echoData();
        })
      } else {
        productApi.queryProductAuthorizeList(this.searchInfo).then(res => {
          this.productList = res.records;
          this.searchInfo.totalCount = res.totalCount;
          // 回选数据
          this.echoData();
        })
      }
    },
    // 数据回选
    echoData() {
      this.$nextTick(() => {
        if (this.pageMap.size === 0) {
          // 当前行商品
          const detail = this.menu.detail[this.rowIndex].productDetail;
          // 回选
          for (let i = 0; i < this.productList.length; i++) {
            if (detail && detail.id === this.productList[i].id) {
              this.$refs.multipleTable.toggleRowSelection(this.productList[i], this.productList[i].checked = true);
            }
          }
        } else {
          if (this.pageMap.get(this.searchInfo.pageNumber)) {
            const dataArr = this.pageMap.get(this.searchInfo.pageNumber);
            for (let i = 0; i < dataArr.length; i++) {
              for (let j = 0; j < this.productList.length; j++) {
                if (dataArr[i].id === this.productList[j].id) {
                  this.$refs.multipleTable.toggleRowSelection(this.productList[j], this.productList[j].checked = true);
                }
              }
            }
          }
        }
      })
    },
    // 保存选中的商品
    saveProduct(isProduct) {
      // 处理选中的数据
      const valMaps = Array.from(this.pageMap.values());
      let selectRow = [];
      for (let i = 0; i < valMaps.length; i++) {
        selectRow.pushAll(valMaps[i])
      }
      // 校验是否为编辑
      if (this.menu.detail[this.rowIndex].productDetail && selectRow.length > 1) {
        this.$message.warning("编辑模式下，只能选择一个商品！");
        return;
      }
      // 如果选中的数据大于1时，则自动增加对应的信息列
      if (selectRow.length > 1) {
        for (let i = 0; i < selectRow.length; i++) {
          // 校验信息
          if (this.checkProduct(selectRow[i],isProduct)) {
            continue;
          }
          // 选择一个商品给当前列添加( 通过rowIndex找到对应的detail )
          if (i === 0) {
            isProduct
                ? this.menu.detail[this.rowIndex].productDetail = selectRow[i]
                : this.menu.detail[this.rowIndex].productSkuDetail = selectRow[i];
            this.menu.detail[this.rowIndex].dataId = selectRow[i].id;
            continue;
          }
          // 新增多列
          this.createMoreColumn(selectRow[i], isProduct);
        }
        // this.menu.detail.forEach(item => item.isSave = true);
      } else {
        // 校验信息
        if (!this.checkProduct(selectRow[0],isProduct)) {
          // 小于1
          isProduct
              ? this.menu.detail[this.rowIndex].productDetail = selectRow[0]
              : this.menu.detail[this.rowIndex].productSkuDetail = selectRow[0];
          this.menu.detail[this.rowIndex].isSave = true;
          this.menu.detail[this.rowIndex].dataId = selectRow[0].id;
        }
      }
      this.showProduct = false;
      this.showSku = false;
    },
    // 校验商品是否已存在
    checkProduct(row,isProduct) {
      for (let i = 0; i < this.menu.detail.length; i++) {
        if(isProduct){
          if (this.menu.detail[i].productDetail && this.menu.detail[i].productDetail.id === row.id) {
            this.$message.warning(row.productName + "商品已存在，请勿重复添加！");
            return true;
          }
        }else{
          if (this.menu.detail[i].productSkuDetail && this.menu.detail[i].productSkuDetail.id === row.id) {
            this.$message.warning(row.skuName + "商品已存在，请勿重复添加！");
            return true;
          }
        }
      }
      return false;
    },
    // 新增多列
    createMoreColumn(currentRow, isProduct) {
      let data = {
        dataType: isProduct ? 2 : 3,
        index: this.menu.detail.length + 1,
        dataId: currentRow.id,
        isSave: true,
      }
      isProduct ? data.productDetail = currentRow : data.productSkuDetail = currentRow;
      this.menu.detail.unshift({...data})
    },
    // 重置商品查询
    resetSearch(isProduct) {
      this.searchInfo.pageNumber = 1;
      this.queryList(isProduct);
    },
    // 行点击选中事件
    rowSelected(row) {
      // 通过row找到数据，进行反选
      let selectRow = this.$refs.multipleTable.getSelectionRows();
      if (selectRow.find(item => item.id === row.id)) {
        // 已选中，取消选中
        this.$refs.multipleTable.toggleRowSelection(row, false);
      } else {
        // 未选中，选中
        this.$refs.multipleTable.toggleRowSelection(row, true);
      }
    },
    // 处理表格跨页勾选行，出现数据丢失的问题
    handleSelectionChange() {
      // 当前页数据
      this.$nextTick(() => {
        const currentPageData = this.$refs.multipleTable.getSelectionRows();
        this.pageMap.set(this.searchInfo.pageNumber, currentPageData);
      })
    },
    // 取消关闭dialog
    cancel(){
      // 校验是否还存在未确认的详情数据
      for (let i = 0; i < this.menu.detail.length; i++) {
        if (this.menu.detail[i].isSave) {
          this.menu.detail[i].isSave = !this.menu.detail[i].isSave;
        }
      }
      this.$emit("cancel");
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog";
@import "@/assets/css/el-tables";

.short-cut-setting {

}

.form-width {
  width: 240px;
}

.tables {
  box-sizing: border-box;

  .el-table__row {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.handle {
  cursor: move;
  margin-top: 7px;
}

:deep(.el-form-item__label) {
  font-weight: bolder;
}


:deep(.el-dialog__header) {
  padding: 15px;
  background: red;
}

//:deep(.el-dialog) {
//  padding: 0;
//
//  .el-dialog__header.show-close {
//    text-align: left;
//    //background: #409eff;
//    background: #f5f7fa;
//    //color: #ff0000;
//    padding: 0 0 0 16px;
//    line-height: 40px;
//
//    .el-dialog__title {
//      color: #181818;
//      //color: black;
//      //font-weight: bolder;
//    }
//
//    .el-dialog__headerbtn {
//      .el-dialog__close {
//        color: #0e0e0e;
//        font-weight: bolder;
//      }
//    }
//  }
//
//  .el-dialog__body {
//    padding: 16px;
//  }
//}

</style>