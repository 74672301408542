import axios from "../../../util/axios";

export default {
    // 列表查询
    queryList: (searchInfo) => axios.get({url: `/order/stock/statistic/queryList`, data: searchInfo}),
    // 列表统计
    queryStatistic: (searchInfo) => axios.get({url: `/order/stock/statistic/queryStatistic`, data: searchInfo}),
    // 查询详情列表
    queryDetailList: (searchInfo) => axios.get({url: `/order/stock/statistic/queryDetailList`, data: searchInfo}),
    // 查询明细表统计
    queryDetailStatistic: (searchInfo) => axios.get({url: `/order/stock/statistic/queryDetailStatistic`, data: searchInfo}),
    // 查询采购记录
    queryCgList: (data) => axios.get({url: `/order/stock/statistic/queryCgList`, data: data}),
    // 查询采购详情信息
    queryCgDetail: (data) => axios.get({url: `/order/stock/statistic/queryCgDetail`, data: data}),
    // 查询采购的统计信息
    queryCgStatistic: (data) => axios.get({url: `/order/stock/statistic/queryCgStatistic`, data: data}),
    // 查询进销存汇总表信息
    queryPurchaseSaleList: (data) => axios.get({url: `/order/stock/statistic/queryPurchaseSaleList`, data: data}),
    // 查询采购销存汇总表信息
    queryPurchaseSaleStatistic: (data) => axios.get({url: `/order/stock/statistic/queryPurchaseSaleStatistic`, data: data}),
}