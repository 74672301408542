<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="活动标题">
            <el-input v-model="dataDetail.name" placeholder="请输入标题"/>
          </el-descriptions-item>
          <el-descriptions-item label="宣传日期">
            <date-range style="width: 100%" :show-short-cut="false" v-model:start-time="dataDetail.startTime" placeholder="请选择开始日期"
                        v-model:end-time="dataDetail.endTime"/>
          </el-descriptions-item>
          <el-descriptions-item label="活动排序">
            <el-input v-model="dataDetail.index" type="number" :min="0"/>
          </el-descriptions-item>
          <el-descriptions-item label="首页显示">
            <el-select v-model="dataDetail.showHome">
              <el-option :value="1" label="开启" />
              <el-option :value="0" label="关闭" />
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="简要描述" :span="2">
            <el-input v-model="dataDetail.summary" type="textarea" :rows="1"/>
          </el-descriptions-item>
          <el-descriptions-item label="封面" :span="2">
            <image-upload v-model="dataDetail.coverImage" :limit="1" width="80px" height="80px"/>
          </el-descriptions-item>
          <el-descriptions-item label="分享图片" :span="2">
            <image-upload v-model="dataDetail.shareImage" :limit="1" width="80px" height="80px"/>
          </el-descriptions-item>
          <el-descriptions-item label="详情海报" :span="2">
            <rick-text v-model="dataDetail.detailInfo" @input="getEditorValue"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="display: flex;width: 100%;padding-bottom: 10px;justify-content: flex-end">
          <page-button el-type="primary" style="margin-right: 10px" @click="showCategory = true">筛选分类</page-button>
          <product-list-select :show-search="false" @select="selectProduct" :selected-product-list="dataDetail.productList" :query-list-api="ProductApi.queryList" :btn-name="'筛选商品'" />
        </div>
        <el-table stripe :data="dataDetail.productList">
          <el-table-column :align="'center'" label="序号" width="60px" type="index" fixed/>
          <el-table-column :align="'center'" show-overflow-tooltip min-width="120px" prop="productCode" label="商品编号"
                           fixed/>
          <el-table-column :align="'center'" show-overflow-tooltip min-width="120px" prop="productName" label="商品名称"
                           fixed/>
          <el-table-column :align="'center'" show-overflow-tooltip min-width="180px" prop="productStandard" label="规格"/>
          <el-table-column :align="'center'" show-overflow-tooltip min-width="180px" prop="productType" label="型号"/>

          <el-table-column label="操作" :align="'center'" width="80px" fixed="right">
            <template v-slot="{row,$index}">
              <table-button message="是否确认删除此数据" el-type="delete" @click="deleteRow($index)">删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <page-button el-type="success" type="write" @click="saveSubmit">保存</page-button>
      </form-page>
    </list-page>

    <el-dialog title="选择分类" v-model="showCategory" width="30%">
      <dictionary v-model="currentCategory" :dictionaries="categoryList" place-holder="请选择商品类目"
                  style="display: inline-block;width: 100%"/>
      <div style="display: flex;justify-content: flex-end;margin-top: 15px">
        <page-button el-type="info" @click="showCategory = false">取消</page-button>
        <page-button el-type="primary" @click="addCategoryProduct">选中</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import RickText from "@/components/h5/richText/RichText.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import productApi from "@/api/main/product/productApi";
import ProductApi from "@/api/main/product/productApi";
import miniActivityApi from "@/api/main/mini/miniActivityApi";

export default {
  name: "",
  computed: {
    ProductApi() {
      return ProductApi
    }
  },
  components: {
    Dictionary, TableButton,
    ProductListSelect,
    DateRange,
    RickText,
    ImageUpload,
    PageButton,
    ListPage, TableList, FormPage
  },
  data() {
    return {
      dataDetail: {
        productList: [],
        index: 0,
        showHome: 0,
      },
      showCategory: false,
      categoryList: [],
      currentCategory: '',
    };
  },
  created() {
    // 商品类目
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
    // 数据回显
    if(this.$routerUtil.query.id){
      this.findById(this.$routerUtil.query.id);
    }
  },
  methods: {
    // 数据回显
    findById(id){
      miniActivityApi.findById(id).then(res => {
        this.dataDetail = res;
      });
    },
    // 保存提交
    saveSubmit(){
      miniActivityApi.saveSubmit(this.dataDetail).then(res => this.findById(res.id));
    },
    // 获取富文本信息
    getEditorValue(val) {
      this.dataDetail.posterDetail = val;
    },
    // 选中商品回显
    selectProduct(data) {
      for (let i = 0; i < data.length; i++) {
        this.dataDetail.productList.push({
          productId: data[i].id,
          productCode: data[i].productCode,
          productName: data[i].productName,
          productStandard: data[i].productStandard,
          productType: data[i].productType,
        })
      }
    },
    // 添加一整个分类的商品
    addCategoryProduct() {
      productApi.queryList({category: this.currentCategory}).then(res => {
        if(res.records.length > 0){
          this.selectProduct(res.records);
        }else{
          this.$message.warning("该分类下暂无商品");
        }
        this.showCategory = false;
      })
    },
    // 删除一行数据，前端删除，不涉及数据库，最终修改需要通过保存按钮
    deleteRow(index) {
      this.dataDetail.productList.splice(index, 1);
      this.$message.success("移除成功");
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>