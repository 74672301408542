<template>
  <div style="height: 100%">
    <el-table stripe :data="accessRightList" height="100%" @cell-click="cellClick" :row-class-name="getRowClass">
      <el-table-column label="权限" prop="label" :align="'center'">
        <template v-slot="{row}"><span :class="row.disabled? 'row-label-disabled': ''">{{row.label}}</span></template>
      </el-table-column>
      <el-table-column label="自己的" :align="'center'">
        <template v-slot="{row, $index}">
          <el-checkbox @click.stop="true" :disabled="row.disabled" v-model="row.self" @change="changeValue('self', $index)"/>
        </template>
      </el-table-column>
      <el-table-column label="部门及下级" :align="'center'">
        <template v-slot="{row, $index}">
          <el-checkbox @click.stop="true" :disabled="row.disabled" v-model="row.depart" @change="changeValue('depart', $index)"/>
        </template>
      </el-table-column>
      <el-table-column label="全部" :align="'center'">
        <template v-slot="{row, $index}">
          <el-checkbox @click.stop="true" :disabled="row.disabled" v-model="row.all" @change="changeValue('all', $index)"/>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {watch} from "vue";
import PageButton from "@/components/h5/button/PageButton.vue";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    // 系统的全部权限功能
    rightList: {
      default: () => []
    },
    // 菜单功能支持的权限
    moduleAccessRight: {
      default: "",
    }
  },
  name: "",
  components: {
    PageButton
  },
  data() {
    return {
      checkedList: [],
      // 访问权限{label, value, disabled, grant}
      accessRightList: [],
      timeout: "",
    };
  },
  created() {
    this.initRight();
    this.initData();
    watch(() => this.modelValue, () => {
      this.initData();
    });
    watch(() => [this.rightList, this.moduleAccessRight], ([]) => {
      this.initRight();
      this.initData();
    })
  },
  unmounted() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    getRowClass(data) {
      return data.row.disabled? "row-disabled": "";
    },
    initRight() {
      let arr = [];
      // 这个功能被授权了这些权限，只有这些权限可操作
      let grant = this.moduleAccessRight? this.moduleAccessRight.split(","): [];
      for (let i = 0; i < this.rightList.length; i++) {
        let contains = false;
        for (let j = 0; j < grant.length; j++) {
          if ((this.rightList[i].id + "") === grant[j]) {
            contains = true;
            break;
          }
        }
        arr.push({
          label: this.rightList[i].name,
          value: this.rightList[i].id + "",
          disabled: !contains
        })
      }
      this.accessRightList = arr;
    },
    initData() {
      if (!this.accessRightList.length) {
        this.timeout = setTimeout(() => this.initData(), 100);
        return;
      }
      let ma = this.modelValue.split("");
      let arr = [...this.accessRightList];
      for (let i = 0; i < arr.length; i++) {
        // 没有权限
        if (arr[i].disabled) {
          arr[i].grant = 0;
        } else {
          // 有权限
          if (ma[arr[i].value]) {
            // 有授权过值
            arr[i].grant = ma[arr[i].value];
          } else {
            // 没有授权
            arr[i].grant = 0;
          }
        }
        // 根据授权的值，显示授权的范围，1：自己，2：部门及下级，3：全部
        arr[i].self = parseInt(arr[i].grant) === 1;
        arr[i].depart = parseInt(arr[i].grant) === 2;
        arr[i].all = parseInt(arr[i].grant) === 3;
      }
      this.accessRightList = arr;
    },
    // 数据值变更，提交数据
    changeValue(type, index) {
      // 更新数据值
      if (type === "self") {
        this.accessRightList[index].grant = this.accessRightList[index].self? "1": "0";
        // 把其它两个置为false
        this.accessRightList[index].depart = false;
        this.accessRightList[index].all = false;
      } else if (type === "depart") {
        this.accessRightList[index].grant = this.accessRightList[index].depart? "2": "0";
        this.accessRightList[index].self = false;
        this.accessRightList[index].all = false;
      } else if (type === "all") {
        this.accessRightList[index].grant = this.accessRightList[index].all? "3": "0";
        this.accessRightList[index].depart = false;
        this.accessRightList[index].self = false;
      }
      let arr = [];
      for (let i = 0; i < this.accessRightList.length; i++) {
        arr.push(this.accessRightList[i].grant || "0");
      }
      let result = arr.join("");
      this.$emit("update:modelValue", result);
    },
    cellClick(row, column) {
      switch (column.no) {
        case 1:
          this.changeCellValue(row, "self");
          break;
        case 2:
          this.changeCellValue(row, "depart");
          break;
        case 3:
          this.changeCellValue(row, "all");
          break;
      }
    },
    changeCellValue(data, type) {

      for (let i = 0; i < this.accessRightList.length; i++) {
        if (data.value === this.accessRightList[i].value) {
          // 没有授权
          if (this.accessRightList[i].disabled) {
            return;
          }
          this.accessRightList[i][type] = !this.accessRightList[i][type];
          this.changeValue(type, i);
          break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.row-label-disabled{
  color: #999;
}
:deep(.el-table__row.row-disabled){
  background-color: #fafafa;
}
</style>