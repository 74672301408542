import axios from "../../../util/axios";

export default {
    // 保存领用单
    saveSubmit: (data) => axios.post({url: "/order/back/form/saveSubmit", data: data}),

    // 保存领用单草稿
    saveDraft: (data) => axios.post({url: "/order/back/form/saveDraft", data: data}),

    // 查询领用单列表
    queryList: (data) => axios.get({url: "/order/back/form/queryList", data: data}),

    // 审批
    approval: (data) => axios.post({url: "/order/back/form/approval-wz", data: data}),
}