<template>
  <!-- 自定义表格字段,只支持prop，不支持v-slot插槽传值, 操作栏必须添加到append中去，不然不能置为最右 -->
  <!-- 自动追加一个index -->
  <el-table-column type="index" width="60rem" align="center" fixed="left">
    <template #header>
      <span class="icon-setting">
        <el-icon v-if="settingDisabled" class="icon-setting-disabled"><el-icon-setting/></el-icon>
        <el-icon v-else @click="toShowSetting"><el-icon-setting/></el-icon>
      </span>
    </template>
  </el-table-column>
  <slot name="default"></slot>
  <el-table-column v-for="(t, id) in tableAppends" min-width="120rem" :key="id" :width="`${t.width}rem`" :label="t.rename || t.label" :align="t.align" :fixed="t.fixed" show-overflow-tooltip>
    <template v-slot="{row}">
      <span>{{ row[t.props] }}</span>
    </template>
  </el-table-column>
  <slot name="append"/>
  <el-dialog dialog-width="3" v-model="showSetting" title="列配置" append-to-body>
    <dialog-table>
      <el-table class="dialog-table" :data="settingList" stripe border height="100%" id="dragTable">
        <el-table-column type="index" label="序号" width="60rem" align="center"/>
        <el-table-column prop="label" label="字段名称" align="center" show-overflow-tooltip>
          <template v-slot="{row}">{{row.label}}</template>
        </el-table-column>
        <el-table-column label="重命名">
          <template v-slot="{row}">
            <el-input v-model="row.rename" :placeholder="row.label"/>
          </template>
        </el-table-column>
        <el-table-column label="宽度">
          <template v-slot="{row}">
            <el-input v-model="row.width" placeholder="表格的宽度"/>
          </template>
        </el-table-column>
        <el-table-column label="顺序">
          <template v-slot="{row}">
            <el-input v-model="row.index" placeholder="表格的顺序"/>
          </template>
        </el-table-column>
        <el-table-column label="对齐">
          <template v-slot="{row}">
            <el-select v-model="row.align">
              <el-option label="向左对齐" value="left"/>
              <el-option label="向右对齐" value="right"/>
              <el-option label="居中对齐" value="center"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="是否固定">
          <template v-slot="{row}">
            <el-select v-model="row.fixed">
              <el-option label="不固定" :value="false"/>
              <el-option label="左侧固定" value="left"/>
              <el-option label="右侧固定" value="right"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="是否显示" align="center" width="120rem">
          <template v-slot="{row}">
            <el-switch v-model="row.checked" active-color="#13ce66" inactive-color="#ff4949" />
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
    <div slot="footer" style="text-align: right">
      <page-button el-type="info" @click="showSetting = false">取消</page-button>
      <page-button el-type="primary" @click="changeSetting(true)">保存</page-button>
    </div>
  </el-dialog>
</template>

<script>
import util from "@/util/util";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import tableSetting from "./TableSettingParams.js";
import commonApi from "@/api/commonApi";
import Sortable from "sortablejs";
export default {
  props: {
    // 可以自定义的字段
    pageName: {default: ""}, // 哪个页面，哪个功能，比如职员查询页面，客户查询页面
  },
  name: "",
  components: {
    DialogTable, TableButton
  },
  data() {
    return {
      showSetting: false, // 显示配置项
      settingList: [], // 有哪些配置项
      tableAppends: [], // 要追加到列表的字段
      savedList: [],// 保存起来的数据
      columnId: "",
      sessionKey: "userColumnSetting",
      settingDisabled: false, // 禁用配置功能
    };
  },
  created() {
    // 初始化
    this.init();
  },
  mounted() {

  },
  methods: {
    // 初始化查询数据信息
    async init(){
      let session = util.getSession(this.sessionKey);
      if (!session) {
        // 没有缓存相关的字段显示
        const list = await commonApi.queryColumnSetting();
        let params = {};
        for (let i = 0; i < list.length; i++) {
          params[list[i].pageName] = list[i].pageParams;
        }
        util.setSession(this.sessionKey, JSON.stringify(params));
        this.initData(params);
      } else {
        // 有缓存的字段信息
        this.initData(JSON.parse(session));
      }
    },
    // 让页面table可以拖动
    initDragTable() {
      this.$nextTick().then(() => {
        const el = document.querySelector('#dragTable table tbody')
        new Sortable(el, {
          sort: true,
          ghostClass: 'sortable-ghost',
          onEnd: (e) => {
            let tableData = [...this.settingList];
            const targetRow = tableData.splice(e.oldIndex, 1)[0]
            tableData.splice(e.newIndex, 0, targetRow);
            // 重新渲染
            this.settingList = [];
            this.$nextTick().then(() => {
              this.settingList = tableData;
              // 保存数据
              this.changeSetting(false); // 是否隐藏弹框
            });
            console.log(tableData);
          },
        })
      })
    },
    initData(params) {
      if (this.pageName) {
        this.settingList = tableSetting.pages[this.pageName] || [];
        let setting = params[this.pageName];
        if (setting && setting !== "[]") {
          // 如果有配置，加载配置
          this.savedList = JSON.parse(setting);
          this.showAppendColumns();
        }else{
          // 没有配置初始化保存一份
          this.changeSetting(true).then(() => this.initData(JSON.parse(util.getSession(this.sessionKey))));
        }
        if (!this.settingList.length) {
          console.error(`table设置错误,${this.pageName}未配置字段`)
          this.settingDisabled = true;
        }
      } else {
        console.error("table设置错误，未设置页面信息")
      }
    },
    toShowSetting() {
      this.showSetting = true;
      this.initDragTable();
    },
    // 变更配置
    changeSetting(clear) {
      // 找到这个页面的配置
      let obj = {
        id: this.columnId,
        pageName: this.pageName,
        pageParams: JSON.stringify(this.settingList)
      };
      return commonApi.saveColumnSetting(obj).then(data => {
        this.columnId = data.id;
        // 更新缓存中的配置
        let params = util.getSession(this.sessionKey);
        let objParams = JSON.parse(params);
        objParams[this.pageName] = JSON.stringify(this.settingList);
        util.setSession(this.sessionKey, JSON.stringify(objParams));
        // 显示配置字段到页面
        this.savedList = JSON.parse(data.pageParams);
        this.showAppendColumns();
        if (clear) {
          this.showSetting = false;
        }
      })
    },
    // 显示配置字段到页面
    showAppendColumns() {
      let arr = [];
      for (let i = 0; i < this.settingList.length; i++) {
        for (let j = 0; j < this.savedList.length; j++) {
          if (this.settingList[i].props === this.savedList[j].props) {
            // 配置信息
            this.settingList[i] = {
              ...this.settingList[i],
              ...this.savedList[j]
            }
            // 添加到列表中去
            let obj = {
              ...this.settingList[i],
              ...this.savedList[j],
            }
            if (obj.checked) {
              // 选中
              arr.push(obj);
            }
            break;
          }
        }
      }
      // 按顺序进行排序
      arr.sort((a, b) => (a.index || 0) - (b.index || 0));
      console.log(arr)
      this.tableAppends = arr;
    },
  }
};
</script>

<style lang="scss">
// dialog统一设置
.el-overlay-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 130px;
  .el-dialog{
    // body，添加最大高度
    margin: 0;
    //padding-bottom: 55px;
    .el-dialog__header{
      height: 30px;
      line-height: 30px;
    }
    .el-dialog__title{
      user-select: none;
    }
    .el-dialog__body{
      max-height: calc(100vh - 350px);
      overflow: auto;
      //padding-bottom: 20px;
      box-sizing: border-box;
      div[slot='footer']{
        //position: absolute;
        width: 100%;
        box-sizing: border-box;
        text-align: right;
        bottom: 10px;
        left: 0;
        padding-top: 10px;
        background: #FFF;
        height: 45px;
        margin-top: 10px;
        position: relative;
        ::before{
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          content: "";
        }
      }
    }
    // 里面的表单
    .el-form{
      .el-form-item__label{
        width: 90px;
      }
      .el-form-item{
        margin-bottom: 10px;
      }
    }
    .el-table{
      --el-table-border: 1px solid var(--el-text-color-disabled);
      --el-table-border-color: var(--el-text-color-disabled);
    }
  }
  // 一倍距
  .el-dialog[dialog-width='1']{
    width: 400px;
  }
  // 二倍距
  .el-dialog[dialog-width='2']{
    width: 800px;
  }
  // 三倍距
  .el-dialog[dialog-width='3']{
    width: 1200px;
  }
  .el-dialog[dialog-width='4']{
    width: 1500px;
  }
}

@import "@/assets/css/el-tables";
.icon-setting{
  color: var(--el-color-primary);
  .icon-setting-disabled{
    color: var(--el-color-info);
  }
}
.dialog-table .el-table__cell{
  //border-right: none !important;
}
</style>