import axios from "@/util/axios";

export default {

    // 查询列表
    queryList: (data) => axios.get({url: "/order/material/maintenance/queryList", data: data}),

    // 查询领用人的信息
    findByCode: (originalCode) => axios.get({url: `/order/material/maintenance/findByCode`, data: {originalCode}}),

    // 保存提交
    saveSubmit: (data) => axios.post({url: "/order/material/maintenance/saveSubmit", data: data}),

    // 根据id回显
    findById: (id) => axios.get({url: `/order/material/maintenance/findById`, data: {id}}),

    // 维修完成
    toRepairFinish: (originalCode, id) => axios.get({url: `/order/material/maintenance/toRepairFinish`, data: {originalCode, id}}),

    // 查询救援物资的首页列表
    queryWzTime: () => axios.get({url: "/order/common/queryWzTime"}),

    // 报废
    toScrapOut: (id) => axios.get({url: `/order/material/maintenance/toScrapOut`, data: {id}}),

    //审批
    approval: (data) => axios.post({url: `/order/material/maintenance/approval-wz`, data: data}),
}