<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="exportData">导出</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="write" el-type="success" @click="addNew">新建</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
          <el-input v-model="searchInfo.searchKey" placeholder="请扫描或输入溯源码" @keydown.enter="resetSearch" clearable/>
          <el-input v-model="searchInfo.name" placeholder="请输入物资编号/名称/型号" @keydown.enter="resetSearch" clearable/>
        </template>
        <template #moreCondition>
          <div class="customer-select" style="display: inline-block; width: 240px">
            <employee-select :placeholder-tit="'维保人'" v-model="searchInfo.employeeId"/>
          </div>

          <div class="customer-select" style="display: inline-block; width: 240px">
            <el-select v-model="searchInfo.mtResult" placeholder="请选择维保结果">
              <el-option label="正常" :value="0"/>
              <el-option label="维修" :value="1"/>
              <el-option label="报废" :value="2"/>
            </el-select>
          </div>

          <div class="customer-select" style="display: inline-block; width: 240px">
            <dictionary v-model="searchInfo.mtType" :place-holder="'请选择维保类型'"
                        :dictionaries="mtTypeList"
                        dict-code="dictCode"
                        dict-name="dictName"/>
          </div>
        </template>
      </search-condition>
      <table-list>
        <yq-table page-name="mtRecord" stripe :data="dataList" ref="multipleTable" height="100%">
          <yq-table-column align="center" label="维保时间" prop="mtTime" min-width="200" />
          <yq-table-column align="center" label="物资分类" prop="categoryName" min-width="120" />
          <yq-table-column align="center" label="物资编号" prop="skuCode" min-width="120" />
          <yq-table-column align="center" label="物资名称" prop="skuName" min-width="160" />
          <yq-table-column align="center" label="规格" prop="skuStandard" min-width="120">
            <template v-slot:default="{row}">
              {{ row.skuStandard ? row.skuStandard : '-' }}
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="型号" prop="skuType" min-width="120">
            <template v-slot="{row}">
              <span>{{ row.skuType ? row.skuType : '-' }}</span>
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="溯源码" prop="originalCode" min-width="150" />
          <yq-table-column align="center" label="维保类型" prop="mtTypeName" min-width="100" />
          <yq-table-column align="center" label="维保结果" pro="mtReuslt" min-width="120">
            <template v-slot="{row}">
              <el-tag v-if="row.mtResult === 0" type="success">正常</el-tag>
              <el-tag v-else-if="row.mtResult === 1" type="warning">维修</el-tag>
<!--              <el-tag v-else-if="row.mtResult === 1 && row.status === 99" type="success">维修完成</el-tag>-->
              <el-tag v-else-if="row.mtResult === 2" type="danger">报废</el-tag>
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="维保人" prop="mtEmployeeName" min-width="100" />
          <yq-table-column align="center" label="状态" min-width="100" prop="status">
            <template v-slot="{row}">
              <el-tag type="success" v-if="row.status === 99">已完成</el-tag>
              <el-tag type="danger" v-else-if="row.status === 20">待处理</el-tag>
              <el-tag type="danger" v-else-if="row.status === 10">待审批</el-tag>
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="操作" prop="options" fixed="right" :required="true" min-width="180">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="toView(row)">查看</table-button>
              <table-button message="确定维修完成了吗?" el-type="warning" v-if="row.status === 20" @click="toRepairFinish(row)">维修完成</table-button>
              <table-button el-type="danger" v-if="row.status === 20 && row.originalOrderId" @click="toScrapOut(row)">报废出库</table-button>
            </template>
          </yq-table-column>
        </yq-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>
  </div>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import mtRecordApi from "@/api/main/material/mtRecordApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import YqTable from "@/components/h5/column/YqTable.vue";
import YqTableColumn from "@/components/h5/column/YqTableColumn.vue";
export default {
  computed: {
  },
  components: {
    Dictionary,
    PageButton,
    FormPage, YqTable, YqTableColumn,
    TableButton, EmployeeSelect,
    ListPage, SearchCondition, TableList, DateRange,  PageHeader, ProductListSelect
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      mtTypeList: [],
    };
  },
  created() {
    // 查询字典
    util.getUserInfo().then((user) => {
      commonApi.queryDictionaryList("MATERIAL_TYPE", user.merchantId).then(list => this.mtTypeList = list);
    });
    // 初始化，默认查询一个月内的数据
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  methods: {
    exportData(){
      util.exportExcel("/order/material/maintenance/exportData",this.searchInfo);
    },
    toUpdate(row){
      this.$routerUtil.push(`/mt/control/record/detail?id=${row.id}`)
    },
    toView(row){
      this.$routerUtil.push(`/mt/control/record/detail?id=${row.id}&isUpdated=1`)
    },
    toRepairFinish(row){
      mtRecordApi.toRepairFinish(row.originalCode, row.id).then(() => {
        this.$message.success("操作成功");
        this.queryList();
      });
    },
    toScrapOut(row){
      if(row.originalOrderId){
        // 有来源订单，归还单生成的检修，需要报废出库，需要走单独的逻辑
        mtRecordApi.toScrapOut(row.id).then(() => {
          this.$message.success("操作成功");
          this.queryList();
        })
      }else{
        this.$routerUtil.push(`/stock/outstock/detail-original?recordId=${row.id}&code=${row.originalCode}`);
      }
    },
    queryList(){
      mtRecordApi.queryList(this.searchInfo).then(data => {
        this.dataList = data.records;
        this.searchInfo.totalCount = data.totalCount;
      });
    },
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    addNew(){
      this.$routerUtil.push(`/mt/control/record/detail`);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
</style>