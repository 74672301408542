<template>
  <!-- -->
  <div :class=" isHalfWidth ? 'half-separate' : 'separate-page'" :style="{'padding-left': isHalfWidth ? '50%' : leftWidth}">
    <div class="left" :style="{width: isHalfWidth ? '50%' : leftWidth}">

      <slot name="category"></slot>
    </div>
    <div class="right">

      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // 是否为50%的宽度
    isHalfWidth: {
      type: Boolean,
      default: false
    },
    // 是否显示滚动条
    isShowScroll: {
      type: Boolean,
    },
    // 左侧距离
    leftWidth: {default: "240px"}
  },
  name: "",
  components: {
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.separate-page{
  height: 100%;
  box-sizing: border-box;
  padding-left: 240px;
  position: relative;
  .left{
    position: absolute;
    width: 240px;
    height: 100%;
    left: 0;
    overflow: scroll;
    border-right: 1px solid #eaeaea;
  }
  .right{
    padding-left: 10px;
    height: 100%;
  }
}

.half-separate{
  height: 100%;
  padding-left: 50%;
  box-sizing: border-box;
  position: relative;
  .left{
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    border-right: 1px solid #eaeaea;
  }
  .right{
    padding-left: 10px;
    height: 100%;
  }
}
</style>