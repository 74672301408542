<template>
  <div class="defaultViewStyle">
    <long-form>
      <long-form-panel label="基本信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="商品名称">{{ detail.productName }}</el-descriptions-item>
          <el-descriptions-item label="商品编码">{{ detail.productCode }}</el-descriptions-item>
          <el-descriptions-item label="商品分类">
            <dictionary v-model="detail.category" :dictionaries="productCategories"
                        style="display: inline-block;width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="基本单位">{{ detail.productUnitName }}</el-descriptions-item>
          <el-descriptions-item label="零售价格">
            <el-input v-model="detail.basePrice" :disabled="true"/>
          </el-descriptions-item>
          <el-descriptions-item label="成本价格">
            <el-input v-model="detail.buyPrice" :disabled="true"/>
          </el-descriptions-item>
          <el-descriptions-item label="商品状态">
            <el-select v-model="detail.status" placeholder="商品状态" size="default" style="width: 100%;">
              <el-option label="上架" :value="0"/>
              <el-option label="下架" :value="-1"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="库存类型">
            <el-select v-model="detail.stockType" disabled>
              <el-option :value="0" label="非库存类商品"/>
              <el-option :value="1" label="库存类商品"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="商品规格">{{ detail.productStandard }}</el-descriptions-item>
          <el-descriptions-item label="商品型号">{{ detail.productType }}</el-descriptions-item>
          <el-descriptions-item label="条形码">{{ detail.barCode }}</el-descriptions-item>
          <el-descriptions-item label="拼音码">{{ detail.pinyinCode }}</el-descriptions-item>
          <el-descriptions-item label="税收编码" v-if="!detail.productAttributeValue.addStandard">
            <el-input v-model="detail.taxCode" placeholder="请输入税收编码"/>
          </el-descriptions-item>
          <el-descriptions-item label="税收项目名称" v-if="!detail.productAttributeValue.addStandard">
            <el-input v-model="detail.taxCategory" placeholder="税收简称"/>
          </el-descriptions-item>
          <el-descriptions-item label="税率" v-if="!detail.productAttributeValue.addStandard">
            <el-input v-model="detail.taxRate" placeholder="税率"/>
          </el-descriptions-item>
          <el-descriptions-item :label="`质保期限(${warrantyTypeName})`"
                                v-if="!detail.productAttributeValue.addStandard">
            <el-input v-model="detail.warrantyPeriod" :placeholder="`质保期限,单位(${warrantyTypeName})`"/>
          </el-descriptions-item>
          <el-descriptions-item label="商品摘要" :span="2">
            <el-input v-model="detail.summary" type="textarea" :rows="5" disabled/>
          </el-descriptions-item>
        </el-descriptions>
      </long-form-panel>
      <long-form-panel label="商品描述">
        <el-form label-width="100px">
          <el-form-item label="商品图标">
            <image-upload v-model="detail.titleImage" :limit="1" width="80px" height="80px" :disabled="true"/>
          </el-form-item>
          <el-form-item label="轮播图片">
            <image-upload v-model="detail.bannerImage" :limit="5" width="80px" height="80px" :disabled="true"/>
          </el-form-item>
          <el-form-item label="详情描述">
            <rick-text v-model="detail.productDetail" @input="getEditorValue" disabled/>
          </el-form-item>
        </el-form>
      </long-form-panel>

      <long-form-panel label="商品多规格">
        <div style="margin-left: 20px;padding-bottom: 10px;">
          <el-checkbox  v-model="detail.productAttributeValue.addStandard" size="small" disabled>启用商品多规格</el-checkbox>
        </div>
      </long-form-panel>
      <long-form-panel label="商品SKU明细" v-if="detail.productAttributeValue.addStandard">
        <div>
          <el-table stripe :data="detail.skuList" border key="skuTable">
            <el-table-column label="序号" type="index" :align="'center'" width="60px" fixed>
            </el-table-column>
            <el-table-column label="商品名称" prop="skuName" min-width="200px" show-overflow-tooltip fixed/>
            <el-table-column label="供应商" prop="authorizeMerchantName" min-width="150px" show-overflow-tooltip/>
            <el-table-column label="规格信息" min-width="120px" v-if="detail.productAttributeValue.addStandard"
                             show-overflow-tooltip>
              <template v-slot="{ row, $index }">
              <span v-for="(item, i) in row.skuAttributeList" :key="$index + '_' + i">
                <span v-if="i > 0">,</span>
                <span>
                  {{ item.name }}
                </span>
              </span>
              </template>
            </el-table-column>
            <el-table-column label="零售价格" min-width="100px">
              <template v-slot="{ row }">
                <el-input v-model="row.skuSalePrice"/>
              </template>
            </el-table-column>
            <el-table-column label="基础进价" min-width="100px">
              <template v-slot="{ row }">
                <el-input v-model="row.skuBuyPrice"/>
              </template>
            </el-table-column>
            <el-table-column label="税收编码" min-width="150px" prop="pinyinCode">
              <template v-slot="{row}">
                <el-input v-model="row.taxCode" placeholder="请输入税收编码"/>
              </template>
            </el-table-column>
            <el-table-column label="税收项目名称" min-width="150px" prop="pinyinCode">
              <template v-slot="{row}">
                <el-input v-model="row.taxCategory" placeholder="请输入税收编码"/>
              </template>
            </el-table-column>
            <el-table-column label="税率" min-width="150px" prop="pinyinCode">
              <template v-slot="{row}">
                <el-input v-model="row.taxRate" placeholder="请输入税收编码"/>
              </template>
            </el-table-column>
            <el-table-column :label="`质保期限(${warrantyTypeName})`" min-width="150px" prop="pinyinCode">
              <template v-slot="{row}">
                <el-input v-model="row.warrantyPeriod" placeholder="请输入税收编码"/>
              </template>
            </el-table-column>
            <el-table-column label="基本单位" min-width="100px" prop="skuUnitName" show-overflow-tooltip/>
            <el-table-column label="规格" min-width="150px" prop="skuStandard" show-overflow-tooltip/>
            <el-table-column label="型号" min-width="150" prop="skuType" show-overflow-tooltip/>
            <el-table-column label="商品编码" min-width="150px" prop="skuCode" show-overflow-tooltip/>
            <el-table-column label="条形码" min-width="150px" prop="barCode" show-overflow-tooltip/>
            <el-table-column label="拼音码" min-width="150px" prop="pinyinCode" show-overflow-tooltip/>
            <el-table-column label="操作" width="80px" fixed="right" :align="'center'">
              <template v-slot="{row, $index}">
                <table-button type="delete" el-type="danger" message="这个商品来自供应商，是否删除商品授权？"
                              @click="deleteSku(row, $index)">删除
                </table-button>
              </template>
            </el-table-column>
          </el-table>

        </div>

      </long-form-panel>
      <long-form-panel label="商品自定义">
        <el-table stripe :data="detail.productAttributeList" border key="customerTable">
          <el-table-column label="序号" type="index" :align="'center'" width="100px"/>
          <el-table-column label="属性名称" prop="name" width="100px"/>
          <el-table-column label="属性内容" prop="value"/>
        </el-table>
      </long-form-panel>
    </long-form>
    <form-page :show-page="false">
      <el-button type="primary" @click="saveDetail">保存</el-button>
    </form-page>
  </div>
</template>

<script>
import Dictionary from "@/components/h5/Dictionary";
import LongForm from "@/components/h5/layout/LongForm";
import LongFormPanel from "@/components/h5/layout/LongFormPanel";
import ImageUpload from "@/components/h5/image/ImageUpload";
import TableList from "@/components/h5/layout/TableList.vue";
import RickText from "@/components/h5/richText/RichText";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn";
import TableButton from "@/components/h5/button/TableButton";
import DialogTable from "@/components/h5/layout/DialogTable";
import commonApi from "@/api/commonApi";
import productApi from "@/api/main/product/productApi";
import util from "@/util/util";
import PageButton from "@/components/h5/button/PageButton.vue";
import AttributeMerchant from "../attribute/AttributeMerchant.vue";
import FormPage from "@/components/h5/layout/FormPage";

export default {
  components: {
    AttributeMerchant,
    PageButton,
    TableList,
    ImageUpload,
    LongForm,
    LongFormPanel,
    Dictionary,
    RickText,
    DetailPageBtn,
    TableButton,
    DialogTable,
    FormPage
  },
  //
  data() {
    return {
      // 商品详情
      detail: {
        id: "",
        barCode: "",
        productName: "",
        pinyinCode: "",
        productSupplier: "",
        productBrade: "",
        stockType: "",
        basePrice: "",
        buyPrice: "",
        productUnitId: "",
        productDetail: "",
        bannerImage: "",
        titleImage: "",
        status: null,
        attributeItems: "",
        // 商品多规格设置
        productAttributeValue: {
          addStandard: true,
          attributeList: []
        },

        productAttributeList: [], // 商品自定义信息
        skuList: [],
        productStandard: "",
        productType: "",
        productCode: "",
      },
      addProductInformation: false,
      // 商品分类字典
      productCategories: [],
      // 商品品牌
      productBrades: [],
      productSuppliers: [],
      // 单位管理
      showUnits: false,
      // 是否允许在单位页面选择单位
      selectUnitAble: false,
      // 商品单位
      units: [{unitName: "测试单位", id: "1"}],
      // 当前编辑的商品属性
      currentProductAttribute: {},
      // 单位转换率
      unitRateList: [{unitId: ""}],
      // 显示单位详情
      showUnitDetail: false,
      // 单位详情
      currentUnit: {},
      // 要添加到商品单位的单位
      newUnits: [],
      // 属性分组
      productAttributeList: [],
      // 显示商品规格
      showAttribute: false,
      showAttributeDetail: false,
      currentSkuInfo: {}, // 编辑sku信息
      showSkuDialog: false,
      //轮播图片信息
      toSaveBannerImgJson: "",
      warrantyTypeName: "",
    };
  },
  created() {
    this.unitRateList = [];
    // 查询商品分类
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.productCategories = list);
    // 读取配置
    commonApi.findSetting("OD_WARRANTY_TYPE").then(setting => {
      if (setting.settingValue === "1") {
        this.warrantyTypeName = "天";
      } else if (setting.settingValue === "2") {
        this.warrantyTypeName = "月";
      } else if (setting.settingValue === "3") {
        this.warrantyTypeName = "年";
      }
    });
    // 查询单位
    this.queryUnits();
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
      // 查询商品单位
      this.queryProductUnitRates(this.$routerUtil.query.id);
    }
  },
  methods: {
    //更新规格dialog
    updateMerchant(list) {
      this.productAttributeList = list;
      this.showSkuInfo();
    },
    queryUnits() {
      productApi.queryUnits().then(list => this.units = list);
    },
    findById(id) {
      productApi.findById(id).then(data => {
        // 全部商品属性集
        productApi.queryAttributeList(data.merchantId).then(list => {
          this.productAttributeList = list;
          this.showSkuInfo();
        });
        // 多规格信息
        if (data.attributeItems) {
          data.productAttributeValue = JSON.parse(data.attributeItems);
        } else {
          // 没有设置
          data.productAttributeValue = {
            addStandard: true,
            attributeList: []
          };
        }
        // 自定义属性
        if (data.productAttributes) {
          data.productAttributeList = JSON.parse(data.productAttributes);
        } else {
          data.productAttributeList = [];
        }
        this.detail = data;
        this.showSkuInfo();
      });
    },
    // 显示sku信息
    showSkuInfo() {
      // 获取sku规格信息
      if (!this.productAttributeList || this.productAttributeList.length === 0) {
        // 没有信息
        return;
      }
      if (!this.detail.skuList || this.detail.skuList.length === 0) {
        // 没有sku信息
        return;
      }
      let skuList = [...this.detail.skuList];
      // 找到属性集名称
      for (let i = 0; i < skuList.length; i++) {
        if (!skuList[i].skuAttributes) {
          // 没有属性
          skuList[i].skuAttributeList = [];
          continue
        }
        let arr = JSON.parse(skuList[i].skuAttributes);
        // 找到属性集名称
        for (let j = 0; j < arr.length; j++) {
          let data = arr[j];
          for (let k = 0; k < this.productAttributeList.length; k++) {
            let attr = this.productAttributeList[k];
            if (data.id === attr.id) {
              // 找到数据
              let opList = attr.options ? JSON.parse(attr.options) : [];
              for (let l = 0; l < opList.length; l++) {
                if (data.value === opList[l].code) {
                  // 属性结果
                  data.name = opList[l].name;
                  // 属性名
                  data.columnName = attr.column;
                }
              }
              break;
            }
          }
        }
        skuList[i].skuAttributeList = arr;
      }
      this.detail.skuList = skuList;
    },
    // 商品单位转换率
    queryProductUnitRates(id) {
      productApi.queryUnitRates(id).then(list => this.unitRateList = list);
    },
    // 显示规格dialog
    addAttribute() {
      this.showAttribute = true;
    },
    addSku() {
      this.currentSkuFormat({attributeObject: {}})
      this.showSkuDialog = true;
    },
    // 保存商品
    saveDetail() {
      debugger;
      if (!this.detail.productName) {
        this.$message.error("请添加商品名称")
        return;
      }
      //调用轮播图片，手动保存
      let data = {...this.detail};
      if (data.skuList) {
        for (let i = 0; i < data.skuList.length; i++) {
          if (!data.skuList[i].erpId) {
            delete data.skuList[i].erpId;
          }
        }
      }
      // 商品多规格
      if (data.productAttributeValue) {
        data.attributeItems = JSON.stringify(data.productAttributeValue);
      }
      // 商品自定义属性
      if (data.productAttributeList) {
        data.productAttributes = JSON.stringify(data.productAttributeList);
      }
      // 如果没有启用多规格
      if (!data.productAttributeValue.addStandard) {
        if (!data.skuList || data.skuList.length === 0) {
          // 没有sku信息，添加默认
          data.skuList = [{
            prodcutId: this.detail.id,
            barCode: this.detail.barCode,
            pinyinCode: this.detail.pinyinCode,
            skuName: this.detail.productName,
            skuUnitId: this.detail.productUnitId,
            skuBuyPrice: this.detail.buyPrice,
            skuSalePrice: this.detail.basePrice,
            skuCode: this.detail.productCode,
            skuStandard: this.detail.productStandard,
            skuType: this.detail.productType,
            taxCode: this.detail.taxCode,
            taxCategory: this.detail.taxCategory,
            taxRate: this.detail.taxRate,
            warrantyPeriod: this.detail.warrantyPeriod,
          }];
        } else {
          // 有sku，同步更新
          data.skuList[0] = {
            ...data.skuList[0],
            prodcutId: this.detail.id,
            barCode: this.detail.barCode,
            pinyinCode: this.detail.pinyinCode,
            skuName: this.detail.productName,
            skuUnitId: this.detail.productUnitId,
            skuBuyPrice: this.detail.buyPrice,
            skuSalePrice: this.detail.basePrice,
            skuCode: this.detail.productCode,
            skuStandard: this.detail.productStandard,
            skuType: this.detail.productType,
            taxCode: this.detail.taxCode,
            taxCategory: this.detail.taxCategory,
            taxRate: this.detail.taxRate,
            warrantyPeriod: this.detail.warrantyPeriod,
          }
        }
      }
      // 判断是否添加了sku
      productApi.save(data).then(data => {
        this.detail.id = data.id;
        this.findById(data.id);
      });
    },
    //使用v-model绑定获取editor信息报错，改用组件传参
    getEditorValue(value) {
      this.detail.productDetail = value;
    },
    addNewUnit() {
      this.currentUnit = {};
      this.showUnitDetail = true;
    },
    editUnit(data) {
      this.currentUnit = {
        ...data
      };
      this.showUnitDetail = true;
    },
    deleteUnit(data) {
      this.$confirm(`是否确认删除单位[${data.unitName}]`).then(() => {
        productApi.deleteUnit(data.id).then(() => this.queryUnits());
      })
    },
    saveUnit() {
      productApi.saveUnit(this.currentUnit).then(() => {
        this.queryUnits();
        this.showUnitDetail = false;
      })
    },
    addUnit2Product() {
      this.selectUnitAble = true;
      this.showUnits = true;
    },
    selectUnit(data) {
      this.newUnits = data;
    },
    // 选中某个单位
    unitClick(row) {
      util.tableSelectionRowClick("id", row, this.newUnits, this.units, this.$refs.unitDialogTable);
    },
    // 保存转换率
    addProductUnitRate() {
      if (!this.detail.id) {
        this.$message.error("请先保存商品");
        return;
      }
      let arr = [];
      // 转换率，找到新增的，不是新增的不处理
      this.newUnits.forEach(u => {
        let contains = false;
        for (let i = 0; i < this.unitRateList.length; i++) {
          if (this.unitRateList[i].unitId === u.id) {
            contains = true;
          }
        }
        if (!contains) {
          // 不存在，添加
          arr.push({
            productId: this.detail.id,
            unitId: u.id,
            rate: 1,
          });

        }
      });
      // 保存商品单位
      let idx = 0;
      for (let i = 0; i < arr.length; i++) {
        // 不存在，添加
        productApi.saveUnitRate(arr[i]).then(() => {
          idx++;
          if (idx === arr.length) {
            this.queryProductUnitRates(this.detail.id);
            this.showUnits = false;
          }
        });
      }
    },
    // 保存商品单位
    saveUnitRate(data) {
      productApi.saveUnitRate(data).then(() => this.queryProductUnitRates(this.detail.id));
    },
    deleteUnitRate(data) {
      this.$confirm("是否确认删除商品单位").then(() => {
        productApi.deleteUnitRate(data.id).then(() => this.queryProductUnitRates(this.detail.id));
      })
    },
    // 添加一条自定义属性
    addCustomerAttribute() {
      this.detail.productAttributeList.push({name: "", value: ""})
    },
    // 删除一条自定义属性
    deleteCustomerAttribute(idx) {
      this.detail.productAttributeList.splice(idx, 1);
    },
    // 显示商品规格的选择页面
    toShowAttributes() {
      this.showAttribute = true;
    },
    // 选中的商品属性集，添加到商品中
    addAttribute2Detail() {
      let arr = [];
      let old = this.detail.productAttributeValue.attributeList;
      for (let i = 0; i < this.productAttributeList.length; i++) {
        if (this.productAttributeList[i].checked) {
          // 选中了这个属性集
          // 看原来是否在商品中，如果在商品中，不进行覆盖
          let contains = false;
          for (let j = 0; j < old.length; j++) {
            if (this.productAttributeList[i].id === old[j].id) {
              // 已有这个数据
              contains = true;
              break;
            }
          }
          if (!contains) {
            arr.push({
              ...this.productAttributeList[i],
              optionList: this.productAttributeList[i].options ? JSON.parse(this.productAttributeList[i].options) : [], // 选项，变更为list
            });
          }
        }
      }
      // 进行拼接，把新的数据添加到老数据后面
      this.detail.productAttributeValue.attributeList = [...this.detail.productAttributeValue.attributeList, ...arr];
      this.showAttribute = false;
    },
    // 从已选择的商品属性集中删除一条
    deleteAttributeFromProduct(idx) {
      this.detail.productAttributeValue.attributeList.splice(idx, 1);
    },
    // 添加一条新的sku
    toAddNewSku() {
      if (!this.detail.id) {
        this.$message.error("请先保存商品数据");
        return;
      }
      // 开启多规格
      if (this.detail.productAttributeValue.addStandard) {

      } else {
        // 未开启

      }
      if (!this.detail.productAttributeValue.addStandard) {
        this.currentSkuInfo.prodcutId = this.detail.id;
        this.currentSkuInfo.barCode = this.detail.barCode;
        this.currentSkuInfo.pinyinCode = this.detail.pinyinCode;
        this.currentSkuInfo.skuName = this.detail.productName;
        this.currentSkuInfo.skuUnitId = this.detail.productUnitId;
        this.currentSkuInfo.skuBuyPrice = this.detail.buyPrice;
        this.currentSkuInfo.skuSalePrice = this.detail.basePrice;
        this.currentSkuInfo.skuCode = this.detail.productCode;
        this.currentSkuInfo.skuStandard = this.detail.productStandard;
        this.currentSkuInfo.skuType = this.detail.productType;
        this.saveSkuSingle();
      }
    },
    // 添加一条新的sku
    getNewSku() {
      return {
        productId: this.detail.id,
        skuName: this.detail.productName,
        skuSalePrice: this.detail.basePrice,
        skuUnitId: this.detail.productUnitId,
        skuStandard: this.detail.productStandard,
        skuType: this.detail.productType,
        skuBuyPrice: this.detail.buyPrice,
        skuCode: this.detail.productCode,
        attributeObject: {}
      };
    },
    currentSkuFormat(data) {
      if (data.skuAttributeList && data.skuAttributeList.length > 0) {
        // 有对应的属性集
        let obj = {};
        for (let i = 0; i < data.skuAttributeList.length; i++) {
          obj[data.skuAttributeList[i].id] = {code: data.skuAttributeList[i].value};
        }
        data.attributeObject = obj;
        this.currentSkuInfo = data;
        this.showSkuDialog = true;
        return;
      }
      // 商品已添加的属性集
      let attrs = this.detail.productAttributeValue.attributeList;
      for (let i = 0; i < attrs.length; i++) {
        data.attributeObject[attrs[i].id] = {code: ""}
      }
      this.currentSkuInfo = data;
      this.showSkuDialog = true;
    },
    // 编辑sku
    toEditSku(data) {
      // 复制sku信息，初始化显示
      this.currentSkuFormat({...data, attributeObject: {}})
    },
    // 单条sku保存
    saveSkuSingle() {
      let data = {
        ...this.currentSkuInfo,
        productId: this.detail.id,
      };
      if (data.attributeObject) {
        let arr = [];
        for (let k in data.attributeObject) {
          arr.push({id: k, value: data.attributeObject[k].code})
        }
        data.skuAttributes = JSON.stringify(arr);
      }
      if (!data.erpId) {
        delete data.erpId;
      }
      productApi.saveSku(data).then(() => {
        // 保存完成，查询sku列表
        this.showSkuDialog = false;
        productApi.querySkuList(this.detail.id).then(list => {
          this.detail.skuList = list;
          this.showSkuInfo();
        })
      })
    },
    // 删除一个sku
    deleteSku(sku, index) {
      productApi.deleteGrantSku(sku.grantProductId, sku.authorizeMerchantId, sku.grantedMerchantId).then(() => {
        this.detail.skuList = this.detail.remove(index)
      });
    },
    // 两个属性集实例是否相同
    isSkuAttributeSames(attrs, skus) {
      for (let i = 0; i < attrs.length; i++) {
        for (let j = 0; j < skus.length; j++) {
          if (attrs[i].id === skus[j].id && attrs[i].value === skus[j].value) {
            // 数据不同
            return false;
          }
        }
      }
      return true;
    },
    // 自动添加
    autoAddSku() {
      if (!this.detail.id) {
        this.$message.error("请先保存商品数据")
        return
      }
      // 组装好的sku
      let arr = this.getAllAttributeSkus();
      let skuList = [];
      // 是否这个属性已经被添加成sku
      for (let i = 0; i < arr.length; i++) {
        let contains = true;
        for (let j = 0; j < this.detail.skuList.length; j++) {
          // 是否属性集相同
          if (!this.isSkuAttributeSames(arr[i], this.detail.skuList[j].skuAttributeList)) {
            contains = false;
            break;
          }
        }
        // 没有这个sku
        if (!contains || this.detail.skuList.length === 0) {
          // 不相同，添加一个新的sku
          let data = this.getNewSku();
          // 只保存id和value
          let a = [];
          let names = [];
          arr[i].forEach(at => {
            // 名称
            names.push(at.optionName);
            a.push({id: at.id, value: at.value})
          })
          data.skuName += names.join("");
          data.skuAttributeList = a;
          // 添加一个数据用于保存
          data.skuAttributes = JSON.stringify(a);
          skuList.push(data);
        }
      }
      this.detail.skuList = [...this.detail.skuList, ...skuList];
      this.showSkuInfo();
    },
    // 整理所有被选中的属性集，组合成属性实例
    getAllAttributeSkus() {
      let attrList = this.detail.productAttributeValue.attributeList;
      // 添加所有的sku
      let arrays = [[]];
      for (let i = 0; i < attrList.length; i++) {
        // 选项类型，每一个选项都要添加进去
        let newArr = [];
        for (let j = 0; j < attrList[i].optionList.length; j++) {
          // 深复制
          let arr = [];
          arrays.forEach(ar => {
            let arr_ = [];
            ar.forEach(a => arr_.push({...a}));
            arr.push(arr_);
          })
          arr.forEach(at => {
            at.push({
              id: attrList[i].id,
              columnName: attrList[i].columnName,
              columnType: attrList[i].columnType,
              value: attrList[i].optionList[j].code,
              optionName: attrList[i].optionList[j].name,
              checked: attrList[i].optionList[j].checked,
            })
          });
          newArr = newArr.concat(arr);
        }
        arrays = newArr;
      }
      // 移除掉没有选中的数据
      let arr = [];
      for (let i = 0; i < arrays.length; i++) {
        // 是否有未选中的数据
        let checked = true;
        for (let j = 0; j < arrays[i].length; j++) {
          if (!arrays[i][j].checked) {
            checked = false;
            break;
          }
        }
        if (checked) {
          arr.push(arrays[i]);
        }
      }
      return arr;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/form.scss";
@import "@/assets/css/theme/handle.scss";
@import "@/assets/css/components/table.scss";
@import "@/assets/css/components/description";

.add-in-table {
  color: #409EFF;
  font-weight: 600;
  margin-left: 3px;
}

.el-overlay {
  display: block !important;
}

.table-list {
  height: 480px !important;
}
</style>