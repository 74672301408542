<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list ref="tableList">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="规则组名称" >
            <el-input v-model="dataDetail.ruleName"/>
          </el-descriptions-item>
          <el-descriptions-item label="仓库名称">
            <el-select v-model="dataDetail.stockId" placeholder="请选择仓库">
              <el-option v-for="(s, i) in stockList" :key="i" :label="s.stockName" :value="s.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="规则组状态">
            <el-select v-model="dataDetail.status" placeholder="请选择是否启用">
              <el-option label="禁用" :value="-1"/>
              <el-option label="启用" :value="0"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="备注">
            <el-input type="textarea" :rows="1" v-model="dataDetail.remark"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;padding-bottom: 10px">
          <div style="display: inline-block; width: 400px">
            <product-list-select @select="selectList" :query-list-api="queryStockProducts" @selectOne="selectOne" :selected-product-list="dataDetail.ruleList" sku-id-column="productSkuId"/>
          </div>
        </div>
        <div style="height: calc(100% - 170px)">
          <el-table stripe :data="dataDetail.ruleList" ref="ruleList" highlight-current-row height="100%">
            <el-table-column label="序号" type="index" width="60px"/>
            <el-table-column label="商品名称" prop="skuName" show-overflow-tooltip/>
            <el-table-column label="规则状态" prop="status" width="150p">
              <template v-slot="{row}">
                <el-select v-model="row.status" placeholder="请选择状态">
                  <el-option label="禁用" :value="-1"/>
                  <el-option label="正常" :value="0"/>
                  <!--                <el-option label="忽略时间之前挂起" :value="1"/>-->
                  <!--                <el-option label="永久挂起" :value="2"/>-->
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="库存上限" width="150px">
              <template v-slot="{row}">
                <el-input v-model="row.warnMax"/>
              </template>
            </el-table-column>
            <el-table-column label="库存下限" width="150px">
              <template v-slot="{row}">
                <el-input v-model="row.warnMin"/>
              </template>
            </el-table-column>
            <!--          <el-table-column label="忽略时间" width="260px">-->
            <!--            <template v-slot="{row}">-->
            <!--              <date-picker v-model="row.hangOnTime"/>-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column label="操作" width="60px">
              <template v-slot="{$index}">
                <table-button el-type="danger" type="delete" @click="remove($index)">删除</table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </table-list>
      <form-page :show-page="false">
        <page-button type="write" el-type="success" @click="saveRuleGroup">保存</page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import stockWarningApi from "@/api/main/stock/stockWarningApi";
import TableButton from "@/components/h5/button/TableButton";
import ProductListSelect from "@/components/h5/product/ProductListSelect";
import DatePicker from "@/components/h5/date/DatePicker";
import {watch} from "vue";
import PageHeader from "@/components/h5/layout/PageHeader";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, ProductListSelect, DatePicker, PageHeader
  },
  data() {
    return {
      dataDetail: {
        ruleName: "",
        ruleList: [

        ]
      },
      stockList: [],
      // 查询库存类商品
      queryStockProducts: {},
    };
  },
  created() {
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    }
    // 查询仓库
    stockWarningApi.queryStock().then(list => this.stockList = list);
    this.queryStockProducts = stockWarningApi.queryStockProducts;
  },
  mounted() {

  },
  methods: {
    findById(id) {
      stockWarningApi.findById(id).then(data => this.dataDetail = data);
    },
    selectList(list) {
      let arr = [...this.dataDetail.ruleList];
      for (let i = 0; i < list.length; i++) {
        this.addProductIfDistinct(list[i], arr)
      }
      this.dataDetail.ruleList = arr;
    },
    selectOne(skuData) {
      // 如果没有就添加，有就不进行任何操作
      let arr = [...this.dataDetail.ruleList];
      let idx = this.addProductIfDistinct(skuData, arr)
      this.dataDetail.ruleList = arr;
      // 当选中一条时，定位到这条数据
      this.$nextTick(() => {
        this.$refs.ruleList.setCurrentRow(arr[idx]);
        document.querySelector(".el-scrollbar__wrap").scrollTop = idx * 57;
      });
    },
    addProductIfDistinct(data, arr) {
      // 这里返回插入的arr的index
      let contains = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].productSkuId === data.id) {
          contains = true;
          return i;
        }
      }
      if (!contains) {
        arr.push({
          productSkuId: data.id,
          skuName: data.skuName,
          status: 0,
        })
        return arr.length - 1;
      }
    },
    remove(idx) {
      this.dataDetail.ruleList = this.dataDetail.ruleList.remove(idx);
    },
    saveRuleGroup() {
      // 检查数据，不能为空
      if (this.dataDetail.ruleName === "") {
        this.$message.error("规则组名称不能为空");
        return;
      }
      if (this.dataDetail.stockId === "") {
        this.$message.error("仓库不能为空");
        return;
      }
      if (this.dataDetail.ruleList.length === 0) {
        this.$message.error("规则不能为空");
        return;
      }
      for (let i = 0; i < this.dataDetail.ruleList.length; i++) {
        if (!this.dataDetail.ruleList[i].warnMax || !this.dataDetail.ruleList[i].warnMin) {
          this.$message.error("库存上限和下限不能为空");
          return;
        }
      }
      stockWarningApi.saveGroup(this.dataDetail).then((data) => {
        this.dataDetail = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description";
@import "@/assets/css/el-tables";
</style>