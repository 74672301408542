<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
<!--          <settlement-comp style="display: inline-block" />-->
          <page-button el-type="primary" v-if="enableRoom" @click="findSubscribe">预定查询</page-button>
          <page-button el-type="primary" type="delete" @click="resetSearch">查询</page-button>
          <!--          <page-button el-type="primary" @click="exportData">导出</page-button>-->
          <page-button type="ex" el-type="primary" @click="exportData">导出</page-button>
          <page-button type="write" @click="$routerUtil.push({path: `/report/offline-order/detail`})" el-type="success">
            新建
          </page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range ref="dateRange" v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <el-input ref="orderNumberInput" v-model="searchInfo.orderId" placeholder="请扫描/输入单据号" clearable
                    @change="resetSearch"/>

          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>
        </template>
        <template #moreCondition>
          <div v-if="readAccess === '3'" style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.departCode" @clearData="clearDic" :place-holder="'请选择部门'"
                        :dictionaries="departList"
                        :is-multiple="true"
                        @change-data="setDepartCode"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择职员'" :is-multiple="true" v-model="searchInfo.employeeId"
                             @select-more="setEmployee"/>
          </div>

          <div v-if="readAccess === '2' || readAccess === '3'" class="customer-select"
               style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择创建人'" :is-multiple="true" v-model="searchInfo.createdId"
                             @select-more="setCreated"/>
          </div>

          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择收款人'" :is-multiple="true" v-model="searchInfo.payeeId"
                             @select-more="setPayee"/>
          </div>

          <el-select v-model="batchInfo.stockIds" multiple collapse-tags collapse-tags-tooltip placeholder="请选择仓库"
                     clearable>
            <el-option v-for="(st, i) in stockList" :key="i + 's'" :label="st.stockName" :value="st.id"/>
          </el-select>

          <el-select v-model="batchInfo.payTypeIds" multiple collapse-tags collapse-tags-tooltip
                     placeholder="请选择支付方式" clearable>
            <el-option v-for="(st, i) in payType" :key="i + 'p'" :label="st.payName" :value="st.id"/>
          </el-select>

          <el-select v-model="searchInfo.payStatus" placeholder="请选择支付状态" clearable>
            <el-option label="未支付" :value="0"/>
            <el-option label="部分支付" :value="50"/>
            <el-option label="支付完成" :value="99"/>
          </el-select>

          <el-select v-model="searchInfo.orderStatus" placeholder="请选择订单状态" clearable>
            <el-option label="草稿" :value="0"/>
            <el-option label="未完成" :value="50"/>
            <el-option label="已完成" :value="99"/>
          </el-select>

          <el-select v-model="searchInfo.orderType" placeholder="请选择订单来源" clearable>
            <el-option label="线上" :value="11"/>
            <el-option label="线下" :value="1"/>
          </el-select>

          <el-select v-model="searchInfo.isSelectFp" placeholder="请选择查询发票" clearable>
            <el-option label="已开具发票" :value="2"/>
            <el-option label="未开具发票" :value="1"/>
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%"  :default-sort="{ prop: 'id', order: 'descending' }" show-summary
                  :summary-method="getSummary">
          <table-setting page-name="order/list">
            <template #default>
              <el-table-column align="center" label="单据编号" prop="id" show-overflow-tooltip min-width="180px" fixed="left">
                <template v-slot="{row}">
                  <table-button class="add" el-type="primary" type="write"
                                @click="$routerUtil.push(`/report/offline-order/detail?id=${row.id}`)">{{ row.id }}
                  </table-button>
                </template>
              </el-table-column>
            </template>
          </table-setting>
<!--          <el-table-column align="center" label="单据日期" prop="orderTime" show-overflow-tooltip min-width="160px"/>-->
<!--          <el-table-column align="center" label="客户" prop="customerName" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="合计数量" prop="qty" min-width="100px"/>-->
<!--          <el-table-column align="center" label="合计金额" prop="amount" min-width="130px" sortable>-->
<!--            <template v-slot="{ row }">-->
<!--              <money :value="row.amountNotZero"/>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="优惠金额" prop="amountDiscount" min-width="100px">-->
<!--            <template v-slot="{ row }">-->
<!--              <money :value="row.amountDiscount"/>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="本单应收" prop="amountDiscountZero" min-width="100px">-->
<!--            <template v-slot="{ row }">-->
<!--              <money :value="row.amount"/>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="已收金额" prop="amount" min-width="130px" sortable>-->
<!--            <template v-slot="{ row }">-->
<!--              <money :value="row.amountCollect"/>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="未收金额" prop="amount" min-width="130px" sortable>-->
<!--            <template v-slot="{ row }">-->
<!--              <money :value="row.amount - row.amountCollect"/>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="经办人" prop="employeeName" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="部门" prop="departName" min-width="150px"/>-->
<!--          <el-table-column align="center" label="仓库" prop="stockName" min-width="150px"/>-->
<!--          <el-table-column label="支付方式" min-width="120px" show-overflow-tooltip :align="'center'">-->
<!--            <template v-slot="{row}">-->
<!--              <span v-for="(p, i) in payType" :key="i">-->
<!--                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>-->
<!--              </span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="支付状态" min-width="100px">-->
<!--            <template v-slot="{ row }">-->
<!--              <el-tag v-if="row.payStatus === 0" type="info">未支付</el-tag>-->
<!--              <el-tag v-if="row.payStatus === 20" type="warning">支付中</el-tag>-->
<!--              <el-tag v-if="row.payStatus === 50" type="primary">部分支付</el-tag>-->
<!--              <el-tag v-if="row.payStatus === 30" type="danger">支付失败</el-tag>-->
<!--              <el-tag v-if="row.payStatus === 99" type="success">支付完成</el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="支付时间" show-overflow-tooltip min-width="160px" sortable>-->
<!--            <template v-slot="{ row }">-->
<!--              <span v-if="row.payTime == null">暂未支付</span>-->
<!--              <span v-else>{{ row.payTime }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="订单状态" min-width="100px" prop="statusName" />-->
<!--          <el-table-column align="center" label="订单来源" min-width="100px" prop="orderTypeName" />-->
<!--          <el-table-column align="center" label="创建人" prop="createdName" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="收款人" prop="payeeName" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="支付流水号" prop="outTradeNo" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="发票号码" prop="invoiceNo" show-overflow-tooltip min-width="100px"/>-->
          <el-table-column label="操作" align="center" min-width="100px" fixed="right">
            <template v-slot="{ row }">
              <list-button>
                <page-button type="write" :disabled="(row.status !== 50 && row.payStatus !== 20) || row.orderType !== 1" @click="batchPay(row)">收款</page-button>
                <page-button type="write" :disabled="row.status === 0" @click="exchangeGood(row)">换货</page-button>
                <page-button type="write" v-if="enableRoom" @click="showRoom(row)">客房信息</page-button>
                <page-button type="write" :disabled="(row.status !== 50) || row.orderType !== 1" @click="termination(row)">终止</page-button>
                <page-button type="write" :disabled="(row.orderType !== 1 && row.orderType !== 11) || row.qty === row.refundQty || row.payStatus < 50" @click="toRefund(row)">退货</page-button>
                <page-button type="write" :disabled="(row.status > 50 && row.orderType === 1) || row.payStatus >= 50 || row.orderType === 11" @click="toDelete(row)">删除</page-button>
                <page-button type="write" :disabled="row.payStatus !== 99" @click="toShowPaySerial(row)">流水号</page-button>
                <page-button type="write" :disabled="row.payStatus !== 99" @click="toShowInvoice(row)">发票</page-button>
                <page-button type="write" :disabled="row.invoiceIssue === 1" @click="toShowRedInvoice(row)">红冲</page-button>
                <page-button type="write" :disabled="row.payStatus !== 20" @click="toRefresh(row)">刷新</page-button>
                <page-button type="write" @click="zfjl(row)">支付记录</page-button>
              </list-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
    <el-dialog title="收款记录" style="background-color: #f4f5f6" v-model="showPayRecord" width="40%">
      <div class="dialog-scss">
        <el-form label-width="70px">
          <el-form-item label="客户名称">
            {{ payRecords.detail.customerName }}
          </el-form-item>
          <el-form-item label="实收金额">
            {{ payRecords.detail.amount }}
          </el-form-item>
        </el-form>
        <dialog-table style="box-shadow: 3px 3px 15px #e5e0e0">
          <el-table stripe :data="payRecords.recordList" height="100%">
            <el-table-column align="center" label="收款日期" prop="payTime" show-overflow-tooltip min-width="100px"/>
            <el-table-column align="right" label="收款金额" prop="currentAmount" show-overflow-tooltip
                             min-width="100px"/>
            <el-table-column align="center" label="支付状态" min-width="100px">
              <template v-slot="{ row }">
                <span v-if="row.payStatus === 0" type="info">未支付</span>
                <span v-if="row.payStatus === 20" type="info">支付中</span>
                <span v-if="row.payStatus === 30" type="error">支付失败</span>
                <span v-if="row.payStatus === 50" type="primary">部分支付</span>
                <span v-if="row.payStatus === 99" type="success">支付成功</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="收款方式" show-overflow-tooltip min-width="100px">
              <template v-slot="{row}">
               <span v-for="(p, i) in payType" :key="i">
                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>
              </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="收款人" prop="payeeName" show-overflow-tooltip min-width="100px"/>
            <el-table-column align="center" label="流水号" prop="outTradeNo" show-overflow-tooltip min-width="120px"/>
            <el-table-column align="center" label="操作" fixed="right">
              <template v-slot="{ row }">
                <table-button type="text" @click="toSinglePrint(row)">打印</table-button>
              </template>
            </el-table-column>
          </el-table>
        </dialog-table>
      </div>
    </el-dialog>
  </div>

  <el-dialog title="发票详情" :close-on-click-modal="false" @close="closeInvoice" v-model="showInvoice" width="40%">
    <div>
      <div style="height: 100%;">
        <el-form label-width="70px" style="">
          <el-form-item label="购方姓名">
            <el-input placeholder="请填写购方姓名" v-model="invoiceVo.name"/>
          </el-form-item>
          <el-form-item label="发票抬头">
            <el-input placeholder="请填写发票抬头" v-model="invoiceVo.invoiceTitle"/>
          </el-form-item>
          <el-form-item label="购方地址">
            <el-input placeholder="请填写购方地址" v-model="invoiceVo.buyerAddress"/>
          </el-form-item>
          <el-form-item label="购方电话">
            <el-input placeholder="请填写购方电话" v-model="invoiceVo.buyerTel"/>
          </el-form-item>
          <el-form-item label="购方税号">
            <el-input placeholder="请填写购方税号" v-model="invoiceVo.checkTaxNo"/>
          </el-form-item>
          <el-form-item label="购方账户">
            <el-input placeholder="购⽅银⾏名称-购⽅银⾏账号，中间⽤ ' - ' 隔开" v-model="invoiceVo.buyerAccount"/>
          </el-form-item>
          <el-form-item label="发票接口">
            <el-select v-model="invoiceVo.invoiceCodeType" placeholder="请选择接口类型">
              <el-option v-for="item in invoiceTypeList" :key="item.id" :label="item.invoiceName"
                         :value="item.invoiceCode"/>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: flex-end">
          <page-button el-type="info" @click="showInvoice = false">取消</page-button>
          <page-button v-loading.fullscreen.lock="fullscreenLoading" el-type="primary" @click="getInvoiceResult">获取发票
          </page-button>
        </div>
      </div>
    </div>
  </el-dialog>

  <el-dialog title="交易流水号" v-model="showSerialNumber" width="600px" :close-on-click-modal="false">
    <dialog-table>
      <el-form label-width="80px">
        <el-form-item label="订单编号">{{ currentOrder.id }}</el-form-item>
        <el-form-item label="支付方式">

        <span v-for="(p, i) in payType" :key="i">
          <span v-if="currentOrder.payTypeId === p.id">{{ p.payName }}</span>
        </span>
        </el-form-item>
        <el-form-item label="支付状态">{{ currentOrder.payStatus === 0 ? '未支付' : '已支付' }}</el-form-item>
        <el-form-item label="交易参数">
          <div class="pay-result-div">
            {{ currentOrder.paySend }}
          </div>
        </el-form-item>
        <el-form-item label="交易结果">
          <div class="pay-result-div">
            {{ currentOrder.payReport }}
          </div>
        </el-form-item>
      </el-form>
    </dialog-table>
    <div style="text-align: right;padding-top: 10px;">
      <page-button @click="showSerialNumber = false" el-type="primary">确认</page-button>
    </div>
  </el-dialog>

  <el-dialog title="支付流水号" v-model="pay.showPaySerial" width="600px" :close-on-click-modal="false">
    <el-input v-model="pay.paySerialNum" placeholder="请输入支付流水号"/>
    <div style="text-align: right;padding-top: 10px;">
      <page-button @click="pay.showPaySerial = false">取消</page-button>
      <page-button el-type="primary" @click="toSavePaySerial">保存</page-button>
    </div>
  </el-dialog>

  <el-dialog v-model="showOrder" width="30%" height="310px" :close-on-click-modal="false"
             :title="isPaying ? '订单结算&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支付中...' : '订单结算'"
             @opened="dialogAutoFocus">
    <sale-balance :sale-info="currentOrder" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                  :is-pay="true" :is-batch-pay="true" :is-refresh-pay="isRefreshPay" ref="autoFocus"
                  @find-sale-status="toFindPayStatus" @change-pay="changePay" @close-page="showOrder = false"
                  @pay="payReceipt" @batch-submit="batchSubmit" v-loading.fullscreen.lock="fullscreenPayLoading">
      <div style="display: flex;align-items: center">
        <el-checkbox v-model="isAutoPrint" label="打印凭证" size="large"/>
      </div>
    </sale-balance>
  </el-dialog>

  <el-dialog title="发票详情" v-model="showInvoiceDetail" width="60%" border>
    <dialog-table>
      <el-table stripe height="100%" :data="invoiceTicketList">
        <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
        <el-table-column label="批次号" show-overflow-tooltip :align="'center'" min-width="150px">
          <template v-slot="{row}">{{ row.settleCode }}</template>
        </el-table-column>
        <el-table-column label="发票类型" show-overflow-tooltip :align="'center'" min-width="150px">
          <template v-slot="{row}">{{ row.invoiceType === 1 ? '正票' : '红票' }}</template>
        </el-table-column>
        <el-table-column label="总金额" prop="invoiceVo.payAmount" show-overflow-tooltip :align="'center'"
                         min-width="150px"/>
        <el-table-column label="开票时间" prop="invoiceVo.makeTime" show-overflow-tooltip :align="'center'"
                         min-width="150px"/>
        <el-table-column label="操作" show-overflow-tooltip :align="'center'" min-width="150px">
          <template v-slot="{row}">
            <table-button el-type="primary" @click="getInvoiceResult(row)">查看</table-button>
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
  </el-dialog>

  <room-subscribe v-if="enableRoom" ref="subscribe" :order-id="orderId" :check-info="false" :show-btn="false" />
</template>

<script>
import SettlementComp from "@/components/h5/sale/SettlementComp.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import DialogTable from "@/components/h5/layout/DialogTable";
import Money from "@/components/h5/Money";
import PageHeader from "@/components/h5/layout/PageHeader";
import util from "@/util/util";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import {h} from "vue";
import SaleBalance from "@/components/h5/sale/saleBalance.vue";
import receiptApi from "@/api/main/order/receiptApi";
import {ElLoading, ElMessageBox} from "element-plus";
import systemInvoiceApi from "@/api/main/sys/systemInvoiceApi";
import TableSetting from "@/components/h5/column/TableSetting.vue";
import {
  ArrowDown, HomeFilled, Setting,
  Wallet
} from "@element-plus/icons-vue";
import stockApi from "@/api/main/stock/stockApi";
import memberApi from "@/api/main/customer/memberApi";
import localPrint from "@/util/new/LocalPrint";
import ListButton from "@/components/h5/button/ListButton.vue";
import RoomSubscribe from "@/views/main/sale/order/businessComponent/RoomSubscribe.vue";
import houseDocApi from "@/api/main/house/houseDocApi";
export default {
  name: "OrderList",
  props: {
    enableRoom: {default: false},
    selectOrderId: {default: ""},
  },
  computed: {
    Setting() {
      return Setting
    },
    Wallet() {
      return Wallet
    },
  },
  components: {
    ArrowDown,
    SaleBalance,
    EmployeeSelect,
    Dictionary,
    CustomerSelect,
    TableButton,
    PageButton, SettlementComp, TableSetting,
    ListPage, TableList, FormPage, SearchCondition, DateRange, DialogTable, Money, PageHeader, ListButton, RoomSubscribe
  },
  data() {
    return {
      orderId: "",
      searchInfo: {
        // customerId: "",
        orderStatus: null, //全部
        payStatus: null,
        customerName: "",
        orderId: "",
        departCodes: [],
      },
      fullscreenLoading: false,
      fullscreenPayLoading: false,
      loading: null,
      batchInfo: {},
      departList: [],
      dataList: [],
      stockList: [],
      showSerialNumber: false,
      currentOrder: {},
      showOrder: false,
      isAutoPrint: true,
      payType: [],
      bottomList: {},
      pay: {
        paySerialNum: "", //支付流水号
        showPaySerial: false,
        orderId: "",
      },
      showInvoice: false, //开具发票
      showInvoiceDetail: false, // 开票详情列表
      invoiceVo: {
        invoiceCodeType: 1,
      },
      invoiceTicketList: [],
      disableToClickPay: false,
      preparePay: false,
      currentAmountCollect: "",
      employeeList: [],
      isActual: true,
      isRefreshPay: false,
      isPaying: false,
      // 计时器，支付中时开启
      timerToPayResult: null,
      // 所有已对接的发票列表
      invoiceTypeList: [],
      // 设置读的权限值
      readAccess: null,
      // 支付记录
      payRecords: {},
      showPayRecord: false,
      myTimer: null,
      showSubscribe: false,
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    },
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 获取发票列表
    systemInvoiceApi.queryAllList().then(res => this.invoiceTypeList = res);
    // 员工
    commonApi.queryAllEmployee().then(list => this.employeeList = list);
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询全部仓库
    stockApi.queryListByNotCondition().then(list => this.stockList = list);
    // 查询默认的时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    systemPayApi.queryAllList().then(res => this.payType = res);
  },
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.orderNumberInput.focus()
    });
  },
  unmounted() {
    if (this.myTimer !== null) {
      clearInterval(this.myTimer);
    }
  },
  methods: {
    /**
     * 预约单据信息
     * @param orderId 单据id
     */
    otherResetSearch(orderId){
      this.$nextTick(()=>{
        this.searchInfo.orderId = orderId;
        // 获取时间
        this.searchInfo.startTime = new Date(Number(orderId.substring(0, 4)), Number(orderId.substring(4, 6)) - 1, 1).format("yyyy-MM-dd") + " 00:00:00";
        this.searchInfo.endTime = new Date(Number(orderId.substring(0, 4)), Number(orderId.substring(4, 6)) - 1, 29).format("yyyy-MM-dd") + " 23:59:59";
        this.resetSearch();
      })
    },
    findSubscribe(){
      this.orderId = null;
      this.$refs.subscribe.showSub(null, false, null, null, false);
    },
    showRoom(row){
      // 附件id
      offlineOrderApi.findAttachment(row.id).then(data => {
        if(!data){
          this.orderId = row.id;
          // 查询类型
          houseDocApi.findByOrderId(row.id).then(typeId => {
            this.$refs.subscribe.showSub(null, false, row.customerId, typeId);
          })
        }else{
          this.orderId = null;
          this.$refs.subscribe.showSub(data.dataId, false, row.customerId);
        }
      })
    },
    toRefresh(row){
      this.findById(row.id).then(() => {
        this.toFindPayStatus().then(() => {
          this.queryList();
        });
      })
    },
    zfjl(row){
      this.findById(row.id)
      this.getPayRecord(row);
    },
    // 红冲
    toShowRedInvoice(row) {
      ElMessageBox.confirm(
          '您确定要红冲吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        systemInvoiceApi.findRedInvoice(row.id, row.invoiceInterfaceCode).then(() => {
          this.queryList();
          this.$message.success("红冲成功！");
        });
      })
    },
    getPayRecord(row) {
      receiptApi.queryPayRecords(row.id).then(res => {
        this.payRecords.recordList = res;
        this.payRecords.detail = row;
        this.showPayRecord = true;
      });
    },
    termination(row) {
      ElMessageBox.confirm(
          '您确定要终止这笔订单吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        offlineOrderApi.termination(row.id).then(() => {
          this.$message.success("订单已终止");
          this.queryList();
        })
      })
    },
    exchangeGood(row){
      this.$routerUtil.push(`/change/document/detail?id=${row.id}`);
    },
    toRefund(row) {
      offlineOrderApi.findHasRefundOrder(row.id).then(res => {
        res
            ? this.$routerUtil.push(`/report/refund-order/detail?id=${res}&isFinish=2`)  // 有未处理退货单走回显逻辑
            : this.$routerUtil.push(`/report/refund-order/detail?id=${row.id}&isFinish=1`)  // 无未处理退货单走调入订单
      })
    },
    setDepartCode(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          arr.push(data[i][j]);
        }
      }
      this.batchInfo.departCodes = arr;
    },
    dialogAutoFocus() {
      this.$nextTick(() => {
        this.$refs.autoFocus.initInput();
      })
    },
    changePay(data) {
      receiptApi.updateRefundType(this.currentOrder.id, data.id, data.payCode)
    },
    batchPay(row) {
      this.findById(row.id).then(() => {
        if (this.currentOrder.amountCurrent !== 0) {
          ElMessageBox.confirm('您上次的提交的支付信息还未结算，确认继续吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.showOrder = true;
          })
        } else {
          this.showOrder = true;
        }
        this.isActual = false;
        this.currentOrder.amountCollect = this.currentOrder.amountShould;
      })
    },
    batchSubmit(data) {
      this.preparePay = true;
      this.disableToClickPay = true;
      this.currentAmountCollect = data.amountCollect;
      offlineOrderApi.updateAmount(this.currentOrder.id, data.amountCollect).then(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
        this.$message.success("提交成功");
        if (this.isAutoPrint) {
          this.findById(this.currentOrder.id).then(() => {
            this.toBatchPrint();
          })
        }
        this.showOrder = false;
        this.queryList();
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      });
    },
    setEmployee(data) {
      this.batchInfo.employeeIds = data;
    },
    setCreated(data) {
      this.batchInfo.createdIds = data;
    },
    setPayee(data) {
      this.batchInfo.payeeIds = data;
    },
    clearDic() {
      this.batchInfo.departCode = "";
    },
    // 关闭发票界面前，清空掉数据
    closeInvoice() {
      this.invoiceVo = {
        invoiceCodeType: 1,
        invoiceTitle: "",
        invoiceType: "",
        name: "",
        buyerTel: "",
        buyerAddress: "",
      };
    },
    getInvoiceResult(row) {
      let loading = ElLoading.service({
        lock: true,
        text: '正在努力开票中...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let data = {
        invoiceCodeType: this.invoiceVo.invoiceCodeType, // 发票接口
        dxInvoiceVo: this.invoiceVo,
      }
      // if(row){
      //   data.invoiceCodeType = row.invoiceInterfaceCode;
      // }
      // row不存在的时候默认开的是红票
      data.dxInvoiceVo.invoiceType = "1";
      if (row) {
        // 正票、红票
        data.dxInvoiceVo.invoiceType = row.invoiceType;
        // 订单id
        data.dxInvoiceVo.orderId = row.originalId;
        // 获取发票接口
        data.invoiceCodeType = this.invoiceVo.invoiceCodeType;
      }
      systemInvoiceApi.findInvoice(data).then(res => {
        // window.open(process.env.VUE_APP_FILE_URL+res,'_blank');
        window.open(process.env.VUE_APP_FILE_URL + res, '_blank');
        // window.open(config.fileUrl + res, '_blank');
        loading.close();
        this.showInvoice = false;
        this.queryList();
      }).catch(() => {
        loading.close();
      });
    },
    toShowInvoice(row) {
      if (row.invoiceIssue === 1) {
        // 当前还未开具过发票，打开发票页面
        this.handleInvoiceData(row);
        this.showInvoice = true;
      } else {
        // 已经开具了发票，展示发票列表
        this.invoiceVo.orderId = row.id;
        // 设置开票的接口
        this.invoiceVo.invoiceCodeType = row.invoiceInterfaceCode;
        offlineOrderApi.findTicketsByOrderId(row.id).then(res => {
          this.invoiceTicketList = res;
        })
        this.showInvoiceDetail = true;
      }
    },
    handleInvoiceData(row) {
      if (row.customerName === "临时客户") {
        this.invoiceVo.name = "个人";
        this.invoiceVo.invoiceTitle = "个人";
      } else {
        this.invoiceVo.name = row.customerName;
        this.invoiceVo.invoiceTitle = row.customerName;
      }
      this.invoiceVo.orderId = row.id;
      this.invoiceVo.buyerTel = row.customerPhone;
      this.invoiceVo.buyerAddress = row.customerAddress;
      this.invoiceVo.payAmount = row.amount;
      this.invoiceVo.totalCount = row.qty;
    },
    toAddNew() {
      this.$routerUtil.push({path: `/report/offline-order/detail`})
    },
    setCustomer(data) {
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    // 导出
    exportData() {
      this.handleBatchSearch();
      util.exportExcel("/order/saleOrder/offlineOrder/exportList", this.searchInfo);
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },

    handleBatchSearch() {
      if (this.batchInfo.createdIds && this.batchInfo.createdIds.length > 0) {
        this.searchInfo.createdId = this.batchInfo.createdIds.join(',');
      } else {
        this.searchInfo.createdId = "";
      }
      if (this.batchInfo.departCodes && this.batchInfo.departCodes.length > 0) {
        this.searchInfo.departCode = this.batchInfo.departCodes.join(',');
      } else {
        this.searchInfo.departCode = "";
      }
      if (this.batchInfo.employeeIds && this.batchInfo.employeeIds.length > 0) {
        this.searchInfo.employeeId = this.batchInfo.employeeIds.join(',');
      } else {
        this.searchInfo.employeeId = "";
      }
      if (this.batchInfo.payTypeIds && this.batchInfo.payTypeIds.length > 0) {
        this.searchInfo.payTypeId = this.batchInfo.payTypeIds.join(',');
      } else {
        this.searchInfo.payTypeId = "";
      }
      if (this.batchInfo.payeeIds && this.batchInfo.payeeIds.length > 0) {
        this.searchInfo.payeeId = this.batchInfo.payeeIds.join(',');
      } else {
        this.searchInfo.payeeId = "";
      }
      if (this.batchInfo.stockIds && this.batchInfo.stockIds.length > 0) {
        this.searchInfo.stockId = this.batchInfo.stockIds.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
    },

    queryList() {
      this.queryBottomList({...this.searchInfo, ...this.batchInfo});
      this.handleBatchSearch();
      offlineOrderApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
        this.$forceUpdate();
      })
    },
    queryBottomList(searchInfo) {
      // let searchVo = searchInfo;
      // searchVo.orderType = 1;
      orderStatisticApi.querySaleStatisticInfo(searchInfo).then(res => this.bottomList = res);
    },
    toDelete(row) {
      ElMessageBox.confirm('确定删除该订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        offlineOrderApi.delete(row.id).then(() => {
          this.queryList();
        })
      })
    },
    toShowPaySerial(row) {
      if (row.status === 99) {
        this.pay.showPaySerial = true;
        this.pay.orderId = row.id;
      }
    },
    toSavePaySerial() {
      offlineOrderApi.savePaySerial(this.pay.orderId, this.pay.paySerialNum).then(() => {
        this.queryList();
        this.pay.showPaySerial = false;
      })
    },
    toShowSerialNumber(data) {
      this.showSerialNumber = true;
      this.currentOrder = {...data};
    },
    getSummary(param) {
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return
        }
        // 数量合计
        if (index === 4) {
          sums[index] = h('div', [Number(this.bottomList.totalQty).toFixed(0)])
        }
        // 总金额合计
        if (index === 5) {
          sums[index] = h('div', [Number(this.bottomList.totalAmountAll).toFixed(2)])
        }
        // 明细优惠合计
        if (index === 6) {
          sums[index] = h('div', [Number(this.bottomList.totalDiscount).toFixed(2)])
        }
        //整单抹零
        // if (index === 7) {
        //   sums[index] = h('div', [Number(this.bottomList.totalBillDiscount).toFixed(2)])
        // }
        // 未支付金额
        if (index === 9) {
          sums[index] = h('div', [Number(this.bottomList.notTotalAmount).toFixed(2)])
        }
        // 已支付金额
        if (index === 8) {
          sums[index] = h('div', [Number(this.bottomList.totalAmount).toFixed(2)])
        }
        // 订单应收金额(总金额 - 全部优惠)
        if (index === 7) {
          sums[index] = h('div', [Number(this.bottomList.totalAmountResult).toFixed(2)])
        }
      })
      return sums
    },

    findById(id) {
      return offlineOrderApi.findById(id).then(data => {
        this.currentOrder = data;
      })
    },
    payReceipt(data) {
      let myCount = 60;
      this.myTimer = setInterval(() => {
        if (myCount > 0) {
          myCount--;
          this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
        } else {
          clearInterval(this.myTimer);
          this.loading.close();
        }
      }, 1000)
      this.loading = ElLoading.service({
        lock: true,
        text: `获取支付结果中，${myCount}秒后自动更新...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 赋值
      this.isActual = true;
      this.currentAmountCollect = data.amountCollect;
      this.currentOrder.amountCollect = data.amountCollect;
      this.currentOrder.qrCode = data.qrCode;
      // 收费标签
      this.currentOrder.paymentType = 2;
      // 锁定操作状态
      if (this.preparePay) {
        console.log("正在支付中，不能进行操作")
        return;
      }
      this.preparePay = true;
      this.disableToClickPay = true;
      this.isPaying = true;
      offlineOrderApi.preparePay(this.currentOrder).then(saleOrder => {
        // 刷新数据
        this.findById(saleOrder.id).then(() => {
          if (this.isAutoPrint && this.currentOrder.payStatus >= 50) {
            this.toPrint();
            this.loading.close();
          }
          if (this.currentOrder.payStatus === 99 || this.currentOrder.payStatus === 50) {
            this.successResult();
            this.$message.success("支付成功");
            this.loading.close();
          } else {
            // this.$message.warning("支付中，请稍后刷新支付状态！！！")
            this.toFindPayStatus();
          }
        })
        this.queryList();
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
        this.loading.close();
        this.isPaying = false;
      })
    },
    // 支付记录补打
    toSinglePrint(row) {
      // 收费人员
      this.currentOrder.payeeId = row.payeeId;
      // 本次收费
      this.currentAmountCollect = row.currentAmount;
      // 收费时间
      this.currentOrder.orderTime = row.payTime;
      // 已收金额
      let idx = this.payRecords.recordList.findIndex(item => item.id === row.id);
      this.currentOrder.amountCollect = 0;
      for (let i = 0; i <= idx; i++) {
        this.currentOrder.amountCollect += this.payRecords.recordList[i].currentAmount;
      }
      // 支付方式
      this.currentOrder.payTypeId = row.payTypeId;
      // 未收金额
      this.currentOrder.amountShould = this.payRecords.detail.amount - this.currentOrder.amountCollect;
      // 应收金额
      this.currentOrder.amount = this.payRecords.detail.amount;
      this.toPrint();
    },
    async toPrint() {
      // 组装打印数据
      const customerInfo = await memberApi.findById(this.currentOrder.customerId);
      let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'), "收费凭证");
      localPrint.print("收费凭证", data);
    },
    // 提交的打印
    async toBatchPrint() {
      // 组装打印数据
      const customerInfo = await memberApi.findById(this.currentOrder.customerId);
      let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'), "销售凭证");
      localPrint.print("销售凭证", data);
    },
    successResult() {
      this.showOrder = false;
      this.disableToClickPay = false;
      this.isPaying = false;
      this.preparePay = false;
    },
    toFindPayStatus() {
      if (this.isRefreshPay) this.$message.warning("正在努力获取结果，请耐心等待！！！")
      this.isRefreshPay = true;
      return offlineOrderApi.findPayStatus(this.currentOrder.id, this.currentOrder.payId).then(res => {
        this.findById(this.currentOrder.id).then(() => {
          if (this.currentOrder.payStatus >= 50) {
            this.successResult();
            this.currentAmountCollect = this.currentOrder.amountCurrentToPrint;
            this.$message.success(res);
            clearInterval(this.timerToPayResult);
            this.loading.close();
            this.queryList();
            if (this.isAutoPrint) {
              this.toPrint();
            }
            return;
          }
          if (this.currentOrder.payStatus === 30) {
            this.successResult();
            this.$message.error(res);
            clearInterval(this.timerToPayResult);
            this.loading.close();
            this.queryList();
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          clearInterval(this.timerToPayResult);
          this.loading.close();
          return;
        } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
          // this.$message.warning("正在努力获取结果，请耐心等待！！！");
        }
        this.isRefreshPay = false;
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus();
        }, 2000);
      }).catch(() => {
        this.isRefreshPay = false;
        this.loading.close();
      })
    },
    // 获取打印的参数
    getPrintData(type, customerName, customerPhone, name) {
      let data = {
        reportName: name,
        amount: this.currentOrder.amount,
        amountActual: this.isActual ? this.currentAmountCollect : 0,
        amountCollect: this.currentAmountCollect, // 需要打印本次支付的金额
        amountShould: this.currentOrder.amountShould,
        customerName: customerName,
        customerPhone: customerPhone,
        orderNumber: this.currentOrder.id,
        amountAlreadyReceived: this.currentOrder.amountCollect,
        orderTime: this.currentOrder.orderTime,
        employeeName: this.currentOrder.employeeName,
        createdName: this.currentOrder.createdName,
        countQty: this.currentOrder.qty,
        excuteType: type,
      }

      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.currentOrder.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      //仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.currentOrder.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.currentOrder.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
        if (this.currentOrder.payeeId === this.employeeList[i].id) {
          data.payeeName = this.employeeList[i].employeeName
        }
      }
      // 支付状态
      if (this.currentOrder.payStatus === 0) {
        data.payStatus = "未支付";
      } else if (this.currentOrder.payStatus === 99) {
        data.payStatus = "已支付";
      } else if (this.currentOrder.payStatus === 30) {
        data.payStatus = "支付失败";
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.currentOrder.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }
      let arr = [];
      for (let i = 0; i < this.currentOrder.productList.length; i++) {
        if (this.currentOrder.productList[i].qty === 0) {
          continue;
        }
        let p = this.currentOrder.productList[i];
        arr.push({
          productIndex: i + 1,
          productName: p.skuName,
          productNumber: p.barCode,
          productPrice: p.skuOriginalPrice, //商品原价
          productCount: p.qty,
          productTotalPrice: p.qty * p.skuSalePrice,
          productDisCount: p.rate,
          productUnitName: p.skuUnitName,
          productSalePrice: p.skuSalePrice, //售价
        })
      }
      data.productList = arr;
      return data;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog.scss";
@import "@/assets/css/el-tables";
.headInfo-sty {
  display: flex;
  flex-direction: row;
}


.pay-result-div {
  width: 100%;
  word-break: break-all;
}

:deep(.el-dialog__header) {
  color: red;
}

:deep(.el-dialog) {
  padding: 0;

  .el-dialog__header.show-close {
    text-align: left;
    //background: #409eff;
    background: #f5f7fa;
    //color: #ff0000;
    padding: 0 0 0 16px;
    line-height: 40px;

    .el-dialog__title {
      color: #181818;
      //color: black;
      //font-weight: bolder;
    }

    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #0e0e0e;
        font-weight: bolder;
      }
    }
  }

  .el-dialog__body {
    padding: 16px;
  }
}

.headInfo-sty {
  display: flex;
  flex-direction: row;
}

.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}

.pay-result-div {
  width: 100%;
  word-break: break-all;
}

.add:hover {
  text-decoration: underline;
}

.block-col-2 .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.block-col-2 .el-dropdown-link {
  display: flex;
  align-items: center;
}



</style>