<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="generate">生成溯源码</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <el-input v-model="searchInfo.searchKey" placeholder="请扫描或输入溯源码" @keydown.enter="resetSearch"/>
          <el-input v-model="searchInfo.name" placeholder="请输入物资编号/名称/型号" @keydown.enter="resetSearch"/>
          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.category" :dictionaries="categoryList" dict-code="dictCode"
                        dict-name="dictName" :place-holder="'请选择商品分类'"
                        @clear-data="clearCode" style="width: 100%"/>
          </div>

        </template>
        <template #moreCondition>
          <el-select v-model="searchInfo.stockStatus" clearable
                     placeholder="请选择状态" style="width: 240px">
            <el-option label="在库" :value="1" />
            <el-option label="维修" :value="2" />
            <el-option label="领用" :value="3" />
            <el-option label="报废" :value="4" />
          </el-select>
          <el-select v-model="searchInfo.isExist" clearable
                     placeholder="请选择是否添加溯源码" style="width: 240px">
            <el-option label="已添加" :value="1" />
            <el-option label="未添加" :value="2" />
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" ref="multipleTable" height="100%"  @row-click="clickRow">
          <el-table-column align="center" type="selection" width="50px" fixed/>
          <el-table-column align="center" type="index" label="序号" width="60px" fixed/>
          <el-table-column align="center" label="物资状态" min-width="160px">
            <template v-slot="{row}">
              <span v-if="row.wzType === 0">在库</span>
              <span v-if="row.wzType === 1">使用</span>
              <span v-if="row.wzType === 2">报废</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="维保状态" min-width="120px">
            <template v-slot="{row}">
              <span v-if="row.stockStatus === 1">正常</span>
              <span v-if="row.stockStatus === 2">维修</span>
              <span v-if="row.stockStatus === 3">正常</span>
              <span v-if="row.stockStatus === 4">报废</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="物资分类" prop="categoryName" min-width="160px" show-overflow-tooltip />
          <el-table-column align="center" label="物资编号" prop="skuCode" min-width="120px" show-overflow-tooltip />
          <el-table-column align="center" label="物资名称" prop="skuName" min-width="180px" show-overflow-tooltip />
          <el-table-column align="center" label="规格" prop="skuStandard" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.skuStandard">{{row.skuStandard}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="型号" prop="skuType" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.skuType">{{row.skuType}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="溯源码" prop="originalCode" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.originalCode">{{row.originalCode}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="使用机构" prop="recipientDepartName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.recipientDepartName">{{row.recipientDepartName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="使用人" prop="recipientName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.recipientName">{{row.recipientName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="购入日期" prop="firstInstockTime" min-width="200px" show-overflow-tooltip />
          <el-table-column align="center" label="报废日期" prop="scrapTime" min-width="160px" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.scrapTime ? row.scrapTime.split(" ")[0] : "-" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="维保单位" prop="maintainUnitName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.maintainUnitName">{{row.maintainUnitName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="上次维保日期" prop="lastMtTime" min-width="200px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.lastMtTime">{{row.lastMtTime}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" min-width="120px">
            <template v-slot="{row}">
              <table-button type="read" el-type="primary" @click="toView(row)">查看</table-button>
              <table-button type="read" el-type="primary" :disabled="!row.originalCode" @click="gen(row)">小程序码</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>
  </div>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import mtLegderApi from "@/api/main/material/mtLegderApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
export default {
  components: {
    Dictionary,
    PageButton,
    FormPage,
    TableButton,
    ListPage, SearchCondition, TableList, DateRange,  PageHeader, ProductListSelect
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      categoryList: [],
    };
  },
  created() {
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  methods: {
    gen(row){
      // 生成二维码信息、返回一个zip文件
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = `${process.env.VUE_APP_BASE_URL}/order/base/getStoreCode?storeId=${row.originalCode}&storeName=${row.skuName}`;
      document.body.appendChild(link);
      link.click();
    },
    clickRow(data) {
      data.checked = !data.checked
      this.$refs.multipleTable.toggleRowSelection(data, data.checked);
    },
    generate(){
      // 生成
      let originalList = this.$refs.multipleTable.getSelectionRows();
      // 删除已添加溯源码的数据
      let list = originalList.filter(item => !item.originalCode);
      //
      if(list.length < originalList.length){
        this.$message.error("请选择未生成溯源码的台账信息！");
        return;
      }
      // 按skuId进行分类
      let map = new Map();
      if(list.length === 0){
        this.$message.error("请选择台账信息！");
        return;
      }
      for (let i = 0; i < list.length; i++) {
        if(map.has(list[i].id)){
          let sdList = map.get(list[i].id);
          sdList.push(list[i]);
          map.set(list[i].id, sdList);
        }else{
          map.set(list[i].id, [list[i]]);
        }
      }
      let resultList = [];
      // 遍历
      map.forEach((value, key) => {
        resultList.push({
          skuId: key,
          count: value.length,
          skuName: value[0].skuName
        })
      })
      mtLegderApi.toGenerate(resultList).then(data => {
        // mtLegderApi.exportData(data);
        util.export3Excel("/order/material/ledger/exportData", data);
      });
    },
    queryList(){
      mtLegderApi.queryList(this.searchInfo).then(data => {
        this.dataList = data.records;
        this.searchInfo.totalCount = data.totalCount;
      });
    },
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    toView(data){
      this.$routerUtil.push(`/stock/ledger/detail?id=${data.productId}&code=${data.originalCode}`);
    },
    // 移除分类编码
    clearCode() {
      this.searchInfo.category = "";
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>