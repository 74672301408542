<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" type="delete" @click="resetSearch">查询</page-button>
          <!--          <page-button el-type="primary" @click="exportData">导出</page-button>-->
<!--          <page-button el-type="primary" type="print" @click="exportData">导出</page-button>-->
        </template>
      </page-header>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>

          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>

          <el-input ref="orderNumberInput" v-model="searchInfo.orderId" placeholder="请扫描/输入单据号"
                    @change="resetSearch"/>

          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>

        </template>
        <template #moreCondition>
          <div v-if="readAccess === '3'" style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.departCode" @clearData="()=>batchInfo.departCode = ''" :place-holder="'请选择部门'"
                        :dictionaries="departList"
                        :is-multiple="true"
                        @change-data="setDepartCode"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>

          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择职员'" :is-multiple="true" v-model="searchInfo.employeeId"
                             @select-more="(data)=>batchInfo.employeeIds = data"/>
          </div>

          <el-select v-model="batchInfo.stockIds" multiple collapse-tags collapse-tags-tooltip placeholder="请选择仓库"
                     clearable>
            <el-option v-for="(st, i) in stockList" :key="i + 's'" :label="st.stockName" :value="st.id"/>
          </el-select>

          <el-select v-model="searchInfo.payStatus" placeholder="请选择支付状态" clearable>
            <el-option label="未支付" :value="0"/>
            <el-option label="部分支付" :value="50"/>
            <el-option label="支付完成" :value="99"/>
          </el-select>

          <el-select v-model="searchInfo.orderStatus" placeholder="请选择订单状态" clearable>
            <el-option label="草稿" :value="0"/>
            <el-option label="未完成" :value="50"/>
            <el-option label="已完成" :value="99"/>
          </el-select>

          <el-select v-model="searchInfo.isSelectFp" placeholder="请选择查询发票" clearable>
            <el-option label="已开具发票" :value="2"/>
            <el-option label="未开具发票" :value="1"/>
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" :default-sort="{ prop: 'id', order: 'descending' }">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="单据编号" prop="id" show-overflow-tooltip min-width="180px" sortable
                           fixed>
            <template v-slot="{row}">
              <table-button class="add" el-type="primary" type="write"
                            @click="$routerUtil.push(`/report/offline-order/detail?id=${row.id}`)">{{ row.id }}
              </table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="单据日期" prop="orderTime" show-overflow-tooltip min-width="160px"/>
          <el-table-column align="center" label="客户" prop="customerName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="数量" prop="qty" min-width="60px"/>
          <el-table-column align="center" label="金额" prop="amount" min-width="100px">
            <template v-slot="{ row }">
              <money :value="row.amount"/>
            </template>
          </el-table-column>
<!--          <el-table-column align="center" label="经办人" prop="employeeName" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="部门" prop="departName" min-width="150px"/>-->
<!--          <el-table-column align="center" label="仓库" prop="stockName" min-width="150px"/>-->

          <el-table-column align="center" label="支付状态" min-width="100px">
            <template v-slot="{ row }">
              <el-tag v-if="row.refundQty && row.refundQty > 0" type="warning">已退款</el-tag>
              <el-tag v-else-if="row.payStatus === 0" type="info">未支付</el-tag>
              <el-tag v-else-if="row.payStatus === 20" type="warning">支付中</el-tag>
              <el-tag v-else-if="row.payStatus === 50" type="primary">部分支付</el-tag>
              <el-tag v-else-if="row.payStatus === 30" type="danger">支付失败</el-tag>
              <el-tag v-else-if="row.payStatus === 99" type="success">支付完成</el-tag>
            </template>
          </el-table-column>
<!--          <el-table-column align="center" label="支付时间" show-overflow-tooltip min-width="160px" sortable>-->
<!--            <template v-slot="{ row }">-->
<!--              <span v-if="row.payTime == null">暂未支付</span>-->
<!--              <span v-else>{{ row.payTime }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column align="center" label="订单状态" min-width="80px">
            <template v-slot="{ row }">
              <el-tag type="warning" v-if="row.status === 0">草稿</el-tag>
              <el-tag type="info" v-if="row.status === 40">已取消</el-tag>
              <el-tag type="warning" v-if="row.status === 50">未完成</el-tag>
              <el-tag type="success" v-else-if="row.status === 99">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="自提/配送" min-width="100px">
            <template v-slot="{ row }">
              <span v-if="row.miniOrderDetail.type === 1">客户自提</span>
              <span v-else>商家配送</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="到店时间(自提)" min-width="140px">
            <template v-slot="{row}">
              <span v-if="row.miniOrderDetail.type !== 1"> - </span>
              <span v-else> {{row.miniOrderDetail.arriveTime}} </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="配送人员(配送)" prop="miniOrderDetail.deliveryName" min-width="140px">
            <template v-slot="{row}">
              <span v-if="row.miniOrderDetail.type !== 2"> - </span>
              <span v-else>
                <span v-if="row.miniOrderDetail.deliveryName">{{row.miniOrderDetail.deliveryName}}</span>
                <table-button el-type="primary" v-else @click="setPsy(row)">配送人员</table-button>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="配送信息" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <span v-if="row.miniOrderDetail.type !== 2"> - </span>
              <table-button el-type="primary" @click="toShowAddress(row.miniOrderDetail.addressDto)" v-else>详细地址</table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单备注" prop="remark" show-overflow-tooltip min-width="120px">
            <template v-slot="{row}">
              <span v-if="row.remark === '' || !row.remark"> - </span>
              <span v-else>{{ row.remark }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column align="center" label="支付流水号" prop="outTradeNo" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="发票号码" prop="invoiceNo" show-overflow-tooltip min-width="100px"/>-->
          <el-table-column label="操作" :align="'center'" min-width="100px" fixed="right">
            <template v-slot="{ row }">
              <el-dropdown placement="left" size="default" style="padding-top: 6px"
                           @command="handleCommand({ row, command: $event})">
                <span class="el-dropdown-link">
                  <table-button>操作<el-icon class="el-icon--right"><arrow-down/></el-icon></table-button>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :icon="UserFilled" command="p-s-y"
                                      :disabled="row.status !== 50 || row.payStatus !== 99 || row.miniOrderDetail.type === 1 || row.refundQty > 0">选择配送人员
                    </el-dropdown-item>
                    <el-dropdown-item :icon="UserFilled" command="d-d-w-c"
                                      :disabled="row.status === 99">订单完成
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
    <el-dialog title="选择配送员" v-model="showSelectDelivery" width="30%">
      <employee-select @select-one="(data)=>this.currentSelectDelivery = data" :access="readAccess"/>
      <div style="display: flex;justify-content: flex-end;margin-top: 10px">
        <div>
          <page-button @click="showSelectDelivery = false">取消</page-button>
          <page-button el-type="primary" @click="setDelivery">
            确认
          </page-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="地址信息" v-model="showAddress" width="30%">
      <el-form label-width="70px">
        <el-form-item label="客户名称">
          {{ addressDetail.name }}
        </el-form-item>
        <el-form-item label="联系电话">
          {{ addressDetail.addressPhone }}
        </el-form-item>
        <el-form-item label="配送地址">
          {{ addressDetail.addressName }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import PageHeader from "@/components/h5/layout/PageHeader.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import {ArrowDown, UserFilled, Wallet} from "@element-plus/icons-vue";
import commonApi from "@/api/commonApi";
import stockApi from "@/api/main/stock/stockApi";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import util from "@/util/util";
import Money from "@/components/h5/Money.vue";
import miniOrderApi from "@/api/main/mini/miniOrderApi";
import {ElMessageBox} from "element-plus";

export default {
  name: "",
  computed: {
    UserFilled() {
      return UserFilled
    },
    Wallet() {
      return Wallet
    }
  },
  components: {
    ArrowDown,
    DateRange,
    FormPage,
    TableList,
    EmployeeSelect,
    Dictionary,
    SearchCondition,
    TableButton,
    PageButton,
    ListPage,
    CustomerSelect,
    PageHeader,
    Money
  },
  data() {
    return {
      // 读的权限
      readAccess: null,
      // 查询条件
      searchInfo: {},
      // 数据列表
      dataList: [],
      // 批量查询条件
      batchInfo: {},
      // 部门
      departList: [],
      // 仓库
      stockList: [],
      // 底部统计
      bottomInfo: {},
      // 选择配送人员
      showSelectDelivery: false,
      // 当前的小程序订单详情信息
      currentMiniDetail: {},
      // 当前选择的配送员
      currentSelectDelivery: {},
      // 打印小票
      isAutoPrint: true,
      addressDetail: {},
      showAddress: false,
    };
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询全部仓库
    stockApi.queryListByNotCondition().then(list => this.stockList = list);
    // 初始化时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  methods: {
    toShowAddress(data){
      this.addressDetail = data;
      this.showAddress = true;
    },
    // 重置查询条件
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询
    queryList(){
      this.handleBatchSearch();
      offlineOrderApi.queryListForMini(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 操作
    handleCommand(data){
      if (data.command === "p-s-y") {
        this.setPsy(data.row);
      }else if(data.command === "d-d-w-c"){
        ElMessageBox.confirm("确认订单已完成？", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          offlineOrderApi.updateOrderStatus(data.row.id).then(() => {
            this.$message.success("操作成功！");
            this.queryList();
          });
        })
      }
    },
    setPsy(row){
      this.showSelectDelivery = true;
      this.currentMiniDetail = row.miniOrderDetail;
    },
    // 选择配送人员
    setDelivery(){
      miniOrderApi.updateDelivery({id:this.currentMiniDetail.id,employeeId: this.currentSelectDelivery.id}).then(()=> {
        this.$message.success("操作成功");
        this.showSelectDelivery = false;
        this.queryList();
      });
    },
    // 设置部门
    setDepartCode(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push(data[i][0]);
      }
      this.batchInfo.departCodes = arr
    },
    // 设置客户
    setCustomer(data) {
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    // 处理批量查询的参数
    handleBatchSearch() {
      if (this.batchInfo.createdIds && this.batchInfo.createdIds.length > 0) {
        this.searchInfo.createdId = this.batchInfo.createdIds.join(',');
      } else {
        this.searchInfo.createdId = "";
      }
      if (this.batchInfo.departCodes && this.batchInfo.departCodes.length > 0) {
        this.searchInfo.departCode = this.batchInfo.departCodes.join(',');
      } else {
        this.searchInfo.departCode = "";
      }
      if (this.batchInfo.employeeIds && this.batchInfo.employeeIds.length > 0) {
        this.searchInfo.employeeId = this.batchInfo.employeeIds.join(',');
      } else {
        this.searchInfo.employeeId = "";
      }
      if (this.batchInfo.payTypeIds && this.batchInfo.payTypeIds.length > 0) {
        this.searchInfo.payTypeId = this.batchInfo.payTypeIds.join(',');
      } else {
        this.searchInfo.payTypeId = "";
      }
      if (this.batchInfo.payeeIds && this.batchInfo.payeeIds.length > 0) {
        this.searchInfo.payeeId = this.batchInfo.payeeIds.join(',');
      } else {
        this.searchInfo.payeeId = "";
      }
      if (this.batchInfo.stockIds && this.batchInfo.stockIds.length > 0) {
        this.searchInfo.stockId = this.batchInfo.stockIds.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
</style>