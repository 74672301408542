<template>
  <!-- -->
  <div class="defaultViewStyle">

  </div>
</template>

<script>

export default {
  name: "",
  components: {
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>