<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="ex" el-type="primary" @click="exportRefundList">导出</page-button>
          <page-button type="write" el-type="success" @click="$routerUtil.push(`/report/refund-order/detail`)">新建</page-button>
        </template>
      </page-header>
      <search-condition :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime" />

          <el-input ref="orderNumberInput" v-model="searchInfo.orderId" placeholder="请扫描/输入单据号" @change="resetSearch"/>

          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer" />
          </div>

        </template>
        <template #moreCondition>
          <div v-if="readAccess === '3'" style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.departCode" @clearData="clearDic" :place-holder="'请选择部门'" :dictionaries="departList"
                        :is-multiple="true"
                        @change-data="setDepartCode"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择职员'" v-model="searchInfo.employeeId" :is-multiple="true" @select-more="setEmployee"/>
          </div>
          <div  v-if="readAccess === '2' || readAccess === '3'" class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择创建人'" v-model="searchInfo.createdId" :is-multiple="true" @select-more="setCreated"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择收款人'" v-model="searchInfo.payeeId" :is-multiple="true" @select-more="setPayee"/>
          </div>
          <el-select v-model="batchInfo.stockIds" placeholder="请选择仓库" multiple collapse-tags collapse-tags-tooltip clearable>
            <el-option v-for="(st, i) in stockList" :key="i + 's'" :label="st.stockName" :value="st.id" />
          </el-select>
          <el-select v-model="batchInfo.payTypeIds" placeholder="请选择支付方式" multiple collapse-tags collapse-tags-tooltip clearable>
            <el-option v-for="(st, i) in payType" :key="i + 'p'" :label="st.payName" :value="st.id"/>
          </el-select>
          <el-select v-model="searchInfo.payStatus" placeholder="请选择支付状态" clearable>
            <el-option label="未退款" :value="0" />
            <el-option label="已退款" :value="99" />
          </el-select>
          <el-select v-model="searchInfo.orderStatus" placeholder="请选择订单状态" clearable>
            <el-option label="草稿" :value="0" />
            <el-option label="已完成" :value="99" />
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" show-summary :summary-method="getSummary">
          <el-table-column align="center" label="序号" type="index" width="60px" fixed />
          <el-table-column align="center" label="单据编号" prop="id" show-overflow-tooltip min-width="200px" sortable fixed>
            <template v-slot="{row}">
              <table-button class="add" el-type="primary" type="write" @click="$routerUtil.push(`/report/refund-order/detail?id=${row.id}&isFinish=2`)">{{row.id}}</table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="单据日期" prop="createdTime" show-overflow-tooltip min-width="160px" />
          <el-table-column align="center" label="客户" prop="customerName" min-width="100px" show-overflow-tooltip/>
          <el-table-column align="center" label="合计数量" prop="qty" min-width="100px"/>
          <el-table-column align="center" label="合计金额" prop="amount" min-width="130px"/>
          <el-table-column align="center" min-width="100px" label="经办人" prop="employeeName" show-overflow-tooltip/>
          <el-table-column align="center" label="部门" prop="departName" min-width="150px"/>
          <el-table-column align="center" label="仓库" prop="stockName" min-width="150px"/>
          <el-table-column :align="'center'" label="退款方式" min-width="200px" >
            <template v-slot="{row}">
              <pay-type-select :echo-pay-type-id="row.payTypeId" :echo-pay-type-list="payType"  />
            </template>
          </el-table-column>
          <el-table-column align="center" label="退款状态" min-width="100px">
            <template v-slot="{row}">
              <el-tag type="info" v-if="row.payStatus === 0">未退款</el-tag>
              <el-tag type="danger" v-if="row.payStatus === 10">退款失败</el-tag>
              <el-tag type="warning" v-if="row.payStatus === 50">部分退款</el-tag>
              <el-tag type="success" v-if="row.payStatus === 99">退款成功</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="160px" label="退款时间" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.payTime == null">暂未退款</span>
              <span v-else>{{ row.payTime }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单状态" min-width="100px">
            <template v-slot="{ row }">
              <el-tag type="warning" v-if="row.status === 0">草稿</el-tag>
              <el-tag type="warning" v-else-if="row.status === 50">未完成</el-tag>
              <el-tag type="success" v-else-if="row.status === 99">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单来源" min-width="100px">
            <template v-slot="{ row }">
              <span v-if="row.orderType === 1">线下</span>
              <span v-else-if="row.orderType === 2">线下</span>
              <span v-else-if="row.orderType === 11">线上</span>
              <span v-else-if="row.orderType === 12">线上</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建人" prop="createdName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="退款人" prop="payeeName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="支付流水号" prop="outTradeNo" show-overflow-tooltip min-width="100px"/>
          <el-table-column label="操作" :align="'center'" min-width="80px" fixed="right">
            <template v-slot="{row}">
              <list-button>
                <!--              <table-button @click="$routerUtil.push(`/report/refund-order/detail?id=${row.id}`)">编辑</table-button>-->
                <page-button message="是否确认删除此数据" :disabled="(row.status > 50 && row.orderType === 2) || row.payStatus >= 50 || row.orderType === 12" el-type="danger" type="delete" @click="toDelete(row)">删除</page-button>
                <page-button type="write" el-type="primary" :disabled="row.status !== 99" @click="toShowPaySerial(row)">
                  流水号
                </page-button>
                <page-button el-type="primary" @click="toShowSerialNumber(row)">日志</page-button>
              </list-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
  <el-dialog title="支付流水号" v-model="pay.showPaySerial" width="600px">
    <el-input v-model="pay.paySerialNum" placeholder="请输入支付流水号"/>
    <div style="text-align: right;padding-top: 10px;">
      <page-button @click="pay.showPaySerial = false">取消</page-button>
      <page-button el-type="primary" @click="toSavePaySerial">保存</page-button>
    </div>
  </el-dialog>

  <el-dialog title="交易流水号" v-model="showSerialNumber" width="600px">
    <dialog-table>
      <el-form label-width="80px">
        <el-form-item label="订单编号">{{ currentOrder.id }}</el-form-item>
        <el-form-item label="支付方式">

        <span v-for="(p, i) in payType" :key="i">
          <span v-if="currentOrder.payTypeId === p.id">{{ p.payName }}</span>
        </span>
        </el-form-item>
        <el-form-item label="支付状态">{{ currentOrder.payStatus === 0 ? '未支付' : '已支付' }}</el-form-item>
        <el-form-item label="交易参数">
          <div class="pay-result-div">
            {{ currentOrder.paySend }}
          </div>
        </el-form-item>
        <el-form-item label="交易结果">
          <div class="pay-result-div">
            {{ currentOrder.payReport }}
          </div>
        </el-form-item>
      </el-form>
    </dialog-table>
    <div style="text-align: right;padding-top: 10px;">
      <page-button @click="showSerialNumber = false" el-type="primary">确认</page-button>
    </div>
  </el-dialog>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import RefundOrderApi from "@/api/main/order/online/RefundOrderApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import CustomerSelect from "@/components/h5/customer/CustomerSelect";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import {h} from "vue";
import util from "@/util/util";
import stockApi from "@/api/main/stock/stockApi";
import ListButton from "@/components/h5/button/ListButton.vue";
export default {
  name: "OrderList",
  components: {
    PayTypeSelect,
    DialogTable,
    EmployeeSelect,
    Dictionary,
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, DateRange, CustomerSelect, PageHeader, ListButton
  },
  data() {
    return {
      batchInfo:{},
      searchInfo: {},
      dataList: [],
      payType: [],
      stockList: [],
      departList: [],
      // 手动添加支付流水号
      pay: {
        paySerialNum: "", //支付流水号
        showPaySerial: false,
        orderId: "",
      },
      // 显示支付日志
      showSerialNumber: false,
      currentOrder: {},
      // 设置读的权限值
      readAccess: null,
    };
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path,"read").then(res => this.readAccess = res);
    systemPayApi.queryAllList().then(res => this.payType = res);
    commonApi.queryDepartList().then(res => this.departList = res);
    // 查询全部仓库
    stockApi.queryListByNotCondition().then(list => this.stockList = list);
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  watch:{
    'searchInfo.startTime':{
      handler(){
        this.resetSearch();
      }
    }
  },
  methods: {
    exportRefundList(){
      this.handleBatchSearch();
      util.exportExcel("/order/saleOrder/refund/exportRefundList", this.searchInfo);
    },
    handleBatchSearch() {
      if (this.batchInfo.createdIds && this.batchInfo.createdIds.length > 0) {
        this.searchInfo.createdId = this.batchInfo.createdIds.join(',');
      } else {
        this.searchInfo.createdId = "";
      }
      if (this.batchInfo.departCodes && this.batchInfo.departCodes.length > 0) {
        this.searchInfo.departCode = this.batchInfo.departCodes.join(',');
      } else {
        this.searchInfo.departCode = "";
      }
      if (this.batchInfo.employeeIds && this.batchInfo.employeeIds.length > 0) {
        this.searchInfo.employeeId = this.batchInfo.employeeIds.join(',');
      } else {
        this.searchInfo.employeeId = "";
      }
      if (this.batchInfo.payTypeIds && this.batchInfo.payTypeIds.length > 0) {
        this.searchInfo.payTypeId = this.batchInfo.payTypeIds.join(',');
      } else {
        this.searchInfo.payTypeId = "";
      }
      if (this.batchInfo.payeeIds && this.batchInfo.payeeIds.length > 0) {
        this.searchInfo.payeeId = this.batchInfo.payeeIds.join(',');
      } else {
        this.searchInfo.payeeId = "";
      }
      if (this.batchInfo.stockIds && this.batchInfo.stockIds.length > 0) {
        this.searchInfo.stockId = this.batchInfo.stockIds.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
    },
    setDepartCode(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          arr.push(data[i][j]);
        }
      }
      this.batchInfo.departCodes = arr
    },
    setEmployee(data) {
      this.batchInfo.employeeIds = data;
    },
    setCreated(data) {
      this.batchInfo.createdIds = data;
    },
    setPayee(data) {
      this.batchInfo.payeeIds = data;
    },
    clearDic() {
      this.batchInfo.departCode = "";
    },
    setCustomer(data) {
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      this.queryBottomList({...this.searchInfo, ...this.batchInfo});
      this.handleBatchSearch();
      RefundOrderApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    queryBottomList(searchInfo){
      let searchVo = searchInfo;
      searchVo.orderType = 2;
      orderStatisticApi.querySaleStatisticInfo(searchVo).then(res => this.bottomList = res);
    },
    toDelete(row){
      offlineOrderApi.delete(row.id).then(() => {
        this.queryList();
      })
    },
    toShowSerialNumber(data) {
      this.showSerialNumber = true;
      this.currentOrder = {...data};
    },
    toShowPaySerial(row) {
      if (row.status === 99) {
        this.pay.showPaySerial = true;
        this.pay.orderId = row.id;
      }
    },
    toSavePaySerial() {
      offlineOrderApi.savePaySerial(this.pay.orderId, this.pay.paySerialNum).then(() => {
        this.queryList();
        this.pay.showPaySerial = false;
      })
    },
    getSummary(param){
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }
        if(index === 4){
          sums[index] = h('div', [Number(this.bottomList.totalQty).toFixed(2)])
        }

        if(index === 5){
          sums[index] = h('div',[ Number(this.bottomList.totalAmount).toFixed(2)])
        }
      })
      return sums
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
//.customer-select{
//  width: 240px;
//  margin-right: 10px;
//  :deep(.el-input){
//    width: 100% !important;
//  }
//}
.customer-select{
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
.add:hover{
  text-decoration: underline;
}
</style>