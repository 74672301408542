<template>
  <!-- 通用模板，列表数据 -->
  <list-page>
    <search-condition @search="resetSearch" :show-search="true">
      <el-input v-model="searchInfo.searchKey" placeholder="请输入规则组名称"/>
      <el-select v-model="searchInfo.stockId" placeholder="请选择仓库" clearable>
        <el-option v-for="(s, i) in stockList" :key="i" :label="s.stockName" :value="s.id"/>
      </el-select>
      <template #option>
        <page-button type="write" el-type="success" @click="$routerUtil.push(`/stock/warning/detail`)">新建</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table stripe :data="dataList" height="100%">
        <el-table-column label="序号" width="60px" type="index"/>
        <el-table-column label="规则组名称" prop="ruleName"/>
        <el-table-column label="仓库名称" prop="stockName"/>
        <el-table-column label="规则组状态" prop="status">
          <template v-slot="{row}">
            <span v-if="row.status === 0">启用</span>
            <span v-if="row.status === -1">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template v-slot="{row}">
            <table-button @click="$routerUtil.push(`/stock/warning/detail?id=${row.id}`)">编辑</table-button>
            <table-button @click="openRule(row)" v-if="row.status === -1" message="启用规则组后，原本挂起的规则将被全部更新，是否确认进行禁用，如果不想进行全部的规则修改，请使用编辑功能">启用</table-button>
            <table-button @click="closeRule(row)" v-else-if="row.status === 0" message="禁用后将不再产生新的库存预警消息，但原本的预警消息依然会存在，是否确认禁用规则">禁用</table-button>
            <table-button message="是否确认删除规则组？删除后不可恢复,所有基于此规则组的预警消息也会一起被删除" type="delete" @click="deleteGroup(row)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
  </list-page>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import stockWarningApi from "@/api/main/stock/stockWarningApi";
import DialogTable from "@/components/h5/layout/DialogTable";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, DialogTable
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      stockList: [],
    };
  },
  created() {
    // 查询仓库
    stockWarningApi.queryStock().then(list => this.stockList = list);
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      stockWarningApi.queryRuleGroupList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      });
    },
    deleteGroup(data){
      stockWarningApi.deleteRuleGroup(data.id).then(()=>{
        this.queryList();
      })
    },
    openRule(data) {
      stockWarningApi.openRuleGroup(data.id).then(() => {
        this.queryList();
      })
    },
    closeRule(data) {
      stockWarningApi.closeRuleGroup(data.id).then(() => {
        this.queryList();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>