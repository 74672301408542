<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header/>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="下游客户" label-class-name="require">
            <dictionary v-if="dataDetail.orderType === 11" v-model="dataDetail.clientId" :disabled="true" :dictionaries="storeList" :dict-name="'storeName'" :dict-code="'id'" />
            <client-select v-else :disabled="dataDetail.status > 0 || dataDetail.orderType === 11" v-model="dataDetail.clientId" :merchant-name="dataDetail.clientName" :query-api="queryClientApi"/>
          </el-descriptions-item>
          <el-descriptions-item label="仓库名称" label-class-name="require">
            <dictionary v-if="dataDetail.orderType === 11" v-model="dataDetail.stockId" :disabled="true" :dictionaries="stockList" :dict-name="'stockName'" :dict-code="'id'" />
            <el-select v-else :disabled="dataDetail.status >= 20" v-model="dataDetail.stockId">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="部门" label-class-name="require">
            <dictionary v-model="dataDetail.departCode" :disabled="dataDetail.status >= 20 || dataDetail.orderType === 11"
                        @change-data="changeDepart" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>
          <el-descriptions-item label="业务人员">{{dataDetail.employeeName}}</el-descriptions-item>
          <el-descriptions-item label="订单时间">
            <text v-if="dataDetail.orderType === 11">{{ dataDetail.orderTime }}</text>
            <el-date-picker v-else v-model="dataDetail.orderTime" type="datetime" style="width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="订单状态">{{dataDetail.statusName}}</el-descriptions-item>
          <el-descriptions-item label="备注信息" :span="2">
            <text v-if="dataDetail.orderType === 11">{{ dataDetail.remark }}</text>
            <el-input v-else v-model="dataDetail.remark" type="textarea"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="display: flex;justify-content: flex-end; padding-bottom: 10px" v-if="!dataDetail.orderType || dataDetail.orderType !== 11">
          <div style="width: 400px;display: inline-block">
            <product-list-select :query-list-api="queryProductApi" :before-query="beforeQueryProduct" :query-params="{clientId: dataDetail.clientId}" :selected-product-list="dataDetail.detail" @selectOne="addProductOne" @select="addProductList"/>
          </div>
        </div>
        <div :style="{height: dataDetail.orderType === 11 ? 'calc(100% - 218px)' : 'calc(100% - 320px)'}">
          <el-table stripe :data="dataDetail.detail" height="100%">
            <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
            <el-table-column label="商品名称" prop="skuName" min-width="150px" show-overflow-tooltip/>
            <el-table-column label="商品编码" prop="skuCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="条码" prop="skuBarCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="数量" prop="qty" width="100px" />
            <el-table-column label="单位" prop="unitName" width="100px" :align="'center'"/>
            <el-table-column label="采购价格" prop="salePrice" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice"/>
              </template>
            </el-table-column>
            <el-table-column label="订单金额" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice * row.qty"/>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150px" :align="'center'" fixed="right" v-if="!dataDetail.orderType || dataDetail.orderType !== 11">
              <template v-slot="{row, $index}">
                <order-original-code :disabled="isDisabled" :stock-id="dataDetail.stockId" :in-code="true" :original-code-type="originalCodeType" @addOriginalCode="addOriginalCode2List($index, $event)" :code-list="row.originalCodeList" :sku-name="row.skuName"/>
                <table-button type="delete" el-type="danger" @click="removeData($index)">删除</table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <page-button v-if="dataDetail.status === 0" el-type="success" type="write" @click="saveDraft">保存草稿</page-button>
        <page-button v-if="dataDetail.status < 20" el-type="primary" type="write" @click="saveSubmit">提交单据</page-button>
      </form-page>
    </list-page>
<!--    <el-drawer v-model="showOriginalDialog" :title="currentProduct.skuName + '溯源码'" dir="ltr" size="500px" :close-on-click-modal="false">-->
<!--      &lt;!&ndash;    自动生成溯源码&ndash;&gt;-->
<!--      <div style="height: calc(100vh - 180px)">-->
<!--        <table-button v-if="!isDisabled" @click="tosheng">自动生成溯源码</table-button>-->
<!--        <el-table stripe id="skuOriginalTable" :data="skuOriginalList" height="100%" border>-->
<!--          <el-table-column label="序号" type="index" width="60px" :align="'center'"/>-->
<!--          <el-table-column label="溯源码" prop="originalCode" v-if="isDisabled" :align="'center'"/>-->
<!--          <el-table-column label="溯源码" v-else>-->
<!--            <template v-slot="{row}">-->
<!--              <el-input v-model="row.originalCode" placeholder="请输入溯源码" @change="addTableOriginalCode"/>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table>-->
<!--      </div>-->
<!--      <div style="text-align: right;padding-top: 10px;margin-top: 20px">-->
<!--        <page-button @click="showOriginalDialog = false">返回</page-button>-->
<!--        <page-button v-if="!isDisabled" el-type="primary" @click="addOriginalCode2List">确定</page-button>-->
<!--      </div>-->
<!--    </el-drawer>-->

<!--    &lt;!&ndash;  生成溯源码&ndash;&gt;-->
<!--    <el-drawer  v-model="shengOriginalDialog" size="500px">-->
<!--      <div>-->
<!--        <table-list>-->
<!--          <el-descriptions :column="1" border >-->
<!--            <el-descriptions-item label="开始编码值">-->
<!--              <el-input v-model="startValue" placeholder="请输入开始编码的值" />-->
<!--            </el-descriptions-item>-->
<!--            <el-descriptions-item label="截至编码值">-->
<!--              <el-input v-model="endValue" placeholder="请输入截至编码的值" />-->
<!--            </el-descriptions-item>-->
<!--          </el-descriptions>-->
<!--        </table-list>-->
<!--        <page-button v-if="!isDisabled" el-type="primary"  @click="toGenertOriginalCode">生成</page-button>-->
<!--      </div>-->
<!--    </el-drawer>-->
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import Money from "@/components/h5/Money.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";

import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import OrderOriginalCode from "@/views/main/supplyChain/merchantOrder/components/OrderOriginalCode.vue";
import storeApi from "@/api/alpine/merchant/storeApi";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, ProductListSelect, ClientSelect, Dictionary, Money, PageHeader, OrderOriginalCode
  },
  data() {
    return {
      dataDetail: {
        detail: [],
        employeeName: "",
        status: 0,
        stockId: "",
        departCode: "",
      },
      departList: [],
      stockList: [],
      queryProductApi: {},
      showOriginalDialog: false,
      shengOriginalDialog: false,
      isDisabled: false,
      startValue:"",//溯源码起始值
      endValue:"",//溯源码结束值
      skuOriginalList: [],
      queryClientApi: null,
      storeList: [],
      originalCodeType: "",
      userInfo: {},
    };
  },
  created() {
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.originalCodeType = setting.settingValue);
    // 查询商品时，查询供应商的商品
    this.queryProductApi = merchantOrderApi.queryProductAuthorizeList;
    util.getUserInfo().then(user => {
      this.userInfo = user;
      this.dataDetail.employeeName = user.employeeName;
      this.dataDetail.employeeId = user.id;
      if (user.merchantId === "10000") {
        this.queryClientApi = commonApi.queryMerchantList;
      }
    });
    // 查询部门
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询仓库
    this.queryAllStockList();
    // 门店
    storeApi.queryAll().then(res => this.storeList = res);
  },
  methods: {
    findById(id) {
      merchantOrderApi.findById(id).then(data => {
        this.dataDetail = data;
        if(!this.dataDetail.employeeId){
          this.dataDetail.employeeId = this.userInfo.id;
          this.dataDetail.employeeName = this.userInfo.employeeName;
        }
        this.isDisabled = data.status >= 20;
      });
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.employeeName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.statusName = "草稿";
          this.dataDetail.orderTime = new Date();
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2();
          commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.dataDetail.originalCodeType = setting.settingValue);
        } else {
          this.findById(this.$routerUtil.query.id);
          this.queryAllStockList2()
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      commonApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 新建时默认选中仓库
        if (!this.$routerUtil.query.id && list.length > 0) {
          this.dataDetail.stockId = list[0].id;
        }
      });
    },
    // 部门变更时,重新查询仓库
    changeDepart(data) {
      this.queryAllStockList2(data.dictCode);
    },

    // 根据商品，添加一行明细
    createDetailData(product) {
      return {
        skuId: product.id,
        skuName: product.skuName,
        skuCode: product.skuCode,
        barCode: product.barCode,
        salePrice: product.skuSalePrice,
        buyPrice: product.skuBuyPrice,
        unitName: product.skuUnitName,
        unitId: product.skuUnitId,
        qty: 0,
        rate: 1,
        originalCodeList: [],
        skuOriginalList: [],
      }
    },
    // 添加一个商品
    addProductOne(data) {
      // 如果没有，则添加
      let contains = false;
      for (let j = 0; j < this.dataDetail.detail.length; j++) {
        if (data.id === this.dataDetail.detail[j].skuId) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.detail.push(this.createDetailData(data));
      }
    },
    // 添加多个商品
    addProductList(list) {
      for (let i = 0; i < list.length; i++) {
        this.addProductOne(list[i])
      }
    },
    checkData() {
      if (!this.dataDetail.clientId) {
        this.$message.error("请选择客户")
        return false;
      }
      if (!this.dataDetail.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (!this.dataDetail.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      // 判断溯源码添加的和数量是否是一致的
      let details = this.dataDetail.detail;
      for (let i = 0; i < details.length; i++) {
        if(details[i].qty !== details[i].originalCodeList.length && this.dataDetail.orderType !== 11){
          this.$message.error("溯源码数量与商品数量不一致！");
          return false;
        }
      }
      return true;
    },
    saveDraft() {
      if (!this.checkData()) {
        return;
      }
      if(!this.dataDetail.orderType){
        this.dataDetail.orderType = 1;
      }
      if(!this.dataDetail.payType ){
        // 这里不涉及到支付，不为空就行
        this.dataDetail.payType = -1;
      }
      merchantOrderApi.saveDraft(this.dataDetail).then(data => this.findById(data.id));
    },
    saveSubmit() {
      if (!this.checkData()) {
        return;
      }
      if(this.dataDetail.orderType === 11){
        this.dataDetail.status = 20;
      }
      if(!this.dataDetail.orderType ){
        // orderType 不存在，默认为销售订单
        this.dataDetail.orderType = 1;
      }
      if(!this.dataDetail.payType ){
        // 这里不涉及到支付，不为空就行
        this.dataDetail.payType = -1;
      }
      merchantOrderApi.saveSubmit(this.dataDetail).then(data => this.findById(data.id));
    },
    beforeQueryProduct() {
      if (!this.dataDetail.clientId) {
        this.$message.error("请先选择客户")
        return false;
      }
      if (!this.dataDetail.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      return true;
    },
    removeData(idx) {
      this.dataDetail.detail = this.dataDetail.detail.remove(idx);
    },

    addOriginalCode2List(idx, resList) {
      let arr = [...this.dataDetail.detail];
      // 检查溯源码，成功之后，再进行添加
      merchantOrderApi.checkoutSkuOriginalCode(resList, this.dataDetail.stockId, arr[idx].skuId).then(() => {
        // 没有码的数据不要
        // 找到商品
        arr[idx].originalCodeList = resList;
        arr[idx].qty = resList.length;
        this.dataDetail.detail = arr;
        this.showOriginalDialog = false;
      })
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
:deep(.el-drawer__header) {
  margin-bottom: 0;
  white-space: nowrap;
}
:deep(.el-drawer__header>:first-child) {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>