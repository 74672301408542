import {ElMessage} from 'element-plus';

const LocalPrint = {
    port: 8990,
    url: "ws://127.0.0.1",
    _websocket: null,
    reportName: "",
    excuteType: "preview, print,design",
    _initWebsocket: function () {
        var that = this;
        if ("WebSocket" in window) {
            if (this._websocket) {
                //激活一下 报错就提示
                this._websocket.send("get=Version");
                this._websocket.send("method=GetStylesInfo:" + this.reportName);
            }
            else {
                this._websocket = new WebSocket(this.url + ":" + this.port);
            }
        } else {
            ElMessage.error({
                message: '浏览器不支持WebSocket',
                type: 'error',
                duration: 1500,
            });
        }

        this._websocket.onopen = function (evt) {

            that._socketConnected = true;
            that._websocket.send("get=Version");
            that._websocket.send("set=DataDirName:data_erp");
            that._websocket.send("method=GetStylesInfo:" + that.reportName);
            if (callbackfun && that._websocket.readyState == 1)
                callbackfun();
        };

        this._websocket.onerror = function (evt) {
            if (evt.target.readyState === 3) {
                ElMessage.error({
                    message: '打印管理器未连接成功，或连接不可用！',
                    type: 'error',
                    duration: 1500,
                });
            }
        };

        this._websocket.onclose = function (evt) {
            ElMessage.error({
                message: '打印管理器连接已断开！',
                type: 'error',
                duration: 1500,
            });
        };

        this._websocket.onmessage = function (evt) {

            console.log("返回了", JSON.stringify(evt.data));
            var iPos = evt.data.indexOf(':');
            var methodName, value;
            if (iPos < 0) {
                methodName = evt.data;
            } else {
                methodName = evt.data.slice(0, iPos);
                value = evt.data.slice(iPos + 1);
            }
        };

    },
    new_doSend: function (message) {
        if (null==this._websocket) {
            this._initWebsocket();
        }
        var that = this;
        var sendPromise = new Promise(function (resolve, reject) {
            that._websocket.onmessage = function (evt) {
                console.log("返回了", JSON.stringify(evt.data));
                var iPos = evt.data.indexOf(':');
                if (iPos > -1) {
                    var resultMethodName = evt.data.slice(0, iPos);
                    var value = evt.data.slice(iPos + 1);
                    resolve(value);
                }
                else {
                    resolve('');
                };

                var methodName, value;
                if (iPos < 0) {
                    methodName = evt.data;
                } else {
                    methodName = evt.data.slice(0, iPos);
                    value = evt.data.slice(iPos + 1);
                }
            }
        });
        console.log("输入了:", message);
        this._websocket.send(message);
        return sendPromise;
    },
    Print: function (data) {
        var that = this;
        var printParam = {};
        printParam.reportName = data.reportName;
        printParam.allowPrint = true;
        printParam.allowExport = true;
        printParam.printTimesLimit = 999;
        // 这个鬼玩意儿用于设计使用
        printParam.baseTemplate = JSON.stringify(this.createDefaultStyle(data.reportName));
        printParam.excuteType = data.excuteType;//"preview 预览, print 打印,design 设计",
        // 这个是数据了
        if (!printParam.excuteType) {
            printParam.excuteType = "design";
        }
        printParam.printData = JSON.stringify(this.createDataInfo(data));
        //数据拼完了
        console.log(printParam);
        that.new_doSend("method=ExecuteByType:" + JSON.stringify(printParam));
    },
    createDefaultStyle: function (reportName) {
        let data = {
            "reportName": "销售订单",
            "pages": [
                {
                    "table": [
                        {
                            "cells": [{ "cellText": "`日期","fontName": "宋体","hAlign": 0,"lineVisible": false,"formatKind": 3},
                                {
                                    "cellText": "`总页码",
                                    "fontName": "宋体",
                                    "hAlign": 2,
                                    "lineVisible": false
                                }
                            ]
                        },
                        {
                            "cells": [
                                {"cellText": "结算金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3},
                                {"cellText": "@结算金额：", "fontName": "宋体", "hAlign": 0},
                                {"cellText": "合计优惠", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3},
                                {"cellText": "@合计优惠：", "fontName": "宋体", "hAlign": 0}
                            ]
                        },
                        {
                            "cells": [
                                {"cellText": "客户名称", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3},
                                {"cellText": "@客户名称：", "fontName": "宋体", "hAlign": 0},
                                {"cellText": "单据编号", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3},
                                {"cellText": "@单据编号：", "fontName": "宋体", "hAlign": 0}
                            ]
                        },
                        {
                            "cells": [
                                { "cellText": "单据时间", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@单据时间：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "职员名称", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@职员名称：", "fontName": "宋体", "hAlign": 0 },
                            ]
                        },
                        {
                            "cells": [
                                { "cellText": "部门名称", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@部门名称：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "合计数量", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@合计数量：", "fontName": "宋体", "hAlign": 0 },
                            ]
                        },
                        {
                            "cells": [
                                { "cellText": "仓库名称", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@仓库名称：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "操作人员", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@操作人员：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "优惠前金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@优惠前金额：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "支付方式", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@支付方式：", "fontName": "宋体", "hAlign": 0 },
                            ]
                        },
                        {
                            "cells": [
                                { "cellText": "订单编号", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@订单编号：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "订单状态", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@订单状态：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "订单时间", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@订单时间：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "经办职员", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@经办职员：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "支付状态", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@支付状态：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "订单金额合计", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@订单金额合计：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "订单数量合计", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@订单数量合计：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "退单金额合计", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@退单金额合计：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "退单数量合计", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@退单数量合计：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "交易笔数合计", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@交易笔数合计：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "流水金额合计", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@流水金额合计：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "收费人员", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@收费人员：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "日结报表日期", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@日结报表日期：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "押金类型", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@押金类型：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "押金退款金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@押金退款金额：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "出入库日期", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@出入库日期：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "出入库字典类型", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@出入库字典类型：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "合计金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@合计金额：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "本次收款", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@本次收款：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "本单未收", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@本单未收：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "本次实收", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@本次实收：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "实退金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@实退金额：", "fontName": "宋体", "hAlign": 0 },


                                { "cellText": "账户名称-支付金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@账户名称-支付金额：", "fontName": "宋体", "hAlign": 0 },













                                { "cellText": "验光单号", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@验光单号：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "验光时间", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@验光时间：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "球面度数(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@球面度数(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "球面度数(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@球面度数(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "柱面度数(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@柱面度数(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "柱面度数(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@柱面度数(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "轴向(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@轴向(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "轴向(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@轴向(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "近瞳距(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@近瞳距(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "近瞳距(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@近瞳距(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "远瞳距(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@远瞳距(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "远瞳距(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@远瞳距(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "棱镜(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@棱镜(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "棱镜(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@棱镜(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "基底(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@基底(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "基底(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@基底(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "原光度(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@原光度(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "原光度(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@原光度(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "全矫光度(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@全矫光度(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "全矫光度(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@全矫光度(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "曲率(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@曲率(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "曲率(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@曲率(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "裸视力(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@裸视力(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "裸视力(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@裸视力(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "矫正视力(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@矫正视力(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "矫正视力(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@矫正视力(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "ADD(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@ADD(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "ADD(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@ADD(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "瞳高(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@瞳高(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "瞳高(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@瞳高(左)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "隐斜(右)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@隐斜(右)：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "隐斜(左)", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@隐斜(左)：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "客户手机号", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@客户手机号：", "fontName": "宋体", "hAlign": 0 },

                                { "cellText": "本单已经收款的金额", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@本单已经收款的金额：", "fontName": "宋体", "hAlign": 0 },




















                             ]
                        },

                        {
                            "cells": [
                                {
                                    "cellText": "行号",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "商品名称",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "商品编号",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "商品原价",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "商品数量",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "商品总价",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                }
                                ,
                                {
                                    "cellText": "商品单位",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "商品折扣",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },

                                {
                                    "cellText": "商品单价",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },

                                {
                                    "cellText": "订单金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "订单数量",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "退单金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "退单数量",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "交易笔数",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "流水金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "账户名称",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "账户对应的退款金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                }




                            ]
                        },
                        {
                            "cells": [
                                {
                                    "cellText": "#行号",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },
                                {
                                    "cellText": "#商品名称",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },
                                {
                                    "cellText": "#商品编号",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },
                                {
                                    "cellText": "#商品原价",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                }
                                ,
                                {
                                    "cellText": "#商品数量",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },
                                {
                                    "cellText": "#商品总价",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },

                                {
                                    "cellText": "#商品单位",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },
                                {
                                    "cellText": "#商品折扣",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },
                                {
                                    "cellText": "#商品单价",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true,
                                    "formatKind": 0
                                },


                                {
                                    "cellText": "#订单金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#订单数量",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#退单金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#退单数量",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#交易笔数",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#流水金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#账户名称",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                },
                                {
                                    "cellText": "#账户对应的退款金额",
                                    "fontName": "宋体",
                                    "hAlign": 0,
                                    "isBold": true
                                }
                            ]
                        }],
                    "pageSetting": {
                        "paperOrientation": 1,
                        "marginTop": 10,
                        "marginLeft": 10,
                        "marginRight": 10,
                        "marginBottom": 10,
                        "showHeaderInPerPage": false,
                        "showFooterInPerPage": false
                    }
                }
            ]
        };
        data.reportName = reportName;
        return data;
    },
    createDataInfo: function (data) {
        //这里需要一个cookieId \"ASP.NET_SessionId=ovprujsru25nhyr2ou2cc0qi\" 后面按实际获取吧
        var cookid = "ASP.NET_SessionId=ovprujsru25nhyr2ou2cc0qi";
        var reportName =
            {
                "ReportUrl": null,
                "Token": null,
                "templatename": data.reportName,
                "Cookie": cookid,
                "CallBackUrl": null, //回调地址
                "ClientParams": {
                    "billtype": 999, //可能为了验证后面的格式 暂时传999 因为管家婆没有使用999这个单据类型
                    "billnumberid": 1, //单据唯一id 用于回调 这里自己看着办吧 不需要回调不管
                    "billstate": 0,
                    "billcode": data.orderNumber,
                    "etypeid": data.employeeName
                } //妈的这里的参数全是用于回调的  心累
            };
        return {
            "reportName": JSON.stringify(reportName),
            //    "callBack": { "token": "null", "callBack": "null" },
            "reportNumber": -1, //报表格式选择  同一个report下面有多个报表样式 换了样式自己换索引 用于后期不预览直接打印当然如果只有一个 这里传啥都一样
            "dataDirName": "data_erp", //报表样式存放位置
            "masterFields": [
            { "name": "合计金额", "dataField": "amount", "value": data.amount, "hasValue": true, "isFooter": false},
            { "name": "合计优惠", "dataField": "discountAmount", "value": data.discountAmount, "hasValue": true, "isFooter": false,},
            { "name": "客户名称", "dataField": "customerName", "value": data.customerName, "hasValue": true, "isFooter": false, },
            { "name": "订单编号", "dataField": "orderNumber", "value": data.orderNumber, "hasValue": true, "isFooter": false, },
            { "name": "订单时间", "dataField": "orderTime", "value": data.orderTime, "hasValue": true, "isFooter": false, },
            { "name": "经办职员", "dataField": "employeeName", "value": data.employeeName, "hasValue": true, "isFooter": false, },
            { "name": "仓库名称", "dataField": "stockName", "value": data.stockName, "hasValue": true, "isFooter": false, },
            { "name": "操作人员", "dataField": "createdName", "value": data.createdName, "hasValue": true, "isFooter": false, },
            { "name": "部门名称", "dataField": "departName", "value": data.departName, "hasValue": true, "isFooter": false, },
            { "name": "合计数量", "dataField": "countQty", "value": data.countQty, "hasValue": true, "isFooter": false, },
            { "name": "优惠前金额", "dataField": "amountTotal", "value": data.amountTotal, "hasValue": true, "isFooter": false, },
            { "name": "支付方式", "dataField": "payType", "value": data.payType, "hasValue": true, "isFooter": false, },
            { "name": "订单状态", "dataField": "status", "value": data.status, "hasValue": true, "isFooter": false, },
            { "name": "支付状态", "dataField": "payStatus", "value": data.payStatus, "hasValue": true, "isFooter": false, },

            { "name": "订单金额合计", "dataField": "payStatus", "value": data.saleTotalAmount, "hasValue": true, "isFooter": false, },
            { "name": "订单数量合计", "dataField": "payStatus", "value": data.saleTotalQty, "hasValue": true, "isFooter": false, },
            { "name": "退单金额合计", "dataField": "payStatus", "value": data.reduceAmount, "hasValue": true, "isFooter": false, },
            { "name": "退单数量合计", "dataField": "payStatus", "value": data.refundTotalQty, "hasValue": true, "isFooter": false, },
            { "name": "交易笔数合计", "dataField": "payStatus", "value": data.totalQty, "hasValue": true, "isFooter": false, },
            { "name": "流水金额合计", "dataField": "payStatus", "value": data.totalAmount, "hasValue": true, "isFooter": false, },
            { "name": "收费人员", "dataField": "payStatus", "value": data.payeeName, "hasValue": true, "isFooter": false, },
            { "name": "日结报表日期", "dataField": "payStatus", "value": data.reportDate, "hasValue": true, "isFooter": false, },

            { "name": "押金类型", "dataField": "payStatus", "value": data.depositType, "hasValue": true, "isFooter": false, },
            { "name": "押金退款金额", "dataField": "payStatus", "value": data.refundAmount, "hasValue": true, "isFooter": false, },

            { "name": "单据编号", "dataField": "payStatus", "value": data.normalId, "hasValue": true, "isFooter": false, },
            { "name": "出入库日期", "dataField": "payStatus", "value": data.createdTime, "hasValue": true, "isFooter": false, },
            { "name": "出入库字典类型", "dataField": "payStatus", "value": data.inoutTypeName, "hasValue": true, "isFooter": false, },

            { "name": "本次收款", "dataField": "payStatus", "value": data.amountCollect, "hasValue": true, "isFooter": false, },
            { "name": "本单未收", "dataField": "payStatus", "value": data.amountShould, "hasValue": true, "isFooter": false, },

            { "name": "本次实收", "dataField": "amountActual", "value": data.amountActual, "hasValue": true, "isFooter": false, },
            { "name": "实退金额", "dataField": "refundActual", "value": data.refundActual, "hasValue": true, "isFooter": false, },

            { "name": "账户名称-支付金额", "dataField": "payTypeName", "value": data.payTypeName, "hasValue": true, "isFooter": false, },

            { "name": "验光单号", "dataField": "refundActual", "value": data.ygdId, "hasValue": true, "isFooter": false, },
            { "name": "验光时间", "dataField": "refundActual", "value": data.ygdTime, "hasValue": true, "isFooter": false, },
            { "name": "球面度数(右)", "dataField": "refundActual", "value": data.leftSphericalDegree, "hasValue": true, "isFooter": false, },
            { "name": "球面度数(左)", "dataField": "refundActual", "value": data.rightSphericalDegree, "hasValue": true, "isFooter": false, },
            { "name": "柱面度数(右)", "dataField": "refundActual", "value": data.leftCylinderDegree, "hasValue": true, "isFooter": false, },
            { "name": "柱面度数(左)", "dataField": "refundActual", "value": data.rightCylinderDegree, "hasValue": true, "isFooter": false, },
            { "name": "轴向(右)", "dataField": "refundActual", "value": data.leftAxial, "hasValue": true, "isFooter": false, },
            { "name": "轴向(左)", "dataField": "refundActual", "value": data.rightAxial, "hasValue": true, "isFooter": false, },
            { "name": "近瞳距(右)", "dataField": "refundActual", "value": data.leftClosePupilDis, "hasValue": true, "isFooter": false, },
            { "name": "近瞳距(左)", "dataField": "refundActual", "value": data.rightClosePupilDis, "hasValue": true, "isFooter": false, },
            { "name": "远瞳距(右)", "dataField": "refundActual", "value": data.leftRemotePupilDis, "hasValue": true, "isFooter": false, },
            { "name": "远瞳距(左)", "dataField": "refundActual", "value": data.rightRemotePupilDis, "hasValue": true, "isFooter": false, },
            { "name": "棱镜(右)", "dataField": "refundActual", "value": data.leftPrism, "hasValue": true, "isFooter": false, },
            { "name": "棱镜(左)", "dataField": "refundActual", "value": data.rightPrism, "hasValue": true, "isFooter": false, },
            { "name": "基底(右)", "dataField": "refundActual", "value": data.leftBasement, "hasValue": true, "isFooter": false, },
            { "name": "基底(左)", "dataField": "refundActual", "value": data.rightBasement, "hasValue": true, "isFooter": false, },
            { "name": "原光度(右)", "dataField": "refundActual", "value": data.leftOriginalLum, "hasValue": true, "isFooter": false, },
            { "name": "原光度(左)", "dataField": "refundActual", "value": data.rightOriginalLum, "hasValue": true, "isFooter": false, },
            { "name": "全矫光度(右)", "dataField": "refundActual", "value": data.leftFullCorRange, "hasValue": true, "isFooter": false, },
            { "name": "全矫光度(左)", "dataField": "refundActual", "value": data.rightFullCorRange, "hasValue": true, "isFooter": false, },
            { "name": "曲率(右)", "dataField": "refundActual", "value": data.leftCurvature, "hasValue": true, "isFooter": false, },
            { "name": "曲率(左)", "dataField": "refundActual", "value": data.rightCurvature, "hasValue": true, "isFooter": false, },
            { "name": "裸视力(右)", "dataField": "refundActual", "value": data.leftNakedVision, "hasValue": true, "isFooter": false, },
            { "name": "裸视力(左)", "dataField": "refundActual", "value": data.rightNakedVision, "hasValue": true, "isFooter": false, },
            { "name": "矫正视力(右)", "dataField": "refundActual", "value": data.leftCorVision, "hasValue": true, "isFooter": false, },
            { "name": "矫正视力(左)", "dataField": "refundActual", "value": data.rightCorVision, "hasValue": true, "isFooter": false, },
            { "name": "ADD(右)", "dataField": "refundActual", "value": data.leftAdd, "hasValue": true, "isFooter": false, },
            { "name": "ADD(左)", "dataField": "refundActual", "value": data.rightAdd, "hasValue": true, "isFooter": false, },
            { "name": "瞳高(右)", "dataField": "refundActual", "value": data.leftPupilHeight, "hasValue": true, "isFooter": false, },
            { "name": "瞳高(左)", "dataField": "refundActual", "value": data.rightPupilHeight, "hasValue": true, "isFooter": false, },
            { "name": "隐斜(右)", "dataField": "refundActual", "value": data.leftHiddenOblique, "hasValue": true, "isFooter": false, },
            { "name": "隐斜(左)", "dataField": "refundActual", "value": data.rightHiddenOblique, "hasValue": true, "isFooter": false, },

            { "name": "客户手机号", "dataField": "refundActual", "value": data.customerPhone, "hasValue": true, "isFooter": false, },

            { "name": "本单已经收款的金额", "dataField": "refundActual", "value": data.amountAlreadyReceived, "hasValue": true, "isFooter": false, },

            ],
            "detailFields":
                        [
                            { "name": "行号", "caption": "", "dataField": "productIndex", "size": 0, "columnWidth": 40, "headerAlign": "center", "textAlign": "center" },
                            { "name": "商品名称", "caption": "商品名称", "dataField": "productName", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品编号", "caption": "商品编号", "dataField": "productNumber", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品原价", "caption": "商品原价", "dataField": "productPrice", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品数量", "caption": "商品数量", "dataField": "productCount", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "小计", "caption": "小计", "dataField": "productTotalPrice", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品单位", "caption": "商品单位", "dataField": "productUnitName", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品折扣", "caption": "商品折扣", "dataField": "productDisCount", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品单价", "caption": "商品单价", "dataField": "productSalePrice", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },


                            { "name": "订单金额", "caption": "订单金额", "dataField": "saleTotalAmount", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "订单数量", "caption": "订单数量", "dataField": "saleTotalQty", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "退单金额", "caption": "退单金额", "dataField": "reduceAmount", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "退单数量", "caption": "退单数量", "dataField": "refundTotalQty", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "交易笔数", "caption": "交易笔数", "dataField": "totalQty", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "流水金额", "caption": "流水金额", "dataField": "totalAmount", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "账户名称", "caption": "账户名称", "dataField": "payTypeName", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "账户对应的退款金额", "caption": "账户对应的退款金额", "dataField": "refundCurrent", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                        ],
            "detailSumTexts": [
                // { "name": "@账面库存", "sumText": "0" },
                // { "name": "账面库存", "sumText": "0" },
                    { "name": "账面库存", "sumText": "0" },
                    { "name": "销售数量", "sumText": "40" },
                    { "name": "销售金额", "sumText": "1400" },
                    { "name": "锁库数量", "sumText": "0" },
                    { "name": "已发货数量", "sumText": "0" }],

            "cookie": cookid,
            "detailDataUrl": "null",//远程数据这里配置 restful可以直接获取到数据的链接可以直接放这里

            "detailData": {
                "itemCount": data.productList.length,
                "itemList": data.productList
            },
            "rowNoExpr": ""//数字公式列，用不到但是要传个空过去
        }
    }

}
export default LocalPrint;