<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border size="default">

          <el-descriptions-item label="客户名称" label-class-name="require">
            <customer-select :disabled="dataDetail.status === 99" v-model="dataDetail.customerId"
                             :name="dataDetail.customerName" @selectOne="setCustomer"/>
          </el-descriptions-item>

          <el-descriptions-item label="客户电话" label-class-name="require">
            <span>{{ dataDetail.customerPhone ? dataDetail.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') : '空' }}</span>
          </el-descriptions-item>

          <el-descriptions-item label="经办职员" label-class-name="require">
            <employee-select :disabled="dataDetail.status === 99" v-model="dataDetail.employeeId"
                             @selectOne="setEmployee"/>
          </el-descriptions-item>

          <el-descriptions-item label="部门" label-class-name="require">
            <dictionary :disabled="dataDetail.status === 99" v-model="dataDetail.departCode" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>

          <el-descriptions-item label="单据时间">
            <el-date-picker style="width: 100%"
                            v-model="dataDetail.advanceTime"
                            type="datetime"
                            placeholder="选择时间"
                            :disabled="dataDetail.status === 99"
            />
          </el-descriptions-item>

          <el-descriptions-item label="单据编号" v-if="dataDetail.status !== 0 && dataDetail.status !== -1">
            {{ dataDetail.id }}
          </el-descriptions-item>

          <!-- 押金类型从字典获取 -->
          <el-descriptions-item label="押金类型" label-class-name="require">
            <dictionary :disabled="dataDetail.status === 99" v-model="dataDetail.depositType"
                        :dictionaries="dictionaryList"/>
          </el-descriptions-item>
          <el-descriptions-item label="单据状态">
            <el-select v-model="dataDetail.status" disabled placeholder="订单状态" style="width: 100%" clearable>
              <el-option label="未提交" :value="-1"/>
              <el-option label="草稿" :value="0"/>
              <el-option label="已完成" :value="99"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="收费标签">
            押金
          </el-descriptions-item>

          <el-descriptions-item label="备注" :span="2">
            <el-input v-model="dataDetail.remark" type="textarea" :rows="2"
                      :disabled="dataDetail.status !== 0 && dataDetail.status !== -1"/>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions border>
          <el-descriptions-item :label="dataDetail.surplusPayType === 1 ? '支付方式' : '退款方式'">
            <!--            <el-select v-model="dataDetail.payType" placeholder="选择支付方式" style="width: 100%" clearable :disabled="dataDetail.status === 99" >-->
            <!--              <el-option v-for="(p, i) in payType" :value="p.value" :label="p.label" :key="i"/>-->
            <!--            </el-select>-->
            <pay-type-select :disabled="dataDetail.status === 99" v-model="dataDetail.payTypeId"
                             @select-one="changePay"/>
          </el-descriptions-item>
          <el-descriptions-item label="押金金额">
            <el-input type="number" v-model="dataDetail.amountPay" :disabled="dataDetail.status === 99"/>
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <form-page :show-page="false">
        <page-button el-type="primary" @click="toSaveSubmit" :disabled="dataDetail.payStatus >= 50">结算</page-button>
        <page-button el-type="success" @click="toSaveDraft" :disabled="dataDetail.status > 0">提交</page-button>

        <el-dropdown split-button type="primary" @click="toPrint" @command="handlerSelect" style="margin-top: 1px">
          打印
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Edit" command="setPrint">打印设置</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

<!--        <page-button el-type="primary" @click="toPrint" style="margin-right: -5px">打印</page-button>-->
<!--        <page-button @click="toSetPrint" el-type="primary">-->
<!--          <el-icon>-->
<!--            <el-icon-setting/>-->
<!--          </el-icon>-->
<!--        </page-button>-->
      </form-page>
    </list-page>
  </div>
  <el-dialog v-model="showOrder" width="500px" height="310px"  :title=" isPay ? isPaying ? '订单结算&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支付中...' : '押金结算' : '提交收款' "
             @opened="openBefore">
    <div style="height: 238px;overflow: auto">
      <el-form label-width="70px" style="">
        <div style="height: 198px">
          <el-form-item label="扫码支付">
            <div style="position: relative;width: 100%;overflow: hidden">
              <el-input v-model="dataDetail.qrCode" ref="inputRef" placeholder="支付码" style="width: 100%"
                        @change="pay" :disabled="dataDetail.surplusPayType === 2 || dataDetail.payType !== 2"/>
              <div v-if="dataDetail.payStatus === 20"
                   style="position: absolute;right: 0;top: 0;line-height: 40px;border-left: 1px solid #dcdfe6;width: 32px;text-align: center;">
                <el-tooltip :disabled="isRefreshPay" content="支付中，请稍后刷新支付状态" effect="light">
                  <el-icon :class="isRefreshPay ? 'rotating-element' : ''">
                    <el-icon-refresh @click="toFindPayStatus"/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="客户名称">
            <div style="position: relative;width: 100%;overflow: hidden">
              <el-input v-model="dataDetail.customerName" disabled style="width: 100%"/>
            </div>
          </el-form-item>
          <el-form-item label="金额合计">
            <el-input disabled v-model="dataDetail.amountPay"/>
          </el-form-item>
          <el-form-item :label="dataDetail.surplusPayType === 1 ? '支付方式' : '退款方式'">
            <pay-type-select :disabled="dataDetail.surplusPayType === 2" v-model="dataDetail.payTypeId"
                             @select-one="changePay"/>
          </el-form-item>
        </div>
      </el-form>
      <div style="display: flex;justify-content: space-between">
        <el-checkbox v-model="isAutoPrint" label="打印押金凭证" size="large"/>
        <div>
          <page-button el-type="info" @click="showOrder = false">取消</page-button>
          <page-button v-if="isPay" el-type="primary"  v-loading.fullscreen.lock="fullscreenLoading" @click="pay" :loading="preparePay" :disabled="disableToClickPay">
            确认
          </page-button>
          <page-button v-else el-type="primary" @click="saveDraft" :loading="preparePay" :disabled="disableToClickPay">
            确认
          </page-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>

import Dictionary from "@/components/h5/Dictionary.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import commonApi from "@/api/commonApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import AdvanceApi from "@/api/main/advance/advanceApi";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageHeader from "@/components/h5/layout/PageHeader";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import util from "@/util/util";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import {Edit} from "@element-plus/icons-vue";
import {ElLoading} from "element-plus";
import memberApi from "@/api/main/customer/memberApi";

export default {
  name: "AdvanceListDetail",
  computed: {
    Edit() {
      return Edit
    }
  },
  components: {
    PayTypeSelect,
    PageButton,
    CustomerSelect, EmployeeSelect, Dictionary, ListPage, TableList, FormPage, PageHeader
  },
  data() {
    return {
      fullscreenLoading: false,
      loading: null,
      dataDetail: {
        amountPay: 0,
        surplusPayType: 1,
        status: -1,
        surplusPay: 2,
        refundAmount: null,
        paymentType: 4, // 押金
      },
      employeeList: [],
      departList: [],
      showOrder: false,
      payType: [],

      dictionaryList: [],

      isAutoPrint: true,
      isActual: true,
      isPay: false,
      // 按钮状态
      preparePay: false,
      disableToClickPay: false,
      isPaying: false,
      isRefreshPay: false,
      timerToPayResult: null, // 计时器
      myTimer: null,
    };
  },
  watch: {
    'dataDetail.payType': {
      handler(val) {
        if (val === 2) {
          this.$refs.inputRef.focus();
        }
      }
    }
  },
  unmounted() {
    clearInterval(this.myTimer);
  },
  created() {
    //获取职员
    commonApi.queryAllEmployee().then(list => this.employeeList = list)
    //获取字典列表
    commonApi.queryDictionaryList("DEPOSIT_TYPE").then(list => {
      this.dictionaryList = list
    });
    //获取部门列表
    commonApi.queryDepartList().then(list => this.departList = list);
    //初始化时间
    this.dataDetail.advanceTime = new Date();
    //数据回显，通过路由,说明当前是数据回显
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    } else {
      //初始化数据
      this.init();
    }
    systemPayApi.queryAllList().then(res => this.payType = res);
  },
  methods: {
    handlerSelect(command){
      if(command === "setPrint"){
        this.toSetPrint();
      }
    },
    successResult(){
      this.disableToClickPay = false;
      this.isPaying = false;
      this.preparePay = false;
      this.showOrder = false;
    },
    toFindPayStatus() {
      if(this.isRefreshPay) this.$message.warning("正在获取最新状态，请耐心等待！！！");
      this.isRefreshPay = true;
      AdvanceApi.findSurplusStatus(this.dataDetail.payId).then(res => {
        this.findById(this.dataDetail.id).then(() => {
          if (this.dataDetail.payStatus >= 50) {
            this.successResult();
            if (this.isAutoPrint) this.toPrint();
            this.$message.success(res);
            clearInterval(this.timerToPayResult);
            this.loading.close();
            return;
          }
          if(this.dataDetail.payStatus === 30){
            this.successResult();
            this.$message.error(res);
            clearInterval(this.timerToPayResult);
            this.loading.close();
            return;
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          clearInterval(this.timerToPayResult);
          this.loading.close();
          return;
        } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
          // this.$message.warning("正在努力获取结果，请耐心等待！！！");
        }
        this.isRefreshPay = false;
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus();
        }, 2000);
      }).catch(()=>{
        this.isRefreshPay = false;
        this.loading.close();
      })
    },
    changePay(data) {
      this.dataDetail.payTypeId = data.id;
      this.dataDetail.payType = data.payCode;
    },
    init() {
      util.getUserInfo().then(res => {
        console.log(res);
        this.dataDetail.employeeId = res.id;
        this.dataDetail.employeeName = res.employeeName;
        this.dataDetail.departCode = res.mainDepartCode;
        this.dataDetail.departName = res.mainDepartName;
      })
    },
    pay() {
      // 赋值
      let myCount = 60;
      this.myTimer = setInterval(()=>{
        if(myCount > 0){
          myCount--;
          this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
        }else{
          clearInterval(this.myTimer);
          clearTimeout(this.timerToPayResult);
          this.loading.close();
        }
      },1000)
      this.loading = ElLoading.service({
        lock: true,
        text: `获取支付结果中，${myCount}秒后自动更新...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })

      this.isActual = true;
      this.disableToClickPay = true;
      this.preparePay = true;
      this.isPaying = true;

      AdvanceApi.saveSubmit(this.dataDetail).then((res) => {

        this.findById(res.id).then(() => {
          if (this.isAutoPrint && this.dataDetail.payStatus >= 50) {
            try {
              this.toPrint();
            }catch (e){
             console.error(e);
            }
            this.$routerUtil.push("/advance/list/detail");
            this.loading.close();
          }
          if (this.dataDetail.status === 99 || this.dataDetail.payStatus >= 50) {
            this.successResult();
            this.$message.success("操作成功");
            this.$routerUtil.push("/advance/list/detail");
            this.loading.close();
          } else if(this.dataDetail.payStatus === 20){
            // this.$message.warning("支付中，请稍后刷新支付状态！！！")
            this.toFindPayStatus();
          }
        });
      }).catch(() => {
        this.loading.close();
        this.preparePay = false;
        this.disableToClickPay = false;
        this.isPaying = false;
      })
    },
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      this.dataDetail.customerPhone = data.customerPhone;
      // 备份一个customerInfo用于其它组件
      this.dataDetail.customerInfo = {...data};
    },
    setEmployee(data) {
      this.dataDetail.employeeName = data.employeeName;
    },
    saveDraft() {
      this.isActual = false;
      this.dataDetail.status = 0;
      this.preparePay = true
      AdvanceApi.saveDraft(this.dataDetail).then(res => {
        this.findById(res.id).then(() => {
          if (this.isAutoPrint) {
            this.toPrint();
          }
          this.showOrder = false;
          this.$routerUtil.push("/advance/list/detail");
        });
        this.$message.success("保存成功");
        this.preparePay = false;
      }).catch(() => {
        this.preparePay = true;
      })
    },
    toSaveDraft() {
      if(!this.checkParams()){
        return;
      }
      this.showOrder = true;
      this.isPay = false;
    },
    findById(id) {
      return AdvanceApi.findById(id).then(res => {
        this.dataDetail = res;
      })
    },
    //检查参数
    checkParams() {
      if (!this.dataDetail.customerId || !this.dataDetail.employeeId || !this.dataDetail.departCode || !this.dataDetail.surplusPay || !this.dataDetail.payType) {
        this.$message.warning("请填写完整的头部信息");
        return false;
      }
      return true;
    },
    toSaveSubmit() {
      if(!this.checkParams()){
        return;
      }
      this.showOrder = true;
      this.isPay = true;
    },
    openBefore() {
      this.$nextTick(() => {
        this.$refs.inputRef.$el.querySelector('input').focus();
      });
    },
    // 打印
    async toPrint() {
      // 组装打印数据
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.getPrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    // 打印设置
    async toSetPrint() {
      let customerInfo;
      if(this.dataDetail.customerId){
        customerInfo = await memberApi.findById(this.dataDetail.customerId);
      }
      let data = this.getPrintData("design",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    // 拼接打印数据
    getPrintData(type,customerName,customerPhone) {
      if(this.dataDetail.status === 99){
        this.isActual = true;
      }
      let data = {
        reportName: "押金订单",
        amount: this.dataDetail.amountPay,
        customerName: customerName,
        customerPhone: customerPhone,
        amountActual: this.isActual ? this.dataDetail.amountPay : 0,
        // orderTime: this.dataDetail.orderTime,
        employeeName: this.dataDetail.employeeName,
        status: this.dataDetail.status !== 99 ? '草稿' : '已支付',
        // depositType: this.dataDetail.depositType,
        excuteType: type,
      }

      //操作人员
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.createdId === this.employeeList[i].id) {
          data.createdName = this.employeeList[i].employeeName
        }
      }
      // 订单编号
      if (this.dataDetail.id) {
        data.orderNumber = this.dataDetail.id;
      }
      data.orderTime = this.dataDetail.createdTime;
      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
      }
      //押金类型
      for (let i = 0; i < this.dictionaryList.length; i++) {
        if (this.dataDetail.depositType === this.dictionaryList[i].dictCode) {
          data.depositType = this.dictionaryList[i].dictName;
        }
      }
      // 支付状态
      if (this.dataDetail.payStatus === 0 || !this.dataDetail.payStatus) {
        data.payStatus = "未支付";
      } else if (this.dataDetail.payStatus === 99) {
        data.payStatus = "已支付";
      } else if (this.dataDetail.payStatus === 30) {
        data.payStatus = "支付失败";
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.dataDetail.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }
      data.productList = [];
      return data;
    },


  }
};
</script>

<style lang="scss" scoped>
.rotating-element {
  animation: rotate 1.5s linear infinite;
}
</style>