<template>
  <!-- 通用模板，列表数据 -->
  <list-page>
    <search-condition @search="resetSearch" :show-search="true">
      <product-one-select v-model="searchInfo.skuId" style="display: inline-block;width: 23%" @selectOne="resetSearch"/>
      <el-select v-model="searchInfo.merchantId" placeholder="请选择商户" filterable clearable>
        <el-option v-for="item in merchantList" :key="item.id" :label="item.merchantName" :value="item.id"/>
      </el-select>
    </search-condition>
    <table-list>
      <el-table stripe :data="dataList" height="100%" ref="tableList" @row-click="clickRow" @selection-change="selectionChange" empty-text="请选择商户后查询授权">
        <el-table-column type="selection" :align="'center'"/>
        <el-table-column label="商品名称" prop="skuName" show-overflow-tooltip min-width="150px"/>
        <el-table-column label="商品单位" prop="unitName" show-overflow-tooltip width="100px" :align="'center'">
          <template v-slot="{row}">
            <span>{{ row.unitName ? row.unitName : row.unitName2 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品编码" prop="skuCode" min-width="100px" show-overflow-tooltip/>
        <el-table-column label="拼音码" prop="pinyinCode" min-width="100px" show-overflow-tooltip/>
        <el-table-column label="条码" prop="barCode" min-width="100px" show-overflow-tooltip/>
        <el-table-column label="成本价格" min-width="120px">
          <template v-slot="{row}">
            <el-input v-model="row.originalBuyPrice" type="number" @click.stop/>
          </template>
        </el-table-column>
        <el-table-column label="销售价格" min-width="120px">
          <template v-slot="{row}">
            <el-input v-model="row.originalSalePrice" type="number" @click.stop/>
          </template>
        </el-table-column>
        <el-table-column label="操作" :align="'center'" width="100px">
          <template v-slot="{row, $index}">
            <table-button type="write" @click="saveSingleData(row)">保存</table-button>
<!--            <table-button type="delete" el-type="danger" @click="remove($index)">删除</table-button>-->
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false">
      <page-button type="write" el-type="success" @click="saveData">保存</page-button>
    </form-page>
  </list-page>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import grantApi from "@/api/main/grant/grantApi";
import ProductOneSelect from "@/components/h5/product/ProductOneSelect";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, ProductOneSelect
  },
  data() {
    return {
      searchInfo: {
        isGrant: 0,
      },
      dataList: [],
      merchantList: [],
      elSelected: [],
    };
  },
  created() {
    grantApi.queryMerchantList().then(res => {
      this.merchantList = res;
    });
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      if (this.searchInfo.isGrant === 0 && !this.searchInfo.merchantId) {
        this.$message.error("请先选择授权商户");
        return;
      }
      grantApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      });
    },
    remove(index) {
      this.dataList = this.dataList.remove(index);
      // remove后需要重新set
      this.$nextTick().then(() => {
        for (let i = 0; i < this.dataList.length; i++) {
          this.$refs.tableList.toggleRowSelection(this.dataList[i], !!this.dataList[i].checked);
        }
      });
    },
    clickRow(data) {
      data.checked = !data.checked;
      this.$refs.tableList.toggleRowSelection(data, data.checked);
    },
    selectionChange(list) {
      this.elSelected = list;
    },
    // 保存数据
    saveData() {
      let arr = [];
      for (let i = 0; i < this.elSelected.length; i++) {
        arr.push(this.createNewData(this.elSelected[i]));
      }
      // 数据
      grantApi.saveGrantByList(arr, this.searchInfo.merchantId).then(() => this.resetSearch());
    },
    // 保存一条数据
    saveSingleData(data) {
      grantApi.saveGrantByList([this.createNewData(data)], this.searchInfo.merchantId).then(() => this.resetSearch());
    },
    createNewData(data) {
      return {
        ...data,
        authorizeBuyPrice:data.originalBuyPrice,
        authorizeSalePrice:data.originalSalePrice,
        skuId: data.originalSkuId,
        productId: data.originalProductId,
        taxCode: data.originalTaxCode,
        productCategory: data.category,
        unitId: data.skuUnitId,
        id: "",
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>