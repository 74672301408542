<template>
  <div class="erpRelation defaultViewStyle scrollPage">
    <page-header />
    <el-form ref="form_test" :model="erp" :rules="rules" label-width="120px" size="small">
      <el-form-item label="ERP系统版本" required prop="thirdVersion">
        <el-select v-model="erp.thirdVersion" placeholder="请选择" @change="handleChange">
          <el-option
              v-for="(i,k) in thirdVersions"
              :key="k"
              :label="i.name"
              :value="i.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据库对接状态">
        <el-tag :type="linkType ? 'success' : 'danger'">
          {{ linkType ? "已连接" : "未连接" }}
        </el-tag>
      </el-form-item>
      <el-form-item label="主机" required prop="thirdDatabaseIp">
        <el-input v-model="erp.thirdDatabaseIp"></el-input>
      </el-form-item>
      <el-form-item label="端口号" required prop="thirdDatabasePort">
        <el-input v-model="erp.thirdDatabasePort"></el-input>
      </el-form-item>
      <el-form-item label="用户名" required prop="thirdDatabaseUser">
        <el-input v-model="erp.thirdDatabaseUser" ></el-input>
      </el-form-item>
      <el-form-item label="登陆密码" required prop="thirdDatabasePassword">
        <el-input type="password" v-model="erp.thirdDatabasePassword" autocomplete="new-password"></el-input>
        <el-button type="text" @click="testLink">连接测试</el-button>
      </el-form-item>
    </el-form>
    <el-form ref="form_data" :model="erp" :rules="rules" label-width="120px" size="small">
      <el-form-item label="数据库实例" required prop="thirdDatabaseName">
        <el-select v-model="erp.thirdDatabaseName" placeholder="请选择" clearable filterable>
          <el-option
              v-for="(i,k) in dataBase"
              :key="k"
              :label="i"
              :value="i">
          </el-option>
        </el-select>
        <el-button type="text" @click="link">连接</el-button>
      </el-form-item>
    </el-form>
    <el-form ref="form_defaultOperator" :model="erp" :rules="rules" label-width="120px" size="small">
      <el-form-item label="默认制单人" required prop="thirdDefaultOperator">
        <el-select v-model="erp.thirdDefaultOperator" placeholder="请选择" clearable>
          <el-option
              v-for="(i,k) in thirdOperators"
              :key="k"
              :label="i.name"
              :value="i.thirdKeyBackup">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form ref="form_defaultStock" :model="erp" :rules="rules" label-width="120px" size="small">
      <el-form-item label="默认仓库" required prop="defaultStock">
        <el-select v-model="erp.defaultStock" placeholder="请选择" clearable>
          <el-option
              v-for="(i,k) in stocks"
              :key="k"
              :label="i.name"
              :value="i.thirdKeyBackup">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form ref="form" :model="erp" :rules="rules" label-width="120px" size="small">
      <el-form-item>
        <page-button el-type="primary" plain>重置</page-button>
        <page-button el-type="success" @click="onSubmit">保存</page-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import erpApi from "@/api/main/sync/erpConfigApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
export default {
  name: "erpRelation",
  data() {
    return {
      erp: {
        thirdVersion: "",
        thirdDatabaseIp: "",
        thirdDatabasePort: "",
        thirdDatabaseUser: "",
        thirdDatabasePassword: ""
      },
      rules: {
        thirdVersion: [{required: true, trigger: "blur", message: "必填项"}],
        thirdDatabaseIp: [{required: true, trigger: "blur", message: "必填项"}],
        thirdDatabasePort: [{required: true, trigger: "blur", message: "必填项"}],
        thirdDatabaseUser: [{required: true, trigger: "blur", message: "必填项"}],
        thirdDatabasePassword: [{required: true, trigger: "blur", message: "必填项"}],
        thirdDatabaseName: [{required: true, trigger: "blur", message: "必填项"}],
        thirdDefaultOperator: [{required: true, trigger: "blur", message: "必填项"}],
        defaultStock: [{required: true, trigger: "blur", message: "必填项"}],
      },
      linkType: false,
      thirdVersions: [],
      dataBase: [],
      thirdOperators: [],
      stocks: []
    };
  },
  created() {
    this.queryAllVersions();
  },
  components: {
    PageButton,PageHeader
  },
  methods: {
    queryAllVersions() {
      erpApi.queryAllVersions().then(res => {
        this.thirdVersions = res;
        this.findThirdConfig();
      })
    },
    findThirdConfig() {
      erpApi.findThirdConfig().then(res => {
        if (res.thirdVersion) {
          this.erp = res
          this.linkType = !!res.reachConnect;
          this.testOperatorLink();
          this.testStockLink();
        }
      })
    },
    handleChange(value) {
      this.erp.thirdVersion = this.thirdVersions[value].code;
      this.erp.thirdVersionNumber = this.thirdVersions[value].name;
    },
    testLink() {
      this.$refs.form_test.validate(res => {
        if (res) {
          this.erp.thirdVersionNumber = this.thirdVersions.filter(i => i.code === this.erp.thirdVersion)[0].name;
          erpApi.testLink(this.erp).then(res => {
            this.dataBase = res;
            this.$message.success('连接测试成功')
          })
        }
      })
    },
    link() {
      this.$refs.form_data.validate(res => {
        if (!res) return;
        this.testOperatorLink();
        this.testStockLink();
        this.$message.success('数据库实例连接成功');
      })
    },
    testOperatorLink() {
      erpApi.testOperatorLink(this.erp).then(res => {
        this.thirdOperators = res;
      })
    },
    testStockLink() {
      erpApi.testStockLink(this.erp).then(res => {
        this.stocks = res;
      })
    },
    onSubmit() {
      this.$refs.form_test.validate(res1 => {
        this.$refs.form_data.validate(res2 => {
          if (res1 && res2) {
            erpApi.save(this.erp).then(res => {
              this.findThirdConfig()
            })
          }
        })
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.erpRelation {
  .el-input, .el-select {
    width: 300px;
    margin-right: 10px;
  }

  //.el-form {
  //  width: 500px;
  //}
}
</style>