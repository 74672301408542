<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <page-button el-type="primary" type="print" @click="exportData">导出</page-button>
        <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
      </template>
    </page-header>
    <list-page>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.categoryCode" :dictionaries="categoryList" dict-code="dictCode"
                        dict-name="dictName" :place-holder="'请选择商品分类'"
                        @clear-data="clearCode" style="width: 100%"/>
          </div>
          <el-input ref="orderNumberInput" v-model="searchInfo.skuName" placeholder="请输入商品名称"
                    clearable @change="resetSearch"/>
        </template>
        <template #moreCondition>


          <el-select v-if="readAccess !== '1'" v-model="batchSearchInfo.stockId" multiple collapse-tags
                     collapse-tags-tooltip clearable
                     placeholder="请选择仓库" style="width: 240px">
            <el-option
                v-for="item in stockList"
                :key="item.id"
                :label="item.stockName"
                :value="item.id"
            />
          </el-select>

          <el-checkbox v-model="isSelectEmpty" label="不显示出入库记录为0的商品" size="large"/>


        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" show-summary :summary-method="getSummaries" @row-click="rowClick">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed="left">
            <template #header>
              <table-setting2 ref="tableSetting" @set-data="getColumnData" :setting-list="tableSetting"/>
            </template>
          </el-table-column>

          <el-table-column v-for="(item,idx) in columnList" :key="idx" :label="item.name"
                           align="center" show-overflow-tooltip
                           :prop="item.prop" :min-width="item.width">
            <el-table-column v-if="item.children && item.children.length > 0" v-for="(itemCld2, idx2) in item.children" :key="idx2"
                             show-overflow-tooltip align="center"
                             :label="itemCld2.name" :prop="itemCld2.prop"
                             :min-width="itemCld2.width">
              <el-table-column v-if="itemCld2.children && itemCld2.children.length > 0" v-for="(itemCld3, idx3) in itemCld2.children" :key="idx3"
                               show-overflow-tooltip align="center"
                               :label="itemCld3.name" :prop="itemCld3.prop"
                               :min-width="itemCld3.width" />
            </el-table-column>
          </el-table-column>

<!--          <el-table-column align="center" label="仓库" prop="stockName" show-overflow-tooltip min-width="150px"-->
<!--                           fixed="left"/>-->
<!--          <el-table-column align="center" label="商品编号" prop="skuCode" show-overflow-tooltip min-width="150px"-->
<!--                           fixed="left"/>-->
<!--          <el-table-column align="center" label="商品名称" prop="skuName" show-overflow-tooltip min-width="150px"-->
<!--                           fixed="left"/>-->
<!--          <el-table-column align="center" label="规格" prop="skuStandard" show-overflow-tooltip min-width="150px"/>-->
<!--          <el-table-column align="center" label="型号" prop="skuType" show-overflow-tooltip min-width="150px"/>-->
<!--          <el-table-column align="center" label="条码" prop="barCode" show-overflow-tooltip min-width="150px"/>-->
<!--          <el-table-column align="center" label="基本单位" prop="unitName" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="采购含税成本单价" prop="averagePrice" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="采购未税成本单价" prop="taxExcludePrice" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="期初库存" prop="initialQty" show-overflow-tooltip min-width="100px"/>-->
<!--          <el-table-column align="center" label="期初含税金额" prop="initialAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="期初未税金额" prop="taxExcludeInitialAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="本期入库数量" prop="skuInQty" show-overflow-tooltip min-width="150px"/>-->
<!--          <el-table-column align="center" label="本期入库含税金额" prop="skuInAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="本期入库未税金额" prop="taxExcludeSkuInAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="本期出库数量" prop="skuOutQty" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="本期出库含税金额" prop="skuOutAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="本期出库未税金额" prop="taxExcludeSkuOutAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->

<!--          <el-table-column align="center" label="期末库存" prop="endQty" show-overflow-tooltip min-width="120px"/>-->
<!--          <el-table-column align="center" label="期末含税金额" prop="endAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->
<!--          <el-table-column align="center" label="期末未税金额" prop="taxExcludeEndAmount" show-overflow-tooltip-->
<!--                           min-width="150px"/>-->

          <el-table-column label="操作" :align="'center'" min-width="120px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="toShowDetail(row)">查看明细</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import stockStatisticApi from "@/api/main/stock/stockStatisticApi";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import util from "@/util/util";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import {h} from "vue";
import TableSetting2 from "@/components/h5/column/TableSetting2.vue";

export default {
  name: "StockRecord",
  components: {
    TableSetting2,
    TableButton, FormPage, TableList,
    DateRange, SearchCondition,
    PageHeader, PageButton, ListPage, Dictionary
  },
  data() {
    return {
      // 查询条件
      searchInfo: {
        skuName: "",  // 传入详情页面的指定商品
      },
      // 批量查询
      batchSearchInfo: {},
      // 仓库列表
      stockList: [],
      // 表格数据列表
      dataList: [],
      // 商品分类字段
      categoryList: [],
      // 列表统计信息
      stockStatistic: {},
      // 是否查询空
      isSelectEmpty: false,
      // 设置读的权限值
      readAccess: null,
      // 配置列
      tableSetting: [
        // {prop: "stockName", name: "仓库名称", isChange: false, isChecked: true, index: 1},
        {prop: "skuCode", name: "商品编号", isChange: true, isChecked: true, index: 1},
        {prop: "skuName", name: "商品名称", isChange: true, isChecked: true, index: 2},
        {prop: "skuStandard", name: "规格", isChange: false, isChecked: false, index: 3},
        {prop: "skuType", name: "型号", isChange: false, isChecked: false, index: 4},
        {prop: "barCode", name: "条码", isChange: false, isChecked: false, index: 5},
        {prop: "unitName", name: "基本单位", isChange: false, isChecked: true, index: 6},
        {
          prop: "", name: "期初结存", isChange: false, isChecked: true, index: 7, children: [
            {prop: "initialQty", name: "数量", isChange: false, isChecked: true, index: 1},
            {prop: "averagePrice", name: "平均单价", isChange: false, isChecked: true, index: 2},
            {prop: "initialAmount", name: "金额", isChange: false, isChecked: true, index: 3},
          ]
        },
        {
          prop: "", name: "本期入库", isChange: false, isChecked: true, index: 8, children: [
            {prop: "skuInQty", name: "数量", isChange: false, isChecked: true, index: 1},
            {prop: "skuInAmount", name: "金额", isChange: false, isChecked: true, index: 2},
          ]
        },
        {
          prop: "", name: "本期出库", isChange: false, isChecked: true, index: 9, children: [
            {prop: "skuOutQty", name: "数量", isChange: false, isChecked: true, index: 1},
            {prop: "skuOutAmount", name: "金额", isChange: false, isChecked: true, index: 2},
          ]
        },
        {
          prop: "", name: "期末结存", isChange: false, isChecked: true, index: 10, children: [
            {prop: "endQty", name: "数量", isChange: false, isChecked: true, index: 1},
            {prop: "averagePrice", name: "平均单价", isChange: false, isChecked: true, index: 2},
            {prop: "endAmount", name: "金额", isChange: false, isChecked: true, index: 3},
          ]
        },
      ],
      // 实际列
      columnList: [],
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    },
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
    // 仓库
    orderStatisticApi.queryStockList().then(res => {
      this.stockList = res;
    })
    // 时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    this.$nextTick(() => {
      this.$refs.tableSetting.setRowKey(this.tableSetting);
    });
  },
  methods: {
    getColumnData(data) {
      this.columnList = [...data];
      // 初始化查询
      this.handlerBatchSearch();
      this.resetSearch();
    },
    handlerBatchSearch() {
      if (this.batchSearchInfo.stockId && this.batchSearchInfo.stockId.length > 0) {
        this.searchInfo.stockId = this.batchSearchInfo.stockId.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
    },
    queryList() {
      // 处理批量查询的数据
      this.handlerBatchSearch();
      this.searchInfo.isSelectEmpty = this.isSelectEmpty;
      stockStatisticApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
      stockStatisticApi.queryStatistic(this.searchInfo).then(res => {
        this.stockStatistic = res;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查看明细
    toShowDetail(row) {
      this.handlerBatchSearch();
      // 设置商品名称
      const currentSearch = {
        ...this.searchInfo,
        skuName: row.skuName,
        skuId: row.skuId,
      };
      const currentBatch = {
        stockId: [],
      }
      currentBatch.stockId.push(row.stockId);
      this.$routerUtil.push({
        path: "/plat/recordDetail",
        query: {
          searchInfoStr: JSON.stringify(currentSearch),
          batchSearchInfoStr: JSON.stringify(currentBatch),
        },
      })
    },
    // 导出
    exportData() {
      this.handlerBatchSearch();
      util.exportExcel("/order/stock/statistic/exportList", this.searchInfo);
    },
    // 移除分类编码
    clearCode() {
      this.searchInfo.categoryCode = "";
    },
    // 统计
    getSummaries(param) {
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
        }
        if (this.stockStatistic[column.property]) {
          sums[index] = h('div', [Number(this.stockStatistic[column.property]).toFixed(2)])
        }
      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
:deep(.el-table__header) {
  .el-table__cell {
    padding: 2px 0 !important;
    font-size: 13px !important;
  }

  //:deep(.el-table .el-table__cel){

  //}
}
</style>