<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <!--          <page-button el-type="primary" @click="toPrepareSetPrint">打印设置</page-button>-->
          <div style="display: flex; align-items: center">
            <el-dropdown split-button trigger="click" type="primary" @command="handleSetPrint"
                         style="margin: 2px 15px 0 0;">
              打印设置
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :icon="Edit" :command="1">订单收款</el-dropdown-item>
                  <el-dropdown-item :icon="Edit" :command="2">订单退款</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
          </div>
        </template>
      </page-header>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <el-input ref="orderNumberInput" v-model="searchInfo.orderId" placeholder="请扫描/输入订单号"
                    @change="resetSearch"/>

          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>
        </template>
        <template #moreCondition>
          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.departCode" @clearData="clearDic" :place-holder="'请选择部门'"
                        :dictionaries="departList"
                        :is-multiple="true"
                        @change-data="setDepartCode"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择职员'" :is-multiple="true" v-model="searchInfo.employeeId" @select-more="setEmployee"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择创建人'" :is-multiple="true" v-model="searchInfo.createdId" @select-more="setCreated"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择收款人'" :is-multiple="true" v-model="searchInfo.payeeId" @select-more="setPayee"/>
          </div>

          <el-select v-model="searchInfo.payTypeIds" multiple collapse-tags collapse-tags-tooltip  placeholder="请选择支付方式" clearable>
            <el-option v-for="(st, i) in payType" :key="i + 'p'" :label="st.payName" :value="st.id"/>
          </el-select>

          <el-select v-model="searchInfo.payStatus" placeholder="请选择支付状态" clearable>
            <el-option label="未支付" :value="0"/>
            <el-option label="部分支付" :value="50"/>
            <el-option label="支付完成" :value="99"/>
          </el-select>
          <el-select v-model="searchInfo.orderStatus" placeholder="请选择订单状态" clearable>
            <el-option label="草稿" :value="0"/>
            <el-option label="未完成" :value="50"/>
            <el-option label="已完成" :value="99"/>
          </el-select>
          <el-select v-model="searchInfo.orderType" placeholder="请选择单据类型" clearable>
            <el-option label="销售订单" :value="1"/>
            <el-option label="销售退单" :value="2"/>
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" :default-sort="{ prop: 'id', order: 'descending' }">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="单据编号" show-overflow-tooltip min-width="180px" sortable fixed>
            <template v-slot="{row}">
              <table-button v-if="row.orderType === 1 || row.orderType === 11" class="add" el-type="primary" type="write"
                            @click="$routerUtil.push(`/report/offline-order/detail?id=${row.id}&isDraftUpdate=true`)">
                {{ row.id }}
              </table-button>
              <table-button v-else-if="row.orderType === 2 || row.orderType === 12" class="add" el-type="primary" type="write"
                            @click="$routerUtil.push(`/report/refund-order/detail?id=${row.id}&isFinish=2&isDraftUpdate=true`)">
                {{ row.id }}
              </table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="单据时间" prop="createdTime" show-overflow-tooltip min-width="160px"/>
          <el-table-column align="center" label="客户" prop="customerName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="合计数量" prop="qty" min-width="100px"/>
          <el-table-column align="center" label="合计金额" prop="amount" min-width="130px" sortable>
            <template v-slot="{ row }">
              <money :value="row.amount"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="未支付金额" prop="amountShould" min-width="100px"/>
          <el-table-column align="center" label="销售人员" prop="employeeName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="部门" property="departName" min-width="150px"/>

          <el-table-column label="支付方式" min-width="120px" :align="'center'">
            <template v-slot="{row}">
              <!--              <span v-for="(p, i) in payType" :key="i">-->
              <!--                <span v-if="row.payType === p.value">{{ p.label }}</span>-->
              <!--              </span>-->
              <pay-type-select :echo-pay-type-id="row.payTypeId" :echo-pay-type-list="payType"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="支付状态" min-width="100px">
            <template v-slot="{ row }">
              <el-tag v-if="row.payStatus === 0 && (row.orderType === 1 || row.orderType === 11)" type="info">未支付</el-tag>
              <el-tag v-if="row.payStatus === 20 && (row.orderType === 1 || row.orderType === 11)" type="info">支付中</el-tag>
              <el-tag v-if="row.payStatus === 30 && (row.orderType === 1 || row.orderType === 11)" type="danger">支付失败</el-tag>
              <el-tag v-if="row.payStatus === 50 && (row.orderType === 1 || row.orderType === 11)" type="primary">部分支付</el-tag>
              <el-tag v-if="row.payStatus === 99 && (row.orderType === 1 || row.orderType === 11)" type="success">支付成功</el-tag>
              <el-tag v-if="row.payStatus === 0 && (row.orderType === 2 || row.orderType === 12)" type="info">未退款</el-tag>
              <el-tag v-if="row.payStatus === 99 && (row.orderType === 2 || row.orderType === 12)" type="success">退款成功</el-tag>
            </template>
          </el-table-column>

          <el-table-column align="center" label="支付时间" show-overflow-tooltip min-width="160px" sortable>
            <template v-slot="{ row }">
              <span v-if="row.payTime == null">暂未支付</span>
              <span v-else>{{ row.payTime }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="单据类型" min-width="120px">
            <template v-slot="{ row }">
              <el-tag type="primary" v-if="row.orderType === 1">销售订单</el-tag>
              <el-tag type="primary" v-if="row.orderType === 11">小程序订单</el-tag>
              <el-tag type="primary" v-else-if="row.orderType === 2">销售退单</el-tag>
              <el-tag type="primary" v-else-if="row.orderType === 12">小程序退单</el-tag>
            </template>
          </el-table-column>

          <el-table-column align="center" label="创建人" prop="createdName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="收款人" prop="payeeName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="支付流水号" prop="outTradeNo" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="发票号码" prop="invoiceNo" show-overflow-tooltip min-width="100px"/>
          <el-table-column label="操作" :align="'center'" min-width="80px" fixed="right">
            <template v-slot="{ row }">
              <list-button>
                <page-button v-if="row.orderType === 1 || row.orderType === 11" el-type="primary" @click="toPayment(row)"
                              :disabled="row.status !== 0 && row.payStatus === 99">收款
                </page-button>
                <page-button v-if="row.orderType === 2 || row.orderType === 12" el-type="primary" @click="toRefundment(row)"
                              :disabled="row.status === 99">退款
                </page-button>
                <page-button :disabled="row.status === 0" @click="payRecord(row)">支付记录</page-button>
                <page-button el-type="primary" @click="toShowInvoice(row)" :disabled="row.status !== 99">发票
                </page-button>
                <page-button el-type="primary" @click="toPreparePrint(row)">打印</page-button>
              </list-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>

      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false"></form-page>
    </list-page>

<!--    <settlement-comp :is-submit-pay="true" ref="settlement" @pay-callback="payCallback" :sale-data="dataDetail"/>-->

    <el-dialog title="发票详情" :close-on-click-modal="false" @close="closeInvoice" v-model="showInvoice" width="40%">
      <div>
        <div style="height: 100%;">
          <el-form label-width="70px" style="">
            <el-form-item label="购方姓名">
              <el-input placeholder="请填写购方姓名" v-model="invoiceVo.name"/>
            </el-form-item>
            <el-form-item label="发票抬头">
              <el-input placeholder="请填写发票抬头" v-model="invoiceVo.invoiceTitle"/>
            </el-form-item>
            <el-form-item label="购方地址">
              <el-input placeholder="请填写购方地址" v-model="invoiceVo.buyerAddress"/>
            </el-form-item>
            <el-form-item label="购方电话">
              <el-input placeholder="请填写购方电话" v-model="invoiceVo.buyerTel"/>
            </el-form-item>
            <el-form-item label="购方税号">
              <el-input placeholder="请填写购方税号" v-model="invoiceVo.checkTaxNo"/>
            </el-form-item>
            <el-form-item label="购方账户">
              <el-input placeholder="购⽅银⾏名称-购⽅银⾏账号，中间⽤ ' - ' 隔开" v-model="invoiceVo.buyerAccount"/>
            </el-form-item>
            <el-form-item label="发票接口">
              <el-select v-model="invoiceVo.invoiceCodeType" placeholder="请选择接口类型">
                <el-option v-for="item in invoiceTypeList" :key="item.id" :label="item.invoiceName"
                           :value="item.invoiceCode"/>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="display: flex;justify-content: flex-end">
            <page-button el-type="info" @click="showInvoice = false">取消</page-button>
            <page-button v-loading.fullscreen.lock="fullscreenLoading" el-type="primary" @click="getInvoiceResult">获取发票
            </page-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="发票详情" v-model="showInvoiceDetail" width="60%" border>
      <dialog-table>
        <el-table stripe height="100%" :data="invoiceTicketList">
          <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
          <el-table-column label="批次号" show-overflow-tooltip :align="'center'" min-width="150px">
            <template v-slot="{row}">{{ row.settleCode }}</template>
          </el-table-column>
          <el-table-column label="发票类型" show-overflow-tooltip :align="'center'" min-width="150px">
            <template v-slot="{row}">{{ row.invoiceType === 1 ? '正票' : '红票' }}</template>
          </el-table-column>
          <el-table-column label="总金额" prop="invoiceVo.payAmount" show-overflow-tooltip :align="'center'"
                           min-width="150px"/>
          <el-table-column label="开票时间" prop="invoiceVo.makeTime" show-overflow-tooltip :align="'center'"
                           min-width="150px"/>
          <el-table-column label="操作" show-overflow-tooltip :align="'center'" min-width="150px">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="getInvoiceResult(row)">查看</table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
    </el-dialog>

    <el-dialog title="支付流水号" v-model="pay.showPaySerial" width="600px">
      <el-input v-model="pay.paySerialNum" placeholder="请输入支付流水号"/>
      <div style="text-align: right;padding-top: 10px;">
        <page-button @click="pay.showPaySerial = false">取消</page-button>
        <page-button el-type="primary" @click="toSavePaySerial">保存</page-button>
      </div>
    </el-dialog>

    <el-dialog v-model="showOrder" width="30%" height="310px" :close-on-click-modal="false" @opened="openBefore" :title="isPaying ? '收款确认&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支付中...' : '收款确认'">
      <sale-balance :sale-info="dataDetail" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                    :is-pay="true" :is-disabled="true" :is-refresh-pay="isRefreshPay" ref="isAutoFocus"
                    v-loading.fullscreen.lock="fullscreenPayLoading" @change-pay="changePay" @close-page="showOrder = false" @pay="payReceipt" @find-sale-status="toFindPayStatus">
        <el-checkbox v-model="isAutoPrint" label="打印收款凭证" size="large"/>
      </sale-balance>
    </el-dialog>

    <el-dialog v-model="showRefund" width="30%" height="310px" title="退款确认" :close-on-click-modal="false">
      <sale-balance :sale-info="payDetail" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay" :pay-type-list="payType" :is-refund="true"
                    @change-refund="changeRefundType" @change-pay="changePay" @close-page="showRefund = false"
                    @pay="submitRefund">
        <el-checkbox v-model="isAutoPrint" label="打印退单凭证" size="large"/>
      </sale-balance>
    </el-dialog>

    <el-dialog title="收款记录" style="background-color: #f4f5f6" v-model="showPayRecord" width="40%">
      <div class="dialog-scss">
        <el-form label-width="70px">
          <el-form-item label="客户名称">
            {{ payRecords.detail.customerName }}
          </el-form-item>
          <el-form-item label="实收金额">
            {{ payRecords.detail.amount }}
          </el-form-item>
        </el-form>
        <dialog-table style="box-shadow: 3px 3px 15px #e5e0e0">
          <el-table stripe :data="payRecords.recordList" height="100%">
            <el-table-column align="center" label="收款日期" prop="payTime" show-overflow-tooltip min-width="100px"/>
            <el-table-column align="right" label="收款金额" prop="currentAmount" show-overflow-tooltip min-width="100px"/>
            <el-table-column align="center" label="支付状态" min-width="100px">
              <template v-slot="{ row }">
                <span v-if="row.payStatus === 0" type="info">未支付</span>
                <span v-if="row.payStatus === 20" type="info">支付中</span>
                <span v-if="row.payStatus === 30" type="error">支付失败</span>
                <span v-if="row.payStatus === 50" type="primary">部分支付</span>
                <span v-if="row.payStatus === 99" type="success">支付成功</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="收款方式" show-overflow-tooltip min-width="100px">
              <template v-slot="{row}">
               <span v-for="(p, i) in payType" :key="i">
                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>
              </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="收款人" prop="payeeName" show-overflow-tooltip min-width="100px"/>
          </el-table>
        </dialog-table>
      </div>

    </el-dialog>
  </div>



</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import Money from "@/components/h5/Money.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
// import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import SettlementComp from "@/components/h5/sale/SettlementComp.vue";
import commonApi from "@/api/commonApi";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import receiptApi from "@/api/main/order/receiptApi";
import RefundOrderApi from "@/api/main/order/online/RefundOrderApi";
import util from "@/util/util";
import {Edit, Plus, Setting} from "@element-plus/icons-vue";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import SaleBalance from "@/components/h5/sale/saleBalance.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import systemInvoiceApi from "@/api/main/sys/systemInvoiceApi";
import {ElLoading} from "element-plus";
import memberApi from "@/api/main/customer/memberApi";
import selfFunction from "@/selfFunction";
import localPrint from "@/util/new/LocalPrint";
import ListButton from "@/components/h5/button/ListButton.vue";
export default {
  name: "OrderList",
  computed: {
    Edit() {
      return Edit
    },
    Setting() {
      return Setting
    },
    Plus() {
      return Plus
    }
  },
  components: {
    DialogTable,
    SaleBalance,
    PayTypeSelect,
    EmployeeSelect,
    Dictionary,
    CustomerSelect,
    TableButton,
    PageButton, SettlementComp,
    ListPage, TableList, FormPage, SearchCondition, DateRange, Money, PageHeader, ListButton
  },
  data() {
    return {
      isAutoPrint: true,
      preparePay: false,
      disableToClickPay: false,
      searchInfo: {
        customerId: "",
        customerName: "",
        orderId: "",
        // payStatus: 0,
        departCodes: [],
      },
      dataList: [],
      stockList: [],
      showSerialNumber: false,
      currentOrder: {},
      payType: [],
      pay: {
        paySerialNum: "", //支付流水号
        showPaySerial: false,
        orderId: "",
      },
      showInvoice: false, //发票详情
      invoiceVo: {},
      payDetail: {},
      showOrder: false,
      dataDetail: {
        // payType: 2,
        productList: [],
        attachmentList: [],
        orderTime: new Date().format("yyyy-MM-dd hh:mm:ss"),
      },
      currentRowData: {}, // 调用打印之前 存储当前行的数据
      departList: [],
      refundDetail: {},
      showRefund: false,
      setPrintSelect: {
        isSelect: 1, // 1、订单 2、退单
      },
      payRecords: {
        recordList: [],
        detail: {},
      },
      showPayRecord: false,
      currentAmountCollect: "",
      isRefreshPay: false,
      isPaying: false,
      // 计时器，见名知意好吧
      timerToPayResult: false,
      invoiceTypeList: [],
      fullscreenLoading: false,
      fullscreenPayLoading: false,
      loading: null,
      showInvoiceDetail: false,
      invoiceTicketList: [],
      // 设置读的权限值
      readAccess: null,
    };
  },
  watch: {
    // 'searchInfo.startTime': {
    //   handler() {
    //     this.resetSearch();
    //   }
    // },
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path,"read").then(res => this.readAccess = res);
    // 获取发票列表
    systemInvoiceApi.queryAllList().then(res => this.invoiceTypeList = res);

    systemPayApi.queryAllList().then(res => this.payType = res);
    // 部门
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询全部仓库
    offlineOrderApi.queryAllStock().then(list => this.stockList = list);
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.orderNumberInput.focus()
    });
  },
  methods: {
    setDepartCode(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push(data[i][0]);
      }
      this.searchInfo.departCodes = arr
    },
    successResult() {
      this.showOrder = false;
      this.isPaying = false;
      this.disableToClickPay = false;
      this.preparePay = false;
    },
    toFindPayStatus() {
      if (this.isRefreshPay) this.$message.warning("正在努力获取结果，请耐心等待！！！");
      this.isRefreshPay = true;
      offlineOrderApi.findPayStatus(this.dataDetail.id,this.dataDetail.payId).then(res => {
        this.findById(this.dataDetail.id).then(() => {
          if (this.dataDetail.payStatus >= 50) {
            this.successResult();
            this.$message.success(res);
            this.currentAmountCollect = this.dataDetail.amountCurrentToPrint;
            if (this.isAutoPrint) {
              this.toPrint();
              this.successPayPrint();
            }
            this.loading.close();
            clearTimeout(this.timerToPayResult);
            selfFunction.createAudio(require("@/assets/video/result.mp3")).then(()=>{
              this.$routerUtil.push("/advance/collection");
            });
            return;
          }
          if (this.dataDetail.payStatus === 30) {
            this.successResult();
            this.$message.error(res);
            clearTimeout(this.timerToPayResult);
            this.loading.close();
            return;
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          clearTimeout(this.timerToPayResult);
          this.loading.close();
          return;
        } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
          // this.$message.warning("正在努力获取结果，请耐心等待！！！");
        }
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus();
        }, 2000);
        this.isRefreshPay = false;
      }).then(()=>{
        this.loading.close();
        this.isRefreshPay = false;
      })
    },
    payRecord(row) {
      receiptApi.queryPayRecords(row.id).then(res => {
        this.payRecords.recordList = res;
        this.payRecords.detail = row;
        this.showPayRecord = true;
      });
    },
    changePay(data) {
      this.dataDetail.payTypeId = data.id;
      this.dataDetail.payType = data.payCode;
    },
    changeRefundType() {
      receiptApi.updateRefundType(this.payDetail.id, this.payDetail.payTypeId, this.payDetail.payType).then(() => {
        this.queryList();
      });
    },
    handleSetPrint(command) {
      this.setPrintSelect.isSelect = command;
      this.toPrepareSetPrint();
    },
    clearDic() {
      this.searchInfo.departCode = "";
    },
    getInvoiceResult(row) {
      let loading = ElLoading.service({
        lock: true,
        text: '正在努力开票中...',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let data = {
        invoiceCodeType: this.invoiceVo.invoiceCodeType, // 发票接口
        dxInvoiceVo: this.invoiceVo,
      }
      data.dxInvoiceVo.invoiceType = "1";
      if (row) {
        // 正票、红票
        data.dxInvoiceVo.invoiceType = row.invoiceType;
        // 订单id
        data.dxInvoiceVo.orderId = row.originalId;
        // 获取发票接口
        data.invoiceCodeType = this.invoiceVo.invoiceCodeType;
      }
      systemInvoiceApi.findInvoice(data).then(res => {
        // window.open(process.env.VUE_APP_FILE_URL+res,'_blank');
        window.open(process.env.VUE_APP_FILE_URL + res, '_blank');
        // window.open(config.fileUrl + res, '_blank');
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 关闭发票界面前，清空掉数据
    closeInvoice() {
      this.invoiceVo = {
        invoiceCodeType: 1,
        invoiceTitle: "",
        invoiceType: "",
        name: "",
        buyerTel: "",
        buyerAddress: "",
      };
    },
    toShowInvoice(row) {
      // if (row.invoiceIssue === 1) {
      //   this.handleInvoiceData(row);
      //   this.showInvoice = true;
      // } else {
      //   this.invoiceVo.orderId = row.id;
      //   offlineOrderApi.findTicketsByOrderId(row.id).then(res => {
      //     this.invoiceTicketList = res;
      //   })
      //   this.showInvoiceDetail = true;
      // }


      if (row.invoiceIssue === 1) {
        // 当前还未开具过发票，打开发票页面
        this.handleInvoiceData(row);
        this.showInvoice = true;
      } else {
        // 已经开具了发票，展示发票列表
        this.invoiceVo.orderId = row.id;
        // 设置开票的接口
        this.invoiceVo.invoiceCodeType = row.invoiceInterfaceCode;
        offlineOrderApi.findTicketsByOrderId(row.id).then(res => {
          this.invoiceTicketList = res;
        })
        this.showInvoiceDetail = true;
      }
    },
    handleInvoiceData(row) {
      if (row.customerName === "临时客户") {
        this.invoiceVo.name = "个人";
        this.invoiceVo.invoiceTitle = "个人";
      } else {
        this.invoiceVo.name = row.customerName;
        this.invoiceVo.invoiceTitle = row.customerName;
      }
      this.invoiceVo.orderId = row.id;
      this.invoiceVo.buyerTel = row.customerPhone;
      this.invoiceVo.buyerAddress = row.customerAddress;
      this.invoiceVo.payAmount = row.amount;
      this.invoiceVo.totalCount = row.qty;
    },
    setCustomer(data) {
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    setPayee(data) {
      this.searchInfo.payeeIds = data;
    },
    setCreated(data) {
      this.searchInfo.createdIds = data;
    },
    setEmployee(data) {
      this.searchInfo.employeeIds = data;
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 列表查询、查询所有的订单（不包括线上的订单s）
    queryList() {
      return receiptApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },

    toSavePaySerial() {
      offlineOrderApi.savePaySerial(this.pay.orderId, this.pay.paySerialNum).then(() => {
        this.queryList();
        this.pay.showPaySerial = false;
      })
    },
    successPayPrint(){
      this.currentRowData.payStatus = 99;
      this.currentRowData.status = 99;
      this.currentRowData.payTypeId = this.dataDetail.payTypeId;
      this.currentRowData.amountCollect = this.dataDetail.amountCollect;
      this.currentRowData.amountShould = this.dataDetail.amountShould;
      this.currentRowData.payeeName = this.dataDetail.payeeName;
      this.toPrint();
    },
    //支付
    payReceipt(data) {
      this.loading = ElLoading.service({
        lock: true,
        text: '正在支付并获取支付结果,请勿刷新页面...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 赋值
      this.currentAmountCollect = data.amountCollect;
      this.dataDetail.amountCollect = data.amountCollect;
      this.dataDetail.qrCode = data.qrCode;
      // 收费标签
      this.dataDetail.paymentType = 2;
      // 锁定操作状态
      if (this.preparePay) {
        console.log("正在支付中，不能进行操作")
        return;
      }
      // 修改按钮状态
      this.isPaying = true;
      this.preparePay = true;
      this.disableToClickPay = true;
      offlineOrderApi.preparePay(this.dataDetail).then(saleOrder => {
        this.dataDetail = saleOrder;
        this.findById(this.dataDetail.id).then(() => {
          if (this.isAutoPrint && this.dataDetail.payStatus >= 50) {
            this.currentRowData = this.dataDetail;
            this.toPrint(false);
            this.successPayPrint();
            this.loading.close();
          }
          if (this.dataDetail.payStatus === 99 || this.dataDetail.payStatus === 50) {
            this.successResult();
            this.loading.close();
            this.isPaying = false;
            this.$message.success("支付成功");
            selfFunction.createAudio(require("@/assets/video/result.mp3")).then(()=>{
              this.$routerUtil.push("/advance/collection");
            });
          } else {
            // this.$message.warning("支付中，请稍后刷新支付状态！！！");
            this.toFindPayStatus();
          }
          this.dataList = []
        });
        //准备支付
      }).catch(() => {
        this.loading.close();
        this.preparePay = false;
        this.disableToClickPay = false;
        this.isPaying = false;
      })
    },
    findById(id) {
      return offlineOrderApi.findById(id).then(data => {
        this.dataDetail = data;
      });
    },
    openBefore() {
      this.$nextTick(()=>{
        this.$refs.isAutoFocus.initInput();
      })
    },
    payCallback(data){

    },
    toPayment(row) {
      if (row.amountCurrent === 0) {
        this.$message.error("当前该订单无需支付！！！");
        return;
      }
      this.findById(row.id).then(() => {
        this.dataDetail.amountCollect = this.dataDetail.amountCurrent;
        // this.showOrder = true;
        this.$refs.settlement.toSettlement(2);
      });
      this.currentRowData = row;
    },
    // 提交退款
    submitRefund(data) {
      this.preparePay = true;
      this.disableToClickPay = true;
      RefundOrderApi.refund(this.payDetail.id).then(() => {
        if (this.isAutoPrint) {
          this.currentRowData.status = 99;
          this.currentRowData.payStatus = 99;
          this.currentRowData.payTypeId = this.payDetail.payTypeId;
          this.currentRowData.payType = this.payDetail.payType;
          this.toPrint(true);
        }
        this.isRefund = true;
        this.showRefund = false;
        this.preparePay = false;
        this.disableToClickPay = false;
        this.$message.success("退款成功")
        this.queryList();
        this.dataList = [];
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      });
    },
    // 退款方法
    toRefundment(row) {
      util.getUserInfo().then(res => {
        row.payeeName = res.employeeName;
      })
      this.findById(row.id).then(()=>{
        this.payDetail = this.dataDetail;
        this.handlerRefundAccount();
      })
      this.currentRowData = row;
      this.showRefund = true;
    },

    // 页面直接打印前，先去拿取该条订单的数据
    toPreparePrint(row) {
      if(row.orderType === 2 || row.orderType === 12){
        this.findById(row.id).then(()=>{
          this.currentRowData = this.dataDetail;
          this.toPrint(true);
        })
      }else{
        this.findById(row.id).then(()=>{
          this.currentRowData = this.dataDetail;
          this.toPrint(false);
        })
      }
    },
    async toPrint(isRefund = false) {
      if(isRefund){
        // 组装打印数据
        const customerInfo = await memberApi.findById(this.currentRowData.customerId);
        let data = this.getPrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
        localPrint.print("销售退单",data);
      }else{
        // 组装打印数据
        const customerInfo = await memberApi.findById(this.currentRowData.customerId);
        let data = this.getPrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
        localPrint.print("收费凭证",data);
      }
    },
    toPrepareSetPrint() {
      if (this.dataList.length > 0) {
        this.currentRowData = this.dataList[0];
      }
      this.toSetPrint();
    },
    // 打印设置
    async toSetPrint() {
      let data = null;
      if(this.currentRowData.customerId){
        const customerInfo = await memberApi.findById(this.currentRowData.customerId);
        data = this.getPrintData("design",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      }else{
        data = this.getPrintData("design");
      }
      localPrint.printDesign("收费凭证",data);
    },
    // 获取打印的参数
    getPrintData(type,customerName,customerPhone) {
      let data = {
        // reportName: "订单收款",
        amountCollect: this.currentRowData.amountCurrentToPrint,
        amount: this.currentRowData.amount,
        customerName: customerName,
        customerPhone: customerPhone,
        orderNumber: this.currentRowData.id,
        amountShould: this.currentRowData.amountShould,
        amountAlreadyReceived: this.currentRowData.amountCollect,
        // orderTime: this.currentRowData.payTime,
        employeeName: this.currentRowData.employeeName,
        createdName: this.currentRowData.createdName,
        countQty: this.currentRowData.qty,
        stockName: this.currentRowData.stockName,
        status: this.dataDetail.status === 0 ? '草稿' : '已支付',
        excuteType: type,
      }
      // 单据类型
      if (type === "print") {
        if (this.currentRowData.orderType === 1) {
          data.reportName = "收费凭证";
          data.amountCollect = this.currentRowData.amountCurrentToPrint;
          data.amountActual = this.currentAmountCollect;
        } else if (this.currentRowData.orderType === 2) {
          data.reportName = "销售退单";
        }
      } else if (type === "design") {
        if (this.setPrintSelect.isSelect === 1) {
          data.reportName = "收费凭证";
        } else {
          data.reportName = "销售退单";
        }
      }

      // 收费人
      if (this.currentRowData.payeeName !== null) {
        data.payeeName = this.currentRowData.payeeName;
      } else {
        util.getUserInfo().then(res => {
          data.payeeName = res.employeeName;
        })
      }

      if (this.currentRowData.payTime) {
        data.orderTime = this.currentRowData.payTime;
      } else {
        data.orderTime = this.currentRowData.createdTime;
      }
      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.currentRowData.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.currentRowData.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }
      if (this.currentRowData.payStatus === 0) {
        data.payStatus = "未支付";
      } else if (this.currentRowData.payStatus === 99) {
        data.payStatus = "已支付";
      }


      let arr = [];
      for (let i = 0; i < this.currentRowData.productList.length; i++) {
        if(this.currentRowData.productList[i].qty === 0){
          continue;
        }
        let p = this.currentRowData.productList[i];
        arr.push({
          productIndex: i + 1,
          productName: p.skuName,
          productNumber: p.barCode,
          productPrice: p.skuOriginalPrice, //商品原价
          productCount: p.qty,
          productTotalPrice: p.qty * p.skuSalePrice,
          productDisCount: p.rate,
          productUnitName: p.skuUnitName,
          productSalePrice: p.skuSalePrice, //售价
        })
      }

      data.productList = arr;
      return data;
    },
    // 处理退货款账户
    handlerRefundAccount() {

      let payList = this.payDetail.payList;
      let myMap = new Map();
      for (let i = 0; i < payList.length; i++) {
        payList[i].orderId = this.payDetail.id;
        for (let j = 0; j < this.payType.length; j++) {
          if (payList[i].payTypeId === this.payType[j].id) {
            payList[i].payName = this.payType[j].payName;
          }
        }
        if (myMap.has(payList[i].payTypeId)) {
          let payInfo = myMap.get(payList[i].payTypeId);
          payInfo.refundTotal += payList[i].refundTotal;
          payInfo.amountCurrent += payList[i].amountCurrent;
          myMap.set(payList[i].payTypeId, payInfo);
        } else {
          myMap.set(payList[i].payTypeId, payList[i])
        }
        this.payDetail.refundTotal += payList[i].refundTotal;
      }
      this.payDetail.payList = [];
      myMap.forEach((value) => {
        if(value.refundCurrent > 0){
          this.dataDetail.payList.push(value);
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog.scss";
@import "@/assets/css/el-tables";
.headInfo-sty {
  display: flex;
  flex-direction: row;
}


.pay-result-div {
  width: 100%;
  word-break: break-all;
}

.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}

.add:hover {
  text-decoration: underline;
}

:deep(.el-dialog__header) {
  color: red;
}

:deep(.el-dialog){
  padding: 0;
  .el-dialog__header.show-close{
    text-align: left;
    //background: #409eff;
    background: #f5f7fa;
    //color: #ff0000;
    padding: 0 0 0 16px;
    line-height: 40px;
    .el-dialog__title{
      color: #181818;
      //color: black;
      //font-weight: bolder;
    }
    .el-dialog__headerbtn{
      .el-dialog__close{
        color: #0e0e0e;
        font-weight: bolder;
      }
    }
  }
  .el-dialog__body{
    padding: 16px;
  }
}
</style>
