<template>
  <!-- 图片上传组件，图片数据为json格式的字符串 -->
  <div class="image-upload">
    <el-upload
        :class="(hideAddBtn? 'hideAddBtn image-self': 'image-self') + (disabled? ' upload-disabled': '')"
        :style="{ '--width': width, '--height': height }"
        :disabled="disabled"
        v-model:file-list="imageUrl"
        list-type="picture-card"
        :action="uploadUrl"
        :headers="uploadHeaders"
        multiple
        :on-preview="picPreview"
        :on-success="picAdd"
        :before-remove="picRemove"
        :limit="limit"
    >
      <el-icon v-if="!hideAddBtn"><el-icon-plus/></el-icon>
      <template #tip>
        <div class="el-upload__tip">
          {{uploadTips}}
        </div>
      </template>
    </el-upload>
    <div :id="divId">
      <el-image
          class="hide-image-class"
          :id="id"
          ref="picRef"
          src="https://file.alpinestyle.vip/20240516180940969121/store/store_20240516195430675121.jpg"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="previewList"
          :initial-index="4"
          fit="fill"
      >
      </el-image>
    </div>
  </div>
</template>

<script>
import util from "@/util/util";
import {watch} from "vue";
// 上传图片通用组件，可上传和显示图片，自带预览功能
export default {
  emits: ["dataChange", "update:modelValue"],
  props: {
    // 显示多少条，只用于disabled的时候，显示图片，可以在有多张图片时只显示一张
    showLimit: {default: -1},
    // 上传多少张图片/文件
    limit: { default: 6 },
    // 上传文件地址
    url: { default: "" },
    // 图片地址，如果是多张图片，使用,分隔
    modelValue: { default: "" },
    // 是否使用图片服务器域名，默认不使用，使用相对路径
    useDomain: { default: false },
    // 上传图片类型限制
    accept: { default: "" },
    disabled: { default: false },
    // 尺寸，css中使用prop属性
    width: { default: '100px' },
    height: { default: '100px' },
    // 是否显示拍摄图片时获取的地理位置
    showAddress: { default: false },
    // 上传文件提示信息
    uploadTips: {default: ""},
    // 图片文件夹，这个图片要放在哪里
    folder: {default: "common"},
    // 图片上传地址
    javaUrl: {default: "/basedata/common/updateImage"},
  },
  computed: {
    style() {
      return {
        '--item-width': '50px',
        '--item-height': '50px',
      }
    },
  },
  components: {
  },
  data() {
    return {
      id: "",
      divId: "",
      // 是否页面已经刷新过，只被动刷新一次，不然每次上传都会闪
      updatedPage: false,
      // 是否需要隐藏上传按钮
      hideAddBtn: false,
      uploadUrl: "",
      uploadHeaders: { token: "" },
      imageUrl: [
      ],
      preview: "",
      previewList: []
    };
  },
  created() {
    this.id = "imageUpload_" + (Math.random() * 9999).toFixed(0);
    this.divId = "div_" + (Math.random() * 9999).toFixed(0);
    util.getUserInfo().then(user => {
      this.uploadHeaders.token = user.token;
      this.uploadHeaders.folder = this.folder;
    });
    this.init();
    watch(() => this.modelValue, () => {
      this.init();
      this.$forceUpdate();
    }, {deep: true});
    watch(() => this.disabled, () => this.init());
  },
  unmounted() {
    const body = document.querySelector('body')
    body.removeChild(document.querySelector("#" + this.divId))
  },
  mounted() {
    // 显示数据
    // 当整个组件渲染完成，将其添加到body
    this.$nextTick(() => {
      const body = document.querySelector('body')
      // 将匹配DOM添加到body中
      if (body.append) {
        body.append(document.querySelector("#" + this.divId));
      } else {
        body.appendChild(document.querySelector("#" + this.divId))
      }
    })
  },
  methods: {
    init() {
      this.uploadUrl = process.env.VUE_APP_BASE_URL + this.javaUrl;
      this.imageUrl = [];
      if (!this.modelValue) {
        // 没有图片显示
        this.hideAddBtn = this.imageUrl.length >= this.limit || this.disabled;
        return;
      }
      // 显示图片，转json对象,有可能直接是url
      let arr = [];
      if (this.modelValue.startsWith("[")) {
        let imgArr = JSON.parse(this.modelValue);
        for (let i = 0; i < imgArr.length; i++) {
          // 获取url
          arr.push({
            ...imgArr[i],
            url: this.getUrl4Image(imgArr[i].url, process.env.VUE_APP_FILE_URL)
          });

        }
      } else if(this.modelValue.startsWith("{")){
        let json = JSON.parse(this.modelValue);
        arr.push({
          ...json,
          url: this.getUrl4Image(json.url, process.env.VUE_APP_FILE_URL)
        })
      } else {
        // 兼容老数据或其它地方的外部链接
        arr.push({
          name: 'default',
          url: this.getUrl4Image(this.modelValue, process.env.VUE_APP_FILE_URL)
        })
      }
      // 只显示这么多张
      if (this.showLimit > 0) {
        if (arr.length > this.showLimit) {
          arr = arr.slice(0, this.showLimit);
        }
      }
      this.imageUrl = arr;
      this.hideAddBtn = this.imageUrl.length >= this.limit || this.disabled;
    },
    getUrl4Image(url, baseUrl) {
      if (url.startsWith("http")) {
        return url;
      } else if (url.startsWith("data:")) {
         return url;
      }
      return baseUrl + url;
    },
    // 预览
    picPreview(file) {
      this.preview = file.url;
      let arr = [];
      for (let i = 0; i < this.imageUrl.length; i++) {
        arr.push(this.imageUrl[i].url);
      }
      this.previewList = arr;
      this.$nextTick().then(() => {
        // document.querySelector(".hide-image-class").click();
        document.querySelector(`#${this.id}`).click();
      })
    },
    // 删除图片
    picRemove(dataRemoved) {
      // plus逻辑修改，remove不是手动操作，而是消息通知，需要update更新数据回调即可
      this.$nextTick().then(() => this.showImage(dataRemoved));
    },
    // 上传一张图片完成
    picAdd(res, file, fileList) {
      console.log("添加了一张图片")
      console.log(res)
      // 同时上传多张，只能set一次，不然就不会再触发success函数
      let allSuccess = true;
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].status !== "success") {
          allSuccess = false;
        }
      }
      if (!allSuccess) {
        return;
      }
      for (let i = 0; i < fileList.length; i++) {
        let prevImage = 0;
        for (let j = prevImage; j < this.imageUrl.length; j++) {
          if (this.imageUrl[j].status === "success") {
            prevImage = j;
            this.imageUrl[j] = {
              name: this.imageUrl.length, url: process.env.VUE_APP_FILE_URL + (!fileList[i].response? fileList[i].url: fileList[i].response.data),
              dateTime: new Date().format("yyyy-MM-dd hh:mm:ss")
            }
            break;
          }
        }
      }
      this.showImage();
    },
    // 显示图片
    showImage(dataRemoved) {
      // cv数据
      let is = [];
      // 把域名去掉
      for (let i = 0; i < this.imageUrl.length; i++) {
        if (dataRemoved && dataRemoved.uid === this.imageUrl[i].uid) {
          continue;
        }
        if (!this.useDomain) {
          is.push({
            url: this.imageUrl[i].url.indexOf(process.env.VUE_APP_FILE_URL) < 0 ?this.imageUrl[i].url: this.imageUrl[i].url.replaceAll(process.env.VUE_APP_FILE_URL, ""),
            name: i,
            dateTime: this.imageUrl[i].dateTime
          });
        } else {
          is.push(this.imageUrl[i]);
        }
      }
      // 更新数据
      this.$emit("update:modelValue", JSON.stringify(is));
      this.hideAddBtn = this.imageUrl.length >= this.limit || this.disabled;
      // 触发上传完成事件
      this.$emit("dataChange");
    }
  },
};
</script>

<style lang="scss" scoped>



.hideAddBtn :deep(.el-upload--picture-card) {
  display: none;
}

.image-self :deep(.el-upload--picture-card) {
  width: var(--width);
  height: var(--height);
  line-height: var(--height);
}

.image-self {
  :deep(.el-upload-list__item) {
    width: var(--width);
    height: var(--height);
    line-height: var(--height);
    margin-bottom: 0;
  }
}

.image-item {
  width: var(--width)
}

.image-upload .image-item .image-file-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  text-align: center;
  color: #FFF;
  font-size: 20px;
  display: none;

  span {
    margin: 0 5px;
    cursor: pointer;
  }

}

.image-upload .image-item:hover .image-file-cover {
  display: inline-block;
}

.hide-image-class{
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  float: left;
}
.el-upload__tip{
  margin: 0;
  line-height: 16px;
  font-size: .7rem;
}
// disable的时候不显示status
.upload-disabled{
  :deep(.el-upload-list__item.is-success .el-upload-list__item-status-label) {
    display: none;
  }
}

</style>
