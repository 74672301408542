<template>
  <div>
    <table-button el-type="primary" @click="showApproval = true" v-if="smallBtn">审批</table-button>
    <page-button el-type="warning" @click="showApproval = true" v-else>审批</page-button>
    <el-dialog v-model="showApproval" title="单据审批" width="40%">
      <el-form label-width="70px">
        <el-form-item label="审批结果">
          <el-radio-group v-model="dataDetail.approvalResult">
            <el-radio label="通过" :value="1"></el-radio>
            <el-radio label="不通过" :value="0"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审批备注">
          <el-mention
              v-model="dataDetail.approvalMsg"
              type="textarea"
              :options="epOptions"
              :rows="10"
              placeholder="请输入审批备注"
          />
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: flex-end">
        <page-button el-type="info" @click="showApproval = false">取消</page-button>
        <page-button el-type="primary" @click="saveSubmit">保存</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageButton from "@/components/h5/button/PageButton.vue";
import commonApi from "@/api/commonApi";
import TableButton from "@/components/h5/button/TableButton.vue";

export default {
  props: {
    saveApi: {
      type: Function,
      default: null,
      required: true,
    },
    dataId: {default: "", required: true},
    smallBtn: {default: false},
  },
  name: "",
  components: {
    TableButton,
    PageButton,
  },
  data() {
    return {
      showApproval: false,
      dataDetail: {
        approvalResult: 1,
        approvalMsg: "",
      },
      epOptions: [],
    };
  },
  created() {
    this.initOptions();
  },
  methods: {
    saveSubmit(){
      let data = {
        dataId: this.dataId,
        ...this.dataDetail
      }
      this.saveApi(data).then(() => {
        this.$message.success("审批成功");
        this.showApproval = false;
      });
    },
    initOptions() {
      commonApi.queryAllEmployee().then(list => {
        list.forEach(item => {
          this.epOptions.push({
            value: item.employeeName,
            label: item.employeeName,
          });
        })
      })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>