<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #content>
          <el-text type="info" style="margin-right: 10px;">{{ dataDetail.id }}</el-text>
          <el-text type="info" style="margin-right: 10px;">{{ dataDetail.createdName }}</el-text>
          <el-text type="info" style="margin-right: 10px;">{{ dataDetail.orderTime }}</el-text>
          <el-text :type="dataDetail.status === 0 ? 'warning' : dataDetail.status === 1 ? 'primary' : 'success'"
                   style="margin-right: 10px;">
            {{ dataDetail.status === 0 ? '草稿' : dataDetail.status === 1 ? '未完成' : '已完成' }}
          </el-text>
        </template>
        <template #default>
          <page-button v-if="showEditBtn" @click="editDataInfo" el-type="primary">{{ editWord }}</page-button>
          <page-button v-if="!isDraftUpdate" el-type="success" @click="reloading">新建</page-button>
        </template>
      </page-header>
      <table-list>
        <div :class="showAll
                        ? (dataDetail.status === 99 || isDraftUpdate) ? 'top-all-suc' : 'top-all'
                        : (dataDetail.status === 99 || isDraftUpdate) ? 'top-part-suc' : 'top-part'">
          <el-descriptions :column="3" border size="small">

            <el-descriptions-item label="客户" label-class-name="require">
              <span style="padding-left: 12px"
                    v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.customerName }}</span>
              <customer-select v-else :disabled="isDraftUpdate || dataDetail.status !== 0"
                               v-model="dataDetail.customerId" :name="dataDetail.customerName"
                               @selectOne="setCustomer"/>
            </el-descriptions-item>

            <el-descriptions-item label="客户电话" label-class-name="require">
              <span style="padding-left: 12px">{{
                  dataDetail.customerPhone ? dataDetail.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2') : '空'
                }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="单据日期" label-class-name="require">
              <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.orderTime }}</span>
              <el-date-picker v-else style="width: 100%"
                              v-model="dataDetail.orderTime"
                              type="datetime"
                              placeholder="选择时间"
              />
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="仓库" label-class-name="require">
              <span style="padding-left: 12px" v-if="dataDetail.status === 99 || isDraftUpdate">{{
                  dataDetail.stockName
                }}</span>
              <el-select v-else :disabled="isDraftUpdate  || dataDetail.status !== 0" v-model="dataDetail.stockId"
                         placeholder="选择仓库"
                         style="width: 100%">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="部门" label-class-name="require">
              <span style="padding-left: 12px" v-if="dataDetail.status === 99 || isDraftUpdate">{{
                  dataDetail.departName
                }}</span>
              <dictionary2 v-else v-model="dataDetail.departCode" :disabled="isDraftUpdate  || dataDetail.status !== 0"
                           @change-data="changeDepart" :dictionaries="departList"
                           dict-code="departCode"
                           dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="经办人" label-class-name="require">
              <span style="padding-left: 12px"
                    v-if="dataDetail.status === 99 || isDraftUpdate">{{ dataDetail.employeeName }}</span>
              <employee-select v-else :disabled="isDraftUpdate  || dataDetail.status !== 0"
                               v-model="dataDetail.employeeId" @selectOne="setEmployee" @change="employeeChange(data)"/>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="&nbsp;&nbsp; 收款人">
              <span style="padding-left: 12px">{{ dataDetail.payeeName }}</span>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="&nbsp;&nbsp; 关联单据" :span="3">
              <table-button style="padding-left: 12px" el-type="primary" @click="handleCommand('ygd')">验光单
              </table-button>
              <table-button el-type="primary" @click="handleCommand('kfyd')">客房预定</table-button>
              <span v-for="(d, i) in dataDetail.attachmentList" :key="i" class="attachment-item">
                <span v-if="d.dataType === 1" @click="showYanGuangDan(d.dataId)">验光单</span>
                <span v-if="d.dataType === 2" @click="showSub(d.dataId)">客房预定</span>
                <el-icon v-if="!isDraftUpdate" class="close" @click="removeYGDid(d.dataType, d.dataId)"><el-icon-delete/></el-icon>
              </span>
            </el-descriptions-item>

          </el-descriptions>
          <div style="display: flex;justify-content: space-between; height: 42px">
            <table-button @click="showAll = !showAll" style="margin-top: 8px">
              <el-icon :style="{transform: showAll? 'rotateZ(180deg)': 'rotateZ(0deg)'}">
                <el-icon-arrow-down/>
              </el-icon>
              {{ showAll ? "收起" : "展开" }}
            </table-button>
            <div v-if="dataDetail.status === 0 && !isDraftUpdate"
                 style="display: inline-block;width: 500px;padding-bottom: 10px">
              <product-list-select @select="selectProduct" @selectOne="selectOneProduct"
                                   :selected-product-list="dataDetail.productList" :is-stock="true"
                                   :stock-id="dataDetail.stockId" :show-price="true" :is-depart="true"
                                   :show-cost="false"/>
            </div>
          </div>
        </div>

        <div :class="showAll
                        ? (dataDetail.status === 99 || isDraftUpdate) ? 'table-all-suc' : 'table-all'
                        : (dataDetail.status === 99 || isDraftUpdate) ? 'table-part-suc' : 'table-part'">
          <el-table :data="dataDetail.productList" height="100%" show-summary :summary-method="getSummaries" stripe
                    border>
            <table-setting page-name="order/detail">
              <template #append>
                <el-table-column :align="'center'" show-overflow-tooltip min-width="120px" prop="skuCode" label="商品编号"
                                 fixed/>
                <el-table-column :align="'center'" show-overflow-tooltip min-width="120px" prop="skuName" label="商品名称"
                                 fixed/>
                <el-table-column prop="skuUnitName" label="销售单位" :align="'center'" width="120px">
                  <template v-slot="{row}">
                    <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ row.skuUnitName }}</span>
                    <el-select v-else v-model="row.skuUnitId" size="default" @change="toSelectUnitChange(row)"
                               placeholder="">
                      <el-option v-for="item in row.skuUnitList" :key="item.id" :label="item.unitName"
                                 :value="item.unitId"/>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="qty" label="销售数量" :align="'center'" width="100px">
                  <template v-slot="{row, $index}">
                    <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ row.qty }}</span>
                    <el-input type="number" v-else-if="row.skuName !== ''" v-model="row.qty" :min="1"
                              :max="row.stockType === 1 ? row.stockQty : 99999999999" @change="changeQty($index)"
                              :disabled="dataDetail.status !== 0 || isDraftUpdate"
                              :onkeyup="row.qty=Number((row.qty.toString()).match(/\d+/))"
                              :onafterpaste="row.qty=Number((row.qty.toString()).match(/\d+/))"
                    />
                  </template>
                </el-table-column>

                <el-table-column label="预设售价(含税)" :align="'right'" width="120px">
                  <template v-slot="{row}">
                    {{ row.skuOriginalPrice }}
                  </template>
                </el-table-column>

                <el-table-column label="折扣(0~1)" width="100px">
                  <template v-slot="{row, $index}">
                    <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ row.rate }}</span>
                    <el-input type="number" v-else-if="row.skuName !== ''" v-model="row.rate" @change="changeRate($index)"
                              :disabled="dataDetail.status !== 0 || isDraftUpdate"/>
                  </template>
                </el-table-column>

                <el-table-column label="未税销售单价" :align="'center'" width="130px">
                  <template v-slot="{row, $index}">
                    <span>{{ row.excludeRatePrice }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="未税销售金额" :align="'right'" width="130px" show-overflow-tooltip>
                  <template v-slot="{row}">
                    <money v-if="row.skuName !== ''" :value="row.excludeRatePrice * row.qty"/>
                  </template>
                </el-table-column>

                <el-table-column label="税率(0~1)" align="center" width="100px">
                  <template v-slot="{row, $index}">
                    <span v-if="dataDetail.status === 99 || isDraftUpdate">{{ row.taxRate }}</span>
                    <el-input v-else type="number" v-model="row.taxRate" @change="updateTaxRate($index)"/>
                  </template>
                </el-table-column>

                <el-table-column label="税额" align="center" width="100px">
                  <template v-slot="{row}">
                    <money v-if="row.skuName !== ''" :value="row.excludeRatePrice * row.qty * row.taxRate"/>
                  </template>
                </el-table-column>
                <el-table-column label="销售单价(折后)" :align="'right'" width="130px">
                  <template v-slot="{row, $index}">
                    <money v-if="dataDetail.status === 99 || isDraftUpdate"
                           :value="row.excludeRatePrice * (1 + row.taxRate)"/>
                    <el-input v-else type="number" v-model="row.skuSalePrice" @change="updateIncludeSalePrice($index)"/>
                  </template>
                </el-table-column>
                <el-table-column label="销售金额" :align="'right'" width="130px" fixed="right">
                  <template v-slot="{row,$index}">
                    <money v-if="dataDetail.status === 99 || isDraftUpdate" :value="row.rateTotalAmount"/>
                    <el-input v-else type="number" v-model="row.rateTotalAmount" @change="updateRateTotalAmount($index)"/>
                  </template>
                </el-table-column>
              </template>
            </table-setting>


            <el-table-column label="操作" :align="'center'" width="80px" fixed="right"
                             v-if="dataDetail.status === 0 && !isDraftUpdate">
              <template v-slot="{row,$index}">
                <!-- <table-button @click="$routerUtil.push(`?id=${row.id}`)">编辑</table-button> -->
                <table-button message="是否确认删除此数据" el-type="delete" @click="deleteRow(row,$index)">删除
                </table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <template #left>
          <div class="custom-info">

            <!--            <span>优惠金额: <money :value="dataDetail.amountDiscount"/></span>-->
            <!--            -->
            <!--            <span>本单应收: <money :value="dataDetail.amount" :show-color="true"/></span>-->
          </div>
        </template>
        <template #default>
          <div style="display: flex;flex-direction: row;line-height: 32px;font-size: 14px;">
            <page-button style="display: none" @click="aaa">
              播放
            </page-button>
            <page-button type="write" v-if="!isDraftUpdate && dataDetail.payStatus !== 20" el-type="primary"
                         @click="toBalance(1)"
                         :disabled="(dataDetail.status === 99 && dataDetail.payStatus === 99) || isDraftUpdate">
              收定金
            </page-button>
            <page-button type="write" v-if="!isDraftUpdate && dataDetail.payStatus !== 20" el-type="primary"
                         @click="toBalance(2)"
                         :disabled="(dataDetail.status === 99 && dataDetail.payStatus === 99) || isDraftUpdate">
              结算
            </page-button>

            <!--            <settlement-comp v-if="!isDraftUpdate && dataDetail.payStatus !== 20" @pay-callback="payCallback" :sale-data="dataDetail"/>-->

            <page-button type="write" v-if="!isDraftUpdate" el-type="success" :loading="preparePay" @click="toSaveDraft"
                         :disabled="dataDetail.status > 0 || isDraftUpdate ">存草稿
            </page-button>
            <yan-guang-dan :is-customer="0" :disabled="dataDetail.status > 0 || isDraftUpdate" ref="yanGuangDan"
                           @addYGDid="getYGDid"
                           :customer-id="dataDetail.customerId"
                           :customer-info="dataDetail.customerInfo"/>

            <room-subscribe :check-info="roomCheck" :show-btn="false" ref="subscribe"
                            :customer-id="dataDetail.customerId" :product-id="dataDetail.productList[0].productId"
                            @get-sub-id="getSubId"/>

            <create-new-customer @update-customer="updateCustomer" :is-query="false"
                                 v-if="dataDetail.status === 0 && !isDraftUpdate">客户建档
            </create-new-customer>

            <el-dropdown split-button type="primary" @click="toPrint" @command="handlerSelect" style="margin-top: 1px">
              打印
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :icon="Edit" command="setPrint">收费凭证</el-dropdown-item>
                  <el-dropdown-item :icon="Edit" command="setSubmitPrint">销售凭证</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </form-page>
    </list-page>

    <el-dialog v-model="showOrder" width="40%" @opened="openBefore" @closed="closedBefore" :close-on-click-modal="false"
               :title="dataDetail.paymentType === 1 ? '定金结算' : '订单结算'">
      <sale-balance :sale-info="this.dataDetail" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                    :is-pay="true" :is-batch-pay="true" :is-refresh-pay="isRefreshPay" ref="dialogFocus"
                    @change-pay="changePay" @find-sale-status="toFindPayStatus" @close-page="showOrder = false"
                    @pay="pay" @batch-submit="batchSubmit" v-loading.fullscreen.lock="fullscreenLoading">
        <el-checkbox v-model="isAutoPrint" label="打印凭证" size="large"/>
      </sale-balance>
    </el-dialog>

    <el-dialog v-model="showDraft" width="30%" height="310px" title="保存草稿">
      <sale-balance :sale-info="this.dataDetail" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                    @change-pay="changePay" @close-page="showDraft = false" @pay="saveDraft">
        <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large"/>
      </sale-balance>
    </el-dialog>

    <div style="display: none" ref="audio">
    </div>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import commonApi from "@/api/commonApi";
import Money from "@/components/h5/Money.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import YanGuangDan from "@/views/main/sale/order/businessComponent/YanGuangDan.vue";
import CreateNewCustomer from "@/components/h5/customer/CreateNewCustomer.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
// import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader";
import {h} from "vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import SaleBalance from "@/components/h5/sale/saleBalance.vue";
import {ArrowDown, Edit} from "@element-plus/icons-vue";
import {ElLoading} from "element-plus";
import memberApi from "@/api/main/customer/memberApi";
import selfFunction from "@/selfFunction";
import localPrint from "@/util/new/LocalPrint";
import yanGuangDanApi from "@/api/main/order/yanguangdan/YanGuangDanApi";
import houseSubscribeApi from "@/api/main/house/houseSubscribeApi";
import RoomSubscribe from "@/views/main/sale/order/businessComponent/RoomSubscribe.vue";
import productApi from "@/api/main/product/productApi";
import houseStatusApi from "@/api/main/house/houseStatusApi";
import SettlementComp from "@/components/h5/sale/SettlementComp.vue";
import Dictionary2 from "@/components/h5/sale/Dictionary2.vue";
import TableSetting from "@/components/h5/column/TableSetting.vue";
export default {
  name: "offlineOrder",
  computed: {
    Edit() {
      return Edit
    }
  },
  components: {
    ArrowDown,
    SaleBalance,
    TableButton,
    PageButton,
    ListPage,
    TableList,
    FormPage,
    Dictionary,
    Money,
    ProductListSelect,
    CustomerSelect,
    YanGuangDan,
    CreateNewCustomer,
    EmployeeSelect,
    PageHeader,
    RoomSubscribe,
    SettlementComp,
    Dictionary2,
    TableSetting
  },
  watch: {
    'dataDetail.productList': {
      handler(newVal) {
        if (!this.enableAutoAdd) {
          return;
        }
        // 反向遍历来安全地移除符合条件的元素
        for (let i = newVal.length - 1; i >= 0; i--) {
          if (newVal[i].productBrade === 0) {
            newVal.splice(i, 1);
          }
        }

        this.dataDetail.productList = newVal;
        if (newVal.length < 10) {
          for (let i = newVal.length; i < 10; i++) {
            this.dataDetail.productList.push({
              skuCode: "",
              skuName: "",
              skuStandard: "",
              skuType: "",
              barCode: "",
              productBrade: 0,
              stockQty: "",
              qty: 0,
            });
          }
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      // 开始商品列表的填充功能
      enableAutoAdd: true,
      showAll: false,
      dataDetail: {
        payType: 2,
        productList: [],
        attachmentList: [],
        orderTime: new Date().format("yyyy-MM-dd hh:mm:ss"),
        ygdId: "",
        status: 0,
        echoStockId: "",//回显仓库时使用的id
        customerInfo: {customerName: "临时客户"},
      },
      currentAmountCollect: 0,
      stockList: [],
      departList: [],
      showOrder: false,
      //结算时使用
      orderInfo: {
        appId: "",
        myPrivateKey: "",
        bizContent: {},
        saleOrder: {}
      },
      roomCheck: true,
      // 禁用支付按钮
      disableToClickPay: false,
      payType: [],
      employeeList: [],
      isAutoPrint: true,
      // 支付接口状态
      preparePay: false,
      isDraftUpdate: true,
      showDraft: false,
      // 控制草稿修改按钮
      showEditBtn: true,
      editWord: "修改",
      // 判断当前是否真实付款，用来拼接打印数据时使用
      isActual: false,
      // 控制支付中
      isPaying: false,
      isRefreshPay: false,
      // 计时器，循环获取支付结果
      timerToPayResult: null,
      productBrades: [],
      fullscreenLoading: false,
      loading: null,
      myTimer: null,
      skuUnitList: [],
    };
  },
  unmounted() {
    if (this.myTimer !== null) {
      clearInterval(this.myTimer);
    }
  },
  created() {
    // 商品品牌
    commonApi.queryDictionaryList("PRODUCT_BRADE").then(list => this.productBrades = list);
    // GjpLoaclPrint._initWebsocket();
    if (!this.$routerUtil.query.id && !this.$routerUtil.query.isDraftUpdate) {
      //当前为新建
      this.isDraftUpdate = false;
      this.showEditBtn = false;
    }
    if (this.$routerUtil.query.id) {
      this.enableAutoAdd = false;
      this.findById(this.$routerUtil.query.id);
    }
    commonApi.queryAllEmployee().then(list => {
      this.employeeList = list;
    });
    if (this.$routerUtil.query.isDraftUpdate) {
      this.enableAutoAdd = false;
      this.isDraftUpdate = true;
      this.showEditBtn = false;
    }
    this.queryAllStockList();
    commonApi.queryDepartList().then(list => this.departList = list);
    // this.payType = config.offlinePayType;
    systemPayApi.queryAllList().then(res => this.payType = res);
    if (this.$routerUtil.query.productId || this.$routerUtil.query.customerId) {
      this.findByRoomInfo(this.$routerUtil.query.productId, this.$routerUtil.query.customerId);
    }
  },
  methods: {
    updateTaxRate(idx) {
      let data = this.dataDetail.productList[idx];
      data.excludeRatePrice = data.skuSalePrice * (1 - data.taxRate);
    },
    // 修改价税合计
    updateRateTotalAmount(idx) {
      let data = this.dataDetail.productList[idx];
      data.skuSalePrice = (data.rateTotalAmount / data.qty).toFixed(2);
      this.updateIncludeSalePrice(idx);
    },
    // 修改含税单价
    updateIncludeSalePrice(idx) {
      let data = this.dataDetail.productList[idx];
      data.excludeRatePrice = (data.skuSalePrice / (1 + data.taxRate)).toFixed(2);
      if (!this.changeSalePrice(idx)) {
        data.excludeRatePrice = data.originalExcludeRatePrice;
      }
      data.rateTotalAmount = data.skuSalePrice * data.qty;
    },
    // 商品单位改变
    toSelectUnitChange(row) {
      // 获取当前单位的成本价格
      const skuUnitList = row.skuUnitList;
      for (let i = 0; i < skuUnitList.length; i++) {
        if (skuUnitList[i].unitId === row.skuUnitId) {
          row.excludeRatePrice = skuUnitList[i].salePrice;
          row.skuOriginalPrice = skuUnitList[i].salePrice;
          break;
        }
      }
    },
    findByRoomInfo(productId, customerId) {
      if (productId) {
        productApi.querySkuList(productId).then(list => {
          this.selectProduct(list);
        })
      }
      if (!customerId) {
        return;
      }
      memberApi.findById(customerId).then(data => {
        this.dataDetail.customerId = data.id;
        this.dataDetail.customerName = data.customerName;
        this.dataDetail.customerPhone = data.customerPhone;
      })
    },
    handleCommand(key) {
      if (key === "ygd") {
        this.$refs.yanGuangDan.toShowDetail();
      } else if (key === "kfyd") {
        this.$refs.subscribe.showSub(null, true);
      }
    },
    showSub(id) {
      this.$refs.subscribe.showSub(id, false);
    },
    employeeChange() {
      // 切换部门和仓库信息
      commonApi.findByEmployeeId(this.dataDetail.employeeId).then(dc => {
        this.dataDetail.departCode = dc;
        this.changeDepart(dc);
      });
    },
    aaa() {
      selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio);
    },
    getSubId(id) {
      // 向附件添加数据
      let contains = false;
      for (let i = 0; i < this.dataDetail.attachmentList.length; i++) {
        if (this.dataDetail.attachmentList[i].dataType === 2) {
          this.$message.error("只能添加一张预约单，请移除原来的单据后再添加")
          return;
        }
        if (this.dataDetail.attachmentList[i].dataId === id) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.attachmentList.push({dataId: id, dataType: 2});
      }
    },
    handlerSelect(command) {
      if (command === "setPrint") {
        this.toSetPrint();
      } else if (command === "setSubmitPrint") {
        this.toSubmitSetPrint();
      }
    },
    // 创建客户档案之后实时更新
    updateCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
    },
    // 提交本次的支付金额
    batchSubmit(data) {
      this.handlerProduct();
      // 保存
      this.isActual = false;
      this.preparePay = true;
      this.disableToClickPay = true;
      this.currentAmountCollect = data.amountCollect;
      this.dataDetail.amountCurrent = data.amountCollect;
      this.dataDetail.status = 50; // 设置为未完成
      this.dataDetail.payStatus = 0;
      this.dataDetail.amountCollect = null;
      offlineOrderApi.saveDraft(this.dataDetail).then(res => {
        this.findById(res.id).then(() => {
          this.currentAmountCollect = this.dataDetail.amountCurrent;
          this.preparePay = false;
          this.disableToClickPay = false;
          this.showOrder = false;
          if (this.isAutoPrint) {
            this.toSubmitPrint();
            this.$routerUtil.push({path: "/report/offline-order/detail"});
          }
        })
      });
    },
    changePay(data) {
      this.dataDetail.payTypeId = data.id;
      this.dataDetail.payType = data.payCode;
    },
    editDataInfo() {
      if (this.editWord === "修改") {
        this.isDraftUpdate = false;
        this.editWord = "取消";
        this.getSummaries();
      } else {
        this.findById(this.dataDetail.id).then(() => {
          this.isDraftUpdate = true;
          this.editWord = "修改";
          this.getSummaries();
        })
      }
    },
    reloading() {
      this.$routerUtil.push({path: "/report/offline-order/detail"});
    },
    setEmployee(data) {
      this.dataDetail.employeeName = data.employeeName;
    },
    findDepartByCode(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].departCode) {
          return list[i];
        }
        if (list[i].children && list[i].children.length > 0) {
          let dep = this.findDepartByCode(code, list[i].children);
          if (dep.id) {
            /// 找到数据
            return dep;
          }
        }
      }
      return {};
    },
    changeDepart(data) {
      // 变更部门时，清空仓库
      this.dataDetail.stockId = "";
      // 根据code获得部门id
      let dep = this.findDepartByCode(data, this.departList);
      if (dep.id) {
        // 部门有效
        this.queryAllStockList2(dep.id)
      }
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.createdName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.customerId = "10000";
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2(user.mainDepartId)
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      offlineOrderApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 默认选中一个
        if (this.dataDetail.echoStockId !== null && this.dataDetail.echoStockId !== '') {
          this.dataDetail.stockId = this.dataDetail.echoStockId;
          this.dataDetail.echoStockId = "";
          return;
        }
        if (list.length > 0) {
          this.dataDetail.stockId = list[0].id;
        }
      });
    },
    openBefore() {
      this.$nextTick(() => {
        this.$refs.dialogFocus.initInput();
      })
      if (this.dataDetail.status === 0) {
        this.dataDetail.amountShould = 0;
        this.dataDetail.amountCollect = this.dataDetail.amount;
      } else {
        this.dataDetail.amountCollect = this.dataDetail.amountShould;
      }
    },
    closedBefore() {
      if (this.dataDetail.status === 0) {
        this.dataDetail.amountCollect = 0;
        this.dataDetail.amountShould = 0;
      }
    },
    findById(id) {
      return offlineOrderApi.findById(id).then(data => {
        this.dataDetail = data;
        if (this.dataDetail.amountCurrent && this.dataDetail.amountCurrent === 0) {
          this.isActual = true;
        }
        if (this.dataDetail.amountCurrentToPrint && this.dataDetail.amountCurrentToPrint > 0) {
          this.currentAmountCollect = this.dataDetail.amountCurrentToPrint;
          this.isActual = true;
        }
        if (this.$routerUtil.query.id) {
          this.dataDetail.echoStockId = data.stockId;
          this.changeDepart(this.dataDetail.departCode);
          this.showEditBtn = (data.status !== 99 || data.payStatus === 50);
        }
        for (let i = 0; i < this.dataDetail.productList.length; i++) {
          this.updateIncludeSalePrice(i);
        }
      });
    },
    selectProduct(list) {
      // 排除库存为0的商品
      let strMsg = '';
      for (let i = 0; i < list.length; i++) {
        if (list[i].stockQty === 0 && list[i].stockType === 1) {
          strMsg += list[i].skuName + ' ,';
          list.splice(i, 1);
          i--;
        }
      }
      if (strMsg !== '') {
        this.$message.warning(strMsg.substring(0, strMsg.length - 1) + "等商品暂无库存");
      }
      if (list.length === 0) {
        return;
      }

      let arr = [...this.dataDetail.productList];
      // 有哪些商品，看是否存在，不存在就添加
      for (let i = 0; i < list.length; i++) {
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === list[i].id) {
            // 存在，更新数量
            contains = true
            break;
          }
        }
        // 不存在，添加
        if (!contains) {
          arr.push(this.createOrderDetail(list[i]));
        }
      }
      this.dataDetail.productList = arr;
      // 更新数据
      this.updateOrderData();
    },
    createOrderDetail(data) {
      return {
        skuId: data.id,
        skuName: data.skuName,
        skuCode: data.skuCode,
        barCode: data.barCode,
        skuUnitId: data.skuUnitId,
        productId: data.productId,
        qty: 1,
        skuUnitName: data.skuUnitName,
        originalExcludeRatePrice: (Number(data.skuSalePrice) / (1 + data.taxRate)).toFixed(2),
        excludeRatePrice: (Number(data.skuSalePrice) / (1 + data.taxRate)).toFixed(2),
        skuBuyPrice: data.skuBuyPrice,
        skuOriginalPrice: data.skuSalePrice,
        stockQty: data.stockQty,
        stockType: data.stockType === 0 ? '服务商品' : '库存商品',
        skuStandard: data.skuStandard,
        skuType: data.skuType,
        productBrade: data.productBrade,
        skuUnitList: data.skuUnitList,
        taxRate: data.taxRate ? data.taxRate : 0,
        rate: 1,
        skuSalePrice: data.skuSalePrice,
        rateTotalAmount: data.skuSalePrice
      }
    },
    successResult() {
      this.showOrder = false;
      this.isPaying = false;
      this.disableToClickPay = false;
      this.preparePay = false;
    },
    //查询订单状态
    toFindPayStatus() {
      if (this.isRefreshPay) this.$message.warning("正在努力获取结果，请耐心等待！！！")
      this.isRefreshPay = true;
      offlineOrderApi.findPayStatus(this.dataDetail.id, this.dataDetail.payId).then(res => {
        this.findById(this.dataDetail.id).then(() => {
          if (this.dataDetail.payStatus >= 50) {
            this.addRoomInfo();
            this.successResult();
            this.isDraftUpdate = true;
            this.showEditBtn = true;
            this.currentAmountCollect = this.dataDetail.amountCurrentToPrint;
            // 判断是否需要进行打印
            if (this.isAutoPrint) {
              this.toPrint();
              selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio).then(() => {
                this.$routerUtil.push({path: "/report/offline-order/detail"});
              });
            } else {
              selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio)
            }
            this.loading.close();
            this.$message.success(res);
            clearTimeout(this.timerToPayResult);
            return;
          }
          if (this.dataDetail.payStatus === 30) {
            this.successResult();
            this.$message.error(res);
            this.loading.close();
            clearTimeout(this.timerToPayResult);
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          this.loading.close();
          clearTimeout(this.timerToPayResult);
          return;
        } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
          // this.$message.warning("正在努力获取结果,请您耐心等待...");
        }
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus();
        }, 2000);
        this.isRefreshPay = false;
      }).catch(() => {
        this.loading.close();
        this.isRefreshPay = false;
      })
    },

    updateOrderData() {
      // 现金额，数量，原金额
      let amount = 0, qty = 0, originalAmount = 0;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (this.dataDetail.productList[i].qty === 0) continue;
        amount += Number(this.dataDetail.productList[i].skuSalePrice) * Number(this.dataDetail.productList[i].qty);
        // 避免string参与计算
        qty += this.dataDetail.productList[i].qty * 1;
        originalAmount += this.dataDetail.productList[i].qty * (this.dataDetail.productList[i].skuOriginalPrice);
      }
      this.dataDetail.qty = qty;
      this.dataDetail.amountTotal = originalAmount;
      this.dataDetail.amountDiscount = originalAmount - amount;
      // 现金额减去满减优惠
      if (this.dataDetail.amountDiscountZero) {
        amount = amount - this.dataDetail.amountDiscountZero;
      }
      this.dataDetail.amount = amount;
    },
    selectOneProduct(data) {
      // 商品列表是否有数据，没有已添加，有就数量+1
      let contains = false;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (this.dataDetail.productList[i].skuId === data.id) {
          this.dataDetail.productList[i].qty += 1;
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.productList.push(this.createOrderDetail(data));
      }
    },
    toSaveDraft() {
      this.showDraft = true;
    },
    saveDraft() {
      this.handlerProduct();
      this.isActual = false;
      this.preparePay = true;
      offlineOrderApi.saveDraft(this.dataDetail).then((data) => {
        this.findById(data.id).then(() => {
          if (this.isAutoPrint) {
            this.toPrint();
            // 当打印获取完数据后刷新当前页面
            this.$routerUtil.push({path: "/report/offline-order/detail"});
          }
        });
        this.showEditBtn = true;
        this.isDraftUpdate = true;
        this.editWord = "修改";
        this.preparePay = false;
        this.showDraft = false;
      }).catch(() => this.preparePay = false);
    },
    //结算
    // 1：定金 2：销售收费 --- 收费标签
    toBalance(isReceipt) {
      if (!this.dataDetail.productList || this.dataDetail.productList.length === 0) {
        this.$message.error("请添加商品");
        return;
      }
      if (this.dataDetail.payStatus === 99) {
        this.$message.error("该订单无法再次结算");
        return;
      }
      this.dataDetail.paymentType = isReceipt;
      this.showOrder = true;
    },
    //删除当前行数据
    deleteRow(row, index) {
      this.dataDetail.productList.splice(index, 1);
      this.updateOrderData();
    },
    handlerProduct() {
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (this.dataDetail.productList[i].qty === 0) {
          this.dataDetail.productList.splice(i, 1);
          i--;
        }
      }
    },
    //支付
    pay(data) {
      // 移除商品中为空的信息列表
      this.handlerProduct();
      // 赋值
      let myCount = 60;
      this.myTimer = setInterval(() => {
        if (myCount > 0) {
          myCount--;
          this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
        } else {
          clearInterval(this.myTimer);
          this.loading.close();
        }
      }, 1000)
      this.loading = ElLoading.service({
        lock: true,
        text: `获取支付结果中，${myCount}秒后自动更新...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.isPaying = true;
      this.isActual = true;
      this.currentAmountCollect = data.amountCollect;
      this.dataDetail.amountCollect = data.amountCollect;
      this.dataDetail.qrCode = data.qrCode;
      // 锁定操作状态
      if (this.preparePay) this.$message.error("正在支付中，请稍等！！！");
      this.preparePay = true;
      this.disableToClickPay = true;
      offlineOrderApi.preparePay(this.dataDetail).then(saleOrder => {
        this.dataDetail = saleOrder;
        // 刷新数据
        this.findById(this.dataDetail.id).then(() => {
          if (this.isAutoPrint && this.dataDetail.payStatus >= 50) {
            this.toPrint();
            this.successResult();
            this.loading.close();
            this.$message.success("支付成功");

            selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio).then(() => {
              this.$routerUtil.push({path: "/report/offline-order/detail"});
            });
            this.addRoomInfo();
          } else if (this.dataDetail.payStatus >= 50 || this.dataDetail.payStatus >= 50) {
            this.addRoomInfo();

            this.successResult();
            selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio);
            this.$message.success("支付成功");
            this.loading.close();
          } else {
            // this.$message.warning("支付中，请稍后刷新支付状态！！！")
            this.toFindPayStatus();
          }
        });
        //准备支付
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
        this.loading.close();
        this.isPaying = false;
      })
    },
    payCallback(data) {
      this.findById(data.id).then(() => {
        // 打印
        // this.toPrint();
        // 返回订单列表
        this.$routerUtil.push({path: "/report/offline-order"});
      })
      this.addRoomInfo();
    },
    addRoomInfo() {
      // 添加客房预约信息
      if (this.$routerUtil.query.subId) {
        let days = 0;
        this.dataDetail.productList.forEach(item => days += item.qty);
        houseStatusApi.addContinueLive({
          subscribeId: this.$routerUtil.query.subId,
          days: days,
          orderId: this.dataDetail.id,
        })
      }
    },

    getYGDid(id) {
      // 向附件添加数据
      let contains = false;
      for (let i = 0; i < this.dataDetail.attachmentList.length; i++) {
        if (this.dataDetail.attachmentList[i].dataType === 1) {
          this.$message.error("只能添加一张验光单，请移除原来的单据后再添加")
          return;
        }
        if (this.dataDetail.attachmentList[i].dataId === id) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.attachmentList.push({dataId: id, dataType: 1});
      }
    },
    removeYGDid(type, id) {
      for (let i = 0; i < this.dataDetail.attachmentList.length; i++) {
        if (this.dataDetail.attachmentList[i].dataId === id) {
          this.dataDetail.attachmentList = this.dataDetail.attachmentList.remove(i);
          break;
        }
      }
      if (type === 1) {
        // 移除这张验光单
        yanGuangDanApi.deleteById(id);
      } else if (type === 2) {
        houseSubscribeApi.deleteById(id);
      }
    },
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      this.dataDetail.customerPhone = data.customerPhone;
      this.dataDetail.customerAmount = data.customerAmount;
      this.dataDetail.customerAddress = data.address;
      // 备份一个customerInfo用于其它组件
      this.dataDetail.customerInfo = {...data};
    },
    // 打印
    async toPrint() {
      // 组装打印数据
      // 客户名称+手机号
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'), "收费凭证");
      localPrint.print("收费凭证", data);
    },
    // 打印设置
    async toSetPrint() {
      // 设置字段
      localPrint.print("收费凭证", this.getFieldPrint("收费凭证"));
    },
    // 提交的打印
    async toSubmitPrint() {
      // 组装打印数据
      // 客户名称+手机号
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'), "销售凭证");
      localPrint.print("销售凭证", data);
    },
    // 提交打印设置
    async toSubmitSetPrint() {
      localPrint.print("销售凭证", this.getFieldPrint("销售凭证"));
    },
    // 设置打印字段
    getFieldPrint(reportName) {
      return {
        reportName: reportName,
        amount: "合计金额",
        amountActual: "本次实收",
        amountCollect: "本次收款",
        amountShould: "本单未收",
        discountAmount: "合计优惠",
        customerName: "客户名称",
        customerPhone: "客户手机号",
        orderNumber: "订单编号",
        amountAlreadyReceived: "本单已经收款的金额",
        orderTime: "订单时间",
        employeeName: "经办职员",
        createdName: "操作人员",
        countQty: "合计数量",
        departName: "部门名称",
        stockName: "仓库名称",
        payStatus: "支付状态",
        payType: "支付方式",
        amountTotal: "优惠前金额",
        payeeName: "收费人员",
        productList: {
          productIndex: "行号",
          productName: "商品名称",
          productNumber: "商品编号",
          productPrice: "商品原价",
          productCount: "商品数量",
          productTotalPrice: "小计",
          productDisCount: "商品折扣",
          productUnitName: "商品单位",
          productSalePrice: "商品单价",
        },
      };
    },
    // 获取打印的参数
    getPrintData(type, customerName, customerPhone, reportName) {
      let data = {
        reportName: reportName,
        amount: this.dataDetail.amount,
        amountActual: this.isActual ? this.currentAmountCollect : 0,
        amountCollect: this.currentAmountCollect, // 需要打印本次支付的金额
        amountShould: this.dataDetail.amountShould,
        discountAmount: this.dataDetail.amountDiscount,
        amountAlreadyReceived: this.dataDetail.amountCollect,
        customerName: customerName,
        customerPhone: customerPhone,
        orderNumber: this.dataDetail.id,
        orderTime: this.dataDetail.orderTime,
        employeeName: this.dataDetail.employeeName,
        employeeId: this.dataDetail.employeeId,
        createdName: this.dataDetail.createdName,
        countQty: this.dataDetail.qty,
        amountTotal: this.dataDetail.amountTotal,
        status: this.dataDetail.status === 0 ? '草稿' : '待支付',
        payStatus: this.dataDetail.payStatus === 0 ? '未支付' : '已支付',
        excuteType: type,
      }
      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      //仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.dataDetail.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
        if (this.dataDetail.payeeId === this.employeeList[i].id) {
          data.payeeName = this.employeeList[i].employeeName
        }
      }
      // 支付状态
      if (this.dataDetail.payStatus === 0) {
        data.payStatus = "未支付";
      } else if (this.dataDetail.payStatus === 99) {
        data.payStatus = "已支付";
      } else if (this.dataDetail.payStatus === 30) {
        data.payStatus = "支付失败";
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.dataDetail.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }
      let arr = [];
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (this.dataDetail.productList[i].qty === 0) {
          continue;
        }
        let p = this.dataDetail.productList[i];
        arr.push({
          productIndex: i + 1,
          productName: p.skuName,
          productNumber: p.barCode,
          productPrice: p.skuOriginalPrice, //商品原价
          productCount: p.qty,
          productTotalPrice: p.qty * p.skuSalePrice,
          productDisCount: p.rate,
          productUnitName: p.skuUnitName,
          productSalePrice: p.skuSalePrice, //售价
        })
      }
      data.productList = arr;
      return data;
    },
    showYanGuangDan(id) {
      // 显示一个验光单的数据
      this.$refs.yanGuangDan.showYanGuangDanDetail(id);
    },
    // 变更数量
    changeQty(idx) {
      this.updateOrderData();
      this.updateIncludeSalePrice(idx);
    },
    // 变更折扣
    changeRate(i) {
      let data = this.dataDetail.productList[i];
      if (data.rate > 1) {
        this.$message.error("折扣不能大于1！");
        data.rate = 1;
      }
      data.skuSalePrice = (data.skuOriginalPrice * data.rate).toFixed(2);
      this.updateIncludeSalePrice(i);
      this.updateOrderData();
    },
    // 变更售价
    changeSalePrice(i) {
      // if(skuOriginalPrice)
      let data = this.dataDetail.productList[i];
      if (data.skuSalePrice > data.skuOriginalPrice) {
        this.$message.error("商品售价不能大于商品原价！");
        data.skuSalePrice = data.skuOriginalPrice;
        return false;
      }
      // 折扣
      data.rate = (data.skuSalePrice / data.skuOriginalPrice).toFixed(2);
      // 含税单价
      // this.dataDetail.productList[i].skuSalePrice = (data.excludeRatePrice * (1 + data.taxRate)).toFixed(2);
      this.updateOrderData();
      return true;
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
        }
        if (index === 4) {
          let totalQty = 0;
          for (let i = 0; i < data.length; i++) {
            totalQty += data[i].qty;
          }
          if (!totalQty) return;
          sums[index] = h('div', {style: {ontWeight: 'bolder'}}, [
            totalQty.toString(),
          ])
        }

        if (index === 12) {
          let totalAmount = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i].skuSalePrice) {
              totalAmount += Number(data[i].skuSalePrice) * Number(data[i].qty);
            }
          }
          if (!totalAmount) return;
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            "￥" + totalAmount.toFixed(2).toString(),
          ])
        }


        //   Number(sums[2].substring(1, sums.length)) +   - Number(sums[4].substring(1, sums.length))
      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog";
@import "@/components/h5/table/el-tables";

:deep(.el-descriptions__body .el-descriptions__table .el-descriptions__cell) {
  line-height: 33px !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  line-height: 23px;
  font-size: 12px;
}

.el-icon--right {
  margin-top: 5px;
  margin-left: 0 !important;
}

.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-around;
  padding-right: 10px;
}

//:deep(.el-dialog__title) {
//  font-size: 13px !important;
//}

:deep(.el-divider--horizontal) {
  margin: 10px 0 20px 0 !important;
}

.rotating-element {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.attachment-item {
  position: relative;
  color: #409eff;
  margin-right: 10px;

  .close {
    color: #F56C6C;
    margin-left: 5px;
    line-height: 31px;
  }
}

.attachment-item:last-child {
  margin-right: 0;
}

.table-all {
  height: calc(100% - 184px);
}

.table-all-suc {
  height: calc(100% - 183px);
}

.table-part {
  height: calc(100% - 97px);
}

.table-part-suc {
  height: calc(100% - 96px);
}


.top-all {
  height: 184px;
}

.top-all-suc {
  height: 183px;
}

.top-part {
  height: 97px;
}

.top-part-suc {
  height: 96px;
}


.el-input-text {
  :deep(textarea) {
    padding: 10px;
    box-sizing: border-box;
  }
}

.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-around;
  padding-right: 100px;
}


:deep(.el-descriptions) {
  .el-input {
    .el-input__wrapper {
      box-shadow: none;
      background: transparent;
    }

    .el-input__wrapper:focus-within {
      box-shadow: 0 0 0 1px var(--el-color-primary, var(--el-border-color)) inset;
    }
  }

  .el-select {
    .el-select__wrapper {
      box-shadow: none;
      background: transparent;

      .el-select__suffix {
        display: none;
      }

      .el-select__placeholder.is-transparent {
        display: none;
      }
    }

    .el-select__wrapper.is-focused {
      box-shadow: 0 0 0 1px var(--el-color-primary, var(--el-border-color)) inset;

      .el-select__suffix {
        display: flex;
      }

      .el-select__placeholder {
        display: block;
      }
    }
  }

  tr.hover-row > td.el-table__cell {
    background: var(--el-color-primary-light-9); // 鼠标hover，背景色

  }

  .el-table__cell.is-right {
    .el-input__wrapper {
      .el-input__inner {
        text-align: right;
      }
    }
  }

  // 鼠标指向的时候，input和select样式变化
  tr:hover {
    .el-input {
      .el-input__wrapper {
        box-shadow: 0 0 0 1px var(--el-color-primary, var(--el-border-color)) inset;
      }
    }

    .el-select {
      .el-select__wrapper {
        box-shadow: 0 0 0 1px var(--el-color-primary, var(--el-border-color)) inset;

        .el-select__placeholder {
          display: block;
        }

        .el-select__suffix {
          display: flex;
        }
      }
    }
  }
}

</style>