// 引入微服务的路由
import {shallowRef} from "vue";
import cloudRouter from "./alpineRouter"

import Home from "../views/main/home/Home"
import OrderList from "../views/main/sale/order/saleOrder/offflineOrder/OrderList.vue";
import PlatMerchantList from "../views/main/plat/merchantManage/merchant/PlatMerchantList";
import PlatMerchantDetail from "../views/main/plat/merchantManage/merchant/PlatMerchantDetail";
import MerchantProductList from "../views/main/product/product/ProductList";
import ProductDetail from "../views/main/product/product/ProductDetail";
import ProductDetailView from "../views/main/product/product/ProductDetailView";
import ProductGrant from "../views/main/product/grant/ProductGrant.vue";
import PlatRole from "../views/main/plat/system/role/PlatRole";
import PlatSetting from "../views/main/plat/system/setting/PlatSetting";
import PlatUser from "../views/main/plat/system/user/PlatUser";
import PlatSystemModule from "../views/main/plat/system/menu/PlatMenu.vue"
import Dictionary from "../views/main/plat/system/dictionary/Dictionary";
import PlatSystemService from "../views/main/plat/system/service/PlatSystemService";
import ProductInventory from "../views/main/stock/productInventory/ProductInventory";
import InStorageRecord from "../views/main/stock/instock/InStorageRecord";
import InStorageRecordDetail from "@/views/main/stock/instock/InStorageRecordDetail.vue";
import InStorageRecordOriginalDetail from "../views/main/stock/instock/InStorageRecordOriginalDetail.vue";
import OutStorageRecord from "../views/main/stock/outstock/OutStorageRecord";
import OutStorageRecordDetail from "../views/main/stock/outstock/OutStorageRecordDetail";
import OutStorageRecordDetailOriginal from "@/views/main/stock/outstock/OutStorageRecordDetailOriginal.vue";
import WarningList from "../views/main/stock/warning/WarningList";
import WarningRuleDetail from "../views/main/stock/warning/WarningRuleDetail";
import StockList from "../views/main/stock/stockManage/StockList";
import MemberList from "../views/main/customer/member/MemberList";
import MemberDetail from "../views/main/customer/member/MemberDetail";
import NewOrderDetail from "../views/main/sale/order/newOrder/NewOrderDetail";
import AdvanceList from "../views/main/advance/AdvanceList.vue";
import AdvanceListDetail from "../views/main/advance/AdvanceListDetail.vue";
import AdvanceRefund from "../views/main/advance/AdvanceRefund.vue";
import OfflineOrderDetail from "../views/main/sale/order/saleOrder/offflineOrder/OfflineOrderDetail.vue";
import RefundOrderList from "../views/main/sale/order/saleOrder/refundOrder/RefundOrderList.vue";
import RefundOrderDetail from "../views/main/sale/order/saleOrder/refundOrder/RefundOrderDetail.vue";
import PlatCostStatistic from "@/views/main/plat/finance/cost/PlatCostStatistic.vue";
import ReceiptView from "@/views/main/sale/order/saleOrder/ReceiptView.vue";
import PrlHospital from "@/views/main/stock/instock/PrlHospital.vue";
import PiTab from "../views/main/stock/productInventory/PiTab";

import ErpRelation from "@/views/main/plat/sync/erp/ErpRelation.vue";
import DataSync from "@/views/main/plat/sync/erp/DataSync.vue";
// import PaymentOrder from "@/views/main/advance/receipt/PaymentOrder.vue";
import InventoryCount from "@/views/main/stock/inventoryCount/InventoryCount.vue";
import InventoryCountDetail from "@/views/main/stock/inventoryCount/InventoryCountDetail.vue";
import InventoryCountOriginalDetail from "@/views/main/stock/inventoryCount/InventoryCountOriginalDetail.vue";
import DailySettle from "@/views/main/plat/finance/daily/DailySettle.vue";
import RestockType from "@/views/main/stock/restock/RestockType.vue";
import InventoryRestockDetail from "@/views/main/stock/restock/InventoryRestockDetail.vue";
import InventoryRestockOriginalDetail from "@/views/main/stock/restock/InventoryRestockOriginalDetail.vue";
import PlatOrderStatistic from "../views/main/plat/finance/order/PlatOrderStatistic";
import DetailType from "@/views/main/plat/finance/daily/DetailType.vue";
import SystemPay from "@/views/main/plat/system/setting/SystemPay.vue";
import systemInvoice from "@/views/main/plat/system/setting/SystemInvoice.vue";
import StockRecord from "@/views/main/plat/finance/stockRecord/StockRecord.vue";
import RecordDetail from "@/views/main/plat/finance/stockRecord/RecordDetail.vue";
import CgStockRecord from "@/views/main/plat/finance/stockRecord/CgStockRecord.vue";
import CgRecordDetail from "@/views/main/plat/finance/stockRecord/CgRecordDetail.vue";
// import Invoice from "@/views/main/sale/order/saleOrder/invoice/Invoice.vue";
import SupplierList from "@/views/main/stock/supplier/SupplierList.vue";

import MerchantClientList from "@/views/main/supplyChain/client/MerchantClientList.vue";
import MerchantSupplier from "@/views/main/supplyChain/supplier/MerchantSupplier.vue";
import DealPriceList from "@/views/main/supplyChain/dealPrice/DealPriceList.vue";
import dealPriceDetail from "@/views/main/supplyChain/dealPrice/DealPriceDetail.vue";
import MerchantOrderDetail from "@/views/main/supplyChain/merchantOrder/MerchantOrderDetail.vue";
import MerchantOrderDetailOriginal from "@/views/main/supplyChain/merchantOrder/MerchantOrderDetailOriginal.vue";
import MerchantPurchaseDetail from "@/views/main/supplyChain/purchase/MerchantPurchaseDetail.vue";
import MerchantPurchaseStockDetailWZ from "@/views/main/supplyChain/purchaseStock/MerchantPurchaseStockDetailWZ.vue";
import MerchantPurchase from "@/views/main/supplyChain/purchase/MerchantPurchase.vue";

import MerchantPurchaseStockDetail from "@/views/main/supplyChain/purchaseStock/MerchantPurchaseStockDetail.vue";
import MerchantPurchaseStock from "@/views/main/supplyChain/purchaseStock/MerchantPurchaseStock.vue";

import MerchantOrderList from "@/views/main/supplyChain/merchantOrder/MerchantOrderList.vue";
import SystemVersionRecordList from "@/views/main/plat/system/version/SystemVersionRecordList.vue";

import PlatOrderStatisticDetail from "@/views/main/plat/finance/order/PlatOrderStatisticDetail.vue";

import WarrantySettingList from "@/views/alpine/shop/warrantySetting/WarrantySettingList.vue";
import AlpineForumReplyList from "@/views/alpine/reply/forumReplyList.vue";
import AlpineForumReplyDetail from "@/views/alpine/reply/forumReplyDetail.vue"
import InoutTab from "@/views/main/stock/inoutType/InoutTab.vue";
import UnitTab from "@/views/main/product/unit/Unit.vue";
import ProductByDepartList from "@/views/main/product/product/ProductByDepartList.vue";
import ProductByDeparDetail from "@/views/main/product/product/ProductByDeparDetail.vue";
import MemberYgd from "@/views/main/customer/member/MemberYgd.vue";

import TemplateSetting from "@/views/main/mini/homeSetting/TemplateSetting.vue";
import StoreSetting from "@/views/main/mini/storeSetting/StoreSetting.vue";
import MiniOrderList from "@/views/main/sale/order/saleOrder/offflineOrder/MiniOrderList.vue";
import ActivityPoster from "@/views/main/mini/activity/ActivityPoster.vue";
import PosterDetail from "@/views/main/mini/activity/PosterDetail.vue";
import SystemNotice from "@/views/main/mini/SystemNotice.vue";

import PaymentOrder from "@/views/main/advance/receipt/PaymentOrder.vue";
import HouseStatus from "@/views/main/houseControl/HouseStatus.vue";
import HouseSubscribe from "@/views/main/houseControl/HouseSubscribe.vue";
import HouseDoc from "@/views/main/houseControl/HouseDoc.vue";
import HouseType from "@/views/main/houseControl/HouseType.vue";
import ChangeDocument from "@/views/main/sale/order/ChangeDocument.vue";
import ChangeDocumentDetail from "@/views/main/sale/order/ChangeDocumentDetail.vue";
import RequisiteList from "@/views/main/stock/requisite/RequisiteList.vue";
import RequisiteDetail from "@/views/main/stock/requisite/RequisiteDetail.vue";
import ReturnForm from "@/views/main/stock/return/ReturnForm.vue";
import ReturnFormDetail from "@/views/main/stock/return/ReturnFormDetail.vue";
import MtRecord from "@/views/main/mt/MtRecord.vue";
import MtRecordDetail from "@/views/main/mt/MtRecordDetail.vue";
import MtUnit from "@/views/main/mt/MtUnit.vue";
import MtLedger from "@/views/main/stock/ledger/MtLedger.vue";
import MtLedgerDetail from "@/views/main/stock/ledger/MtLedgerDetail.vue";
import StockAssemble from "@/views/main/stock/assemble/StockAssemble.vue";
import StockAssembleDetail from "@/views/main/stock/assemble/StockAssembleDetail.vue";
import MtProcess from "@/views/main/stock/process/MtProcess.vue";

import PurchaseSale from "@/views/main/plat/finance/stockRecord/PurchaseSale.vue";


import SystemApproval from "@/views/main/plat/system/approval/SystemApproval.vue";
import PurchaseQuality from "@/views/main/supplyChain/purchaseStock/PurchaseQuality.vue";
import PurchaseQualityDetail from "@/views/main/supplyChain/purchaseStock/PurchaseQualityDetail.vue";
// web端各功能的路由
let mainPageRouters = [
    {path: "/main", name: "主页", component: shallowRef(Home)},
    // 平台管理 --商户
    {path: "/plat/merchant/merchant", component: shallowRef(PlatMerchantList), meta: {component: shallowRef(PlatMerchantList), label: "商户列表"}},
    {path: "/plat/merchant/merchant/detail", component: shallowRef(PlatMerchantDetail), meta: {component: shallowRef(PlatMerchantDetail), label: "商户详情"}},
    {path: "/plat/order", component: shallowRef(PlatOrderStatistic), meta: {component: shallowRef(PlatOrderStatistic), label: "销售汇总表"}},
    {path: "/plat/saleDetail", component: shallowRef(PlatOrderStatisticDetail), meta: {component: shallowRef(PlatOrderStatisticDetail), label: "销售明细表"}},
    {path: "/plat/dailySettle", component: shallowRef(DailySettle), meta: {component: shallowRef(DailySettle), label: "日结报表"}},
    {path: "/plat/dailySettle/detail", component: shallowRef(DetailType), meta: {component: shallowRef(DetailType), label: "日结报表详情"}},
    // {path: "/plat/incoming", name: "收入统计", component: shallowRef(import  "../views/h5/plat/finance/incoming/PlatIncomingStatistic", meta: {component: shallowRef(})},
    {path: "/plat/cost", component: shallowRef(PlatCostStatistic), meta: {component: shallowRef(PlatCostStatistic), label: "账户余额表"}},
    {path: "/plat/stockRecord", component: shallowRef(StockRecord), meta: {component: shallowRef(StockRecord), label: "出入库汇总表"}},
    {path: "/plat/recordDetail", component: shallowRef(RecordDetail), meta: {component: shallowRef(RecordDetail), label: "出入库明细表"}},
    {path: "/plat/cgStockRecord", component: shallowRef(CgStockRecord), meta: {component: shallowRef(CgStockRecord), label: "采购汇总表"}},
    {path: "/plat/cgStockRecord/detail", component: shallowRef(CgRecordDetail), meta: {component: shallowRef(CgRecordDetail), label: "采购明细表"}},

    {path: "/plat/purchaseSale", component: shallowRef(PurchaseSale), meta: {component: shallowRef(PurchaseSale), label: "进销存汇总表"}},



    {path: "/plat/system/module", component: shallowRef(PlatSystemModule), meta: {component: shallowRef(PlatSystemModule), label: "菜单管理"}},
    // {path: "/plat/monthBill", name: "月账单", component: shallowRef(import  "../views/h5/plat/finance/monthBill/PlatMonthBill"), meta: {component: shallowRef(}},
    {path: "/product/list", component: shallowRef(MerchantProductList), meta: {component: shallowRef(MerchantProductList), label: "商品档案"}},
    {path: "/product/relateDepart", component: shallowRef(ProductByDepartList), meta: {component: shallowRef(ProductByDepartList), label: "商品授权"}},
    {path: "/product/relateDepart/detail", component: shallowRef(ProductByDeparDetail), meta: {component: shallowRef(ProductByDeparDetail), label: "商品授权详情"}},
    {path: "/product/unit", component: shallowRef(UnitTab), meta: {component: shallowRef(UnitTab), label: "计量单位"}},
    {path: "/product/list/detail", component: shallowRef(ProductDetail), meta: {component: shallowRef(ProductDetail), label: "编辑商品"}},
    {path: "/product/list/detailView", component: shallowRef(ProductDetailView), meta: {component: shallowRef(ProductDetailView), label: "授权商品详情"}},
    {path: "/plat/productGrant", component: shallowRef(ProductGrant), meta: {component: shallowRef(ProductGrant), label: "商品授权"}},
    {path: "/plat/system/role", component: shallowRef(PlatRole), meta: {component: shallowRef(PlatRole), label: "角色管理"}},
    {path: "/system/role", component: shallowRef(PlatRole), meta: {component: shallowRef(PlatRole), label: "商户角色管理"}},
    {path: "/plat/system/setting", component: shallowRef(PlatSetting), meta: {component: shallowRef(PlatSetting), label: "系统设置"}},
    {path: "/system/setting", component: shallowRef(PlatSetting), meta: {component: shallowRef(PlatSetting), label: "商户系统设置"}},
    {path: "/system/pay", component: shallowRef(SystemPay), meta: {component: shallowRef(SystemPay), label: "支付配置"}},
    {path: "/plat/system/pay", component: shallowRef(SystemPay), meta: {component: shallowRef(SystemPay), label: "支付配置"}},
    {path: "/system/invoice", component: shallowRef(systemInvoice), meta: {component: shallowRef(systemInvoice), label: "发票配置"}},
    {path: "/plat/system/user", component: shallowRef(PlatUser), meta: {component: shallowRef(PlatUser), label: "用户管理"}},
    {path: "/system/user", component: shallowRef(PlatUser), meta: {component: shallowRef(PlatUser), label: "职员与部门"}},
    {path: "/system/dictionary", component: shallowRef(Dictionary), meta: {component: shallowRef(Dictionary), label: "商户字典管理"}},
    {path: "/plat/system/service", component: shallowRef(PlatSystemService), meta: {component: shallowRef(PlatSystemService), label: "系统授权"}},

    {path: "/sync/erpRelation", component: shallowRef(ErpRelation), meta: {component: shallowRef(ErpRelation), label: "ERP配置"}},
    {path: "/sync/dataSync", component: shallowRef(DataSync), meta: {component: shallowRef(DataSync), label: "数据同步"}},
    {path: "/system/approvalConfig", component: shallowRef(SystemApproval), meta: {component: shallowRef(SystemApproval), label: "审批配置"}},

    //库存
    {path: "/stock/product", component: shallowRef(PiTab), meta: {component: shallowRef(PiTab), label: "库存管理"}},
    {path: "/stock/instock", component: shallowRef(InStorageRecord), meta: {component: shallowRef(InStorageRecord), label: "入库单列表"}},
    {path: "/stock/instock/detail-simple", component: shallowRef(InStorageRecordDetail), meta: {component: shallowRef(InStorageRecordDetail), label: "入库单详情"}},
    // prl医院
    {path: "/stock/instock/detail-simple-prl", component: shallowRef(PrlHospital), meta: {component: shallowRef(PrlHospital), label: "入库单详情"}},

    {path: "/stock/instock/detail-original", component: shallowRef(InStorageRecordOriginalDetail), meta: {component: shallowRef(InStorageRecordOriginalDetail), label: "入库单-溯源码"}},
    {path: "/stock/outstock", component: shallowRef(OutStorageRecord), meta: {component: shallowRef(OutStorageRecord), label: "出库单列表"}},
    {path: "/stock/outstock/detail-simple", component: shallowRef(OutStorageRecordDetail), meta: {component: shallowRef(OutStorageRecordDetail), label: "出库单详情"}},
    {path: "/stock/outstock/detail-original", component: shallowRef(OutStorageRecordDetailOriginal), meta: {component: shallowRef(OutStorageRecordDetailOriginal), label: "出库单详情-溯源码"}},
    {path: "/stock/inventory", component: shallowRef(InventoryCount), meta: {component: shallowRef(InventoryCount), label: "库存盘点"}},
    {path: "/stock/inventory/detail", component: shallowRef(InventoryCountDetail), meta: {component: shallowRef(InventoryCountDetail), label: "库存盘点详情"}},
    {path: "/stock/inventory/detail-original", component: shallowRef(InventoryCountOriginalDetail), meta: {component: shallowRef(InventoryCountOriginalDetail), label: "库存盘点详情"}},
    {path: "/stock/restock", component: shallowRef(RestockType), meta: {component: shallowRef(RestockType), label: "库存调拨"}},
    {path: "/stock/restock/detail", component: shallowRef(InventoryRestockDetail), meta: {component: shallowRef(InventoryRestockDetail), label: "库存调拨详情"}},
    {path: "/stock/restock/original/detail", component: shallowRef(InventoryRestockOriginalDetail), meta: {component: shallowRef(InventoryRestockOriginalDetail), label: "库存调拨详情"}},
    {path: "/stock/inoutType", component: shallowRef(InoutTab), meta: {component: shallowRef(InoutTab), label: "出入库类型"}},
    {path: "/stock/supplier", component: shallowRef(SupplierList), meta: {component: shallowRef(SupplierList), label: "供应商列表"}},

    {path: "/stock/requisite", component: shallowRef(RequisiteList), meta: {component: shallowRef(RequisiteList), label: "领用单列表"}},
    {path: "/stock/requisite/detail", component: shallowRef(RequisiteDetail), meta: {component: shallowRef(RequisiteDetail), label: "领用单详情"}},
    {path: "/stock/return", component: shallowRef(ReturnForm), meta: {component: shallowRef(ReturnForm), label: "归还单列表"}},
    {path: "/stock/return/detail", component: shallowRef(ReturnFormDetail), meta: {component: shallowRef(ReturnFormDetail), label: "归还单详情"}},

    {path: "/stock/assemble", component: shallowRef(StockAssemble), meta: {component: shallowRef(StockAssemble), label: "组装拆卸单"}},
    {path: "/stock/assemble/detail", component: shallowRef(StockAssembleDetail), meta: {component: shallowRef(StockAssembleDetail), label: "组装拆卸单详情"}},

    {path: "/stock/warning", component: shallowRef(WarningList), meta: {component: shallowRef(WarningList), label: "库存预警"}},
    {path: "/stock/warning/detail", component: shallowRef(WarningRuleDetail), meta: {component: shallowRef(WarningRuleDetail), label: "库存预警规则组"}},

    {path: "/stock/ledger", component: shallowRef(MtLedger), meta: {component: shallowRef(MtLedger), label: "物资台账"}},
    {path: "/stock/ledger/detail", component: shallowRef(MtLedgerDetail), meta: {component: shallowRef(MtLedgerDetail), label: "物资台账详情"}},

    {path: "/stock/process", component: shallowRef(MtProcess), meta: {component: shallowRef(MtProcess), label: "物资历程"}},

    //客户
    // 仓库管理
    {path: "/stock/list", component: shallowRef(StockList), meta: {component: shallowRef(StockList), label: "仓库列表"}},
    // 客户  --会员
    {path: "/customer/member", component: shallowRef(MemberList), meta: {component: shallowRef(MemberList), label: "客户列表"}},
    {path: "/customer/member/detail", component: shallowRef(MemberDetail), meta: {component: shallowRef(MemberDetail), label: "客户档案"}},
    {path: "/customer/ygd", component: shallowRef(MemberYgd), meta: {component: shallowRef(MemberYgd), label: "验光档案"}},

    // 维保管理
    {path: "/mt/control/record", component: shallowRef(MtRecord), meta: {component: shallowRef(MtRecord), label: "维保记录列表"}},
    {path: "/mt/unit", component: shallowRef(MtUnit), meta: {component: shallowRef(MtUnit), label: "维保单位"}},
    {path: "/mt/control/record/detail", component: shallowRef(MtRecordDetail), meta: {component: shallowRef(MtRecordDetail), label: "维保记录"}},


    // 收费单
    {path: "/advance/payDemand", component: shallowRef(PaymentOrder), meta: {component: shallowRef(PaymentOrder), label: "收费单列表"}},

    // 微商城
    // 首页模板配置
    {path: "/shop/info", component: shallowRef(TemplateSetting), meta: {component: shallowRef(TemplateSetting), label: "模板配置"}},
    {path: "/shop/store", component: shallowRef(StoreSetting), meta: {component: shallowRef(StoreSetting), label: "店铺管理"}},
    {path: "/shop/notice", component: shallowRef(SystemNotice), meta: {component: shallowRef(SystemNotice), label: "系统公告"}},
    {path: "/shop/activityPoster", component: shallowRef(ActivityPoster), meta: {component: shallowRef(ActivityPoster), label: "宣传海报"}},
    {path: "/shop/activityPoster/detail", component: shallowRef(PosterDetail), meta: {component: shallowRef(PosterDetail), label: "海报详情"}},

    // 销售开单
    {path: "/sale/order/detail/new", component: shallowRef(NewOrderDetail), meta: {component: shallowRef(NewOrderDetail), label: "快速收银"}},

    //预付款
    {path: "/advance/list", component: shallowRef(AdvanceList), meta: {component: shallowRef(AdvanceList), label: "押金列表"}},
    {path: "/advance/list/detail", component: shallowRef(AdvanceListDetail), meta: {component: shallowRef(AdvanceListDetail), label: "押金"}},
    {path: "/advance/list/refund", component: shallowRef(AdvanceRefund), meta: {component: shallowRef(AdvanceRefund), label: "押金退款"}},

    //收款、付款单
    {path: "/advance/collection", component: shallowRef(ReceiptView), meta: {component: shallowRef(ReceiptView), label: "单据结算列表"}},
    // {path: "/advance/refund", component: shallowRef(PaymentOrder), meta: {component: shallowRef(PaymentOrder), label: "付款单列表"}},

    // 线下订单列表
    {path: "/report/offline-order", component: shallowRef(OrderList), meta: {component: shallowRef(OrderList), label: "销售订单列表"}},
    {path: "/report/offline-order/detail", component: shallowRef(OfflineOrderDetail), meta: {component: shallowRef(OfflineOrderDetail), label: "销售订单"}},
    {path: "/change/document", component: shallowRef(ChangeDocument), meta: {component: shallowRef(ChangeDocument), label: "销售换货"}},
    {path: "/change/document/detail", component: shallowRef(ChangeDocumentDetail), meta: {component: shallowRef(ChangeDocumentDetail), label: "换货详情"}},
    // {path: "/report/offline-order/invoice", name: "发票", component: shallowRef(Invoice), meta: {component: shallowRef(Invoice)}},

    // 线上小程序订单
    {path: "/report/mini-order", component: shallowRef(MiniOrderList), meta: {component: shallowRef(MiniOrderList), label: "小程序订单列表"}},

    // 线下退货订单列表
    {path: "/report/refund-order", component: shallowRef(RefundOrderList), meta: {component: shallowRef(RefundOrderList), label: "销售退单列表"}},
    {path: "/report/refund-order/detail", component: shallowRef(RefundOrderDetail), meta: {component: shallowRef(RefundOrderDetail), label: "销售退单"}},

    // 供应链
    {path: "/supplyChain/clients", component: shallowRef(MerchantClientList), meta: {component: shallowRef(MerchantClientList), label: "下游客户"}},
    {path: "/supplyChain/suppliers", component: shallowRef(MerchantSupplier), meta: {component: shallowRef(MerchantSupplier), label: "上游供应商"}},
    {path: "/supplyChain/purchase", component: shallowRef(MerchantPurchase), meta: {component: shallowRef(MerchantPurchase), label: "采购订单"}},
    {path: "/supplyChain/purchase/detail", component: shallowRef(MerchantPurchaseDetail), meta: {component: shallowRef(MerchantPurchaseDetail), label: "采购订单明细"}},
    {path: "/supplyChain/sale", component: shallowRef(MerchantOrderList), meta: {component: shallowRef(MerchantOrderList), label: "销售订单"}},
    {path: "/supplyChain/sale/detail-simple", component: shallowRef(MerchantOrderDetail), meta: {component: shallowRef(MerchantOrderDetail), label: "销售订单明细"}},
    {path: "/supplyChain/sale/detail-original", component: shallowRef(MerchantOrderDetailOriginal), meta: {component: shallowRef(MerchantOrderDetailOriginal), label: "销售订单明细"}},
    {path: "/supplyChain/dealPrice", component: shallowRef(DealPriceList), meta: {component: shallowRef(DealPriceList), label: "协议价"}},
    {path: "/supplyChain/dealPrice/detail", component: shallowRef(dealPriceDetail), meta: {component: shallowRef(dealPriceDetail), label: "协议价明细"}},

    {path: "/supplyChain/stock/purchase", component: shallowRef(MerchantPurchaseStock), meta: {component: shallowRef(MerchantPurchaseStock), label: "采购订单"}},
    {path: "/supplyChain/stock/purchase/detail", component: shallowRef(MerchantPurchaseStockDetailWZ), meta: {component: shallowRef(MerchantPurchaseStockDetailWZ), label: "采购订单明细"}},

    {path: "/supplyChain/stock/quality", component: shallowRef(PurchaseQuality), meta: {component: shallowRef(PurchaseQuality), label: "采购质检"}},
    {path: "/supplyChain/stock/quality/detail", component: shallowRef(PurchaseQualityDetail), meta: {component: shallowRef(PurchaseQualityDetail), label: "采购质检明细"}},

    {path: "/plat/system/version", component: shallowRef(SystemVersionRecordList), meta: {component: shallowRef(SystemVersionRecordList), label: "版本管理"}},
    //论坛管理
    {path: "/plat/replay/replayList",component: shallowRef(AlpineForumReplyList),meta: {component: shallowRef(AlpineForumReplyList),label:"论坛列表"}},
    {path: "/plat/replay/replayList/replayDetail",component: shallowRef(AlpineForumReplyDetail),meta: {component: shallowRef(AlpineForumReplyDetail),label:"论坛详情"}},


    //房态管理
    {path: "/house/status", component: shallowRef(HouseStatus), meta: {component: shallowRef(HouseStatus), label: "客房状态"}},
    {path: "/house/subscribe", component: shallowRef(HouseSubscribe), meta: {component: shallowRef(HouseSubscribe), label: "客房订单"}},
    {path: "/house/document", component: shallowRef(HouseDoc), meta: {component: shallowRef(HouseDoc), label: "客房档案"}},
    {path: "/house/type", component: shallowRef(HouseType), meta: {component: shallowRef(HouseType), label: "客房类型"}},
];

// web端路由，
export default [
    {path: "/menu", name: "菜单", component: () => import("../views/main/home/Menu"),
        children: mainPageRouters.concat(cloudRouter), // 微服务的路由挂载到主页里面去
    },
]




