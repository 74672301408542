<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header>
          <template #default>
          </template>
        </page-header>
        <div>
          <el-descriptions :column="3" size="small" border>
            <el-descriptions-item label="单据日期">
              <el-date-picker style="width: 100%"
                              :disabled="isDisabled"
                              v-model="headInfo.createdTime"
                              type="date"
                              placeholder="选择日期时间"
              />
            </el-descriptions-item>

            <el-descriptions-item label="入库仓库">
              <el-select v-model="headInfo.stockId" :disabled="isDisabled" placeholder="请选择仓库" size="default"
                         @change="toSelectStockChange">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="入库部门">
              <dictionary :place-holder="'请选择部门'" :disabled="isDisabled" v-model="headInfo.departCode"
                          :dictionaries="departList"
                          dict-code="departCode" dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item label="单据编号">
              <span>{{ headInfo.id }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="入库类型">
              <dictionary :disabled="isDisabled" v-model="headInfo.inoutType" :place-holder="'请选择入库类型'"
                          :dict-code="'inoutCode'" :dict-name="'name'"
                          :dictionaries="dictionaryList"/>
            </el-descriptions-item>

            <el-descriptions-item label="创建人">
              {{ headInfo.createdName }}
            </el-descriptions-item>

            <el-descriptions-item label="备注" :span="2">
              <el-input :disabled="isDisabled" v-model="headInfo.remark" type="textarea" :rows="1"
                        class="el-input-text"/>
            </el-descriptions-item>

          </el-descriptions>
          <div v-if="!isDisabled" style="display: flex; justify-content: flex-end;margin-bottom: 5px">
            <div style="width: 400px">
              <product-list-select :stock-type="true" :is-depart="true" :query-list-api="queryListApi"
                                   :selected-product-list="[]"
                                   @select="selectProduct"/>
            </div>
          </div>
        </div>


        <el-table stripe draggable="true" row-key="id" :data="headInfo.detailList" ref="finalTable">
          <el-table-column align="center" type="index" label="序号" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="150px" show-overflow-tooltip/>

          <el-table-column align="center" label="基本单位" width="120px">
            <template v-slot="{row}">
              <el-select v-model="row.unitIdPackage" :disabled="isDisabled" size="default"
                         @change="toSelectUnitChange(row)">
                <el-option v-for="item in row.skuUnitList" :key="item.id" :label="item.unitName" :value="item.unitId"/>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="skuCode" label="商品编码" min-width="150px"/>

          <el-table-column align="center" prop="skuCode" label="批次号" min-width="150px">
            <template v-slot="{row}">
              <el-input :disabled="isDisabled" v-model="row.batchNo" />
            </template>
          </el-table-column>

          <el-table-column align="center" prop="skuCode" label="生产日期" min-width="200px">
            <template v-slot="{row}">
              <el-date-picker
                  :disabled="isDisabled"
                  v-model="row.produceDate"
                  type="datetime"
                  placeholder="请选择生产日期"
                  format="YYYY/MM/DD HH:mm:ss"
                  style="width: 100%;"
                  clearable
              />
<!--              <el-datetime-picker v-model="." />-->
<!--              <el-input type="date" v-model="row.productDate" />-->
            </template>
          </el-table-column>

          <el-table-column align="center" prop="skuCode" label="供应商" min-width="150px">
            <template v-slot="{row}">
              <el-select :disabled="isDisabled" v-model="row.supplierId" size="default">
                <el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item.id"/>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column align="center" label="数量" width="200">
            <template v-slot="{row}">
              <el-input-number :disabled="isDisabled" min="0" v-model="row.qtyPackage"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="入库单价">
            <template v-slot="{row}">
              <el-input :disabled="isDisabled" v-model="row.buyPricePackage" type="number"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="入库金额">
            <template v-slot="{row}">
              <money :value="row.qtyPackage * row.buyPricePackage"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{row,$index}">
              <table-button type="danger" el-type="danger" :disabled="isDisabled" @click="deletedetailList(row,$index)">
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <page-button :disabled="isDisabled" type="im" el-type="success" @click="toShowImport">导入</page-button>
        <page-button :disabled="isDisabled" type="write" el-type="success" @click="toShow(true)">保存草稿</page-button>
        <page-button :disabled="isDisabled" type="write" el-type="primary" @click="toShow(false)">保存单据</page-button>

        <el-dropdown split-button type="primary" @click="toPrint" @command="handlerSelect" style="margin-top: 1px">
          打印
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Edit" command="setPrint">打印设置</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>


        <!--        <page-button el-type="primary" @click="toPrint"-->
        <!--                     style="margin-right: -5px">打印-->
        <!--        </page-button>-->
        <!--        <page-button @click="toSetPrint" el-type="primary">-->
        <!--          <el-icon>-->
        <!--            <el-icon-setting/>-->
        <!--          </el-icon>-->
        <!--        </page-button>-->
      </form-page>
    </list-page>
  </div>

  <el-dialog v-model="showOther" width="30%" height="310px" :title="isDraft ? '保存草稿' : '保存单据'">
    <div>
      <div style="height: 238px;overflow: auto">
        <el-form label-width="70px" style="">
          <div style="height: 198px">
            <el-form-item label="创建人">
              <el-input disabled v-model="headInfo.createdName"/>
            </el-form-item>
            <el-form-item label="金额合计">
              <el-input disabled v-model="headInfo.amount"/>
            </el-form-item>
            <el-form-item label="数量合计">
              <el-input disabled v-model="headInfo.totalQty"/>
            </el-form-item>
            <el-form-item label="备注信息">
              <el-input disabled v-model="headInfo.remark"/>
            </el-form-item>
          </div>
        </el-form>
        <div style="display: flex;justify-content: space-between">
          <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large"/>
          <div>
            <page-button el-type="info" @click="showOther = false">取消</page-button>
            <page-button el-type="primary" v-if="headInfo.isDraft" @click="saveDraft">确认</page-button>
            <page-button el-type="primary" v-else @click="saveSubmit">确认</page-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <import-component v-model="showImportDialog" title="入库单商品导入" @export-temp="exportTemp"
                    :import-by-excel="importByExcel"/>
</template>
<script>

import ListPage from '@/components/h5/layout/ListPage.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import elementElMessage from "@/util/elementElMessage";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import commonApi from "@/api/commonApi";
import Money from "@/components/h5/Money.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import DialogTable from "@/components/h5/layout/DialogTable";
import PageHeader from "@/components/h5/layout/PageHeader";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import ImportComponent from "@/components/h5/ImportComponent.vue";
import {Edit} from "@element-plus/icons-vue";

export default {
  name: "InStorageRecordDetail",
  components: {
    FormPage,
    TableButton, PageButton,
    ListPage, TableList, ProductListSelect, Money, Dictionary, DialogTable, PageHeader, ImportComponent
  },
  data() {
    return {
      showOther: false,
      isAutoPrint: true,
      isDisabled: false, //是否禁用部分组件
      searchInfo: {},
      showSaveProduct: false,
      productList: [],  //该商户可以入库的所有商品
      skuList: [], //入库商品对应的所有sku
      stockList: [], //仓库列表
      stockProduct: {}, //入库商品信息
      headInfo: {
        remark: "", //备注
        createdName: "", //创建
        createdTime: new Date(), //时间
        stockId: "",  //选择仓库
        status: null,
        depart: "",
        detailList: [], //需要入库的商品
        totalQty: "",
        amount: "",
        isDraft: true,
        inoutType: "CGRK",
      },
      queryListApi: {},
      allUnits: [],
      // 是否开启仓库挂载部门功能
      stockDepartSwitch: false,
      // 部门
      departList: [],
      // 当前选中的商品
      currentProduct: {
        skuOriginalCode: "",
      },
      showOriginalDialog: false,
      skuOriginalList: [],
      dictionaryList: [],
      showImportDialog: false,
      supplierList: [],
      originalSwitch: false,
      storageList: [],
    };
  },
  created() {
    commonApi.querySupplierList().then(list => this.supplierList = list);
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    // 获取字典列表
    commonApi.queryAllTypeList(1).then(res => this.dictionaryList = res);
    // 查询单位
    commonApi.queryUnits().then(list => this.allUnits = list);
    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
    this.queryListApi = stockInoutApi.queryProductAuthorizeList;
    //获取仓库列表
    this.queryStockList();
    if (this.$routerUtil.query.inoutStorage) {
      this.echoInoutProduct(this.$routerUtil.query.inoutStorage);
    } else {
      // 新建
      commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.headInfo.originalCodeType = setting.settingValue);
      util.getUserInfo().then(user => {
        this.headInfo.createdName = user.userName;
      })
    }

  },
  computed: {
    Edit() {
      return Edit
    },
    // 获取入库商品列表
    getTime() {
      return this.headInfo.createdTime.format("yyyy-MM-dd");
    }
  },
  methods: {
    // 商品单位改变
    toSelectUnitChange(row) {
      // 获取当前单位的成本价格
      const skuUnitList = row.skuUnitList;
      for (let i = 0; i < skuUnitList.length; i++) {
        if (skuUnitList[i].unitId === row.unitIdPackage) {
          row.buyPricePackage = skuUnitList[i].buyPrice;
          break;
        }
      }
    },
    handlerSelect(command) {
      if (command === "setPrint") {
        this.toSetPrint();
      }
    },
    toShow(targetInfo) {
      if (!this.checkData()) return;
      this.headInfo.isDraft = targetInfo;
      this.showOther = true;
      // 商品总金额
      let list = this.headInfo.detailList;
      let countQty = 0;
      let amount = 0;
      for (let i = 0; i < list.length; i++) {
        countQty += list[i].qtyPackage;
        amount += list[i].qtyPackage * list[i].buyPricePackage;
      }
      this.headInfo.totalQty = countQty;
      this.headInfo.amount = amount;
    },
    //商品信息回显
    echoInoutProduct(id) {
      //判断该页面是否有参数传入
      stockInoutApi.findByInOrderId(id).then(res => {
        this.headInfo = res;
        if (res.status !== 0) {
          this.isDisabled = true;
        }
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryProductList();
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },

    deleteBatch() {
      let rows = this.$refs.finalTable.getSelectionRows();
      for (let i = 0; i < rows.length; i++) {
        this.headInfo.detailList.splice(this.headInfo.detailList.indexOf(rows[i]), 1);
      }
    },
    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      stockApi.queryStorage(this.headInfo.stockId).then(list => this.storageList = list);
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          // 部门

          this.headInfo.departId = this.stockList[i].bankupId;
          break;
        }
      }
      for (let i = 0; i < this.departList.length; i++) {
        if (this.headInfo.departId === this.departList[i].id) {
          this.headInfo.departCode = this.departList[i].departCode;
        }
      }
    },
    deletedetailList(row, index) {
      this.headInfo.detailList.splice(index, 1);
    },
    saveDraft() {
      this.headInfo.status = 0;
      if (this.checkData()) {
        stockInoutApi.saveDraft(this.headInfo).then(res => {
          this.headInfo = res
          if (this.isAutoPrint) {
            this.toPrint()
          }
          this.showOther = false;
        });
        // this.$routerUtil.push("/stock/instock");
      }
    },
    saveSubmit() {
      this.headInfo.status = 99;
      if (this.checkData()) {
        stockInoutApi.saveSubmitNoCode(this.headInfo).then(res => {
          this.headInfo = res
          if (this.isAutoPrint) {
            this.toPrint()
          }
          this.showOther = false;
          this.echoInoutProduct(res.id);
        });
      }
    },
    checkData() {
      if (!this.headInfo.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      if (!this.headInfo.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (!this.headInfo.inoutType) {
        this.$message.error("请选择入库类型");
        return false;
      }
      if (this.headInfo.detailList.length === 0) {
        elementElMessage.toShowMessage("入库商品不能为空", "warning");
        return false;
      }
      return true;
    },
    // 商品组件选中商品后，回调显示
    selectProduct(productSkuList) {
      // 向detail中添加数据
      let arr = [...this.headInfo.detailList];
      for (let i = 0; i < productSkuList.length; i++) {
        arr.push({
          skuId: productSkuList[i].id,
          qtyPackage: 1,
          skuName: productSkuList[i].skuName,
          skuCode: productSkuList[i].skuCode,
          buyPricePackage: productSkuList[i].skuBuyPrice,
          salePrice: productSkuList[i].skuSalePrice,
          salePricePackage: productSkuList[i].skuSalePrice,
          unitIdPackage: productSkuList[i].skuUnitId,
          skuUnitList: productSkuList[i].skuUnitList,
          supplierId: productSkuList[i].supplierId,
          supplierName: productSkuList[i].supplierName,
        });
      }
      this.headInfo.detailList = arr;
    },
    toAddOriginalCode(data) {
      this.currentProduct = {...data};
      this.showOriginalDialog = true;
      this.skuOriginalList = [{}];
    },
    addTableOriginalCode() {
      // 添加一个溯源码
      this.skuOriginalList.push({});
      this.$nextTick().then(() => {
        // 让新增的input进行聚焦
        let input = document.querySelector("#skuOriginalTable tr:last-child input:last-child");
        input.focus();
      });
    },

    toPrint() {
      // 组装打印数据
      let data = this.getPrintData("print");
      GjpLoaclPrint.Print(data);
    },
    // 打印设置
    toSetPrint() {
      let data = this.getPrintData("design");
      GjpLoaclPrint.Print(data);
    },
    // 获取打印的参数
    getPrintData(type) {
      let data = {
        reportName: "入库单打印",
        createdTime: this.headInfo.createdTime, // 出入库日期
        normalId: this.headInfo.id,
        createdName: this.headInfo.createdName,
        excuteType: type,
      }
      // 仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
          break;
        }
      }
      // 部门
      for (let i = 0; i < this.departList.length; i++) {
        if (this.headInfo.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
          break;
        }
      }
      // 出入库类型
      for (let i = 0; i < this.dictionaryList.length; i++) {
        if (this.headInfo.inoutType === this.dictionaryList[i].inoutCode) {
          data.inoutTypeName = this.dictionaryList[i].name;
          break;
        }
      }
      // 商品总金额
      let list = this.headInfo.detailList;
      let countQty = 0;
      let amount = 0;
      for (let i = 0; i < list.length; i++) {
        countQty += list[i].qtyPackage;
        amount += list[i].qtyPackage * list[i].buyPricePackage;
      }
      data.countQty = countQty;
      data.amount = amount;

      // 商品列表
      let arr = [];
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        arr.push({
          productName: this.headInfo.detailList[i].skuName,
          productNumber: this.headInfo.detailList[i].skuCode,
          productCount: this.headInfo.detailList[i].qtyPackage,
          productUnitName: this.headInfo.detailList[i].skuUnitName,
          productPrice: this.headInfo.detailList[i].buyPricePackage,
          productSalePrice: this.headInfo.detailList[i].salePrice,
          productTotalPrice: this.headInfo.detailList[i].qtyPackage * this.headInfo.detailList[i].buyPricePackage,
        })
      }
      data.productList = arr;
      return data;
    },
    toShowImport() {
      this.showImportDialog = true;
    },
    // 下载模板
    exportTemp() {
      util.exportExcel("/order/stock/inStorage/exportTemp", {});
    },
    // 导入模板数据
    importByExcel(formData) {
      return new Promise(resolve => {
        stockInoutApi.readInStorageTemplate(formData).then(res => {
          // 重新赋值信息
          if (res.length === 0) {
            return;
          }
          for (let i = 0; i < res.length; i++) {
            res[i].unitIdPackage = res[i].skuUnitId;
            res[i].qtyPackage = res[i].skuQty;
            res[i].buyPricePackage = res[i].buyPrice;
          }
          // 获取所有商品id
          this.headInfo.detailList = this.headInfo.detailList.concat(res);
          resolve();
        });
      })

    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.head-sty {
  display: flex;
  justify-content: space-between;
}

.el-input-text {
  :deep(textarea) {
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>