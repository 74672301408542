<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <page-header :is-show="false" />
    <list-page>
      <search-condition @search="resetOrderSearch" :show-search="true">
        <template #default>
          <date-range v-model:start-time="searchInfoOrder.startTime" v-model:end-time="searchInfoOrder.endTime"/>
          <el-select v-if="readAccess !== '1'" v-model="searchInfoOrder.stockId" placeholder="全部仓库" clearable>
            <el-option v-for="(st, i) in stockList" :key="i" :label="st.stockName" :value="st.id"/>
          </el-select>

          <el-select v-model="searchInfoOrder.orderType" placeholder="单据类型" clearable>
            <el-option label="销售订单" :value="1"/>
            <el-option label="退货订单" :value="2"/>
            <el-option label="小程序销售单" :value="11"/>
            <el-option label="小程序退货单" :value="12"/>
          </el-select>
        </template>
        <template #moreCondition>
          <el-input ref="orderNumberInput" v-model="searchInfoOrder.orderId" placeholder="请扫描/输入单据号"
                    @change="resetOrderSearch"/>
          <el-input v-model="searchInfoOrder.skuName" placeholder="请输入商品名称" />
        </template>
        <template #option>
          <page-button el-type="primary" type="print" @click="exportOrderDetail">导出</page-button>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="orderList" height="100%" show-summary :summary-method="getSummary">
          <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
          <el-table-column label="单据日期" min-width="150px" prop="orderTime" show-overflow-tooltip fixed :align="'center'">
            <template v-slot="{row}">{{row.orderTime? row.orderTime.substring(0, 10): ""}}</template>
          </el-table-column>
          <el-table-column label="订单编号" min-width="250px" prop="id" show-overflow-tooltip fixed :align="'center'"/>
          <el-table-column label="仓库名称" min-width="150px" prop="stockName" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="商品编号" min-width="150px" prop="skuCode" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="商品名称" min-width="150px" prop="skuName" show-overflow-tooltip />
          <el-table-column label="税率" :align="'center'" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.taxRate * 100 }}%
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="150px" prop="skuStandard" show-overflow-tooltip/>
          <el-table-column label="型号" min-width="150px" prop="skuType" show-overflow-tooltip />
          <el-table-column label="条码" min-width="150px" prop="barCode" show-overflow-tooltip/>
          <el-table-column label="存货类型" min-width="150px" prop="stockTypeName" show-overflow-tooltip v-if="type === 1"/>
          <el-table-column label="客户名称" min-width="150px" prop="customerName" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="职员名称" min-width="150px" prop="employeeName" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="数量合计" min-width="100px" prop="qty" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="金额合计" min-width="100px" prop="amount" show-overflow-tooltip  :align="'center'">
            <template v-slot="{row}">
              {{ row.amount }}
<!--              {{row.orderType !== 2 ? row.amount : -row.amount}}-->
            </template>
          </el-table-column>
          <el-table-column label="成本均价" min-width="100px" prop="averagePrice" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="成本金额" min-width="100px" prop="amountCost" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="未税入库均价" min-width="120px" prop="taxExcludePrice" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="未税入库金额" min-width="120px" prop="taxExcludeAmount" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="含税毛利" min-width="100px" prop="totalQty" show-overflow-tooltip :align="'center'">
            <template v-slot="{row}">
              {{ row.grossProfit }}
            </template>
          </el-table-column>
          <el-table-column label="含税毛利率" min-width="100px" prop="totalQty" show-overflow-tooltip :align="'center'">
            <template v-slot="{row}">
<!--              {{((row.amount - row.amountCost) / row.amount * 100).toFixed(2)}} %-->
              {{ row.grossProfitRate }}
            </template>
          </el-table-column>
          <el-table-column label="未税毛利" min-width="120px" prop="taxExcludeGrossProfit" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="未税毛利率" min-width="120px" prop="taxExcludeGrossProfitRate" show-overflow-tooltip  :align="'center'"/>
          <el-table-column label="单据类型" min-width="150px" :align="'center'" fixed="right">
            <template v-slot="{row}">
            <span v-for="(t, i) in orderTypes" :key="i">
              <span v-if="t.value === row.orderType">{{t.label}}</span>
            </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" min-width="100px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="viewOrderDetail(row)">查看单据</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfoOrder.pageSize" v-model:page-number="searchInfoOrder.pageNumber" :total-count="searchInfoOrder.totalCount" @onLoad="queryOrderList" :default-load="false"/>
    </list-page>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import config from "@/config/config";
import ProductOneSelect from "@/components/h5/product/ProductOneSelect.vue";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import {h} from "vue";
export default {
  props: {
    // 1 按商品 2按仓库
    type: {default: 1},
  },
  name: "OrderDetail",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, DateRange, ProductOneSelect, PageHeader
  },
  data() {
    return {
      // 查询方式，statistic汇总，order订单
      searchInfoOrder: {},
      dataList: [],
      orderList: [],
      orderTypes: [],
      stockList: [],
      currentBottom: {},
      // 设置读的权限值
      readAccess: null,
    };
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    this.orderTypes = config.saleOrderType;
    this.searchInfoOrder.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfoOrder.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 查询仓库
    orderStatisticApi.queryStockList().then(res => {
      this.stockList = res;
    })
  },
  methods: {
    // 导出
    exportOrderDetail(){
      util.exportExcel("/order/saleOrder/statistic/exportOrderSingleDetail", this.searchInfoOrder);
    },
    // 查询订单
    queryOrderList() {
      orderStatisticApi.queryOrderDetailInfo(this.searchInfoOrder).then(res => {
        this.orderList = res.records;
        this.searchInfoOrder.totalCount = res.totalCount;
        orderStatisticApi.queryOrderDetailStatistic(this.searchInfoOrder).then(res=>{
          this.currentBottom = res;
        })
      })
    },
    resetOrderSearch() {
      this.searchInfoOrder.pageNumber = 1;
      this.queryOrderList();
    },
    // 查看订单明细
    viewOrderDetail(data) {
      if (data.orderType === 1 || data.orderType === 11) {
        // 销售单
        this.$routerUtil.push(`/report/offline-order/detail?id=${data.id}`)
      } else if (data.orderType === 2 || data.orderType === 12) {
        // 退货单
        this.$routerUtil.push(`/report/refund-order/detail?id=${data.id}`)
      }
    },
    getSummary(param){
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return
        }
        if (index === 13) {
          sums[index] = h('div', [Number(this.currentBottom.qty).toFixed(2)])
        }

        if (index === 14) {
          sums[index] = h('div', [Number(this.currentBottom.amount).toFixed(2)])
        }

        // if (index === 14) {
        //   sums[index] = h('div', [Number(this.currentBottom.averagePrice).toFixed(2)])
        // }

        if (index === 16) {
          sums[index] = h('div', [Number(this.currentBottom.amountCost).toFixed(2)])
        }

        if (index === 18) {
          sums[index] = h('div', [Number(this.currentBottom.taxExcludeAmount).toFixed(2)])
        }
        if (index === 19) {
          sums[index] = h('div', [Number(this.currentBottom.grossProfit).toFixed(2)])
        }
        if (index === 21) {
          sums[index] = h('div', [Number(this.currentBottom.taxExcludeGrossProfit).toFixed(2)])
        }
      })
      return sums
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>