<template>
  <!-- -->
    <list-page v-if="type === 'inStock'">
      <search-condition @search="resetSearch">
          <el-input v-model="inSearchInfo.name" placeholder="请输入类型名称" />
        <template #option>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button el-type="success" type="write" @click="editData">新增</page-button>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="名称" prop="name" min-width="140px"/>
          <el-table-column align="center" label="编码" prop="inoutCode" min-width="140px"/>
          <el-table-column label="操作" :align="'center'" min-width="80px" fixed="right">
            <template v-slot="{row}">
              <table-button :disabled="row.businessType === 1" el-type="danger" type="delete"  message="确定删除该条数据吗？" @click="deleteData(row)">删除</table-button>
              <table-button :disabled="row.businessType === 1" el-type="primary" type="write" @click="editData(row)">编辑</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="inSearchInfo.pageSize" v-model:page-number="inSearchInfo.pageNumber"
                 :total-count="inSearchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>

    <list-page v-else-if="type === 'outStock'">
      <search-condition @search="resetSearch">
        <el-input v-model="inSearchInfo.name" placeholder="请输入类型名称" />
        <template #option>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button el-type="success" type="write" @click="editData">新增</page-button>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="名称" prop="name" min-width="140px"/>
          <el-table-column align="center" label="编码" prop="inoutCode" min-width="140px"/>
          <el-table-column label="操作" :align="'center'" min-width="80px" fixed="right">
            <template v-slot="{row}">
              <table-button :disabled="row.businessType === 1" el-type="danger" type="delete"  message="确定删除该条数据吗？" @click="deleteData(row)">删除</table-button>
              <table-button :disabled="row.businessType === 1" el-type="primary" type="write" @click="editData(row)">编辑</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="outSearchInfo.pageSize" v-model:page-number="outSearchInfo.pageNumber"
                 :total-count="outSearchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>

  <el-dialog @open="detail = {}" :title="type === 'inStock' ? '入库类型' : '出库类型'" width="40%" v-model="showInoutType">
      <el-form label-width="50px">
        <el-form-item label="名称">
          <el-input v-model="detail.name" placeholder="请输入类型名称" />
        </el-form-item>
        <el-form-item label="编码">
          <el-input :disabled="true" v-model="detail.inoutCode" />
        </el-form-item>
      </el-form>
    <div style="display: flex; justify-content: flex-end">
      <page-button el-type="info" @click="showInoutType = false">取消</page-button>
      <page-button el-type="primary" type="write" @click="saveData">确认</page-button>
    </div>
  </el-dialog>

</template>

<script>

import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import tableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import StockInoutTypeApi from "@/api/main/stock/StockInoutTypeApi";

export default {
  props:{
    type: {default : "inStock"},
  },
  name: "InoutTab",
  components: {
    FormPage,
    PageButton,
    TableButton,
    SearchCondition, tableList, ListPage
  },
  watch:{
    type:{
      handler(){
        this.queryList();
      }
    }
  },
  data() {
    return {
      // 入库查询
      inSearchInfo: {
        type: 1,  // 入库
      },
      // 出库查询
      outSearchInfo: {
        type: 2, // 出库
      },
      // 数据列表
      dataList: [],
      // dialog展示
      showInoutType: false,
      // 详情
      detail: {},
    };
  },
  created() {
  },
  methods: {
    // 重置查询条件
    resetSearch(){
      this.type === "inStock" ? this.inSearchInfo.pageNumber = 1 : this.outSearchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询
    queryList(){
      let searchInfo = {};
      this.type === "inStock" ? searchInfo = this.inSearchInfo : searchInfo = this.outSearchInfo;
      StockInoutTypeApi.queryList(searchInfo).then(res=>{
        this.type === "inStock" ? this.inSearchInfo.totalCount = res.totalCount : this.outSearchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 编辑或保存
    editData(row){
      if(row){
        this.findById(row)
      }
      this.showInoutType = true;
    },
    // 删除
    deleteData(row){
      StockInoutTypeApi.deleteData(row.id).then(()=> this.queryList());
    },
    // 数据回显
    findById(row){
      StockInoutTypeApi.findById(row.id).then(res => {
        this.detail = res;
      })
    },
    // 提交保存
    saveData(){
      this.type === "inStock" ? this.detail.type = 1 : this.detail.type = 2;
      StockInoutTypeApi.saveData(this.detail).then(()=>{
        this.showInoutType = false;
        this.queryList();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>