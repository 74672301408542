<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <el-input v-model="searchInfo.searchKey" @keydown.enter="resetSearch" @change="resetSearch" placeholder="请输入订单编号" clearable />
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" size="small" height="100%">
          <el-table-column align="center" label="序号" fixed type="index" width="60"/>
          <el-table-column align="center" label="客户名称" prop="customerName" min-width="150px"/>
          <el-table-column align="center" label="退货订单Id" prop="originalId" min-width="150px">
            <template v-slot="{row}">
              <table-button class="add" el-type="primary" type="write" @click="$routerUtil.push(`/report/refund-order/detail?id=${row.originalId}&isFinish=2`)">{{row.originalId}}</table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="新订单Id" prop="newId" min-width="150px">
            <template v-slot="{row}">
              <table-button class="add" el-type="primary" type="write"
                            @click="$routerUtil.push(`/report/offline-order/detail?id=${row.newId}`)">{{ row.id }}
              </table-button>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" :align="'center'" width="100px">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="$routerUtil.push(`/change/document/detail?draftId=${row.id}&status=1`)">查看</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" />
    </list-page>
  </div>
</template>

<script>

import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import changeDocumentApi from "@/api/main/order/changeDocumentApi";
import ListPage from "@/components/h5/layout/ListPage.vue";
export default {
  name: "",
  components: {
    TableList, FormPage, TableButton,
    PageButton, PageHeader, SearchCondition, ListPage
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
    };
  },
  created() {
  },
  methods: {
    queryList(){
      changeDocumentApi.queryList(this.searchInfo).then(list => {
        this.dataList = list.records;
        this.searchInfo.totalCount = list.totalCount
      })
    },
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    toDelete(){},
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.add:hover{
  text-decoration: underline;
}
</style>