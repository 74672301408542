<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header/>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="下游客户" label-class-name="require">
            <client-select :disabled="dataDetail.status > 0" v-model="dataDetail.clientId" :merchant-name="dataDetail.clientName"/>
          </el-descriptions-item>
          <el-descriptions-item label="仓库名称" label-class-name="require">
            <el-select :disabled="dataDetail.status >= 20" v-model="dataDetail.stockId">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="部门" label-class-name="require">
            <dictionary v-model="dataDetail.departCode" :disabled="dataDetail.status >= 20"
                        @change-data="changeDepart" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>
          <el-descriptions-item label="业务人员">{{dataDetail.employeeName}}</el-descriptions-item>
          <el-descriptions-item label="订单时间">
            <el-date-picker v-model="dataDetail.orderTime" type="datetime" style="width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="订单状态">{{dataDetail.statusName}}</el-descriptions-item>
          <el-descriptions-item label="备注信息" :span="2">
            <el-input v-model="dataDetail.remark" type="textarea"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="display: flex;justify-content: flex-end; padding: 8px 0">
          <div style="width: 400px;display: inline-block">
            <product-list-select :query-list-api="queryProductApi" :before-query="beforeQueryProduct" :query-params="{clientId: dataDetail.clientId}" :selected-product-list="dataDetail.detail" @selectOne="addProductOne" @select="addProductList"/>
          </div>
        </div>
        <div style="height: calc(100% - 320px)">
          <el-table stripe :data="dataDetail.detail" height="100%">
            <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
            <el-table-column label="商品名称" prop="skuName" min-width="150px" show-overflow-tooltip/>
            <el-table-column label="商品编码" prop="skuCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="条码" prop="skuBarCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="数量" prop="qty" width="100px">
              <template v-slot="{row}">
                <el-input v-model="row.qty" type="number" :disabled="dataDetail.status > 20"/>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="unitName" width="100px" :align="'center'"/>
            <el-table-column label="采购价格" prop="salePrice" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice"/>
              </template>
            </el-table-column>
            <el-table-column label="订单金额" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice * row.qty"/>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60px" :align="'center'" fixed="right">
              <template v-slot="{row}">
                <table-button type="delete" el-type="danger">删除</table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <page-button :disabled="dataDetail.status !== 0" el-type="success" type="write" @click="saveDraft">保存草稿</page-button>
        <page-button :disabled="dataDetail.status >= 20" el-type="primary" type="write" @click="saveSubmit">提交单据</page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import Money from "@/components/h5/Money.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";

import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, ProductListSelect, ClientSelect, Dictionary, Money, PageHeader
  },
  data() {
    return {
      dataDetail: {
        detail: [],
        employeeName: "",
        status: 0,
        stockId: "",
        departCode: "",
      },
      departList: [],
      stockList: [],
      queryProductApi: {},
    };
  },
  created() {
    // 查询商品时，查询供应商的商品
    this.queryProductApi = merchantOrderApi.queryProductAuthorizeList;
    // 查询部门
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询仓库
    this.queryAllStockList();
  },
  methods: {
    findById(id) {
      merchantOrderApi.findById(id).then(data => {
        this.dataDetail = data
      });
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.employeeName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.statusName = "草稿";
          this.dataDetail.orderTime = new Date();
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2()
        } else {
          this.findById(this.$routerUtil.query.id);
          this.queryAllStockList2()
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      commonApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 新建时默认选中仓库
        if (!this.$routerUtil.query.id && list.length > 0) {
          this.dataDetail.stockId = list[0].id;
        }
      });
    },
    // 部门变更时,重新查询仓库
    changeDepart(data) {
      this.queryAllStockList2(data.dictCode);
    },

    // 根据商品，添加一行明细
    createDetailData(product) {
      return {
        skuId: product.id,
        skuName: product.skuName,
        skuCode: product.skuCode,
        barCode: product.barCode,
        salePrice: product.skuSalePrice,
        buyPrice: product.skuBuyPrice,
        unitName: product.skuUnitName,
        unitId: product.skuUnitId,
        qty: 1,
        rate: 1,
      }
    },
    // 添加一个商品
    addProductOne(data) {
      // 如果没有，则添加
      let contains = false;
      for (let j = 0; j < this.dataDetail.detail.length; j++) {
        if (data.id === this.dataDetail.detail[j].skuId) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.detail.push(this.createDetailData(data));
      }
    },
    // 添加多个商品
    addProductList(list) {
      for (let i = 0; i < list.length; i++) {
        this.addProductOne(list[i])
      }
    },
    checkData() {
      if (!this.dataDetail.clientId) {
        this.$message.error("请选择客户")
        return false;
      }
      if (!this.dataDetail.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (!this.dataDetail.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      return true;
    },
    saveDraft() {
      if (!this.checkData()) {
        return;
      }
      merchantOrderApi.saveDraft(this.dataDetail).then(data => this.findById(data.id));
    },
    saveSubmit() {
      if (!this.checkData()) {
        return;
      }
      merchantOrderApi.saveSubmit(this.dataDetail).then(data => this.findById(data.id));
    },
    beforeQueryProduct() {
      if (!this.dataDetail.clientId) {
        this.$message.error("请先选择客户")
        return false;
      }
      if (!this.dataDetail.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>