<template>
  <el-input-tag v-model="showData"/>
</template>

<script>

import {watch} from "vue";

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: []
    },
    filedName: {default: ""}
  },
  name: "",
  components: {},
  data() {
    return {
      showData: [],
    };
  },
  created() {
    watch(() => this.modelValue, () => {
      this.init();
    },{deep: true})
  },
  methods: {
    init() {
      debugger;
      for (let i = 0; i < this.modelValue.length; i++) {
        this.showData.push(this.modelValue[i][this.filedName]);
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>