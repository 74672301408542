<template>
  <!-- -->
  <div>
    <page-button el-type="primary" v-if="showBtn && !hideBtn" @click="toSettlement(2)">结算</page-button>
    <table-button el-type="primary" v-if="!showBtn && !hideBtn" @click="toSettlement(2)">结算</table-button>
    <el-dialog @opened="openBefore" :close-on-click-modal="false" class="el-dialog-center-area" title="订单结算"
               width="700px"
               v-model="showDialog">
      <div style="padding: 5px 0;">
        <h3>结算信息</h3>
      </div>
      <div class="my-form">
        <el-form-item label="合计金额" style="width: 300px">
          <el-input disabled v-model="saleData.amount"/>
        </el-form-item>
        <el-form-item label="合计数量" style="width: 300px">
          <el-input disabled v-model="saleData.qty"/>
        </el-form-item>
      </div>
      <div class="my-form">
        <el-form-item label="已收金额" style="width: 300px">
          <el-input disabled v-model="getAmountCollect"/>
        </el-form-item>
        <el-form-item label="未收金额" style="width: 300px">
          <el-input disabled v-model="saleData.amountShould"/>
        </el-form-item>
      </div>
      <hr/>
      <div class="btm-title">
        <h3>支付方式</h3>
        <span>未支付/支付成功/支付失败</span>
      </div>
      <div class="yizhifu">
        <div style="display: inline-block">易支付</div>
        <div style="display: inline-block">
          <el-input :disabled="isSubmitPay" @input="changePay(-1)" v-model="payList[0].currentAmount" placeholder="收款金额"
                    style="width: 120px"/>
        </div>
        <div style="display: inline-block">
          <el-input ref="myInput" v-model="saleData.qrCode" placeholder="请扫描支付二维码" style="width: 340px">
            <template #suffix>
              <a href="javascript:" style="line-height: 0">
                <el-tooltip :disabled="isRefreshPay" content="点击刷新支付状态" effect="light">
                  <el-icon size="18px" @click="toRefresh" :class="isRefreshPay ? 'rotating-element' : ''">
                    <el-icon-refresh/>
                  </el-icon>
                </el-tooltip>
              </a>
            </template>
          </el-input>
        </div>
        <div style="display: inline-block">{{ getPayWord }}</div>
      </div>
      <div class="pay-detail">
        <div class="pay-left">
          <div v-for="item in payList" :key="item.id">
            <el-form-item :label="item.payName" v-if="item.id !== -1">
              <el-input @input="changePay(item.id)" :disabled="isSubmitPay" v-model="item.currentAmount" placeholder="请输入收款金额"/>
            </el-form-item>
          </div>
        </div>
        <div class="pay-right">
          <el-card style="height: calc(100% - 19px);">
            <template #header>
              <span class="span-amount">支付金额合计：</span>
            </template>
            <div class="span-sty">
              <span>￥</span>
              <span>{{ totalAmount[0] }}</span>
              <span>.</span>
              <span>{{ totalAmount[1] }}</span>
              <span>/元</span>
            </div>
          </el-card>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end;margin-top: -5px">
        <page-button el-type="success" @click="toSubmit" :disabled="isSubmitPay">提交单据</page-button>
        <page-button el-type="primary" @click="toPay">结算收款</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import {Refresh} from "@element-plus/icons-vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import selfFunction from "@/selfFunction";
import {ElLoading} from "element-plus";
import paymentApi from "@/api/main/order/paymentOrder/paymentApi";

export default {
  name: "",
  props: {
    // 是否隐藏按钮
    hideBtn: {default: false},
    // 控制按钮
    showBtn: {default: true},
    // 信息参数
    saleData: {default: {}},
    // 是否为提交后支付
    isSubmitPay: {default: false},
  },
  emits: ["payCallback", "submitCallback"],
  components: {
    Refresh, PageButton, TableButton,
  },
  computed: {
    // 已收金额
    getAmountCollect() {
      if (!this.saleData.amount || !this.saleData.amountShould) {
        return null;
      }
      return this.saleData.amount - this.saleData.amountShould;
    },
    // 支付文字状态描述
    getPayWord() {
      if (this.payWord === 0) {
        return "未支付";
      }
      if (this.payWord === -1) {
        return "正在支付";
      }
      if (this.payWord === 1) {
        return "支付成功";
      }
      if (this.payWord === 2) {
        return "支付失败";
      }
      if (this.payWord === 3) {
        return "正在提交";
      }
      if (this.payWord === 4) {
        return "提交失败";
      }
      if (this.payWord === 5) {
        return "提交成功";
      }
    },
  },
  data() {
    return {
      // 回显数据
      dataDetail: {},
      // 控制弹窗
      showDialog: false,
      // 支付集合
      payList: [{id: -1, currentAmount: null, payType: 2}], // 支付集合
      // 刷新按钮控制
      isRefreshPay: false,
      // 控制支付状态文字信息
      payWord: 0,
      // 加载遮罩层
      loading: {},
      // 倒计时计时器
      myTimer: {},
      // 支付计时器
      timerToPayResult: {},
      // 总金额
      totalAmount: [],
    };
  },
  created() {
    this.changePay();
    // 查询type为2的支付方式
    systemPayApi.findByType(1, 2).then(data => {
      let idx = this.payList.findIndex(item => item.id === -3);
      if (idx !== -1) {
        this.payList[idx].payTypeId = data.id;
      }
    })
    // 查询所有的type为1的支付方式
    systemPayApi.queryByType(0).then(list => {
      for (let i = 0; i < list.length; i++) {
        list[i].payType = list[i].payCode;
        list[i].payTypeId = list[i].id;
      }
      this.payList.push(...list);
    });
  },
  methods: {
    // 打开之前, 进行金额回显
    openBefore() {
      this.$nextTick(() => {
        if (this.saleData.id && this.saleData.id.length > 8) {
          // 查询支付记录
          paymentApi.queryByOrderId(this.saleData.id).then(list => {
            if(list.length === 0){
              this.$message.error("回显信息失败！");
              return;
            }
            for (let i = 0; i < list.length; i++) {
              // payType
              for (let j = 0; j < this.payList.length; j++) {
                if(list[i].payType === 2 && this.payList[j].payType === 2){
                  this.payList[j].currentAmount = list[i].currentAmount;
                  continue;
                }
                if(list[i].payTypeId === this.payList[j].id){
                  this.payList[j].currentAmount = list[i].currentAmount;
                }
              }
            }
            this.changePay();
            this.$refs.myInput.focus();
          })
        }
      })
    },
    // 重置金额
    updateAmount(id) {
      let list = [];
      for (let i = 0; i < this.payList.length; i++) {
        if (this.payList[i].id === id) {
          list = this.payList.filter(item => item.id !== id);
          let amount = 0;
          for (let j = 0; j < list.length; j++) {
            if (!list[j].currentAmount) {
              continue;
            }
            amount += Number(list[j].currentAmount);
          }
          this.payList[i].currentAmount = Number(this.saleData.amountShould) - amount;
          break;
        }
      }
    },
    // 金额变更
    changePay(id = -1) {
      // 防抖
      if (this.payList.length === 0) {
        this.totalAmount = "0.00".split(".");
      }
      let total = 0;
      for (let i = 0; i < this.payList.length; i++) {
        if (!this.payList[i].currentAmount || this.payList[i].currentAmount === 0) {
          continue;
        }
        total += Number(this.payList[i].currentAmount);
      }
      if (total > Number(this.saleData.amountShould)) {
        this.updateAmount(id);
        total = this.saleData.amountShould;
      }
      if (total === 0) {
        this.totalAmount = "0.00".split(".");
      } else {
        let totalStr = total.toString();
        if (totalStr.includes(".")) {
          let strArr = totalStr.split(".");
          let v = strArr[1];
          if (v.length === 1) {
            v += "0";
            strArr[1] = v;
          }
          this.totalAmount = strArr;
        } else {
          totalStr += ".00";
          this.totalAmount = totalStr.split(".");
        }
      }
    },
    // 结算
    toSettlement(isReceipt) {
      if (!this.saleData.amountShould || this.saleData.amountShould > this.saleData.amount) {
        this.saleData.amountShould = this.saleData.amount;
      } else if (!this.saleData.id && this.saleData.amount !== this.saleData.amountShould) {
        this.saleData.amountShould = this.saleData.amount;
      }
      // 弹窗
      this.showDialog = true;
      // 收费标签
      this.saleData.paymentType = isReceipt;
    },
    // 手动点击刷新
    toRefresh() {
      this.toFindPayStatus();
    },
    // 开始支付，遮罩层
    startPay() {
      // 事件
      let myCount = 60;
      // 计时器
      this.myTimer = setInterval(() => {
        if (myCount > 0) {
          myCount--;
          this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
        } else {
          clearInterval(this.myTimer);
          this.loading.close();
        }
      }, 1000);
      // 遮罩层
      this.loading = ElLoading.service({
        lock: true,
        text: `获取支付结果中，${myCount}秒后自动更新...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
    // 直接结算
    toPay() {
      // 检查参数
      if (!this.checkParams()) {
        return;
      }
      this.saleData.payList = this.payList;
      this.payWord = -1;
      // 开始支付
      this.startPay();
      // 判断是否为提交支付
      if (!this.isSubmitPay) {
        offlineOrderApi.preparePayList(this.saleData).then(data => {
          // 判断是否使用了聚合支付
          if (!this.getJuHe()) {
            // 信息提示
            this.$message.success("支付成功!");
            // 支付成功
            this.successResult();
            // 回调
            this.$emit("payCallback", data);
          } else {
            // 带聚合、判断支付结果
            this.comparePayResult(data.id);
          }
        }).catch(() => this.failResult());
      } else {
        offlineOrderApi.batchPayAfter(this.saleData.id, this.saleData.qrCode).then(() => {
          // 判断是否使用了聚合支付
          if (!this.getJuHe()) {
            this.successResult();
          } else {
            // 判断支付结果
            this.comparePayResult();
          }
        }).catch(() => this.failResult());
      }
    },
    // 判断是否有聚合
    getJuHe() {
      let idx = this.payList.findIndex(item => item.id === -1);
      let detail = this.payList[idx];
      return detail.currentAmount > 0;
    },
    // 查询支付结果
    toFindPayStatus() {
      if (this.isRefreshPay) {
        this.$message.warning("正在努力获取结果，请耐心等待！！！");
        return;
      }
      if (!this.dataDetail.id) {
        this.$message.error("当前订单状态无法刷新！");
        return;
      }
      this.isRefreshPay = true;
      offlineOrderApi.findPayStatus(this.dataDetail.id, this.dataDetail.payId).then(res => {
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          clearTimeout(this.timerToPayResult);
          this.failResult();
          return;
        }
        this.findByPaydetailId(this.dataDetail.id).then(() => {
          if (this.dataDetail.payStatus >= 50) {
            // 支付成功
            this.successResult();
            // 停止计时器
            clearTimeout(this.myTimer);
            // 支付成功回调
            this.$emit("payCallback", this.saleData);
          }
          if (this.dataDetail.payStatus === 30) {

          }
          if (this.dataDetail.payStatus === 20) {
            // 清楚支付计时器
            clearTimeout(this.timerToPayResult);
            // 重新开启查询支付状态
            this.timerToPayResult = setTimeout(() => {
              this.isRefreshPay = false;
              this.toFindPayStatus();
            }, 1000);
          }
        })
      }).catch(() => {
        // 关闭遮罩层
        this.loading.close();
        // 支付刷新改为false
        this.isRefreshPay = false;
        // 停止计时器
        clearInterval(this.myTimer);
        // 停止支付计时器
        clearTimeout(this.timerToPayResult);
      })
    },
    // 比较支付结果
    comparePayResult(id) {
      // 查询最新数据
      this.findByPaydetailId(id ? id : this.saleData.id).then(() => {
        if (this.dataDetail.payStatus >= 50) {
          // 支付成功回调
          this.$emit("payCallback", this.saleData);
          // 成功之后的处理
          this.successResult();
          // 停止计时器
          clearInterval(this.myTimer);
        } else if (this.dataDetail.payStatus === 30) {
          // 支付失败
          this.payWord = 2;
          // 关闭遮罩层
          this.loading.close();
          // 停止计时器
          clearInterval(this.myTimer);
        } else {
          this.timerToPayResult = setTimeout(() => {
            this.toFindPayStatus();
          }, 1000);
        }
      })
    },
    // 数据回显信息
    findByPaydetailId(id) {
      return offlineOrderApi.findByPaydetailId(id).then(data => {
        this.dataDetail = data;
      }).catch(() => this.failResult());
    },
    // 失败结果
    failResult() {
      // 移除计时器
      clearInterval(this.myTimer);
      // 失败
      this.payWord = 2;
      // 关闭遮罩层
      this.loading.close();
    },
    // 成功支付结果
    successResult() {
      this.payWord = 1;
      // 弹窗
      this.showDialog = false;
      // 声音
      selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio);
      // 关闭遮罩层
      this.loading.close();
      // 停止支付计时器
      clearTimeout(this.timerToPayResult);
    },
    // 提交
    toSubmit() {
      if (this.isSubmitPay) {
        this.$message.info("当前单据，无法进行提交！");
        return;
      }
      this.saleData.payList = this.payList;
      this.payWord = 3;
      // 检查参数
      if (this.checkParams()) {
        offlineOrderApi.batchPayList(this.saleData).then(data => {
          // 支付信息
          this.payWord = 5;
          // 关闭弹框
          this.showDialog = false;
          // 回调
          this.$emit("submitCallback", data);
        }).catch(() => this.payWord = 4);
      }
    },
    // 检查参数
    checkParams() {
      if (!this.saleData.qty || !this.saleData.amount) {
        this.$message.error("请选择商品信息！");
        return false;
      }
      if (this.saleData.qty === 0 || this.saleData.amount === 0) {
        this.$message.error("请选择商品信息！");
        return false;
      }
      if (!this.isSubmitPay) {
        let amount = 0;
        for (let i = 0; i < this.payList.length; i++) {
          if (this.payList[i].currentAmount) {
            amount += Number(this.payList[i].currentAmount);
          }
          if (this.payList[i].payType === 2 && this.payList[i].currentAmount && this.payList[i].currentAmount > 0) {
            if (!this.saleData.qrCode) {
              this.$message.error("请输入支付二维码！");
              return false;
            }
          }
        }
        if (amount === 0 || isNaN(amount)) {
          this.$message.error("支付金额不能为空！");
          return false;
        }
      }
      return true;
    },
  }
};
</script>

<style lang="scss" scoped>
.rotating-element {
  animation: rotate 1.5s linear infinite;
}

:deep(.el-dialog__body) {
  padding: 0 20px 16px 20px !important;
}

:deep(.el-form-item) {
  margin-bottom: 17px;
}

h3 {
  margin: 5px 0;
}

.my-form {
  display: flex;
  justify-content: space-between;
}

.my-form:nth-child(4) {
  :deep(.el-form-item) {
    margin-bottom: 8px !important;
  }
}

.btm-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -7px;
  padding: 5px 0;
  span {
    color: #F56C6C;
  }
}

.yizhifu {
  background-color: #409EFF;
  font-size: 15px;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  height: 55px;
  line-height: 55px;
  border-radius: 5px;
}

.pay-detail {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 17px;

  .pay-left {
    width: 47%;

    :deep(.el-form-item__label) {
      padding: 0 10px 0 0;
      width: 70px;
      display: inline !important;
    }
  }

  .pay-right {
    width: 49%;
    border-radius: 7px;
    box-sizing: border-box;

    .span-amount {
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 2px;
      font-family: cursive;
      color: #606266;
    }

    .span-sty {
      font-family: cursive;
      font-size: 25px;
      text-align: center;
      line-height: 70px;
      color: rgb(196, 86.4, 86.4);

      span:nth-child(2) {
        font-size: 37px;
      }
    }
  }
}

:deep(.el-overlay-dialog) {
  display: flex;
  justify-content: center;
  align-items: center;

  .el-dialog {
    margin: 0;
  }
}
</style>