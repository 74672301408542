<template>
  <!-- 使用el-table，增加配置字段显示功能，与YqTableColumn一起使用，如果没有设置页面pageName，无法正常显示,可能会有显示bug，使用showDataColumns进行刷新，可以调用formatData进行数据格式更新，用于懒加载 -->
  <div style="width: 100%;height: 100%">
    <!--    <el-table v-if="displayTable" :data="dataList" stripe border height="100%"-->
    <!--              :highlight-current-row="highlightCurrentRow" :current-row-key="currentRowKey" row-key="id" lazy :load="loadChildren">-->
    <!--      <el-table-column label="序号" type="index" width="60rem"/>-->
    <!--      <el-table-column label="商品名称" prop="productName"/>-->
    <!--      <el-table-column label="单位" prop="unitIdSmall"/>-->
    <!--    </el-table>-->
    <el-table v-if="displayTable" :data="dataList" stripe border height="100%"
              @rowDblclick="$emit('rowDblclick', $event)" @selectionChange="$emit('selectionChange', $event)"
              :highlight-current-row="highlightCurrentRow" :current-row-key="currentRowKey" row-key="id" lazy
              :load="load">
      <template #empty>
        <slot name="empty"></slot>
      </template>
      <el-table-column type="index" align="center" fixed="left" width="60rem">
        <template #header>
          <span class="icon-setting">
            <el-icon v-if="settingDisabled" class="icon-setting-disabled"><el-icon-setting/></el-icon>
            <el-icon v-else @click="toShowSetting"><el-icon-setting/></el-icon>
          </span>
        </template>
      </el-table-column>

      <component v-for="(t, d) in sortShow" :is="t" :key="d" />

      <el-table-column v-for="(t, id) in dynamicColumns" min-width="120rem" :key="id" :width="`${t.width}rem`"
                       :prop="t.props" :label="t.rename || t.label" :align="t.align" :class-name="t.className"
                       :fixed="t.fixed" show-overflow-tooltip>
        <template v-slot="{row}">
          <span v-if="t.dynamicCode === 'systemDefault'">{{ row[t.props] }}</span>
          <span v-else-if="row.dynamicDataJson"> <!-- 有值才处理 -->
            <!-- 自定义字段 特殊处理，选项 -->
        <span v-if="t.dynamicType === 51">
          {{ t.labelOptions[row.dynamicDataJson[t.props]] }}
        </span>
            <!-- 自定义字段 特殊处理，多选 -->
        <span v-else-if="t.dynamicType === 52">
          <span v-for="(idx, i) in row.dynamicDataJson[t.props]" :key="i">{{ t.labelOptions[idx] }}</span>
        </span>
            <!-- 自定义字段 特殊处理，图片 -->
        <span v-else-if="t.dynamicType === 41">
<!--          <image-show v-model="row.dynamicDataJson[t.props]" width="0.8rem"/>-->
        </span>
        <span v-else-if="t.dynamicType === 31">
          {{ new Date().format('yyyy-MM-dd') }}
        </span>
        <span v-else-if="t.dynamicType === 32">
          {{ new Date().format('yyyy-MM-dd hh:mm:ss') }}
        </span>
        <span v-else>{{ row.dynamicDataJson[t.props] }}</span>
      </span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog dialog-width="3" v-model="showSetting" title="列配置" append-to-body>
      <dialog-table>
        <separate-page v-if="hasDynamicColumn">
          <template #category>
            <el-tree :data="selfColumns" :props="{children: 'children', label: 'formName'}" @node-click="clickCategory"
                     empty-text="未添加分类" node-key="id" default-expand-all :expand-on-click-node="false"/>
          </template>
          <template #default>
            <el-table class="dialog-table" :data="settingList" stripe border>
              <el-table-column type="index" label="序号" width="60rem" align="center"/>
              <el-table-column prop="label" label="列名" show-overflow-tooltip>
                <template v-slot="{row}">{{ row.label }}</template>
              </el-table-column>
              <el-table-column label="重命名">
                <template v-slot="{row}">
                  <el-input v-model="row.rename" :placeholder="row.label" :disabled="row.required"/>
                </template>
              </el-table-column>
              <el-table-column label="宽度">
                <template v-slot="{row}">
                  <el-input v-model="row.width" placeholder="表格的宽度" :disabled="row.required"/>
                </template>
              </el-table-column>
              <!--              <el-table-column label="顺序">-->
              <!--                <template v-slot="{row}">-->
              <!--                  <el-input v-model="row.index" placeholder="表格的顺序"/>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column label="对齐">
                <template v-slot="{row}">
                  <el-select v-model="row.align" :disabled="row.required">
                    <el-option label="向左对齐" value="left"/>
                    <el-option label="向右对齐" value="right"/>
                    <el-option label="居中对齐" value="center"/>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="是否固定">
                <template v-slot="{row}">
                  <el-select v-model="row.fixed" :disabled="row.required">
                    <el-option label="不固定" :value="false"/>
                    <el-option label="左侧固定" value="left"/>
                    <el-option label="右侧固定" value="right"/>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="是否启用" align="center" width="120rem">
                <template v-slot="{row}">
                  <el-switch :disabled="row.required" v-model="row.checked" active-color="#13ce66"
                             inactive-color="#ff4949"/>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </separate-page>
        <el-table v-else class="dialog-table" height="100%" :data="settingList" stripe border id="dragTable">
          <el-table-column type="index" label="序号" width="60rem" align="center" />
          <el-table-column prop="label" label="列名" show-overflow-tooltip>
            <template v-slot="{row}">{{ row.label }}</template>
          </el-table-column>
          <el-table-column label="重命名">
            <template v-slot="{row}">
              <el-input v-model="row.rename" :placeholder="row.label" :disabled="row.required"/>
            </template>
          </el-table-column>
          <el-table-column label="宽度">
            <template v-slot="{row}">
              <el-input v-model="row.width" placeholder="表格的宽度" :disabled="row.required"/>
            </template>
          </el-table-column>
          <!--              <el-table-column label="顺序">-->
          <!--                <template v-slot="{row}">-->
          <!--                  <el-input v-model="row.index" placeholder="表格的顺序"/>-->
          <!--                </template>-->
          <!--              </el-table-column>-->
          <el-table-column label="对齐">
            <template v-slot="{row}">
              <el-select v-model="row.align" :disabled="row.required">
                <el-option label="向左对齐" value="left"/>
                <el-option label="向右对齐" value="right"/>
                <el-option label="居中对齐" value="center"/>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="是否固定">
            <template v-slot="{row}">
              <el-select v-model="row.fixed" :disabled="row.required">
                <el-option label="不固定" :value="false"/>
                <el-option label="左侧固定" value="left"/>
                <el-option label="右侧固定" value="right"/>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="是否启用" align="center" width="120rem">
            <template v-slot="{row}">
              <el-switch :disabled="row.required" v-model="row.checked" active-color="#13ce66"
                         inactive-color="#ff4949"/>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <div slot="footer" style="text-align: right">
        <page-button el-tpye="info" @click="showSetting = false">取消</page-button>
        <page-button el-type="primary" @click="changeSetting(false)">保存</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import util from "@/util/util";
import commonApi from "@/api/commonApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import {watch} from "vue";
import YqTableColumn from "@/components/h5/column/YqTableColumn.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import {cloneVNode} from "vue";
import Sortable from "sortablejs";

export default {
  computed: {
    sortShow() {
      if (!util.getSession(this.sessionKey)) {
        this.initTable();
        if (!util.getSession(this.sessionKey)) {
          util.setSession(this.sessionKey, JSON.stringify([]));
        }
      }
      // this.showSlot = false;
      let map = new Map();
      for (let i = 0; i < this.savedList.length; i++) {
        map.set(this.savedList[i].props, this.savedList[i].index);
      }
      // prop设置排序序号
      let list = this.deepClone(this.$slots.default());
      for (let i = 0; i < list.length; i++) {
        list[i].yq_index = map.get(list[i].props.prop);
      }
      return list.sort((a, b) => a.yq_index - b.yq_index);
    },
  },
  props: {
    data: {default: () => []},
    pageName: {default: ""}, // 页面名称
    dynamicColumnType: {default: ""}, // 动态字段
    // 以下是el-table属性
    highlightCurrentRow: {default: ""},
    rowKey: {default: ""},
    lazy: {default: false},
    currentRowKey: {default: ""},
    load: {default: null},
  },
  name: "",
  components: {
    // ImageShow,
    SeparatePage, DialogTable, YqTableColumn, PageButton
  },
  data() {
    return {
      dataList: [], // 列表的数据
      settingDisabled: false,
      tableColumns: [], // 从tableColumn中注册的字段
      sessionKey: "userColumnSetting", // 前端缓存的key
      savedList: [], // 保存在后端的数据
      hasDynamicColumn: false, // 是否有自定义字段
      selfColumns: [], // 自定义的字段列表
      settingList: [], // 显示在页面，进行配置操作的项
      showSetting: false,
      dynamicColumns: [], // 自定义的字段
      settingId: "",
      displayTable: true,
      currentIndex: 0, // 当前的顺序
      showSlot: true,
    };
  },
  created() {
    watch(() => this.data, () => this.showDataColumns())
    if (!this.pageName) {
      this.settingDisabled = true;
    }
  },
  mounted() {
    // 哪些字段显示，哪些字段隐藏
    // 获取配置信息
    this.initTable();

  },
  methods: {
    // 让页面table可以拖动
    initDragTable() {
      this.$nextTick(() => {
        const el = document.querySelector('#dragTable table tbody')
        new Sortable(el, {
          sort: true,
          ghostClass: 'sortable-ghost',
          onEnd: (e) => {
            if(e.oldIndex === e.newIndex){
              return;
            }
            let tableData = [...this.settingList];
            let tableData2 = [...this.savedList];
            const targetRow = tableData.splice(e.oldIndex, 1)[0];
            const targetRow2 = tableData2.splice(e.oldIndex, 1)[0];
            // 数据信息
            tableData.splice(e.newIndex, 0, targetRow);
            tableData2.splice(e.newIndex, 0, targetRow2);
            // 重新排序
            tableData.forEach((item, index) => item.index = index);
            tableData2.forEach((item, index) => item.index = index);
            // 重新渲染
            this.settingList = [];
            this.savedList = [];
            this.$nextTick().then(() => {
              this.settingList = tableData;
              this.savedList = tableData2;
              // 保存数据
              this.changeSetting(true);
            });
          },
        })
      })
    },
    deepClone(slots) {
      return slots.map(slot => cloneVNode(slot, true));
    },
    initTable() {
      this.getSettingParams().then(params => {
        if (params) {
          // 有配置信息
          this.savedList = JSON.parse(params);
        } else {
          // 没有配置信息，获取代码里面默认的字段
          this.savedList = this.getDefaultColumns();
        }
        if (this.dynamicColumnType) {
          // // 有动态字段
          // dynamicDataApi.queryDynamicColumnsSingle(this.dynamicColumnType).then(dynamicList => {
          //   if (dynamicList && dynamicList.length > 0) {
          //     this.hasDynamicColumn = true; // 有自定义字段
          //     this.selfColumns = [
          //       {formName: "系统标准字段", dynamicCode: "systemDefault", id: "-1"},
          //       ...dynamicList,
          //     ];
          //     // 有自定义字段，把自定义字段添加到savedList中去
          //     this.addSelfColumns2All(this.savedList, dynamicList);
          //     this.showDataColumns(); // 显示字段
          //   } else {
          //     // 没有自定义字段，显示数据
          //     this.showDataColumns(); // 显示字段
          //   }
          // });
        } else {
          // 没有动态字段，显示数据
          this.showDataColumns(); // 显示字段
        }
      });
    },
    // 获取配置信息
    getSettingParams() {
      return new Promise(resolve => {
        let session = util.getSession(this.sessionKey);
        if (session) {
          let paramsArray = JSON.parse(session);
          for (let i = 0; i < paramsArray.length; i++) {
            if (paramsArray[i].pageName === this.pageName) {
              this.settingId = paramsArray[i].id;
              resolve(paramsArray[i].pageParams); // 如果找到数据就返回，如果没有找到数据，就返回一个空
              return;
            }
          }
          resolve(""); // 没有找到数据
        } else {
          commonApi.queryColumnSetting().then(savedList => {
            if (savedList && savedList.length > 0) {
              util.setSession(this.sessionKey, JSON.stringify(savedList)); // 缓存数据
              for (let i = 0; i < savedList.length; i++) {
                if (savedList[i].pageName === this.pageName) {
                  this.settingId = savedList[i].id;
                  resolve(savedList[i].pageParams);
                  break;
                }
              }
            } else {
              resolve("");
            }
          });
        }
      })
    }
    ,
    // 按照代码设置的，显示默认字段
    getDefaultColumns() {
      let arr = [];
      for (let i = 0; i < this.tableColumns.length; i++) {
        arr.push({
          label: this.tableColumns[i].label,
          props: this.tableColumns[i].props,
          required: this.tableColumns[i].required,
          checked: this.tableColumns[i].required || this.tableColumns[i].defaultShow, // 是否显示, 如果是required，就显示，否则不显示
          fixed: this.tableColumns[i].fixed || false,
          width: this.tableColumns[i].width || "",
          align: this.tableColumns[i].align || "left",
          index: this.tableColumns[i].index || 0,
          displayInSetting: this.tableColumns[i].displayInSetting,
          dynamicCode: "systemDefault", // 归属到系统标准字段中
        })
      }
      return arr;
    }
    ,
    // 标准化数据，用于懒加载，由于懒加载不会走table，需要手动添加
    formatData(list) {
      let d = {};
      for (let i = 0; i < this.savedList.length; i++) {
        d[this.savedList[i].props] = {
          display: !!this.savedList[i].checked, // 是否显示,
          label: this.savedList[i].rename || this.savedList[i].label,
          fixed: this.savedList[i].fixed || false,
          width: this.savedList[i].width || "",
          align: this.savedList[i].align || "left",
          index: this.savedList[i].index ? parseInt(this.savedList[i].index) : 0,
        };
      }
      this.addDataSetting(list, d);
    },
    // 控制表格字段的显示和隐藏
    showDataColumns() {
      let d = {};
      // 自定义字段，需要append
      let dynamicColumns = [];
      for (let i = 0; i < this.savedList.length; i++) {
        d[this.savedList[i].props] = {
          display: !!this.savedList[i].checked, // 是否显示,
          label: this.savedList[i].rename || this.savedList[i].label,
          fixed: this.savedList[i].fixed || false,
          width: this.savedList[i].width || "",
          align: this.savedList[i].align || "left",
          index: this.savedList[i].index ? parseInt(this.savedList[i].index) : 0,
        };
        // d[this.savedList[i].props] = !!this.savedList[i].checked;
        if (this.savedList[i].dynamicCode !== "systemDefault" && this.savedList[i].checked) {
          dynamicColumns.push(this.savedList[i]);
        }
      }
      let arr = [...this.data];
      // 将配置信息传递给list
      this.addDataSetting(arr, d);

      this.dataList = arr;
      this.dynamicColumns = dynamicColumns;
      // 刷新页面table显示
      this.displayTable = false;
      this.$nextTick().then(() => {
        this.displayTable = true;

      });
    },
    addDataSetting(list, setting) {
      for (let i = 0; i < list.length; i++) {
        list[i].displaySetting = setting;
        if (list[i].children && list[i].children.length > 0) {
          this.addDataSetting(list[i].children, setting)
        }
      }
    },
    // 将自定义字段，添加到全部字段中去
    addSelfColumns2All(arr, selfColumns) {
      // {label: "商品状态", props: "productStatusName", checked: true}, type
      for (let i = 0; i < selfColumns.length; i++) {
        if (selfColumns[i].dynamicInfo) {
          // 有自定义的字段
          let itemArrs = JSON.parse(selfColumns[i].dynamicInfo);
          for (let j = 0; j < itemArrs.length; j++) {
            // 没有这个props才添加
            let props = `dynamicData.${itemArrs[j].userCode}`;
            let contains = false;
            for (let k = 0; k < arr.length; k++) {
              if (arr[k].props === props) {
                contains = true;
                break;
              }
            }
            if (!contains) {
              arr.push({
                label: itemArrs[j].labelName,
                props: `dynamicData.${itemArrs[j].userCode}`,
                dynamicCode: selfColumns[i].dynamicCode,
                dynamicType: itemArrs[j].labelType,
                labelOptions: itemArrs[j].labelOptions,
                required: false, // 非必需字段
              })
            }
          }
        }
        if (selfColumns[i].children && selfColumns[i].children.length > 0) {
          this.addSelfColumns2All(arr, selfColumns[i].children)
        }
      }
    }
    ,
    // 注册一个组件，名称，属性，是否必需，默认显示
    registerColumn(data) {
      let contains = false;
      for (let i = 0; i < this.tableColumns.length; i++) {
        if (this.tableColumns[i].props === data.props) {
          contains = true;
          break;
        }
      }
      if (contains) {
        return;
      }
      data.width = data.width ? data.width.replace("rem", "") : "";
      data.index = this.currentIndex++;
      // 注册后刷新数据
      this.tableColumns.push(data);
    },
    // 保存数据
    changeSetting(isShow = false) {
      let obj = {
        id: this.settingId,
        pageName: this.pageName,
        pageParams: JSON.stringify(this.savedList)
      };
      commonApi.saveColumnSetting(obj).then((data) => {
        this.settingId = data.id;
        this.showDataColumns();
        this.showSetting = isShow;
        // 保存完了数据，把数据更新到session
        let session = util.getSession(this.sessionKey);
        if (session) {
          session = JSON.parse(session);
        } else {
          session = [];
        }
        let contains = false;
        for (let i = 0; i < session.length; i++) {
          if (session[i].pageName === this.pageName) {
            session[i] = data;
            contains = true;
            break;
          }
        }
        if (!contains) {
          session.push(data);
        }
        util.setSession(this.sessionKey, JSON.stringify(session))
      });
    },
    // 选中某一个分类
    clickCategory(data) {
      this.settingList = this.findListByDynamicCode(data.dynamicCode);
    },
    // 显示配置页面
    toShowSetting() {
      // 如果没有自定义字段，就直接显示
      this.settingList = this.findListByDynamicCode("systemDefault");
      console.log(this.settingList);
      this.showSetting = true;
      // 初始化表格拖拽
      this.initDragTable();
    },
    // 根据code，获取字段，返回列表
    findListByDynamicCode(dynamicCode) {
      let arr = [];
      for (let i = 0; i < this.savedList.length; i++) {
        if (this.savedList[i].displayInSetting === false) {
          continue;
        }
        if (this.savedList[i].dynamicCode === dynamicCode) {
          arr.push(this.savedList[i])
        }
      }
      return arr;
    },
  }
}
;
</script>

<style lang="scss" scoped>

</style>