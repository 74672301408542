<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <tree-dictionary v-model:dictionary-list="treeList" dictionary-type="MERCHANT_CATEGORY" merchant-id="10000" @clickCategory="clickCategory" :code="merchantInfo.category"/>
      </template>
      <template #default>
        <list-page>
          <page-header>
            <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
            <page-button el-type="success" type="write" @click="toShowDetail({})">新建</page-button>
            <page-button el-type="primary" type="write" @click="showRepeatMerchant = true">系统商户</page-button>
          </page-header>
          <search-condition >
            <el-input v-model="searchInfo.searchKey" placeholder="请输入查询条件"/>
          </search-condition>
          <table-list>
            <el-table stripe :data="dataList" height="100%">
              <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
              <el-table-column label="客户名称" prop="merchantName" min-width="150px" show-overflow-tooltip/>
              <el-table-column label="商户编号" prop="merchantCode" min-width="100px" show-overflow-tooltip/>
              <el-table-column label="联系电话" prop="merchantPhone" min-width="100px" show-overflow-tooltip/>
              <el-table-column label="法人" prop="legalPerson" min-width="100px" show-overflow-tooltip/>
              <el-table-column label="联系地址" prop="address" min-width="150px" show-overflow-tooltip/>
              <el-table-column label="分类目录" min-width="150px" show-overflow-tooltip>
                <template v-slot="{row}">
                  <dictionary v-model="row.category" :disabled="true" :dictionaries="treeList"/>
                </template>
              </el-table-column>
              <el-table-column label="操作" :align="'center'" fixed="right" width="150px">
                <template v-slot="{row}">
                  <table-button @click="toShowDetail(row)">编辑</table-button>
                  <table-button @click="toGrant(row)">授权登录</table-button>
                  <table-button message="是否确认删除此数据" type="delete" el-type="danger" @click="deleteData(row)">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
        </list-page>
      </template>
    </separate-page>
    <el-dialog v-model="showDetail" title="下游客户" width="30%" :close-on-click-modal="false">
      <dialog-table :auto-height="true">
        <el-form label-width="70px">
          <el-form-item label="商户编号">{{currentData.merchantCode}}</el-form-item>
          <el-form-item label="客户名称">
            <el-input v-model="currentData.merchantName" placeholder="请输入客户名称"/>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="currentData.merchantPhone" placeholder="请输入联系电话"/>
          </el-form-item>
          <el-form-item label="法&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;人">
            <el-input v-model="currentData.legalPerson"/>
          </el-form-item>
          <el-form-item label="联系地址">
            <el-input v-model="currentData.address"/>
          </el-form-item>
          <el-form-item label="分类目录">
            <el-tree-select v-model="currentData.category" :data="treeList"  check-strictly :render-after-expand="false" placeholder="请选择分类目录" :props="{children: 'children', label: 'dictName'}" node-key="dictCode" default-expand-all/>
          </el-form-item>
        </el-form>
      </dialog-table>
      <form-page :show-page="false">
        <page-button @click="showDetail = false">取消</page-button>
        <page-button type="write" el-type="primary" @click="saveMerchant">保存</page-button>
      </form-page>
    </el-dialog>
    <el-dialog v-model="showRepeatMerchant" title="要添加的客户存在重复" width="60%" :close-on-click-modal="false">
      <search-condition :show-search="true" @search="resetRepeatSearch">
        <el-input v-model="nameSearchInfo.searchKey" placeholder="请输入客户名称条件"/>
      </search-condition>
      <dialog-table>
        <el-table stripe :data="repeatList" height="100%">
          <el-table-column label="序号" type="index" :align="'center'" width="60px"/>
          <el-table-column label="客户名称" prop="merchantName" min-width="150px" show-overflow-tooltip/>
          <el-table-column label="商户编号" prop="merchantCode" min-width="100px" show-overflow-tooltip/>
          <el-table-column label="联系电话" prop="merchantPhone" min-width="100px" show-overflow-tooltip/>
          <el-table-column label="法人" prop="legalPerson" min-width="100px" show-overflow-tooltip/>
          <el-table-column label="联系地址" prop="address" min-width="150px" show-overflow-tooltip/>
          <el-table-column label="操作" width="60px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="addRelation(row)">选择</table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-number="nameSearchInfo.pageNumber" v-model:page-size="nameSearchInfo.pageSize" :total-count="nameSearchInfo.totalCount" @onLoad="queryRepeatList"></form-page>
    </el-dialog>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import TreeDictionary from "@/components/h5/TreeDictionary.vue";
import Dictionary from "@/components/h5/Dictionary.vue";

import merchantClientApi from "@/api/main/supplyChain/merchantClientApi";
import util from "@/util/util";
export default {
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader, DialogTable, SeparatePage, TreeDictionary, Dictionary
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      currentData: {
        merchantName: "",
        parentId: "",
        merchantCode: "",
        legalPerson: "",
        address: "",
        merchantPhone: "",
        businessLicense: "",
      },
      showDetail: false,
      userInfo: {},
      nameSearchInfo: {},
      showRepeatMerchant: false,
      repeatList: [],
      treeList: [],
      merchantInfo: {},
    };
  },
  created() {
    util.getUserInfo().then(user => this.userInfo = user);
    merchantClientApi.merchantInfo().then(data => this.merchantInfo = data);
  },
  methods: {
    // 点击分类，按分类重新查询
    clickCategory(data) {
      this.searchInfo.category = data.dictCode;
      this.resetSearch();
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      merchantClientApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toShowDetail(data) {
      this.currentData = {
        ...data,
        merchantType: 1,
        parentId: data.parentId || this.userInfo.merchantId,
      };
      this.showDetail = true;
    },
    toGrant(data) {
      this.$routerUtil.push(`/plat/merchant/merchant/detail?id=${data.id}&type=client`);
    },
    saveMerchant() {
      if (!this.currentData.merchantName) {
        this.$message.error("请输入商户名称")
        return;
      }
      // 保存商户之前，先检查一下是否有重复的
      this.nameSearchInfo.searchKey = this.currentData.merchantName;
      this.nameSearchInfo.pageNumber = 1;
      this.nameSearchInfo.merchantId = this.currentData.id;
      merchantClientApi.queryByName(this.nameSearchInfo).then(res => {
        if (res.records.length > 0) {
          // 有重复的商户
          this.showRepeatMerchant = true;
          this.nameSearchInfo.totalCount = res.totalCount;
          this.repeatList = res.records;
          this.showDetail = false;
        } else {
          // 保存
          merchantClientApi.save(this.currentData).then(() => {
            this.showDetail = false;
            this.queryList();
          });
        }
      })
    },
    addRelation(data) {
      merchantClientApi.saveRelation({merchantId: data.id}).then(() => {
        this.showRepeatMerchant = false;
        this.queryList();
      })
    },
    resetRepeatSearch() {
      this.nameSearchInfo.pageNumber = 1;
      this.queryRepeatList();
    },
    queryRepeatList() {
      merchantClientApi.queryByName(this.nameSearchInfo).then(res => {
        this.nameSearchInfo.totalCount = res.totalCount;
        this.repeatList = res.records;
      });
    },
    deleteData(data) {
      merchantClientApi.delete(data.relationId).then(() => {
        this.queryList();
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>