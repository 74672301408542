<template>
  <!-- -->
  <span v-if="disabled">{{ dateRangeStr }}</span>
  <div class="date-range" v-else>
    <span v-if="showShortCut" class="short-cut">
      <span :class="shortCutStyle && dateType === '1'? 'on': ''" @click="dateChange(1)">今天</span>
      <span :class="shortCutStyle && dateType === '2'? 'on': ''" @click="dateChange(2)">昨天</span>
      <span :class="shortCutStyle && dateType === '3'? 'on': ''" @click="dateChange(3)">近一周</span>
      <span :class="shortCutStyle && dateType === '4'? 'on': ''" @click="dateChange(4)">近一月</span>
    </span>
    <el-date-picker :class="`date-picker date-picker-${showShortCut}`"
                    v-model="dateRange"
                    ref="datePicker"
                    type="daterange"
                    range-separator="至"
                    :start-placeholder="startPlaceholder"
                    :end-placeholder="endPlaceholder"
                    @change="changeDate"
    >
    </el-date-picker>
  </div>
</template>

<script>
import {watch} from "vue";

export default {
  emits: ["update:startTime", "update:endTime", "change"],
  props: {
    isAutoTime: {default: true},
    disabled: {default: false},
    showShortCut: {default: true},
    startTime: {default: ""},
    endTime: {default: ""},
    startPlaceholder: {default: "开始日期"},
    endPlaceholder: {default: "结束日期"},
    // 是否快捷栏显示样式
    shortCutStyle: {default: true},
    startDate: new Date(2023, 3, 1)
  },
  name: "",
  components: {},
  data() {
    return {
      dateRange: [],
      dateRangeStr: "",
      dateType: "",
    };
  },
  created() {
    this.dateRange = [this.startTime, this.endTime];
    let s = this.startTime ? new Date(this.startTime).format("yyyy-MM-dd") : "";
    let e = this.endTime ? new Date(this.endTime).format("yyyy-MM-dd") : "";
    if (s || e) {
      this.dateRangeStr = s + " 至 " + e;
    } else {
      this.dateRangeStr = "";
    }

    this.showStyle();
    // watch的三个参数source, hander, option
    watch([() => this.startTime, () => this.endTime], ([start, end], [oldStart, oldEnd]) => {
      let s = new Date(start).format("yyyy-MM-dd");
      let e = new Date(end).format("yyyy-MM-dd");
      this.dateRange = [s, e];
      this.dateRangeStr = s + " 至 " + e;
      this.showStyle();
    }, {
      // deep: true
    })
  },
  methods: {
    disabledDate(time) {

      console.log(time.getTime() < this.startDate.getTime());

      return !(time.getTime() < this.startDate.getTime());
    },
    showStyle() {
      if (!this.shortCutStyle) {
        // 不显示样式
        return;
      }
      // 选择的日期，符合哪个快捷类型
      if (this.dateRange.length === 0 || !this.dateRange[0] || !this.dateRange[1]) {
        return;
      }
      // 选中的日期是今天
      if (new Date(this.dateRange[0]).format("yyyy-MM-dd") === new Date().format('yyyy-MM-dd') &&
          new Date(this.dateRange[1]).format("yyyy-MM-dd") === new Date().format('yyyy-MM-dd')
      ) {
        this.dateType = "1";
      } else if (new Date(this.dateRange[0]).format("yyyy-MM-dd") === new Date(new Date().getTime() - 24 * 3600 * 1000).format('yyyy-MM-dd') &&
          new Date(this.dateRange[1]).format("yyyy-MM-dd") === new Date(new Date().getTime() - 24 * 3600 * 1000).format('yyyy-MM-dd')) {
        // 选中的日期是昨天
        this.dateType = "2";
      } else if (new Date(this.dateRange[0]).format("yyyy-MM-dd") === new Date(new Date().getTime() - 7 * 24 * 3600 * 1000).format('yyyy-MM-dd') &&
          new Date(this.dateRange[1]).format("yyyy-MM-dd") === new Date().format('yyyy-MM-dd')) {
        // 选中的日期是近一周
        this.dateType = "3";
      } else if (new Date(this.dateRange[0]).format("yyyy-MM-dd") === new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).format('yyyy-MM-dd') &&
          new Date(this.dateRange[1]).format("yyyy-MM-dd") === new Date().format('yyyy-MM-dd')) {
        // 选中的日期是近一月
        this.dateType = "4";
      } else {
        this.dateType = "";
      }
    },
    changeDate() {
      if (!this.dateRange && this.isAutoTime) {
        this.dateRange = [];
        this.dateRange[0] = new Date(2024, 3, 1);
        this.dateRange[1] = new Date().format("yyyy-MM-dd") + " 23:59:59";
      } else if (!this.dateRange) {
        this.dateRange = [];
        this.dateRange[0] = null;
        this.dateRange[1] = null;
      }
      if (this.dateRange[0]) {
        // 当手动输入数据时，返回的不是date，而是一个封装的对象
        let start = this.dateRange[0] instanceof Date ? this.dateRange[0].format('yyyy-MM-dd') : this.dateRange[0].$d.format('yyyy-MM-dd');
        this.$emit("update:startTime", start + " 00:00:00");
      } else {
        this.$emit("update:startTime", "");
      }
      if (this.dateRange[1]) {
        let end = this.dateRange[1] instanceof Date ? this.dateRange[1].format('yyyy-MM-dd') : this.dateRange[1].$d.format('yyyy-MM-dd');
        this.$emit("update:endTime", end + " 23:59:59");
      } else {
        this.$emit("update:endTime", "");
      }
      this.$emit("change");
      this.showStyle();
    },
    dateChange(type) {
      this.dateType = type + "";
      switch (type) {
        case 1:
          this.$emit("update:startTime", new Date().format('yyyy-MM-dd') + " 00:00:00");
          this.$emit("update:endTime", new Date().format('yyyy-MM-dd') + " 23:59:59");
          this.$emit("change");
          break;
        case 2:
          this.$emit("update:startTime", new Date(new Date().getTime() - 24 * 3600 * 1000).format('yyyy-MM-dd') + " 00:00:00");
          this.$emit("update:endTime", new Date(new Date().getTime() - 24 * 3600 * 1000).format('yyyy-MM-dd') + " 23:59:59");
          this.$emit("change");
          break;
        case 3:
          this.$emit("update:startTime", new Date(new Date().getTime() - 7 * 24 * 3600 * 1000).format('yyyy-MM-dd') + " 00:00:00");
          this.$emit("update:endTime", new Date().format('yyyy-MM-dd') + " 23:59:59");
          this.$emit("change");
          break;
        case 4:
          this.$emit("update:startTime", new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).format('yyyy-MM-dd') + " 00:00:00");
          this.$emit("update:endTime", new Date().format('yyyy-MM-dd') + " 23:59:59");
          this.$emit("change");
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.date-range {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  .short-cut {
    line-height: 30px;
    font-size: 12px;
    user-select: none;
    vertical-align: top;

    span {
      display: inline-block;
      box-sizing: border-box;
      width: 50px;
      text-align: center;
      border-top: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      border-left: 1px solid #dcdfe6;
      cursor: pointer;
      color: #409eff;
    }

    span:first-child {
      border-radius: 4px 0 0 4px;
    }

    span.on {
      background: #409eff;
      color: #FFF;
      border-color: #409eff;
    }
  }

  :deep(.date-picker) {
    vertical-align: top;
    width: 100%;
    box-sizing: border-box;
  }

  :deep(.date-picker-true) {
    width: calc(100% - 200px);
    border-radius: 0 4px 4px 0;
  }
}

</style>