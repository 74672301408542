// 通用表单自定义字段设置，page对应的是业务功能的url
export default {
    pages: {
        "customer/member": [
            {label: "就诊卡ID", props: "bankupId", checked: true},
            {label: "客户名称", props: "customerName", checked: true},
            {label: "性别", props: "gender", checked: true},
            {label: "联系电话", props: "customerPhone", checked: true},
            {label: "出生日期", props: "birthday", checked: true},
            {label: "备注", props: "remark", checked: true},
            {label: "创建人", props: "createdName", checked: true},
            {label: "创建时间", props: "createdTime", checked: true},
        ],
        "order/list": [
            {label: "单据日期", props: "orderTime", checked: true},
            {label: "客户", props: "customerName", checked: true},
            {label: "合计数量", props: "qty", checked: true},
            {label: "合计金额", props: "amountNotZero", checked: true},
            {label: "优惠金额", props: "amountDiscount", checked: true},
            {label: "本单应收", props: "amount", checked: true},
            {label: "已收金额", props: "amountCollect", checked: true},
            {label: "未收金额", props: "saleNotAmount", checked: true},
            {label: "经办人", props: "employeeName", checked: true},
            {label: "部门", props: "departName", checked: true},
            {label: "仓库", props: "saleNotAmount", checked: true},
            {label: "支付方式", props: "payName", checked: true},
            {label: "支付状态", props: "payStatusName", checked: true},
            {label: "支付时间", props: "payTime", checked: true},
            {label: "订单状态", props: "statusName", checked: true},
            {label: "订单来源", props: "orderTypeName", checked: true},
            {label: "创建人", props: "createdName", checked: true},
            {label: "收款人", props: "payeeName", checked: true},
            {label: "支付流水号", props: "outTradeNo", checked: true},
            {label: "发票号码", props: "invoiceNo", checked: true},
        ],
        "order/detail":[
            {label: "条码", props: "barCode", checked: true},
            {label: "库存数量", props: "stockQty", checked: false},
            {label: "存货类型", props: "stockType", checked: false},
            {label: "规格", props: "skuStandard", checked: false},
            {label: "型号", props: "skuType", checked: false},
            {label: "品牌", props: "productBrade", checked: false},
        ],
    }
}