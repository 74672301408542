<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="$routerUtil.push({path: `/shop/activityPoster/detail`})">新建</page-button>
      </page-header>
      <search-condition >
        <el-input v-model="searchInfo.searchKey" placeholder="请输入查询条件"/>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%">
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="名称" prop="name" :align="'center'"/>
          <el-table-column label="时间" :align="'center'">
            <template v-slot="{row}">
              {{row.startTime.split(" ")[0]}} 至 {{row.endTime.split(" ")[0]}}
            </template>
          </el-table-column>
          <el-table-column label="首页显示" align="center">
            <template v-slot="{row}">
              {{ row.showHome === 1 ? '开启' : '关闭' }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="statusTitle" align="center">
            <template v-slot="{row}">
              <el-tag v-if="row.statusTitle === '活动进行中'" type="success">{{ row.statusTitle }}</el-tag>
              <el-tag v-else-if="row.statusTitle === '未到活动时间'" type="warning">{{ row.statusTitle }}</el-tag>
              <el-tag v-else-if="row.statusTitle === '活动已结束'" type="danger">{{ row.statusTitle }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="150px">
            <template v-slot="{row}">
              <table-button @click="$routerUtil.push(`/shop/activityPoster/detail?id=${row.id}`)">编辑</table-button>
              <table-button message="是否确认删除此数据" type="delete" el-type="danger">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import miniActivityApi from "@/api/main/mini/miniActivityApi";
import TempEmpty from "@/views/main/mini/homeSetting/components/PosterInfo.vue";
export default {
  name: "",
  components: {
    TempEmpty,
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader
  },
  data() {
    return {
      // 查询条件
      searchInfo: {
        pageSize: 20,
        pageNumber: 1,
      },
      // 数据列表
      dataList: []
    };
  },
  created() {
    this.queryList();
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      miniActivityApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = this.checkTime(res.records);
      })
    },
    // 检查海报时间是否已经过期
    checkTime(list){
      if(list.length === 0){
        return [];
      }
      for (let i = 0; i < list.length; i++) {
        list[i].statusTitle = this.compareTime(list[i].startTime, list[i].endTime);
      }
      return list;
    },
    // 比较时间
    compareTime(startTime, endTime){
      // 将字符串转换为时间戳格式
      let start = new Date(startTime).getTime();
      let end = new Date(endTime).getTime();
      let current = new Date().getTime();
      if(start > current){
        return "未到活动时间";
      }else if(current > end){
        return "活动已结束";
      }else{
        return "活动进行中";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>