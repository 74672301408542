<template>
  <!-- -->
  <div class="view1">
    <div class="left">
      <div class="top border">
        <div style="margin-top: -10px">
          <span class="title">快捷操作</span>
        </div>
        <div class="short-sty">
          <div class="container">
            <div class="layout">
              <div v-for="(item,index) in shortcutList" :key="index.id" class="short-info"
                   @click="$routerUtil.push(item.routerPush)">
                <div>
                  <el-image style="width: 50px;border-radius: 7px" :src="item.url" fit="fill"/>
                </div>
                <div class="text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="border" style="height: 100%">
          <div class="content-info">
            <div>
              <span class="title">逾期归还预警</span>
              <date-range :is-auto-time="false" style="margin: 5px 0 0 10px"
                          v-model:start-time="recipientInfo.startTime"
                          v-model:end-time="recipientInfo.endTime" @change="queryReturn"/>
            </div>
            <div>

              <el-checkbox v-model="recipientInfo.isEb" @change="queryReturn">
                <el-tooltip
                    effect="light"
                    content="使用该条件，时间必须为空"
                    placement="top"
                >
                  只查询超时未归的物资
                </el-tooltip>
              </el-checkbox>
            </div>
          </div>
          <div>
            <el-table stripe :data="returnList" height="400" :align="'center'" border>
              <el-table-column :align="'center'" label="序号" width="60px" type="index"/>
              <el-table-column :align="'center'" label="商品名称" prop="skuName" show-overflow-tooltip/>
              <el-table-column :align="'center'" label="型号" show-overflow-tooltip>
                <template v-slot="{row}">
                  {{ row.skuType ? row.skuType : row.skuName ? '-' : '' }}
                </template>
              </el-table-column>
              <el-table-column :align="'center'" label="溯源码" prop="originalCode" show-overflow-tooltip>
                <template v-slot="{row}">
                  {{ row.originalCode ? row.originalCode : row.skuName ? '-' : '' }}
                </template>
              </el-table-column>
              <el-table-column :align="'center'" label="归还时间" prop="recipientDays" show-overflow-tooltip>
                <template v-slot="{row}">
                  {{ row.recipientDays ? row.recipientDays.split(" ")[0] : '' }}
                </template>
              </el-table-column>
<!--              <el-table-column :align="'center'" label="实际天数" show-overflow-tooltip>-->
<!--                <template v-slot="{row}">-->
<!--                  <span v-if="row.days < 0">已逾期<b style="color: #409EFF">{{ -row.days }}</b>天</span>-->
<!--                  <span v-if="row.days === 0">预计今日归还</span>-->
<!--                  <span v-if="row.days > 0">距归还有<b style="color: #409EFF">{{ row.days }}</b>天</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column :align="'center'" label="领用人" prop="recipientName" show-overflow-tooltip/>
              <el-table-column :align="'center'" label="领用部门" prop="recipientDepartName" show-overflow-tooltip/>
            </el-table>
          </div>
        </div>
      </div>
      <div class="bottom border">
        <div class="content-info">
          <div>
            <span class="title">维保预警</span>
            <!--            <span class="span-btn on" @click="resetWarningSearch('')">查看全部</span>-->
          </div>
          <div>
            <!--            <el-select v-model="searchInfo.stockId" placeholder="全部仓库" clearable @change="resetWarningSearch">-->
            <!--              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>-->
            <!--            </el-select>-->
          </div>
        </div>
        <div style="box-sizing: border-box;" id="stockWarning">
          <el-table stripe :data="dataList" height="400" :align="'center'" border>
            <el-table-column :align="'center'" label="序号" width="60px" type="index"/>
            <el-table-column :align="'center'" label="商品名称" prop="skuName" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="型号" show-overflow-tooltip>
              <template v-slot="{row}">
                {{ row.skuType ? row.skuType : row.skuName ? '-' : '' }}
              </template>
            </el-table-column>
            <el-table-column :align="'center'" label="规格" prop="skuStandard" show-overflow-tooltip>
              <template v-slot="{row}">
                {{ row.skuStandard ? row.skuStandard : row.skuName ? '-' : '' }}
              </template>
            </el-table-column>
            <el-table-column :align="'center'" label="溯源码" prop="originalCode" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="下次维保时间" prop="nextMtTime" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="距当前天数" prop="nextDate" show-overflow-tooltip/>
          </el-table>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top border">
        <div style="margin-top: -10px">
          <span class="title">用户信息</span>
        </div>
        <div class="user-info">
          <div class="name">登录用户</div>
          <div>{{ userInfo.userName }}</div>
        </div>
        <div class="user-info">
          <div class="name">所属部门</div>
          <div>{{ userInfo.mainDepartName }}</div>
        </div>
        <div class="user-info">
          <div class="name">所属机构</div>
          <div>{{ userInfo.merchantName }}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="border" style="height: 100%">
          <div style="margin-top: -10px">
            <span class="title">更新记录</span>
          </div>
          <div style="padding: 0 10px">
            <!-- 最多只能显示5条 -->
            <div class="document-list">
              <a target="_blank" v-for="(r, i) in updateRecordList" :key="i" :href="r.docUrl">{{ r.versionName }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import commonApi from "@/api/commonApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import Money from "@/components/h5/Money.vue";
import util from "@/util/util";
import stockApi from "@/api/main/stock/stockApi";
import mtRecordApi from "@/api/main/material/mtRecordApi";

export default {
  name: "HomeView",
  components: {DateRange, Money},
  watch: {
    'dataList': {
      handler() {
        if (this.dataList.length < 10) {
          for (let i = 0; i < 10 - this.dataList.length; i++) {
            this.dataList.push({});
          }
        }
      },
      immediate: true, // 立即执行一次
      deep: true,
    },
    'returnList': {
      handler() {
        if (this.returnList.length < 10) {
          for (let i = 0; i < 10 - this.returnList.length; i++) {
            this.returnList.push({});
          }
        }
      },
      immediate: true, // 立即执行一次
      deep: true,
    }
  },
  data() {
    return {
      stockList: [],
      dataList: [],
      returnList: [],
      searchInfo: {
        stockId: "",
        stockName: "全部",
        pageNumber: 1,
        pageSize: 10,
      },
      shortcutList: [
        // {
        //   id: 1,
        //   name: '领用单',
        //   url: require(`../../../assets/image/h5/home/收银流水.png`),
        //   routerPush: `/report/offline-order`,
        // },
        // {
        //   id: 2,
        //   name: '日结报表',
        //   url: require(`../../../assets/image/h5/home/功能图标/日结报表.png`),
        //   routerPush: `/plat/dailySettle`,
        // },
        // {
        //   id: 3,
        //   name: '客户档案',
        //   url: require(`../../../assets/image/h5/home/功能图标/客户档案.png`),
        //   routerPush: `/customer/member`,
        // },
        // {
        //   id: 4,
        //   name: '押金管理',
        //   url: require(`../../../assets/image/h5/home/功能图标/费用管理.png`),
        //   routerPush: `/advance/list`,
        // },
        {
          id: 5,
          name: '商品档案',
          url: require(`../../../assets/image/h5/home/功能图标/商品档案.png`),
          routerPush: `/product/list`,
        },
        {
          id: 6,
          name: '库存查询',
          url: require(`../../../assets/image/h5/home/功能图标/库存查询.png`),
          routerPush: `/stock/product`,
        },
      ],
      stockWarningHeight: "200px",
      // 销售数据统计的参数
      recipientInfo: {
        isEb: true,
      },
      orderStatisticInfo: {},
      orderCountType: "today",
      userInfo: {},
      updateRecordList: [],
    };
  },
  created() {

    // 查询版本信息
    commonApi.queryVersionInfo().then(list => {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type === "1") {
          arr.push(list[i]);
        }
      }
      this.updateRecordList = arr;
    })
    this.queryWarnList();
    // 销售订单统计时间
    // this.recipientInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    // this.recipientInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    this.queryReturn();
    // 获取登录用户信息
    util.getUserInfo().then(res => {
      this.userInfo = res;
    });
  },
  mounted() {
    this.stockWarningHeight = document.querySelector("#stockWarning").clientHeight + 'px';
  },
  methods: {
    resetWarningSearch(val) {
      this.searchInfo.stockId = val;
      this.queryWarnList();
    },
    queryWarnList() {
      mtRecordApi.queryWzTime().then(res => {
        this.dataList = res;
      })
    },
    queryReturn() {
      if (this.recipientInfo.isEb === true) {
        this.recipientInfo.searchType = 1;
      } else {
        this.recipientInfo.searchType = 0;
      }
      // 查询归还信息
      commonApi.queryReturnList(this.recipientInfo).then(data => this.returnList = data);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog";

@import "@/assets/css/components/table";

.view1 {
  background: none;
  border: none;
  height: calc(100vh - 100px);
  overflow: scroll;
  position: relative;

  .left {
    width: calc(100% - 305px);
    box-sizing: border-box;
    padding-right: 10px;
    height: 100%;

    .top {
      height: 200px;
      box-sizing: border-box;

      .short-sty {
        height: calc(100% - 60px);
        padding: 0 60px;
        overflow: hidden;
        position: relative;

        .container {
          position: relative;
          overflow: hidden;
          height: 100%;
          overflow-x: auto;

          .layout {
            white-space: nowrap;
            position: absolute;
            height: 0px;
            top: 50%;
            width: 100%;

            .short-info {
              //display: flex;
              //flex-direction: column;
              //align-items: center;
              //justify-content: center;
              margin-right: 50px;
              margin-top: -38px;
              text-align: center;
              display: inline-block;

              > image {
                width: 50px;
                height: 50px;

              }

              > .text {
                font-size: 12px;
                padding-top: 5px;
              }
            }

            .short-info:last-child {
              margin-right: 0;
            }
          }
        }

        //display: flex;
        //flex-direction: row;
        //padding: 25.5px 0 0 60px;
        //align-items: center;


      }


    }

    .center {
      height: 495px;
      box-sizing: border-box;
      padding: 10px 0;
    }

    .bottom {
    }
  }

  .right {
    width: 300px;
    height: 100%;
    position: fixed;
    right: 22px;
    top: 94px;

    .top {
      height: 230px;
    }

    .bottom {
      height: 230px;
      box-sizing: border-box;
      padding-top: 10px;
    }
  }

  .border {
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 #f1f1f1, 0 0 6px 0 #f1f1f1;
    background: #FFF;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
  }
}

.el-dropdown, .el-dropdown * {
  outline: none;
}

.el-tooltip__trigger {
  margin-top: 24px;
  color: blue;
  font-size: 11px !important;
  text-decoration: underline;

  .el-icon {
    font-size: 11px !important;
    color: blue;
  }
}

.content-info {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 10px;

  .title {
    line-height: 40px;
  }

  .span-btn {
    margin-left: 10px;
    font-size: 12px;
    color: #999;
    user-select: none;
    cursor: pointer;
  }

  .span-btn.on {
    color: #409EFF;
  }

  :deep(.el-select) {
    width: 240px;
  }
}

.order-count-div {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 50px);
  padding: 20px 60px;
  box-sizing: border-box;

  > div {
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #dddfe7;
    text-align: center;
    padding: 0 20px;
    width: 24%;
    border-radius: 8px;
    background: #f6f7fa;
    box-shadow: 1px 1px 3px #dddfe7;

    .blank {
      height: calc(50% - 50px);
    }

    .order-info {
      height: 100px;

      .name {
        height: 50px;
      }
    }
  }
}

.user-info {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddfe7;
  font-size: 14px;
  margin: 10px 10px;
  line-height: 30px;

  .name {
    color: #999;
  }
}

.document-list {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 40px;
  border-bottom: 1px solid #dddfe7;
  height: 36px;

  a {
    color: #409eff;
    font-size: 14px;
    text-decoration: auto;
  }
}

@media (max-height: 1200px) {

}

@media (max-height: 800px) {
  .content-info {
    :deep(.el-select) {
      width: 200px;
    }

    .date-range {
      width: 450px;
    }
  }
  .order-count-div > div {
    .blank {
      height: 20px;
    }

    .order-info {
      height: auto;

      .name {
        height: auto;
      }
    }
  }
  .user-info {
  }
  .view1 .right {
    .top {
    }

    .bottom {
    }
  }
}
</style>
