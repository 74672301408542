let config = {};
switch (process.env.NODE_ENV) {
    case 'development':
        config = {
            // 远程微服务项目的地址
            routerLocation: {
                alpine: "http://localhost:8011", // 车膜小程序
                fylwz: "http://localhost:8012" // 泰康非医疗物资
            },

            appid: "ww4a983ad1815241ec",
            suiteId: "ww50bb496d97adba01",
            // baseUrl : `https://server.xyyqgj.ltd`,
            baseUrl: `http://192.168.5.168:8002`,
            // fileUrl : `https://file.xyyqgj.ltd/`,
            fileUrl: `http://192.168.5.168:7091/`,
            // fileUrl : `https://file.yqgj.ltd/`,
            // fileUrl : `https://file.likefix.net/`,
            // fileUrl : `https://file.yqgj.ltd/`,
            systemTitle: "四川泰康医院非医收费系统", // 系统的名称
            // 管家婆云打印参数
            gjpyun: {
                url: "http://yunprint.gmgrasp.com.cn/PrintApi/",
                publicKey: "NDgyM2QwNDZhOGFjNmY3NwNNvdrg1M",
                secretKey: "YzI2ODRlMTUyY2NlYWY1MQRRzCE7v7",
                companyName: "测试账套", // 账套
                operatorName: "超级用户", // 操作员
            },
        };
        break;
    case 'production':
        config = {
            // 远程微服务项目的地址
            routerLocation: {
                alpine: "http://localhost:8011",
                fylwz: "http://localhost:8012"
            },
        };
        break;
    case 'alpine':
        config = {
            // 远程微服务项目的地址
            routerLocation: {
                alpine: "http://localhost:8011",
                fylwz: "http://localhost:8012"
            },
        };
        break;
}
config.offlinePayType = [
    {value: 2, label: '聚合支付'},
    {value: 1, label: '现金支付'},
    {value: 3, label: '转账支付'},
    {value: 4, label: '刷卡支付'},
];
config.saleOrderType = [
    {value: 1, label: '销售订单'},
    {value: 2, label: '退货订单'},
    {value: 11, label: '小程序销售单'},
    {value: 12, label: '小程序退货单'},
];
config.inventory = [
    {value: 51, label: '报溢单'},
    {value: 52, label: '报损单'},
];

// 默认使用的出库类型--字典，无法删除
config.outStock = ["BAOSUNCHUKU", "DIAOBOCHUKU", "QITACHUKU", "XIAOSHOUCHUKU"];
// 默认使用的入库类型--字典,无法删除
config.inStock = ["BAOYIRUKU", "CAIGOURUKU", "DIAOBORUKU", "XIAOSHOUTUIKU"];
// 默认使用的支付方式、无法删除允许编辑
config.defaultPay = ["20240515185359769211", "20240515191223949211"];
// 快速开单类型
config.type = [
    {value: 1, label: '商品分类'},
    {value: 2, label: '商品信息'},
    {value: 3, label: 'SKU信息'},
]
// 收费标签
config.feeTag = [
    {value: 1, label: '销售定金'},
    {value: 2, label: '销售收费'},
    {value: 3, label: '销售退费'},
    {value: 4, label: '押金'},
    {value: 5, label: '押金退费'},
    {value: 6, label: '小程序收费'},
    {value: 7, label: '小程序退费'},
    {value: 8, label: '换货补差'},
]
// 出入库类型配置转换
config.inoutType = [
    {value: 1, label: '手动入库'},
    {value: 2, label: '采购入库', text: 'CGRK'},
    {value: 3, label: '调拨入库', text: 'DBRK'},
    {value: 4, label: '报溢入库', text: 'BYRK'},
    {value: 5, label: '销售退货', text: 'XSTK'},
    {value: 6, label: '零售退货'},
    {value: 99, label: '其它入库', text: 'QTRK'},
    {value: 100, label: '商户-门店：调拨单'},
    {value: 102, label: '销售出库', text: 'XSCK'},
    {value: 103, label: '调拨出库', text: 'DBCK'},
    {value: 104, label: '报损出库'},
    {value: 105, label: '零售出库'},
    {value: 199, label: '其它出库', text: 'QTCK'},
]

export default config;