<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <!--          <page-button el-type="primary" @click="exportList">导出</page-button>-->
          <page-button type="write" el-type="success" @click="toGo">新建</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>

          <div style="display: inline-block; width: 240px;margin-right: 10px">
            <dictionary v-if="readAccess === '3'" v-model="searchInfo.departCode" @clearData="clearDic"
                        :place-holder="'请选择部门'"
                        :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>

        </template>
        <template #moreCondition>
          <el-select v-model="stockId" placeholder="选择仓库" size="default" clearable>
            <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
          </el-select>
          <el-select v-model="searchInfo.status" placeholder="请选择单据状态" size="default" clearable>
            <el-option label="草稿" value="0"/>
            <el-option label="已完成" value="99"/>
          </el-select>
          <el-select v-model="searchInfo.inoutType" placeholder="请选择出入库类型" clearable>
            <el-option v-for="item in inoutTypeList" :key="item.id" :label="item.name" :value="item.inoutCode"/>
          </el-select>

          <el-input v-model="searchInfo.searchKey" placeholder="请输入备注信息"/>
        </template>
        <!--      <template #option>-->
        <!--        <page-button type="write" @click="toGo">添加</page-button>-->
        <!--      </template>-->
      </search-condition>
      <table-list>
        <el-table stripe :data="inOrderList" height="100%" show-summary :summary-method="getSummary">
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="60px" fixed/>
          <el-table-column align="center" prop="id" label="订单编号" width="250" fixed>
            <template v-slot="{row}">
              <table-button class="add" @click="viewDetail(row)">{{ row.id }}</table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdTime" label="单据日期" show-overflow-tooltip fixed/>
          <el-table-column align="center" label="业务类型" show-overflow-tooltip>
            <template v-slot="{row}">
              <!--              <echo-dic :info-list="dictionaryList" :value="row.inoutType" />-->
              <dictionary :disabled="true" v-model="row.inoutType" :dict-code="'inoutCode'" :dict-name="'name'"
                          :dictionaries="dictionaryList"/>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stockName" label="仓库名称"/>
          <el-table-column align="center" prop="skuQty" label="数量"/>
          <el-table-column align="center" label="单据状态">
            <template v-slot="{row}">
              <el-tag v-if="row.status === 0" type="warning">草稿</el-tag>
              <el-tag v-if="row.status === 10" type="primary">待审核</el-tag>
              <el-tag v-if="row.status === 20" type="primary">待确认</el-tag>
              <el-tag v-if="row.status === 99" type="success">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdName" label="创建人员" show-overflow-tooltip/>

          <el-table-column label="单据来源" show-overflow-tooltip>
            <template v-slot="{row}">
              <table-button class="add" @click="findOriginal(row)">{{ row.inoutOrderId }}</table-button>
              <!--              <table-button class="add" @click="toSaleOrder(row)">{{row.inoutOrderId}}</table-button>-->
            </template>
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip/>
          <el-table-column align="center" label="操作 " fixed="right">
            <template v-slot="{row}">
              <table-button :disabled="row.status !== 0" @click="deleteRow(row)" el-type="danger"
                            message="确定删除该条草稿吗？">删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>
  </div>

</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import stockOutStorageApi from "@/api/main/stock/stockOutStorageApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import {h} from "vue";
import stockInStorageApi from "@/api/main/stock/stockInStorageApi";
import util from "@/util/util";
import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import config from "@/config/config";
import stockInoutTypeApi from "@/api/main/stock/StockInoutTypeApi";

export default {
  components: {
    Dictionary,
    PageButton,
    FormPage,
    TableButton,
    ListPage, SearchCondition, TableList, DateRange, PageHeader,
  },
  data() {
    return {
      stockId: "",
      stockList: [], //仓库列表
      inOrderList: [], // 入库订单列表
      searchInfo: {},
      departList: [],
      dictionaryList: [],
      bottomInfo: {},
      inoutTypeList: [],
      // 设置读的权限值
      readAccess: null,
      originalSwitch: false, // 溯源码功能
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    commonApi.queryAllTypeList(2).then(res => this.dictionaryList = res);
    // 查询部门信息
    this.queryAllDepart();
    // 初始化，默认查询一个月内的数据
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    //获取仓库列表
    this.queryStockList();
    // 获取所有的出入库类型
    stockInoutTypeApi.queryAllTypeList(2).then(list => this.inoutTypeList = list);
  },
  methods: {
    queryAllDepart() {
      // 查询部门，查询成树结构返回
      commonApi.queryDepartList().then(list => this.departList = list);
    },
    // 导出
    exportList() {
      util.exportExcel("/order/stock/outStorage/exportList", this.searchInfo);
    },
    // 跳转原单据
    findOriginal(row) {
      if (process.env.VUE_APP_SYSTEM_NAME === "Alpine") {
        // 车膜小程序，跳转订单详情
        this.$routerUtil.push(`/alpine/shop/saleOder/add?id=${data.inoutOrderId}`);
      } else {
        // 其它，跳转订单详情
        switch (row.inoutType) {
          case "BSCK":   // 报损出库
            this.$routerUtil.push(`/stock/inventory/detail?inoutStorage=${row.inoutOrderId}`)
            break;
          case "DBCK":  // 调拨出库
            merchantOrderApi.findById(row.originalOrderId).then(res => {
              if (res && res.orderType === 11) {
                this.$routerUtil.push(`/stock/restock/original/detail?id=${row.inoutOrderId}`)
              } else {
                this.$routerUtil.push(`/stock/restock/detail?id=${row.inoutOrderId}`)
              }
            })
            break;
          case "XSCK":   // 销售出库
            if (row.existCode === "2") {
              this.$routerUtil.push(`/supplyChain/sale/detail-original?id=${row.inoutOrderId}`);
            } else {
              this.$routerUtil.push(`/report/offline-order/detail?id=${row.inoutOrderId}`);
            }
            break;
        }
      }

    },
    clearDic() {
      this.searchInfo.departCode = "";
    },
    //序号
    indexMethod(index) {
      return index + 1;
    },
    toGo() {
      if (this.originalSwitch) {
        // 溯源码功能
        this.$routerUtil.push("/stock/outstock/detail-original");
      } else {
        this.$routerUtil.push("/stock/outstock/detail-simple");
      }
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },
    queryList() {
      stockOutStorageApi.queryList(this.searchInfo, this.stockId).then(res => {
        this.inOrderList = this.convert(res.records);
        this.searchInfo.totalCount = res.totalCount;
      })
      this.queryBottomList();
    },
    convert(list) {
      let configType = config.inoutType;
      for (let i = 0; i < list.length; i++) {
        let inoutType = Number(list[i].inoutType);
        if (!isNaN(inoutType)) {
          let data = configType.find(item => item.value === inoutType);
          list[i].inoutType = data.text;
        }
      }
      return list;
    },
    queryBottomList() {
      let searchVo = this.searchInfo;
      searchVo.inoutStorageType = 2;
      stockInStorageApi.querySaleStatisticInfo(searchVo).then(res => this.bottomInfo = res);
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    viewDetail(row) {
      if (this.originalSwitch) {
        this.$routerUtil.push({
          path: "/stock/outstock/detail-original",
          query: {
            inoutStorage: row.id,
          }
        });
      } else {
        this.$routerUtil.push({
          path: "/stock/outstock/detail-simple",
          query: {
            inoutStorage: row.id,
          }
        });
      }

    },
    deleteRow(row) {
      if (row.status !== 0) {
        this.$message.error("只能删除草稿！！！")
        return;
      }
      stockOutStorageApi.deleteRow(row.id);
    },
    getSummary(param) {
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }
        if (index === 5) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [Number(this.bottomInfo.totalQty).toFixed(2)])
        }

        // if(index === 6){
        //   sums[index] = h('div',{style: {fontWeight: 'bolder'}}, [Number(this.bottomInfo.totalAmount).toFixed(2)])
        // }
      })
      return sums
    },
    toSaleOrder(data) {


    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";

.add:hover {
  text-decoration: underline;
}
</style>