<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <el-tree ref="groupTree" node-key="id" :data="productCategoriesAll"
                 :props="{ children: 'children', label: 'dictName' }" @node-click="clickCategory" default-expand-all
                 :expand-on-click-node="false">
          <template v-slot="{ node, data }">
            <span class="custom-tree-node">
              <span class="node-name">

                <el-tooltip
                    effect="light"
                    :content="node.label"
                    placement="left-start"
                >
                  {{ node.label }}
                </el-tooltip>
              </span>
              <span class="node-option">
                <table-button type="primary" @click="toAddNewCategory({parentId: data.id})"><el-icon><el-icon-plus/></el-icon></table-button>
                <table-button type="primary" @click="toAddNewCategory(data)"><el-icon><el-icon-edit/></el-icon></table-button>
                <table-button type="delete" class="btn" message="是否确认删除这个分类"
                              @click="toDeleteCategory(data.id)"><el-icon><el-icon-delete/></el-icon></table-button>
              </span>
            </span>
          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page>
          <search-condition @search="handleSearch" :show-search="true">
            <template #default>
              <el-input v-model="searchInfo.searchKey" placeholder="请输入编号/商品名称" @keydown.enter="handleSearch"/>
            </template>
            <template #moreCondition>
              <div style="display: inline-block; width: 240px">
                <el-select v-model="searchInfo.supplierId" placeholder="请选择供应商" size="default" clearable
                           style="width: 100%;">
                  <el-option v-for="(item,idx) in supplierList" :key="idx" :label="item.supplierName" :value="item.id"/>
                </el-select>
              </div>
              <div style="display: inline-block;width: 240px; margin-left: 10px">
                <el-select v-model="searchInfo.searchStatus" placeholder="请选择商品状态" size="default" clearable
                           style="width: 100%;">
                  <el-option label="上架" :value="0"/>
                  <el-option label="下架" :value="-1"/>
                  <el-option label="停用" :value="-2"/>
                </el-select>
              </div>
            </template>
            <template #option>
              <page-button el-type="primary" type="write" @click="showSyncDialog = true"
                           v-if="userInfo.merchantId === '10000'">同步erp商品
              </page-button>
              <page-button el-type="primary" type="write" @click="showUpDown = true">批量上/下架</page-button>
              <page-button el-type="primary" type="im" @click="showImportDialog = true">导入</page-button>
              <page-button el-type="primary" type="ex" @click="exportList">导出</page-button>
              <page-button el-type="success" type="write" @click="addNewProduct">新建</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table stripe :row-key="row => row.id" ref="multable" :data="productList" height="100%" @row-click="rowClick">
              <el-table-column type="selection" :reserve-selection="true" width="50px" align="center"/>
              <el-table-column label="序号" type="index" width="60px" :alter="'center'" align="center"/>
              <el-table-column label="商品编号" prop="productCode" show-overflow-tooltip min-width="120px"/>
              <el-table-column label="商品分类" show-overflow-tooltip min-width="120px">
                <template v-slot="{ row }">
                  <dictionary :dictionaries="productCategories" v-model="row.category" :disabled="true"/>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="productName" show-overflow-tooltip align="center" min-width="120px"/>
              <el-table-column label="规格" prop="productStandard" show-overflow-tooltip min-width="120px"/>
              <el-table-column label="型号" prop="productType" show-overflow-tooltip min-width="120px"/>
<!--              <el-table-column label="条码" prop="barCode" show-overflow-tooltip min-width="120px"/>-->
              <el-table-column label="品牌" prop="productBrade" show-overflow-tooltip min-width="120px">
                <template v-slot="{row}">
                  <dictionary :disabled="true" v-model="row.productBrade"
                              :dictionaries="productBrades"
                              dict-code="dictCode"
                              dict-name="dictName"/>
                </template>
              </el-table-column>
              <el-table-column label="基本单位" align="center" prop="productUnitName" min-width="120px"/>
              <el-table-column label="预设售价(含税)" prop="basePrice" align="center" min-width="120px">
                <template v-slot="{row}">
                  <money :value="row.basePrice"/>
                </template>
              </el-table-column>
<!--              <el-table-column label="供应商" prop="supplierName" align="center" min-width="120px" show-overflow-tooltip/>-->
              <el-table-column label="状态" align="center" fixed="right">
                <template v-slot="{ row }">
                  <el-tag type="success" v-if="row.status === 0">上架</el-tag>
                  <el-tag type="warning" v-else-if="row.status === -1">下架</el-tag>
                  <el-tag type="danger" v-else-if="row.status === -2">停用</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="存货类型" width="100px" align="center" fixed="right">
                <template v-slot="{ row }">
                  <span v-if="row.stockType === 1">库存商品</span>
                  <span v-if="row.stockType === 0">服务商品</span>
                  <span v-if="row.stockType === 2">组合商品</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" :align="'center'" width="120px" fixed="right">
                <template v-slot="{ row }">
                  <table-button @click="toEdit(row)">编辑</table-button>
                  <table-button el-type="danger" type="danger" @click="toDelete(row)">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                     :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
        </list-page>
      </template>
    </separate-page>
  </div>
  <el-dialog title="商品分类管理" v-model="showProductDialog" width="400px">
    <el-form label-width="100px">
      <el-form-item label="上级分类">
        <dictionary v-model="currentCategory.parentId" :dictionaries="productCategories" dict-code="id"
                    dict-name="dictName" style="width: 100%"/>
      </el-form-item>
      <el-form-item label="分类名称">
        <el-input v-model="currentCategory.dictName"/>
      </el-form-item>
      <el-form-item label="分类编码">{{ currentCategory.departCode }}</el-form-item>
    </el-form>
    <detail-page-btn>
      <el-button @click="showProductDialog = false">取消</el-button>
      <el-button type="primary" @click="saveProductCategory">确定</el-button>
    </detail-page-btn>
  </el-dialog>

  <el-dialog title="erp商品同步" v-model="showSyncDialog" width="30%">
    <el-form label-width="70px">
      <el-form-item label="预设进价">
        <el-select v-model="searchInfo.preBuyPrice">
          <el-option label="预设进价1" :value="1"/>
          <el-option label="预设进价2" :value="2"/>
          <el-option label="预设进价3" :value="3"/>
          <el-option label="预设进价4" :value="4"/>
          <el-option label="预设进价5" :value="5"/>
        </el-select>
      </el-form-item>
      <el-form-item label="预设售价">
        <el-select v-model="searchInfo.preSalePrice">
          <el-option label="预设售价1" :value="1"/>
          <el-option label="预设售价2" :value="2"/>
          <el-option label="预设售价3" :value="3"/>
          <el-option label="预设售价4" :value="4"/>
          <el-option label="预设售价5" :value="5"/>
          <el-option label="预设售价6" :value="6"/>
          <el-option label="预设售价7" :value="7"/>
          <el-option label="预设售价8" :value="8"/>
          <el-option label="预设售价9" :value="9"/>
          <el-option label="预设售价10" :value="10"/>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: flex-end">
      <page-button el-type="info" @click="showSyncDialog = false">取消</page-button>
      <page-button el-type="primary" @click="startSync">确定</page-button>
    </div>
  </el-dialog>

  <el-dialog title="批量上架管理" v-model="showUpDown" width="400px">
    <el-form label-width="70px">
      <el-form-item label="上下架">
        <el-select v-model="status" placeholder="请选择商品状态" size="default"
                   style="width: 100%;">
          <el-option label="上架" :value="0"/>
          <el-option label="下架" :value="-1"/>
          <el-option label="停用" :value="-2"/>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: flex-end">
      <page-button @click="showUpDown = false">取消</page-button>
      <page-button el-type="primary" type="write" @click="saveUpDown">确定</page-button>
    </div>
  </el-dialog>

  <import-component v-model="showImportDialog" title="批量导入商品" @exportTemp="exportTemp"
                    :importByExcel="importByExcel"/>
</template>
<script>
import TableList from "@/components/h5/layout/TableList";
import TableButton from "@/components/h5/button/TableButton";
import Dictionary from "@/components/h5/Dictionary";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn";
import productApi from "@/api/main/product/productApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import SeparatePage from "@/components/h5/layout/SeparatePage";
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import Money from "@/components/h5/Money.vue"
import ImportComponent from "@/components/h5/ImportComponent.vue";
import productGrantDepartApi from "@/api/main/product/productGrantDepartApi";
import {ElLoading, ElMessageBox} from "element-plus";

export default {
  name: "ProductList",
  components: {
    PageButton,
    FormPage,
    TableList,
    SearchCondition,
    TableButton,
    Dictionary,
    SeparatePage,
    DetailPageBtn,
    ListPage,
    Money,
    ImportComponent
  },
  data() {
    return {
      searchInfo: {},
      productList: [],
      // 商品分类
      productCategories: [],
      productCategoriesAll: [],
      showSyncDialog: false, // 显示同步设置
      syncSetting: {
        deleteOld: 1,
        deleteCategory: 1,
        lastLevel: 1,
        deleteUnit: 1,
        priceColumn: "retailPrice", // 单价字段
        buyPriceColumn: "preBuyPrice1",
      },
      units: [], // 商品单位
      currentCategory: {},
      showProductDialog: false,
      userInfo: {},
      showImportDialog: false,
      showUpDown: false,
      status: 0,
      // 存储每页选中的数据，方便进行回显
      pageMap: new Map(),
      supplierList: [],
      loading: null,
    };
  },
  created() {
    commonApi.querySupplierList().then(list => this.supplierList = list);
    util.getUserInfo().then(user => this.userInfo = user);
    // 查询商品分类
    this.queryDictionTree();
    // 查询单位
    this.findUnits();
    // 获取缓存的查询条件，并进行查询
    // this.searchInfo = util.listGetParams();
    this.queryList();
    // // 商品品牌
    commonApi.queryDictionaryList("PRODUCT_BRADE").then(list => this.productBrades = list);
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
  },
  mounted() {
  },
  methods: {
    findUnits() {
      productApi.queryUnits().then(units => {
        this.units = units;
      })
    },
    startSync() {
      this.loading = ElLoading.service({
        lock: true,
        text: `正在同步商品中,请耐心等待...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      productApi.queryErpProductSync(this.searchInfo).then(() => {
        this.loading.close();
        this.showSyncDialog = false;
        this.$message.success("同步成功！");
        this.findUnits();
        this.queryList();
        this.queryDictionTree();
      }).catch(() => this.loading.close());
    },
    exportList() {
      // 获取选中的商品列表信息
      let list = this.$refs.multable.getSelectionRows();
      if (list.length === 0) {
        ElMessageBox.confirm(
            '未勾选商品，将会全部导出！',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              util.exportExcel("/order/product/exportList", this.searchInfo);
            })
      } else {
        // 不为空，获取选中的商品信息列表
        let ids = [];
        for (let i = 0; i < list.length; i++) {
          ids.push(list[i].id);
        }
        this.searchInfo.productIds = ids;
        util.exportExcel("/order/product/exportList", this.searchInfo);
      }
    },
    rowClick(data) {
      let list = this.$refs.multable.getSelectionRows();
      this.$refs.multable.toggleRowSelection(data, list.findIndex(item => item.id === data.id) < 0);
    },
    saveUpDown() {
      let list = this.$refs.multable.getSelectionRows();
      if (list.length === 0) {
        this.$message.error("未选择商品");
        return;
      }
      let ids = list.map(item => item.id);
      productApi.saveUpDown(ids, this.status).then(() => {
        this.showUpDown = false;
        this.queryList();
      })
    },
    queryDictionTree() {
      commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => {
        this.productCategories = list;
        this.productCategoriesAll = [{dictName: "全部分类", children: list}];
      });
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      productApi.queryList(this.searchInfo).then(data => {
        this.productList = data.records;
        // 处理分类
        if (this.searchInfo.category === "" || !this.searchInfo.category) {
          this.handlerCategory();
        }
        // 回显选中的数据
        // if (this.pageMap.has(this.searchInfo.pageNumber)) {
        //   let list = this.pageMap.get(this.searchInfo.pageNumber);
        //   // 将最新的数据id与原来的数据id进行比较
        //   for (let i = 0; i < this.productList.length; i++) {
        //     this.$refs.multable.toggleRowSelection(
        //         this.productList[i],
        //         list.findIndex(item => item.id === this.productList[i].id) > 0
        //     );
        //   }
        // }
        // this.searchInfo.pageNumber = data.pageNumber;
        // this.searchInfo.pageSize = data.pageSize;
        this.searchInfo.totalCount = data.totalCount;
      })
    },
    async handlerCategory() {
      // 如果当前页面读的权限为全部、则不去操作
      const readAccess = await util.getAccess(this.$routerUtil.path, 'read')
      if (readAccess === '3') {
        return;
      }
      // 获取商品的已授权全部分类
      const list = await productGrantDepartApi.queryAllByDepartCode(this.userInfo.mainDepartCode);
      if (list.length === 0) {
        // 分类和商品全部清空
        this.productList = [];
        this.productCategoriesAll[0].children = [];
        this.searchInfo.totalCount = 0;
        return;
      }
      let myMap = new Map();
      // 将分类划分为集合
      for (let i = 0; i < list.length; i++) {
        if (!myMap.has(list[i].productCategory)) {
          myMap.set(list[i].productCategory, list[i].productCategory);
        }
      }
      // 过滤分类
      this.filterCategory(this.productCategoriesAll[0].children, myMap);
    },
    // 使用递归过滤，判断是否含有子级信息
    filterCategory(list, myMap) {
      // 递归list这个树结构,判断分类是否在map中，如果在的话则保留，如果它是子级分类，则连带着父级分类一起保留
      for (let i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.length > 0) {
          let returnData = this.filterCategory(list[i].children, myMap);
          if (!returnData || returnData.length > 0) {
            continue;
          }
        }
        if (!myMap.has(list[i].dictCode)) {
          // 如果在map中，则保留
          // 如果不在map中，则删除
          list.splice(i, 1);
          i--;
        }
      }
      return list;
    },
    //搜索
    handleSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    clickCategory(data) {
      this.searchInfo.category = data.dictCode;
      this.resetSearch();
    },
    // 添加一个新的商品
    addNewProduct() {
      util.beforeList2Detail(this.searchInfo);
      this.$routerUtil.push("/product/list/detail")
    },
    // 编辑商品
    toEdit(data) {
      util.beforeList2Detail(this.searchInfo);
      // 如果是自己的商品，进行编辑，如果不是自己的商品，只能修改价格
      if (this.userInfo.merchantId === data.merchantId) {
        this.$routerUtil.push(`/product/list/detail?id=${data.id}`);
      } else {
        this.$routerUtil.push(`/product/list/detailView?id=${data.id}`);
      }
    },
    // 删除商品
    toDelete(data) {
      this.$confirm("是否确认删除商品").then(() => {
        productApi.delete(data.id).then(() => this.queryList());
      })
    },
    // 保存商品分类
    saveProductCategory() {
      productApi.saveCategory(this.currentCategory).then(() => {
        this.queryDictionTree();
        this.showProductDialog = false;
      });
    },
    // 编辑，修改分类
    toAddNewCategory(data) {
      this.currentCategory = data;
      this.showProductDialog = true;
    },
    // 删除分类
    toDeleteCategory(id) {
      productApi.deleteCategory(id).then(() => {
        this.queryDictionTree();
      });
    },
    exportTemp() {
      util.exportExcel("/order/product/exportTemp", {});
    },
    importByExcel(formData) {
      return new Promise(resolve => {
        productApi.uploadProductByTemplate(formData).then(() => {
          this.showImportDialog = false;
          this.queryList();
          resolve();
        });
      })

    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
@import "@/assets/css/el-tables";
</style>