<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>

          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="write" el-type="success" @click="toGo">新建</page-button>

        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
          <div style="display: inline-block; margin-right: 10px">
            <el-select v-model="searchInfo.status" placeholder="请选择单据状态" size="default" clearable>
              <el-option label="草稿" value="0"/>
              <el-option label="已完成" value="99"/>
            </el-select>
          </div>
          <el-input v-model="searchInfo.searchKey" placeholder="请输入备注信息"/>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" border ref="multipleTable" height="100%">
          <el-table-column align="center" type="index" label="序号" width="60px" fixed/>
          <el-table-column align="center" prop="id" label="订单编号" width="200px" fixed/>
          <el-table-column align="center" prop="createdTime" label="单据日期" show-overflow-tooltip fixed/>

          <el-table-column align="center" prop="outNum" label="出库数量"/>
          <el-table-column align="center" prop="inNum" label="入库数量"/>

          <el-table-column align="center" label="单据状态" show-overflow-tooltip width="100px">
            <template v-slot="{row}">
              <el-tag :type="row.status === 99 ? 'success' : 'warning'">
                {{ row.status === 99 ? '已完成' : '草稿' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="employeeName" label="创建人员" show-overflow-tooltip/>
          <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip/>
          <el-table-column align="center" label="操作 " fixed="right">
            <template v-slot="{row}">
              <table-button type="write" v-if="row.status === 0" @click="deleteRow(row)" el-type="danger"
                            message="确定删除该条草稿吗？">删除
              </table-button>
              <table-button v-else @click="viewDetail(row)" el-type="primary">
                查看
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>
  </div>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import assembleApi from "@/api/main/stock/assembleApi";

export default {
  name: "",
  components: {
    Dictionary,
    PageButton,
    FormPage,
    TableButton,
    ListPage, SearchCondition, TableList, DateRange, PageHeader, ProductListSelect
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
    };
  },
  created() {
    // 时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  methods: {
    viewDetail(row){
      this.$routerUtil.push(`/stock/assemble/detail?id=${row.id}`);
    },
    toGo() {
      this.$routerUtil.push('/stock/assemble/detail');
    },
    deleteRow(row) {
      assembleApi.deleteById(row.id).then(() => this.queryList());
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      assembleApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>