<template>
  <!-- -->
  <home-tai-kang v-if="systemCode === 'TaiKang'" />
  <home-alpine v-else-if="systemCode === 'Alpine'"/>
  <home-s-h-j-x v-else-if="systemCode === 'SHJX'" />
  <home-j-y-w-z v-else-if="systemCode === 'JYWZ'" />
</template>

<script>
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import HomeSHJX from "@/views/main/home/HomeSHJX.vue";
import HomeTaiKang from "@/views/main/home/HomeTaiKang.vue";
import HomeAlpine from "@/views/main/home/HomeAlpine.vue";
import HomeJYWZ from "@/views/main/home/HomeJYWZ.vue";

export default {
  name: "HomeView",
  components: { HomeTaiKang, HomeAlpine, HomeSHJX, HomeJYWZ},
  data() {
    return {
      systemCode: ""
    };
  },
  created() {
    this.systemCode = process.env.VUE_APP_SYSTEM_NAME;
    // 查询版本信息
    commonApi.queryVersionInfo().then(list => {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type === "1") {
          arr.push(list[i]);
        }
      }
      this.updateRecordList = arr;
    })
    // stockApi.queryListByNotCondition().then(list =>  this.stockList = list)
    this.queryWarnList();
    // 销售订单统计时间
    this.orderCountInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.orderCountInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    this.queryOrderCount();
    // 获取登录用户信息
    util.getUserInfo().then(res => {
      this.userInfo = res;
    });
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>
