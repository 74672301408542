import axios from "../../../util/axios";

export default {
    // 查询列表
    queryList: (data)=> axios.get({url: `/order/supplyChain/purchase/queryList`, data: data}),
    // 保存草稿
    saveDraft: (data)=> axios.post({url: `/order/supplyChain/purchase/saveDraft`, data: data}),
    // 保存并提交
    saveSubmit: (data)=> axios.post({url: `/order/supplyChain/purchase/saveSubmit`, data: data}),
    // 删除
    delete: (id)=> axios.del({url: `/order/supplyChain/purchase/delete`, data: {id}}),
    // 查询明细
    findById: (id)=> axios.get({url: `/order/supplyChain/purchase/findById`, data: {id}}),
    // 查询状态
    getAllStatus: ()=> axios.get({url: `/order/supplyChain/purchase/getAllStatus`}),
    // 查询商品
    queryProductAuthorizeList: (data)=> axios.get({url: `/order/supplyChain/purchase/queryProductAuthorizeList`, data: data}),
    // 获取上一次的采购价格
    queryLastBuyPrice: (data)=> axios.post({url: `/order/supplyChain/purchase/queryLastBuyPrice`, data: data}),
    /**
     * 物资
     */
    // 保存草稿
    saveDraftWZ: (data)=> axios.post({url: `/order/supplyChain/purchase/saveDraft-wz`, data: data}),
    // 保存并提交
    saveSubmitWZ: (data)=> axios.post({url: `/order/supplyChain/purchase/saveSubmit-wz`, data: data}),
    // 审批
    approvalWZ: (data)=> axios.post({url: `/order/supplyChain/purchase/approval-wz`, data: data}),
    // 提交质检
    saveQuality: (data) => axios.post({url: "/order/supplyChain/purchase/saveQuality", data: data})
}