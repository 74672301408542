<template>
  <!-- -->
  <div class="defaultViewStyle">
    <div class="separator-div">
      <div class="left">
        <div style="height: 100%;padding-right: 5px">
          <div class="separator-top box-shadow">
            <div>
              <div style="display: inline-block;width: calc(100% - 80px);margin-right: 7px">
                <customer-select v-model="dataDetail.customerId" :name="dataDetail.customerName"
                                 @selectOne="setCustomer"/>
              </div>
              <page-button type="primary" @click="toSetDefaultCustomer">默认客户</page-button>
            </div>
            <div class="custom-info">
              <span>姓名：{{ dataDetail.customerName || '临时客户' }}</span>
              <span>手机号：{{ dataDetail.customerPhone }}</span>
              <span>余额：<money :value="dataDetail.customerAmount"/></span>
            </div>
          </div>
          <div class="separator-body box-shadow">
            <el-table stripe :data="dataDetail.productList" height="100%" size="small"
                      :row-class-name="productClassName" @row-click="checkSku">
              <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
              <el-table-column show-overflow-tooltip prop="skuName" label="商品名称"/>
              <el-table-column prop="skuUnitName" label="单位" :align="'center'" width="60px"/>
              <el-table-column prop="qty" label="数量" :align="'center'" width="60px"/>
              <el-table-column prop="skuSalePrice" label="单价" :align="'center'" width="60px"/>
              <el-table-column label="小计" :align="'center'" width="100px">
                <template v-slot="{row}">
                  <money :value="row.skuSalePrice * row.qty"/>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="separator-bottom box-shadow">
            <div class="custom-info">
              <span>数量合计:{{ dataDetail.qty }}</span>
              <span>金额合计:<money :value="dataDetail.amountTotal"/></span>
              <span>优惠合计:<money :value="dataDetail.amountDiscount"/></span>
              <span>应收合计:<money :value="dataDetail.amount" :show-color="true"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="separator-top box-shadow">
          <product-list-select :is-depart="true" :selectedProductList="dataDetail.productList" qtyColumn="qty" @select="selectProductList"
                               :show-price="true"/>
          <div class="product-tags">
            <div class="setting-all">
              <el-icon @click="shorCutSetting">
                <el-icon-setting/>
              </el-icon>
            </div>
            <div class="setting-left">
              <a href="javascript:" @click="shortCutToLeft">
                <el-icon>
                  <ArrowLeft/>
                </el-icon>
              </a>
            </div>

            <div id="con_1" class="short-cut-container" style="position:relative; height: 100%">
              <div id="con_2" style="position: absolute;  top: 0; left: 0; height: 100%;">
                <div v-for="(item, idx) in shortCutDetailList" :key="idx"
                     :class="item.isSelected ? 'short-cut-bgcolor' : 'short-cut'"
                     @click="toAddProduct(item)">
                  <div v-if="item.dataType === '3'">{{ item.productSkuDto.skuName }}</div>
                  <div v-if="item.dataType === '2'">{{ item.productDto.productName }}</div>
                  <dictionary v-if="item.dataType === '1'" :disabled="true" v-model="item.dataId"
                              :dictionaries="productCategoryList" dict-code="dictCode" dict-name="dictName"
                              :place-holder="'商品分类'"
                              style="width: 100%"/>
                </div>
              </div>
            </div>
            <div class="setting-right" @click="shortCutToRight">
              <a href="javascript:">
                <el-icon>
                  <ArrowRight/>
                </el-icon>
              </a>
            </div>
            <!--            <el-icon><ArrowRight /></el-icon>-->
          </div>
        </div>
        <div class="separator-all box-shadow">
          <div v-for="(item,index) in selectedProductList" :key="index" class="product-tag">
            <div class="product-item" @click="toAddFromList(item)">
              <div class="title">{{ item.skuName || item.productName }}</div>
              <div class="title">规&nbsp;&nbsp;&nbsp;格：{{ item.skuStandard || item.productStandard }}</div>
              <div>单&nbsp;&nbsp;&nbsp;位：{{ item.skuUnitName || item.productUnitName }}</div>
              <div>零售价：
                <money :value="item.skuSalePrice || item.basePrice"/>
              </div>
              <div>会员价：
                <money :value="item.skuSalePrice || item.buyPrice"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="option-div box-shadow">
      <div>
        <page-button @click="showUpdatePrice = true" el-type="success" :disabled="!currentProduct.skuId">改价/数量
        </page-button>
        <page-button @click="clearAll">清屏</page-button>
        <page-button @click="offlineOrder" el-type="primary">挂单</page-button>
        <page-button @click="showOfflineOrders = true" el-type="primary">挂单列表</page-button>
        <page-button el-type="danger" @click="gift">赠送</page-button>
        <page-button el-type="primary">会员建档</page-button>
        <page-button @click="showRefund = true" el-type="primary">退货</page-button>
      </div>
      <div>
        <page-button el-type="primary" @click="toBalance">结算</page-button>
      </div>
    </div>

    <el-dialog title="基础设置" v-model="showBasicSetting" width="40%">
      <el-form label-width="70px">
        <el-form-item label="部门">
          <div style="width: 240px;">
            <dictionary v-model="dataDetail.departCode"
                        @change-data="changeDepart" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
        </el-form-item>
        <el-form-item label="仓库">
          <div style="width: 240px;">
            <el-select v-model="dataDetail.stockId"
                       style="width: 100%">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="快捷键">
          <table-button @click="showSetting = true">设置快捷键</table-button>
        </el-form-item>
      </el-form>

      <el-dialog class="mySecDialog" title="快捷键设置" v-model="showSetting" :show-close="false" @closed="initShortCut" :close-on-click-modal="false">
        <add-short-cut-setting ref="shortCut" @cancel="showSetting = false" @select-data="selectData"/>
      </el-dialog>

    </el-dialog>

    <el-dialog width="500px" v-model="showUpdatePrice">
      <update-price :value="currentProduct" @confirm="updateProductPrice" @delete-row="deleteRow"
                    @cancel="showUpdatePrice = false"/>
    </el-dialog>

    <el-dialog title="挂单列表" v-model="showOfflineOrders" width="900px">
      <dialog-table>
        <el-table stripe :data="offlineOrderList" height="100%" :row-class-name="offlineOrderClassName"
                  @row-click="checkOfflineOrder">
          <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
          <el-table-column prop="orderTime" label="订单时间" show-overflow-tooltip/>
          <el-table-column prop="customerName" label="客户" show-overflow-tooltip width="100px">
            <template v-slot="{row}">{{ row.customerName || '临时客户' }}</template>
          </el-table-column>
          <el-table-column prop="qty" label="数量合计"/>
          <el-table-column prop="amount" label="金额合计" width="100px">
            <template v-slot="{row}">
              <money :value="row.amountTotal"/>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="优惠合计" width="100px">
            <template v-slot="{row}">
              <money :value="row.amountDiscount"/>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="应收合计" width="100px">
            <template v-slot="{row}">
              <money :value="row.amount"/>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <div style="padding-top: 10px;text-align: end">
        <page-button @click="showOfflineOrders = false">取消</page-button>
        <page-button el-type="primary" :disabled="!currentOfflineOrder.id" @click="selectOfflineOrder">选择</page-button>
        <page-button el-type="danger" :disabled="!currentOfflineOrder.id" @click="deleteOfflineOrder">删除</page-button>
      </div>
    </el-dialog>

    <el-dialog title="单据结算" v-model="showOrder" @open="setAmount">
      <sale-balance :sale-info="this.dataDetail" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                    :is-pay="true" :is-batch-pay="true" :is-refresh-pay="isRefreshPay" ref="dialogFocus"
                    @change-pay="changePay" @find-sale-status="toFindPayStatus" @close-page="showOrder = false"
                    @pay="pay" @batch-submit="batchSubmit" v-loading.fullscreen.lock="fullscreenLoading">
        <el-checkbox v-model="isAutoPrint" label="打印销售凭证" size="large"/>
      </sale-balance>
    </el-dialog>

    <el-dialog v-model="showRefund" title="订单列表">
      <search-condition @search="resetSearch" :show-search="true">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
        </template>
        <template #moreCondition>
          <el-input style="width: 240px" ref="orderNumberInput" v-model="searchInfo.orderId" placeholder="请输入订单编号"
                    @change="resetSearch"/>
          <div style="display: inline-block; width: 240px" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>
        </template>
      </search-condition>
      <dialog-table>
        <el-table stripe :data="dataList" height="100%">
          <el-table-column align="center" label="序号" type="index" width="60px"/>
          <el-table-column align="center" label="订单编号" prop="id" show-overflow-tooltip/>
          <el-table-column align="center" label="客户名称" prop="customerName"/>
          <el-table-column align="center" label="订单状态">
            <template v-slot="{row}">
              <el-tag v-if="row.refundQty === 0" type="info">未退款</el-tag>
              <el-tag v-else-if="row.refundQty < row.qty && row.refundQty !== 0" el-type="primary">部分退款</el-tag>
              <el-tag v-else-if="row.refundQty === row.qty" type="success">退款完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单金额" prop="amount"/>
          <el-table-column align="center" label="下单时间" prop="createdTime" show-overflow-tooltip/>
          <el-table-column align="center" label="支付时间" show-overflow-tooltip>
            <template v-slot="{row}">
              <span>{{ row.payTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="addRefund(row)" :disabled="row.refundQty === row.qty"> 选中
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" :default-load="false" @onLoad="queryAllOrder"></form-page>
    </el-dialog>

    <el-dialog v-model="showSubmitRefund" @open="openToRefund" :close-on-click-modal="false" width="40%" height="390px"
               title="保存单据">
      <dialog-table :auto-height="true">
        <el-form label-width="70px">
          <div style="height: 100%">
            <el-form-item label="成交金额">
              <el-input disabled v-model="allAmount"/>
            </el-form-item>
            <el-form-item label="应退合计">
              <el-input @input="updateOrderData" v-model="dataDetail.amount"/>
            </el-form-item>
            <el-form-item label="数量合计">
              <el-input disabled v-model="dataDetail.qty"/>
            </el-form-item>
            <div>
              <el-form-item :label="index === 0 ? '退款方式' : ''" v-for="(item,index) in accountRefundList"
                            :key="item.payTypeId + new Date()">
                <div style="display: flex;flex-direction: row;width: 100%;">
                  <el-select style="width: 47%" v-model="item.payTypeId" placeholder="请选择支付方式"
                             clearable :disabled="dataDetail.payStatus >= 50">
                    <el-option v-for="(st, i) in payTypeList" :key="i + 'p'" :label="st.payName" :value="st.id"/>
                  </el-select>
                  <el-input style="width: 47%" v-model="item.refundCurrent" :disabled="dataDetail.payStatus >= 50"/>
                  <page-button el-type="success" v-if="index === this.accountRefundList.length - 1" @click="addAccount"
                               style="width: 6%">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                  </page-button>
                  <page-button el-type="danger" v-else @click="reduceAccount(index)" style="width: 6%">
                    <el-icon>
                      <Minus/>
                    </el-icon>
                  </page-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </dialog-table>
      <div style="display: flex;justify-content: space-between">
        <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large"/>
        <div>
          <page-button @click="showSubmitRefund = false">取消</page-button>
          <page-button el-type="primary" :loading="preparePay" @click="submitRefund" :disabled="disableToClickPay">
            确认
          </page-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="showSelectProduct" width="480px" :show-close="false">
      <div>
        <div style="width: 90%; margin: 0 auto">
          <!-- 名称 -->
          <div style="font-size: 18px; font-weight: bolder; width: 400px;overflow: hidden;text-overflow: ellipsis;">
            {{ attributeInfo.skuName }}
          </div>
          <!-- 单位信息 -->
          <div style="margin-top: 8px">
            <span style="margin-right: 30px">单位： {{ attributeInfo.skuUnitName }}</span>
            <span>单价： {{ (attributeInfo.skuOriginalPrice).toFixed(2) }}元</span>
          </div>
          <!-- 分割 -->
          <el-divider/>

          <div style="position: relative;">
            <el-tooltip
                effect="dark"
                content="重置选择"
                placement="top"
            >
              <el-icon size="20" style="position: absolute; right: 0; top: 7px; border: 1px solid #eae5e5; border-radius: 5px" @click="opClick"><Refresh /></el-icon>
            </el-tooltip>
            <div class="radio-box" v-for="item in attributeInfo.attributeList" :key="item.id">
              <div style="display: block;width: 18%;margin-top: 10px">
                {{ item.columnName }}：
              </div>
              <div style="width: 82%">
                <el-radio-group @change="radioChange(item)" v-model="item.myOption">
                  <el-radio v-for="op in item.optionList" :key="op.code" :value="op.code"
                            :disabled="op.isDisabled"
                            size="large">{{ op.name }}
                  </el-radio>
                </el-radio-group>
              </div>
              <br/>
            </div>
          </div>
          <!-- 分割 -->
          <el-divider/>
          <!-- 金额、数量 -->
          <div style="margin-top: 10px">
            <update-price :value="attributeInfo" @confirm="getSaleInfo" @cancel="showSelectProduct = false" :is-show="false" />
<!--            <div style="display: flex;justify-content: space-between;align-items: center;flex-direction: row">-->
<!--              <span>数量：</span>-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <el-input-number style="margin-right: 10px" v-model="attributeInfo.skuQty" :min="0" :max="1000000"-->
<!--                                 size="small"/>-->
<!--                <el-icon @click="showUpdatePrice2 = true" :size="20">-->
<!--                  <EditPen/>-->
<!--                </el-icon>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--                style="margin-top: 15px;display: flex;justify-content: space-between;align-items: center;flex-direction: row">-->
<!--              <span>金额：</span>-->
<!--              <div style="width: 120px;margin-right: 30px">-->
<!--                <el-input size="small" v-model="attributeInfo.amount"/>-->
<!--              </div>-->
<!--            </div>-->
          </div>
<!--          &lt;!&ndash; 分割 &ndash;&gt;-->
<!--          <el-divider/>-->
<!--          <div style="margin-top: 10px; text-align: center">-->
<!--            <page-button el-type="info" @click="showSelectProduct = false">取消</page-button>-->
<!--            <page-button el-type="primary" @click="submit">确定</page-button>-->
<!--          </div>-->

        </div>
      </div>
    </el-dialog>


  </div>


</template>

<script>
import PageButton from "@/components/h5/button/PageButton";
import ProductListSelect from "@/components/h5/product/ProductListSelect";
import Money from "@/components/h5/Money";
import updatePrice from "@/views/main/sale/order/newOrder/NewOrdercomp/UpdatePrice.vue";
import DialogTable from "@/components/h5/layout/DialogTable";
import CustomerSelect from "@/components/h5/customer/CustomerSelect";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import AddShortCutSetting from "@/views/main/sale/order/newOrder/NewOrdercomp/AddShortCutSetting.vue";
import shortCutApi from "@/api/main/shortCut/shortCutApi";
import {ArrowLeft, ArrowRight, Edit, Minus, Plus, Refresh} from "@element-plus/icons-vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import SaleBalance from "@/components/h5/sale/saleBalance.vue";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import selfFunction from "@/selfFunction";
import {ElLoading} from "element-plus";
import stockApi from "@/api/main/stock/stockApi";
import FormPage from "@/components/h5/layout/FormPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import RefundOrderApi from "@/api/main/order/online/RefundOrderApi";
import systemPayApi from "@/api/main/sys/systemPayApi";
import productApi from "@/api/main/product/productApi";
import memberApi from "@/api/main/customer/memberApi";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import localPrint from "@/util/new/LocalPrint";

export default {
  name: "OrderDetail",
  components: {
    Refresh,
    Minus,
    Plus,
    TableButton, DateRange, SearchCondition, FormPage,
    SaleBalance,
    ArrowRight,
    ArrowLeft,
    PageButton, ProductListSelect, Money, updatePrice, DialogTable, CustomerSelect, AddShortCutSetting, Dictionary
  },
  computed: {
    Edit() {
      return Edit
    },
    allAmount() {
      let price = 0; // 初始化 price 为 0
      this.dataDetail.productList.forEach(item => {
        price += item.skuSalePrice * item.skuAllQty;
      });
      return isNaN(price) ? 0 : price;
    }
  },
  data() {
    return {
      //退款订单编号
      refundOrderId: "",
      customerSearchKey: "",
      dataDetail: {
        productList: [],
        qty: 0,
        skuSalePrice: 0,
        amountDiscount: 0,
        amountTotal: 0,
      },
      // 被选中的客户
      selectedCustomerList: [],
      // 被选中的商品
      selectedProductList: [],
      // 快捷方式
      shortCutDetailList: [],
      // 显示改价
      showUpdatePrice: false,
      // 显示改价
      showUpdatePrice2: false,
      // 当前操作的数据，改价
      currentProduct: {},
      // 离线订单，挂单列表
      showOfflineOrders: false,
      // 离线订单，挂单列表
      offlineOrderList: [],
      // 选中的挂单订单
      currentOfflineOrder: {},
      // 快捷菜单新增
      showSetting: false,
      // 显示信息选择
      showSelectProduct: false,
      // 职员
      employeeList: [],
      // 仓库
      stockList: [],
      // 部门
      departList: [],
      // 基础设置
      showBasicSetting: false,
      // 商品类目
      productCategoryList: [],
      // 展示退单
      showRefund: false,
      // 规格信息
      attributeInfo: {
        attributeList: [],
      },
      // 刷新弹窗
      refreshDialog: null,
      // 存放sku规格，商品规格结果的map
      resultMap: new Map(),
      // 退单结算
      showSubmitRefund: false,
      dataList: [],
      searchInfo: {},
      payTypeList: [],
      checkAmountTotal: 0,
      // 选择的退款账户集合
      accountRefundList: [],
      // 展示结算
      showOrder: false,
      disableToClickPay: false,
      isRefreshPay: false,
      preparePay: false,
      fullscreenLoading: false,
      isAutoPrint: false,
      timerToPayResult: null,
      // 判断是否是直接退款
      isActual: false,
      // 判断是退款结算，还是支付结算
      isRefund: false,
      payType: [],
      myTimer: null,
    };
  },
  created() {
    systemPayApi.queryAllList().then(res => this.payType = res);
    commonApi.queryAllEmployee().then(res=>this.employeeList = res);
    // 退款方式
    systemPayApi.queryAllList().then(res => this.payTypeList = res);
    // 初始化时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 初始化快捷菜单
    this.initShortCut();
    // 商品字典分类-树形
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.productCategoryList = list);
    // 仓库列表
    stockApi.queryListByNotCondition().then(list => this.stockList = list);
    // 部门 列表
    commonApi.queryDepartList().then(list => {
      this.departList = list;
      // 根据当前账号设置默认的部门
      util.getUserInfo().then(userRes => {
        this.dataDetail.departCode = userRes.mainDepartCode;
        this.changeDepart(this.dataDetail.departCode);
      })
    });
    // 校验是否有用户id
    if(!this.dataDetail.customerId || this.dataDetail.customerId === ""){
      // 设置为默认客户
      this.dataDetail.customerId = "10000"
    }
  },
  mounted() {
  },
  unmounted() {
    clearInterval(this.myTimer);
  },
  methods: {
    // 退款相关
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryAllOrder();
    },
    // 退款提交
    submitRefund() {
      this.handlerAccountDraft();
      this.dataDetail.amount = Number(this.dataDetail.amount);
      // 检查退款金额
      // let total = 0;
      let jhTotal = 0; // 聚合支付金额
      for (let i = 0; i < this.dataDetail.payList.length; i++) {
        // total += Number(this.dataDetail.payList[i].refundCurrent);
        if (this.dataDetail.payList[i].payType !== 1) {
          jhTotal += Number(this.dataDetail.payList[i].refundCurrent);
        }
      }
      if (jhTotal > this.checkAmountTotal) {
        this.$message.error("聚合退款的金额不能大于支付金额, 聚合支付金额为：" + this.checkAmountTotal);
        return;
      }
      // if (total > this.dataDetail.amount) {
      //   this.$message.error("请检查退款金额！！！");
      //   return;
      // }
      //检查是否选择了商品
      if (this.dataDetail.productList.length === 0) {
        this.$message.error("请选择商品");
        return;
      }
      if (this.dataDetail.qty === 0) {
        this.$message.error("请添加退货商品");
        return;
      }
      if (this.dataDetail.employeeId == null) {
        this.$message.error("请添加经办职员");
        return;
      }
      this.preparePay = true;
      this.disableToClickPay = true;
      this.handleData();
      this.isActual = false;
      RefundOrderApi.saveDraft(this.dataDetail).then(data => {
        this.findRefundById(data.id, true).then(() => {
          this.toSubmit();
        })
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      })
    },
    //数据处理
    handleData() {
      //设置退货单的来源单据
      if (this.dataDetail.orderType === 1) {
        this.dataDetail.originalId = this.dataDetail.id;
      }
      this.handlerAccountDraft();
    },
    // 开始退款
    toSubmit() {
      this.isActual = true;
      RefundOrderApi.refund(this.dataDetail.id).then(res => {
        this.isRefund = true;
        this.preparePay = false;
        this.disableToClickPay = false;
        if (res === "退款失败") {
          this.$message.error(res);
        } else {
          this.$message.success(res);
          if (this.isAutoPrint) {
            this.toRefundPrint();
            this.$routerUtil.push("/sale/order/detail/new");
          } else {
            this.$routerUtil.push("/sale/order/detail/new");
          }
        }
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      });
    },
    // 退款回显
    findRefundById(id, isEdit = false) {
      return RefundOrderApi.findRefundById(id).then(data => {
        this.dataDetail = data;
        this.dataDetail.echoStockId = data.stockId;
        // 过滤掉支付记录未成功的记录
        this.dataDetail.payList = data.payList.filter(item => item.payStatus >= 50);
        this.changeDepart(this.dataDetail.departCode);
        if (data.status === 99) {
          this.showEditBtn = false;
        }
        // 计算已退款金额
        if (!isEdit) {
          this.calcAlreadyRefundAmount();
        }
        //初始化退款数量;
        this.updateOrderData();
        // // 初始化账户
        this.accountRefundList = [];
        this.handlerRefundAccount();

      });
    },
    // 计算已退款金额
    calcAlreadyRefundAmount() {
      for (let i = 0; i < this.dataDetail.payList.length; i++) {
        // 已退款金额
        this.alreadyRefundAmount += this.dataDetail.payList[i].refundTotal;
        // 累加聚合金额
        if (this.dataDetail.payList[i].payType !== 1) {
          this.checkAmountTotal += Number(this.dataDetail.payList[i].currentAmount);
        }
      }
    },
    // 展开退款结算前，初始化数据
    openToRefund() {
      if (this.accountRefundList.length === 0) {
        this.accountRefundList.push({payTypeId: "20240515185359769211", refundCurrent: this.dataDetail.amount});
      }
    },
    // 添加一条退款方式
    addAccount() {
      this.accountRefundList.push({payTypeId: "", refundCurrent: 0});
    },
    // 删除一条退款方式
    reduceAccount(index) {
      this.accountRefundList.splice(index, 1)
    },
    // 处理退货款账户
    handlerRefundAccount() {
      this.$nextTick(() => {
        let list = this.dataDetail.payList;
        for (let i = 0; i < list.length; i++) {
          if (list[i].refundCurrent && list[i].refundCurrent > 0) {
            this.accountRefundList.push({...list[i]});
          }
        }
      })
    },
    // 处理退款账户
    handlerAccountDraft() {
      // 最终上传后端的数据
      let acutalAccount = this.dataDetail.payList;
      // 客户id
      const customerId = acutalAccount[0].customerId;
      // 商户id
      const merchantId = acutalAccount[0].merchantId;
      // 订单id
      const orderId = this.dataDetail.originalId;
      // 退款的支付方式及金额
      let refundAccount = this.accountRefundList;
      // 将相同的支付方式的数据合并，放入到map中，保证payTypeId唯一
      let myAccountMap = new Map();
      // 过滤掉退款金额等于0的数据
      refundAccount = refundAccount.filter(item => {
        return Number(item.refundCurrent) !== 0 && item.payTypeId;
      })
      for (let i = 0; i < refundAccount.length; i++) {
        // 校验map中是否已存在当前支付方式
        if (myAccountMap.has(refundAccount[i].payTypeId)) {
          let account = myAccountMap.get(refundAccount[i].payTypeId);
          account.refundCurrent = Number(account.refundCurrent) + Number(refundAccount[i].refundCurrent);
          myAccountMap.set(refundAccount[i].payTypeId, account);
          // 移除账户数据中的重复账户
          refundAccount.splice(i, 1);
          // i--;
        } else {
          myAccountMap.set(refundAccount[i].payTypeId, refundAccount[i]);
        }
      }
      // 将账户数据与原始数据进行整合
      for (let i = 0; i < acutalAccount.length; i++) {
        let account = myAccountMap.get(acutalAccount[i].payTypeId);
        if (account) {
          acutalAccount[i].refundCurrent = account.refundCurrent;
          // 移除
          myAccountMap.delete(acutalAccount[i].payTypeId)
        } else {
          acutalAccount.splice(i, 1);
          i--;
        }
      }
      // 将未找到原始数据的账户直接添加到原始数据中
      for (const value of myAccountMap.values()) {
        let data = {
          payTypeId: value.payTypeId,
          refundCurrent: value.refundCurrent,
          orderId: orderId,
          payTime: new Date(),
          payStatus: 99,
          amount: 0,
          currentAmount: 0,
        }
        data.customerId = customerId;
        data.merchantId = merchantId;
        this.payTypeList.forEach(item => {
          if (value.payTypeId === item.id) {
            data.payType = item.payCode;
          }
        })
        acutalAccount.push(data)
      }

      this.dataDetail.payList = acutalAccount;

    },
    //查询所有已完成订单
    queryAllOrder() {
      //获取客户id
      this.searchInfo.orderStatus = 99;  //查询已完成支付的订单
      this.searchInfo.payStatus = 99;
      this.searchInfo.orderType = 1;
      offlineOrderApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 检查是否有退款草噶
    checkHasRefund(id) {
      offlineOrderApi.findHasRefundOrder(id).then(res => {
        if (res) {
          this.$message.error("该订单有尚未处理的退货单，请先处理！")
          throw new Error("该订单已存在退款单，请先删除该退款单");
        }
      })
    },
    // 选择要退货的订单
    addRefund(row) {
      // 修改当前为退单状态
      this.isRefund = true;
      this.checkHasRefund(row.id);
      RefundOrderApi.findRefundById(row.id).then(data => {
        // 调入订单并初始化当前订单为一个草稿状态
        this.originalDetail = data;
        //初始化退款数量;
        let arr = [];
        for (let i = 0; i < data.productList.length; i++) {
          let p = data.productList[i];
          if (p.qty === p.refundQty) continue;
          arr.push({
            ...p,
            payStatus: 0,
            qty: p.skuAllQty - p.refundQty,
            allQty: p.qty - p.refundQty,
            // 初始退款价、默认为销售价格
            skuRefundPrice: p.skuSalePrice,
            id: "",
            orderId: "",
            payType: data.payType,
            payTypeId: data.payTypeId,
          })
        }
        this.dataDetail = {
          productList: arr,
          customerId: data.customerId,
          customerName: data.customerName,
          customerPhone: data.customerPhone,
          employeeId: data.employeeId,
          stockId: data.stockId,
          departCode: data.departCode,
          payTypeId: data.payTypeId,
          payType: data.payType,
          originalId: data.id,
          amount: data.amount,
          amountDiscountZero: data.amountDiscountZero,
          status: 0,
        }
        // 处理支付记录数据，排除掉支付未成功的支付记录
        // payList: data.payList,
        this.dataDetail.payList = data.payList.filter(item => item.payStatus >= 50);
        // 设置默认的经手人和制单人
        util.getUserInfo().then(res => {
          this.dataDetail.employeeName = res.employeeName
          this.dataDetail.createdName = res.employeeName
        })

        // 设置订单时间为当前时间
        this.dataDetail.orderTime = new Date().format("yyyy-MM-dd hh:mm:ss")

        // 变更订单金额
        this.updateOrderData();

        // // 计算已退款金额
        this.calcAlreadyRefundAmount();

        this.changeDepart(this.dataDetail.departCode);

        // // 处理账户
        // this.handlerRefundAccount();

      });
      this.showRefund = false;
    },
    // 支付相关
    changePay(data) {
      this.dataDetail.payTypeId = data.id;
      this.dataDetail.payType = data.payCode;
    },
    // 打开dialog前设置金额信息
    setAmount() {
      this.dataDetail.amountShould = 0;
      this.dataDetail.amountCollect = this.dataDetail.amount;
      if (!this.dataDetail.payType) {
        this.dataDetail.payType = 2;
        this.dataDetail.payTypeId = '20240515191223949211';
      }
    },
    // 结算
    toBalance() {
      if (!this.isRefund) {
        if (!this.dataDetail.productList || this.dataDetail.productList.length === 0) {
          this.$message.error("请添加商品");
          return;
        }
        if (this.dataDetail.payStatus === 99) {
          this.$message.error("该订单无法再次结算");
          return;
        }
        this.showOrder = true;
      } else {
        this.showSubmitRefund = true;
      }
    },
    // 获取支付结果
    toFindPayStatus() {
      if (this.isRefreshPay) this.$message.warning("正在努力获取结果，请耐心等待！！！")
      this.isRefreshPay = true;
      offlineOrderApi.findPayStatus(this.dataDetail.id,this.dataDetail.payId).then(res => {
        this.findById(this.dataDetail.id).then(() => {
          if (this.dataDetail.payStatus >= 50) {
            this.successResult();
            this.isDraftUpdate = true;
            this.showEditBtn = true;
            this.currentAmountCollect = this.dataDetail.amountCurrentToPrint;
            // 判断是否需要进行打印
            if (this.isAutoPrint) {
              this.toSalePrint();
              selfFunction.createAudio(require("@/assets/video/result.mp3")).then(() => {
                this.$routerUtil.push("/sale/order/detail/new");
              })
            } else {
              selfFunction.createAudio(require("@/assets/video/result.mp3")).then(() => {
                this.$routerUtil.push("/sale/order/detail/new");
              })
            }
            this.loading.close();
            this.$message.success(res);
            clearTimeout(this.timerToPayResult);
            return;
          }
          if (this.dataDetail.payStatus === 30) {
            this.successResult();
            this.$message.error(res);
            this.loading.close();
            clearTimeout(this.timerToPayResult);
            return;
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          this.loading.close();
          clearTimeout(this.timerToPayResult);
          return;
        } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
          // this.$message.warning("正在努力获取结果,请您耐心等待...");
        }
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus();
        }, 2000);
        this.isRefreshPay = false;
      }).catch(() => {
        this.loading.close();
        this.isRefreshPay = false;
      })
    },
    // 支付
    pay(data) {
      // 赋值
      let myCount = 60;
      this.myTimer = setInterval(()=>{
        if(myCount > 0){
          myCount--;
          this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
        }else{
          clearInterval(this.myTimer);
          clearTimeout(this.timerToPayResult);
          this.loading.close();
        }
      },1000)
      this.loading = ElLoading.service({
        lock: true,
        text: `获取支付结果中，${myCount}秒后自动更新...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.isPaying = true;
      this.isActual = true;
      this.currentAmountCollect = data.amountCollect;
      this.dataDetail.amountCollect = data.amountCollect;
      this.dataDetail.qrCode = data.qrCode;
      // 收费标签
      this.dataDetail.paymentType = 2;
      // 锁定操作状态
      if (this.preparePay) this.$message.error("正在支付中，请稍等！！！");
      this.preparePay = true;
      this.disableToClickPay = true;
      offlineOrderApi.preparePay(this.dataDetail).then(saleOrder => {
        this.dataDetail = saleOrder;
        // 刷新数据
        this.findById(this.dataDetail.id).then(() => {
          if (this.isAutoPrint && this.dataDetail.payStatus >= 50) {
            this.toSalePrint();
            this.successResult();
            this.loading.close();
            selfFunction.createAudio(require("@/assets/video/result.mp3")).then(() => {
              this.$routerUtil.push("/sale/order/detail/new");
            })

            this.$message.success("支付成功");
          } else if (this.dataDetail.payStatus >= 50 || this.dataDetail.payStatus >= 50) {
            this.successResult();
            selfFunction.createAudio(require("@/assets/video/result.mp3")).then(() => {
              this.$routerUtil.push("/sale/order/detail/new");
            });
            this.$message.success("支付成功");
            this.loading.close();
          } else {
            // this.$message.warning("支付中，请稍后刷新支付状态！！！")
            this.toFindPayStatus();
          }
        });
        //准备支付
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
        this.loading.close();
        this.isPaying = false;
      })
    },
    // 成功时修改状态
    successResult() {
      this.showOrder = false;
      this.isPaying = false;
      this.disableToClickPay = false;
      this.preparePay = false;
    },
    // 根据id回显数据
    findById(id) {
      return offlineOrderApi.findById(id).then(data => {
        this.dataDetail = data;
        if (this.dataDetail.amountCurrent && this.dataDetail.amountCurrent === 0) {
          this.isActual = true;
        }
        if (this.dataDetail.amountCurrentToPrint && this.dataDetail.amountCurrentToPrint > 0) {
          this.currentAmountCollect = this.dataDetail.amountCurrentToPrint;
          this.isActual = true;
        }
        if (this.$routerUtil.query.id) {
          this.dataDetail.echoStockId = data.stockId;
          this.changeDepart(this.dataDetail.departCode);
          this.showEditBtn = (data.status !== 99 || data.payStatus === 50);
        }
      });
    },
    // 提交本次的支付金额
    batchSubmit(data) {
      // this.handlerProduct();
      // 保存
      this.isActual = false;
      this.preparePay = true;
      this.disableToClickPay = true;
      this.currentAmountCollect = data.amountCollect;
      this.dataDetail.amountCurrent = data.amountCollect;
      this.dataDetail.status = 50; // 设置为未完成
      this.dataDetail.payStatus = 0;
      this.dataDetail.amountCollect = null;
      offlineOrderApi.saveDraft(this.dataDetail).then(res => {
        this.findById(res.id).then(() => {
          this.currentAmountCollect = this.dataDetail.amountCurrent;
          this.preparePay = false;
          this.disableToClickPay = false;
          this.showOrder = false;
          if (this.isAutoPrint) {
            this.toSubmitSalePrint();
          }
        })
      });
    },
    // 部门仓库
    changeDepart(data) {
      // 变更部门时，清空仓库
      this.dataDetail.stockId = "";
      // 根据code获得部门id
      let dep = this.findDepartByCode(data, this.departList);
      if (dep.id) {
        // 部门有效
        this.queryAllStockList2(dep.id)
      }
    },
    // 根据code获取部门
    findDepartByCode(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].departCode) {
          return list[i];
        }
        if (list[i].children && list[i].children.length > 0) {
          let dep = this.findDepartByCode(code, list[i].children);
          if (dep.id) {
            /// 找到数据
            return dep;
          }
        }
      }
      return {};
    },
    // 仓库查询
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.createdName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.customerId = "10000";
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2(user.mainDepartId)
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      offlineOrderApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 默认选中一个
        if (list.length > 0) {
          this.dataDetail.stockId = list[0].id;
        }
      });
    },
    // 打印
    async toSalePrint() {
      // 组装打印数据
      // 客户名称+手机号
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.salePrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'),"收费凭证");
      localPrint.print("收费凭证",data);
    },
    // 打印
    async toSubmitSalePrint() {
      // 组装打印数据
      // 客户名称+手机号
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.salePrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'),"销售凭证");
      localPrint.print("销售凭证",data);
    },
    // 打印数据
    salePrintData(type,customerName,customerPhone,name){
      let data = {
        reportName: name,
        amount: this.dataDetail.amount,
        amountActual: this.isActual ? this.currentAmountCollect : 0,
        amountCollect: this.currentAmountCollect, // 需要打印本次支付的金额
        amountShould: this.dataDetail.amountShould,
        discountAmount: this.dataDetail.amountDiscount,
        amountAlreadyReceived: this.dataDetail.amountCollect,
        customerName: customerName,
        customerPhone: customerPhone,
        orderNumber: this.dataDetail.id,
        orderTime: this.dataDetail.orderTime,
        employeeName: this.dataDetail.employeeName,
        employeeId: this.dataDetail.employeeId,
        createdName: this.dataDetail.createdName,
        countQty: this.dataDetail.qty,
        amountTotal: this.dataDetail.amountTotal,
        status: this.dataDetail.status === 0 ? '草稿' : '待支付',
        payStatus: this.dataDetail.payStatus === 0 ? '未支付' : '已支付',
        excuteType: type,
      }
      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      //仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.dataDetail.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
      }
      // 支付状态
      if (this.dataDetail.payStatus === 0) {
        data.payStatus = "未支付";
      } else if (this.dataDetail.payStatus === 99) {
        data.payStatus = "已支付";
      } else if (this.dataDetail.payStatus === 30) {
        data.payStatus = "支付失败";
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.dataDetail.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }
      let arr = [];
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if(this.dataDetail.productList[i].qty === 0){
          continue;
        }
        let p = this.dataDetail.productList[i];
        arr.push({
          productIndex: i + 1,
          productName: p.skuName,
          productNumber: p.barCode,
          productPrice: p.skuOriginalPrice, //商品原价
          productCount: p.qty,
          productTotalPrice: p.qty * p.skuSalePrice,
          productDisCount: p.rate,
          productUnitName: p.skuUnitName,
          productSalePrice: p.skuSalePrice, //售价
        })
      }
      data.productList = arr;
      return data;
    },
    // 退货打印
    async toRefundPrint(){
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.refundPrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      localPrint.print("退费凭证",data);
    },
    // 退货打印数据
    refundPrintData(type,customerName,customerPhone){
      let data = {
        reportName: "销售退单",
        amount: this.dataDetail.amount,
        refundActual: this.isActual ? this.dataDetail.amount : 0,
        discountAmount: this.dataDetail.amountDiscount,
        customerName: customerName,
        customerPhone: customerPhone,
        orderNumber: this.dataDetail.id,
        orderTime: this.dataDetail.orderTime,
        // employeeName: this.dataDetail.employeeName,
        employeeId: this.dataDetail.employeeId,
        createdName: this.dataDetail.createdName,
        countQty: this.dataDetail.qty,
        amountTotal: this.dataDetail.amountTotal,
        status: this.dataDetail.status === 0 ? '草稿' : '已支付',
        excuteType: type,
      }

      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      // 支付状态
      if (this.dataDetail.payStatus === 0) {
        data.payStatus = '未退款';
      } else {
        data.payStatus = '已退款';
      }
      //仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.dataDetail.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.dataDetail.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }

      let payMap = new Map();
      this.payTypeList.forEach(item => payMap.set(item.id, item.payName));
      let payStr = '';
      this.dataDetail.payList.forEach(item => {
        if ((item.refundCurrent === 0 || !item.refundCurrent)) {
          if(item.refundTotal > 0){
            payStr += payMap.get(item.payTypeId) + ':         '  + item.refundTotal + '元' +'\n';
          }
        } else {
          payStr += payMap.get(item.payTypeId) + ':         ' + item.refundCurrent + '元'+'\n';
        }
      })
      data.payTypeName = payStr;
      let arr = [];
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        let p = this.dataDetail.productList[i];
        arr.push({
          productIndex: i + 1,
          productName: p.skuName,
          productNumber: p.barCode,
          productPrice: p.skuOriginalPrice, //商品原价
          productCount: p.qty,
          productTotalPrice: p.qty * p.skuSalePrice,
          productDisCount: p.rate,
          productUnitName: p.skuUnitName,
          productSalePrice: p.skuSalePrice, //售价
        })
      }

      data.productList = arr;
      return data;
    },
    // 提交
    submit() {
      // 通过属性信息，拿到具体sku信息
      let resultAttrMap = new Map();
      for (let i = 0; i < this.attributeInfo.attributeList.length; i++) {
        if (this.attributeInfo.attributeList[i].myOption !== "") {
          resultAttrMap.set(this.attributeInfo.attributeList[i].id + this.attributeInfo.attributeList[i].myOption, this.attributeInfo.attributeList[i].myOption)
        }
      }
      // sku的属性及id
      let skuMap = this.resultMap.get("sku");
      for (let i = 0; i < skuMap.length; i++) {
        let result = true;
        for (let j = 0; j < skuMap[i].skuAttributes.length; j++) {
          if (!resultAttrMap.has(skuMap[i].skuAttributes[j].id + skuMap[i].skuAttributes[j].value)
              || skuMap[i].skuAttributes.length !== resultAttrMap.size) {
            result = false;
            break;
          }
        }
        if (result) {
          // skuId
          this.submitSkuInfo(skuMap[i].skuId);
          this.showSelectProduct = false;
          return;
        }
      }
      this.$message.error("该属性暂无对应的sku信息，请先添加！")
    },
    // 添加最终sku信息
    submitSkuInfo(skuId) {
      // 根据id找到对应的sku
      let sku = this.attributeInfo.skuList.find(item => item.id === skuId);
      // 设置金额信息
      sku.skuSalePrice = this.attributeInfo.skuSalePrice;
      sku.qty = this.attributeInfo.qty;
      // 添加到列表中
      this.selectProductList([{...sku}]);
    },
    getSaleInfo(data){
      this.attributeInfo.skuSalePrice = data.skuSalePrice;
      this.attributeInfo.qty = data.qty;
      this.submit();
    },
    // 数组比较 ----小程序适用、标记后面CV
    isSubsetOfObjects(array1, array2) {
      // 先确保array1是较小的数组，array2是较大的数组
      if (array1.length > array2.length) {
        [array1, array2] = [array2, array1];
      }

      // 将对象转换为字符串并创建一个字符串集合
      const stringifiedSet1 = new Set(array1.map(JSON.stringify));

      // 创建array2的字符串集合
      const stringifiedSet2 = new Set(array2.map(JSON.stringify));

      // 检查stringifiedSet1中的所有元素是否都在stringifiedSet2中
      return [...stringifiedSet1].every(item => stringifiedSet2.has(item));
    },
    // 单选框改变 ----小程序适用、标记后面CV
    radioChange(data) {
      // 获取以sku分组的属性列表，并且包含选中的信息
      let mySkuArr = this.resultMap.get("sku");
      let myCheckMap = new Map();
      // 获取已选中的属性
      let mySelect = [];
      for (let i = 0; i < this.attributeInfo.attributeList.length; i++) {
        if (this.attributeInfo.attributeList[i].myOption !== "") {
          mySelect.push({
            id: this.attributeInfo.attributeList[i].id,
            value: this.attributeInfo.attributeList[i].myOption
          });
        }
      }
      for (let i = 0; i < mySkuArr.length; i++) {
        for (let j = 0; j < mySkuArr[i].skuAttributes.length; j++) {
          if(this.isSubsetOfObjects(mySelect, mySkuArr[i].skuAttributes)){
            mySkuArr[i].skuAttributes.forEach(item => {
              myCheckMap.set(item.id + item.value, item.value);
            })
          }
        }
      }

      if (myCheckMap.length === 0) {
        return;
      }
      // 遍历原信息，重新赋值是否禁用
      for (let i = 0; i < this.attributeInfo.attributeList.length; i++) {
        for (let j = 0; j < this.attributeInfo.attributeList[i].optionList.length; j++) {
          // // 同一行的，直接略过不作校验
          if(this.attributeInfo.attributeList[i].id === data.id){
            continue;
          }
          if (myCheckMap.has(this.attributeInfo.attributeList[i].id + this.attributeInfo.attributeList[i].optionList[j].code)) {
            this.attributeInfo.attributeList[i].optionList[j].isDisabled = false;
          } else {
            this.attributeInfo.attributeList[i].optionList[j].isDisabled = true;
          }
        }
      }
    },
    // 重复点击单选框，取消选中
    opClick() {
      this.productInfo(this.refreshDialog);
    },
    // 点击快捷菜单添加商品
    toAddProduct(row) {
      // 改变选中快捷菜单的背景色
      this.changeBgColor(row.id);
      // 判断当前是商品还是sku
      if (row.dataType === '1') {
        // 商品分类
        this.categoryInfo(row);
      } else if (row.dataType === '2') {
        // 商品列表
        this.productInfo(row);
      } else {
        // sku信息、直接添加到列表中去
        this.selectProductList([{...row.productSkuDto}]);
        this.selectedProductList = [];
      }
    },
    // 分类
    categoryInfo(row) {
      if (row.showType === 1) {
        // sku展示
        productApi.querySkuByCode(row.dataId, this.dataDetail.stockId).then(res => {
          this.selectedProductList = res;
        })
      } else if (row.showType === 2) {
        // 商品展示
        productApi.queryProductByCode(row.dataId, this.dataDetail.stockId).then(res => {
          this.selectedProductList = res;
        })
      }
    },
    // 商品
    productInfo(row) {
      this.refreshDialog = row;
      if (row.showType === 1) {
        // sku展示
        this.selectedProductList = [...row.productDto.skuList];
      } else if (row.showType === 2) {
        // 展示参数
        this.attributeInfo = {
          id: row.productDto.id,
          skuName: row.productDto.productName,
          skuUnitName: row.productDto.productUnitName,
          skuUnitId: row.productDto.productUnitId,
          skuOriginalPrice: row.productDto.basePrice,
          skuSalePrice: row.productDto.basePrice,
          qty: 1,
          skuList: row.productDto.skuList,
        }
        // 数据处理出错，可能是没有规格等信息，直接添加sku信息
        try{
          // debugger;
          // 处理弹窗数据
          this.handlerDialogData(row.productDto);
          // 处理sku
          this.handlerSku(row.productDto.skuList);
          // this.$set(this.attributeInfo,'attributeList',[...this.resultMap.get("product").attributeList]);
          this.attributeInfo.attributeList = [...this.resultMap.get("product").attributeList];
          // 弹窗展示
          this.showSelectProduct = true;
        }catch (e){
          console.log(e)
          this.$message.error("出现未知错误，请检查规格等信息，该商品将直接添加到列表中！！！")
          this.selectProductList([...row.productDto.skuList])
        }
      }
    },
    // 处理sku ----小程序适用、标记后面CV
    handlerSku(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let skuObj = {
          skuId: data[i].id,
          skuAttributes: JSON.parse(data[i].skuAttributes),
        }
        arr.push(skuObj);
      }
      this.resultMap.set("sku", arr);
    },
    // 处理弹窗数据 ----小程序适用、标记后面CV
    handlerDialogData(data) {
      // map根据id放入其中
      let skuAttrMap = new Map();
      for (let i = 0; i < data.skuList.length; i++) {
        // 解析规格json
        const attrArr = JSON.parse(data.skuList[i].skuAttributes);
        // 遍历sku规格数组
        for (let j = 0; j < attrArr.length; j++) {
          if (skuAttrMap.has(attrArr[j].id)) {
            let attr = skuAttrMap.get(attrArr[j].id);
            // 校验去重
            if (attr.find(item => item.value === attrArr[j].value)) {
              continue;
            }
            attr.push(attrArr[j])
            skuAttrMap.set(attrArr[j].id, attr);
          } else {
            skuAttrMap.set(attrArr[j].id, [attrArr[j]]);
          }
        }
      }
      this.resultMap.set("sku", skuAttrMap);
      // 如果map为空
      let proAttr = JSON.parse(data.attributeItems);
      if (skuAttrMap.size === 0) {
        for (let i = 0; i < proAttr.attributeList.length; i++) {
          for (let j = 0; j < proAttr.attributeList[i].optionList.length; j++) {
            proAttr.attributeList[i].optionList[j].isDisabled = true;
          }
          proAttr.attributeList[i].myOption = "";
        }
        this.resultMap.set("product", proAttr);
      }
      // map不为空，则找到不存在sku规格中的属性设置为禁用
      if (skuAttrMap.size > 0) {
        for (let i = 0; i < proAttr.attributeList.length; i++) {
          for (let j = 0; j < proAttr.attributeList[i].optionList.length; j++) {
            // 遍历map
            if (skuAttrMap.has(proAttr.attributeList[i].id)) {
              // 获取map中的值
              let attrList = skuAttrMap.get(proAttr.attributeList[i].id);
              // 遍历属性列表
              for (let k = 0; k < attrList.length; k++) {
                // 如果属性列表中的属性ID和map中的属性ID相等，则设置为禁用
                if (proAttr.attributeList[i].optionList[j].code === attrList[k].value) {
                  proAttr.attributeList[i].optionList[j].isDisabled = false;
                }
              }
            }
            // 如果不为false则为true
            if (proAttr.attributeList[i].optionList[j].isDisabled === undefined) {
              proAttr.attributeList[i].optionList[j].isDisabled = true;
            }
          }
          proAttr.attributeList[i].myOption = "";
        }
        this.resultMap.set("product", proAttr);
      }

    },
    // 赠送
    gift() {
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        this.dataDetail.productList[i].skuSalePrice = 0;
      }
      this.updateOrderData();
    },
    // 清除当前行商品
    deleteRow(skuId) {
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (skuId === this.dataDetail.productList[i].skuId) {
          this.dataDetail.productList.splice(i, 1);
          break;
        }
      }
      this.updateOrderData();
      this.showUpdatePrice = false;
    },
    // 改变中中的背景色
    changeBgColor(id) {
      for (let i = 0; i < this.shortCutDetailList.length; i++) {
        if (this.shortCutDetailList[i].id === id) {
          this.shortCutDetailList[i].isSelected = true;
        } else {
          this.shortCutDetailList[i].isSelected = false;
        }
      }
    },
    // 从列表添加信息
    toAddFromList(rowData) {
      if (rowData.skuName) {
        // sku
        this.selectProductList([{...rowData}]);
      } else {
        // 商品、设置为SKU展示
        this.productInfo({
          productDto: {...rowData},
          showType: 2
        });
      }
    },
    // 基础设置
    shorCutSetting() {
      this.showBasicSetting = true;
    },
    // 设置客户
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      this.dataDetail.customerPhone = data.customerPhone;
      this.dataDetail.customerAmount = data.customerAmount;
    },
    // 设置默认客户
    toSetDefaultCustomer() {
      this.setCustomer({})
    },
    // 选择商品
    selectProductList(list) {
      // 将当前改为提交结算状态
      this.isRefund = false;
      let arr = [...this.dataDetail.productList];
      // 有哪些商品，看是否存在，不存在就添加
      for (let i = 0; i < list.length; i++) {
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === list[i].id) {
            // 存在，更新数量
            contains = true
            break;
          }
        }
        // 不存在，添加
        if (!contains) {
          arr.push({
            skuId: list[i].id,
            skuName: list[i].skuName,
            skuCode: list[i].skuCode,
            barCode: list[i].barCode,
            skuUnitId: list[i].skuUnitId,
            qty: list[i].qty ? list[i].qty : 1,
            skuUnitName: list[i].skuUnitName,
            skuSalePrice: list[i].skuSalePrice,
            skuBuyPrice: list[i].skuBuyPrice,
            skuOriginalPrice: list[i].skuSalePrice,
          });
        } else {
          this.$message.warning("该商品已在列表中存在！")
        }
      }
      this.dataDetail.productList = arr;
      // 更新数据
      this.updateOrderData();
    },
    // 更新订单中的数据
    updateOrderData() {
      // 现金额，数量，原金额
      let amount = 0, qty = 0, originalAmount = 0;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        amount += this.dataDetail.productList[i].qty * this.dataDetail.productList[i].skuSalePrice;
        // 避免string参与计算
        qty += this.dataDetail.productList[i].qty * 1;
        originalAmount += this.dataDetail.productList[i].qty * this.dataDetail.productList[i].skuOriginalPrice;
      }
      this.dataDetail.qty = qty;
      this.dataDetail.amountTotal = originalAmount;
      this.dataDetail.amount = amount;
      this.dataDetail.amountDiscount = originalAmount - amount;
    },
    // 商品选中样式
    productClassName({row}) {
      return this.currentProduct.skuId === row.skuId ? "row-checked" : "";
    },
    // 点击列表，选中某一个商品
    checkSku(data) {
      this.currentProduct = {...data};
    },
    // 更新商品的价格
    updateProductPrice(data) {
      this.currentProduct = {...data};
      let arr = [...this.dataDetail.productList];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].skuId === data.skuId) {
          arr[i] = data;
        }
      }
      this.dataDetail.productList = arr;
      this.showUpdatePrice = false;
      // 更新数据
      this.updateOrderData();
    },
    // 订单离线，挂单，订单挂起
    offlineOrder() {
      // 加入离线订单,添加一个时间标记，倒序,创建一个临时id
      this.offlineOrderList = [
        {...this.dataDetail, orderTime: "11111", id: new Date().getTime()},
        ...this.offlineOrderList
      ];
      // 清空数据
      this.dataDetail = {
        productList: [],
      }
    },
    // 商品选中样式
    offlineOrderClassName({row}) {
      return this.currentOfflineOrder.id === row.id ? "row-checked" : "";
    },
    // 点击列表，选中某一个商品
    checkOfflineOrder(data) {
      this.currentOfflineOrder = {...data};
    },
    // 选择某个挂起的订单
    selectOfflineOrder() {
      // 删除这条数据
      this.dataDetail = this.deleteOfflineOrder();
    },
    // 删除挂起的订单
    deleteOfflineOrder() {
      let arr = [];
      for (let i = 0; i < this.offlineOrderList.length; i++) {
        if (this.offlineOrderList[i].id !== this.currentOfflineOrder.id) {
          arr.push(this.offlineOrderList[i]);
        }
      }
      this.offlineOrderList = arr;
      this.showOfflineOrders = false;
      return this.currentOfflineOrder;
    },
    // 清屏
    clearAll() {
      this.dataDetail.productList = [];
      this.updateOrderData();
    },
    // 快捷方式选择的数据
    selectData(data) {
      this.initShortCut({searchType: data});
    },
    // 快捷方式初始化
    initShortCut(data) {
      shortCutApi.queryAllInfo(data ? data : {searchType: 3}).then(res => {
        this.shortCutDetailList = res;
      })
    },
    // 快捷菜单向左移动
    shortCutToLeft() {
      if(this.shortCutDetailList.length === 0){
        this.$message.warning("当前暂无数据！");
        return;
      }
      let first = this.shortCutDetailList.splice(0, 1);
      this.shortCutDetailList.push(first[0]);
    },
    // 快捷菜单向右移动
    shortCutToRight() {
      if(this.shortCutDetailList.length === 0){
        this.$message.warning("当前暂无数据！");
        return;
      }
      let end = this.shortCutDetailList.splice(this.shortCutDetailList.length - 1, 1);
      this.shortCutDetailList.unshift(end[0]);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/table.scss";
@import "@/assets/css/components/dialog";
@import "@/assets/css/el-tables";
:deep(.mySecDialog .el-dialog__header) {
  padding: 15px;
  background: #f5f7fa;
}

.defaultViewStyle {
  border: none;
  background: none;
  padding: 0;
  user-select: none;

  .box-shadow {
    box-shadow: 0 2px 4px 0 #f1f1f1, 0 0 6px 0 #f1f1f1;
    border: 1px solid #dcdfe6;
    background: #FFF;
    border-radius: 6px;
    padding: 5px;
    box-sizing: border-box;
  }

  .option-div {
    height: 60px;
    background: #FFF;
    line-height: 40px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .separator-div {
    height: calc(100% - 75px);
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 45%;
      box-sizing: border-box;
    }

    .right {
      box-sizing: border-box;
      width: 55%;
    }

    .separator-top {
      height: 80px;
      box-sizing: border-box;

      .input-line {
        width: calc(100% - 70px);
        margin-right: 10px;

        :deep(.el-input-group__append) {
          color: #409eff;
        }
      }
    }

    .separator-body {
      height: calc(100% - 130px);
      box-sizing: border-box;
      overflow: auto;
      margin: 5px 0;
    }

    .separator-bottom {
      height: 40px;
      box-sizing: border-box;
      padding: 0 5px;
    }

    .separator-all {
      height: calc(100% - 85px);
      margin-top: 5px;
      box-sizing: border-box;
      overflow: auto;
      padding: 0 5px;

      .product-tag {
        display: inline-block;
        width: 25%;
        box-sizing: border-box;
        padding: 5px;

        .product-item {
          border: 1px solid #dedede;
          border-radius: 4px;
          padding: 5px;
          box-sizing: border-box;
          font-size: 12px;
          font-weight: 400;
          color: #666;
          box-shadow: 0 0 2px 1px #efebeb;

          .title {
            font-weight: 500;
            color: #333;
            font-size: 14px;
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .product-tag:nth-child(4n+1) {
        padding-left: 0;
      }

      .product-tag:nth-child(4n) {
        padding-right: 0;
      }
    }

    .custom-info {
      line-height: 40px;
      font-size: 14px;
      color: #666;
      display: flex;
      justify-content: space-between;
      padding-right: 100px;
    }

    .product-tags {
      position: relative;
      padding-left: 30px;
      padding-right: 10px;
      line-height: 40px;
      white-space: nowrap;
      overflow: hidden;
      height: 40px;

      .setting-all {
        position: absolute;
        left: 0;
        top: 0;
        line-height: 45px;
      }

      .setting-left {
        position: absolute;
        left: 33px;
        top: 0px;

        line-height: 45px;
        //transition: background-color 0.3s ease;
      }

      //.setting-left:hover {
      //  background-color: #f0f0f0; /* 举例：将背景色改为浅灰色，你可以替换为你需要的颜色 */
      //}

      .setting-right {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        line-height: 45px;
        //transition: background-color 0.3s ease;
      }

      //
      //.setting-right:hover {
      //  background-color: #f0f0f0; /* 举例：将背景色改为浅灰色，你可以替换为你需要的颜色 */
      //}

      .short-cut-container {
        width: 95%;
        overflow: hidden;
        margin-left: 20px;

        .short-cut {
          display: inline-block;
          box-sizing: border-box;
          margin: 0 3px 0 0;
          padding: 0 5px;
          border: 1px solid #dedede;
          background-color: white;
          color: #409eff;
          line-height: 28px;
          font-size: 12px;
          border-radius: 4px;
        }

        .short-cut-bgcolor {
          display: inline-block;
          box-sizing: border-box;
          margin: 0 3px 0 0;
          padding: 0 5px;
          border: 1px solid #dedede;
          background-color: #409eff;
          line-height: 28px;
          font-size: 12px;
          border-radius: 4px;
          color: white;
        }

        .short-cut:first-child {
          margin-left: 0;
        }

        .short-cut:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

:deep(.row-checked) {
  background: #409eff;
  color: #FFF;
}

:deep(.row-checked:hover>td.el-table__cell) {
  background: #409eff !important;
  color: #FFF;
}

:deep(.only-show) {
  font-size: 12px;
}

.radio-box {
  //display: inline-block;
  //align-items: center;
  display: flex;
  flex-direction: row;
}

:deep(.el-divider--horizontal) {
  margin: 10px 0 4px 0 !important;
}
</style>