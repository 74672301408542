import axios from "../../../../util/axios";

export default {
    // 列表查询信息
    queryList: (data) => axios.get({url: "/order/payment/queryList", data: data}),

    // 底部的统计信息
    countBottom: (data) => axios.get({url: "/order/payment/countBottom", data: data}),

    // 根据orderId查询
    queryByOrderId: (orderId) => axios.get({url: "/order/payment/queryByOrderId", data: {orderId}}),
}