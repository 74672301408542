<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <page-button el-type="primary" type="print" @click="exportData">导出</page-button>
        <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
      </template>
    </page-header>
    <list-page>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.categoryCode" :dictionaries="categoryList" dict-code="dictCode"
                        dict-name="dictName" :place-holder="'请选择商品分类'"
                        @clear-data="clearCode" style="width: 100%"/>
          </div>
          <el-input ref="orderNumberInput" v-model="searchInfo.skuName" placeholder="请输入商品名称" clearable @change="resetSearch"/>
        </template>
        <template #moreCondition>
          <el-select v-if="readAccess !== '1'" v-model="batchSearchInfo.stockId" multiple collapse-tags
                     collapse-tags-tooltip clearable
                     placeholder="请选择仓库" style="width: 240px">
            <el-option
                v-for="item in stockList"
                :key="item.id"
                :label="item.stockName"
                :value="item.id"
            />
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" show-summary :summary-method="getSummaries">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed="left"/>
          <el-table-column align="center" label="商品编号" prop="skuCode" show-overflow-tooltip min-width="150px" fixed="left" />
          <el-table-column align="center" label="仓库" prop="stockName" show-overflow-tooltip min-width="150px" fixed="left" />
          <el-table-column align="center" label="商品名称" prop="skuName" show-overflow-tooltip min-width="150px" fixed="left" />
          <el-table-column align="center" label="规格" prop="skuStandard" show-overflow-tooltip min-width="150px">
            <template v-slot="{row}">
              {{ row.skuStandard ? row.skuStandard : '-' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="型号" prop="skuType" show-overflow-tooltip min-width="150px">
            <template v-slot="{row}">
              {{ row.skuType ? row.skuType : '-' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="条码" prop="barCode" show-overflow-tooltip min-width="150px">
            <template v-slot="{row}">
              {{ row.barCode ? row.barCode : '-' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="基本单位" prop="unitName" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="供应商" prop="supplierName" show-overflow-tooltip min-width="130px"/>
          <el-table-column align="center" label="入库税率" prop="taxRate" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="采购含税成本单价" prop="buyPrice" show-overflow-tooltip min-width="150px" />
          <el-table-column align="center" label="采购未税成本单价" prop="taxExcludePrice" show-overflow-tooltip min-width="150px" />
          <el-table-column align="center" label="入库数量" prop="skuInQty" show-overflow-tooltip min-width="150px"/>
          <el-table-column align="center" label="入库含税金额" prop="skuInAmount" show-overflow-tooltip min-width="150px" />
          <el-table-column align="center" label="入库未税金额" prop="taxExcludeSkuInAmount" show-overflow-tooltip min-width="150px" />
          <el-table-column label="操作" :align="'center'" min-width="120px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="toShowDetail(row)">查看明细</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import stockStatisticApi from "@/api/main/stock/stockStatisticApi";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import util from "@/util/util";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import {h} from "vue";

export default {
  name: "StockRecord",
  components: {
    TableButton, FormPage, TableList,
    DateRange, SearchCondition,
    PageHeader, PageButton, ListPage, Dictionary
  },
  data() {
    return {
      // 查询条件
      searchInfo: {
        skuName: "",  // 传入详情页面的指定商品
      },
      // 批量查询
      batchSearchInfo: {},
      // 仓库列表
      stockList: [],
      // 表格数据列表
      dataList: [],
      // 商品分类字段
      categoryList: [],
      // 列表统计信息
      stockStatistic: {},
      // 是否查询空
      isSelectEmpty: false,
      // 设置读的权限值
      readAccess: null,
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    },
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
    // 仓库
    orderStatisticApi.queryStockList().then(res => {
      this.stockList = res;
    })
    // 时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  methods: {
    handlerBatchSearch() {
      if (this.batchSearchInfo.stockId && this.batchSearchInfo.stockId.length > 0) {
        this.searchInfo.stockId = this.batchSearchInfo.stockId.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
    },
    queryList() {
      // 处理批量查询的数据
      this.handlerBatchSearch();
      stockStatisticApi.queryCgList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
      stockStatisticApi.queryCgStatistic(this.searchInfo).then(res => {
        this.stockStatistic = res;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查看明细
    toShowDetail(row) {
      this.handlerBatchSearch();
      // 设置商品名称
      const currentSearch = {
        ...this.searchInfo,
        skuName: row.skuName,
        skuId: row.skuId,
      };
      const currentBatch = {
        stockId: [],
      }
      currentBatch.stockId.push(row.stockId);
      currentSearch.taxRate = row.taxRate;
      this.$routerUtil.push({
        path: "/plat/cgStockRecord/detail",
        query: {
          searchInfoStr: JSON.stringify(currentSearch),
          batchSearchInfoStr: JSON.stringify(currentBatch),
        },
      })
    },
    // 导出
    exportData() {
      this.handlerBatchSearch();
      util.exportExcel("/order/stock/statistic/exportCgList", this.searchInfo);
    },
    // 移除分类编码
    clearCode() {
      this.searchInfo.categoryCode = "";
    },
    // 统计
    getSummaries(param) {
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
        }
        if (this.stockStatistic[column.property]) {
          sums[index] = h('div', [Number(this.stockStatistic[column.property]).toFixed(2)])
        }
      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>