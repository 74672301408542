<template>
  <!-- 字典，显示时按字典的层级进行显示，级联选择 -->
  <div :class="disabled? 'only-show': ''">
    <!--    <span v-if="disabled">{{nameList.join("/")}}</span>-->
    <!--    <el-cascader v-else   collapse-tags-->
    <!--                 collapse-tags-tooltip  style="width: 100%;" :placeholder="disabled?'': placeHolder" clearable v-model="dicCode" filterable :props="props" :disabled="disabled" :options="dictionaryList" @change="changeCode" :size="size"/>-->
    <span v-if="disabled">{{ nameList.join("/") }}</span>
    <el-cascader v-else-if="isMultiple" collapse-tags collapse-tags-tooltip :max-collapse-tags="multipleCount" style="width: 100%;" :placeholder="disabled?'': placeHolder" clearable v-model="dicCode" filterable :props="props" :disabled="disabled" :options="dictionaryList" @change="changeCode" :size="size"/>
    <el-cascader v-else-if="!isMultiple" style="width: 100%;" :placeholder="disabled?'': placeHolder" clearable
                 v-model="dicCode" filterable :props="{checkStrictly:true}" :disabled="disabled"
                 :options="dictionaryList" @change="changeCode" :size="size"/>

  </div>
</template>

<script>
import {watch} from "vue";

export default {
  emits: ["changeData", "update:modelValue", "clearData"],
  name: "DicTionary",
  props: {
    modelValue: {
      type: String
    },
    // 字典
    dictionaries: {default: () => []},
    // 取的字段名，字典默认为dictCode
    dictCode: {default: "dictCode"},
    dictName: {default: "dictName"},
    disabled: {default: false},
    // 保存数据的url
    savedUrl: {default: ""},
    placeHolder: {default: "请选择"},
    size: {default: ""}, // 大小
    isMultiple: {default: false}, // 是否开启多选
    multipleCount: {default: 0} // 显示数量
  },
  components: {},
  data() {
    return {
      dicCode: [],
      nameList: [],
      dictionaryList: [{
        dictCode: "",
        dictName: ""
      }],
      props: {
        multiple: this.isMultiple,
        checkStrictlyL: true,
      }
    };
  },
  created() {
    // 把后台的字典转成el使用的格式
    this.dictionaryList = this.formatData(this.dictionaries);
    // 把后台的结果转成el使用的格式
    if (!this.isMultiple) this.transferData(this.modelValue);
    // 监听器, 监听dictionaries和value
    watch([() => this.dictionaries, () => this.modelValue], () => {
      this.dictionaryList = this.formatData(this.dictionaries);
      if (!this.isMultiple) {
        this.transferData(this.modelValue);
      }
    })
  },
  methods: {
    transferData(code) {
      this.dicCode = [];
      // 找到对应的数组
      this.dicCode = this.findData(code, this.dictionaryList);
      // 通过code数组找到文本
      let arr = [];
      for (let i = 0; i < this.dicCode.length; i++) {
        arr.push(this.findDataInfo(this.dicCode[i], this.dictionaryList));
      }
      this.nameList = arr;
    },
    findDataInfo(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].value) {
          return list[i].label;
        }
        // 有子节点
        if (list[i].children && list[i].children.length > 0) {
          let res = this.findDataInfo(code, list[i].children);
          if (res) {
            return res;
          }
        }
      }
      return "";
    },
    changeCode(data) {
      if (this.dicCode == null) {
        this.$emit("clearData");
        this.$emit("update:modelValue", null);
      }
      if (!this.isMultiple) {
        this.$emit('update:modelValue', this.dicCode[this.dicCode.length - 1]);
        this.$emit("changeData", this.dicCode[this.dicCode.length - 1]);
      } else {
        this.$emit("changeData", data);
      }
    },
    // 查找数据
    findData(code, arr) {
      let result = [];
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i].value) {
          // 找到数据了
          result.push(arr[i].value);
          return result;
        } else {
          // 有可能是它的子集
          if (arr[i].children && arr[i].children.length > 0) {
            let contains = this.findData(code, arr[i].children);
            if (contains.length > 0) {
              // 是它的子集，把当前节点放入到第一个
              result = [arr[i].value, ...contains];
              return result;
            }
          }
        }
      }
      // 没有找到数据
      return result;
    },
    // 格式化数据
    formatData(list) {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        let data = {value: list[i][this.dictCode], label: list[i][this.dictName]};
        if (list[i].children && list[i].children.length > 0) {
          // 有下级
          data.children = this.formatData(list[i].children);
        }
        arr.push(data);
      }
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-input.is-disabled) {
  .el-input__inner {
    border: none;
    background: none;
  }

  .el-input__suffix {
    display: none;
  }
}

.only-show :deep(.el-cascader) {
  line-height: 24px;

  .el-input__inner {
    height: 24px;
    padding: 0;
  }

  .el-input.is-disabled .el-input__wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: red;
  }
}
</style>