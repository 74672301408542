<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="$routerUtil.push(`/supplyChain/stock/purchase/detail`)">新建
        </page-button>
      </page-header>
      <search-condition>
        <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入单据编号"/>
        <el-select v-model="searchInfo.clientStockId" filterable clearable placeholder="请选择仓库">
          <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
        </el-select>
        <template #moreCondition>
          <div style="display: inline-block; width: 240px">
            <dictionary class="condition" v-model="searchInfo.clientDepartCode" :dictionaries="departList"
                        place-holder="请选择部门" dict-name="departName" dict-code="departCode"/>
          </div>
          <el-select v-model="searchInfo.status" filterable clearable placeholder="请选择订单状态">
            <el-option v-for="item in statusList" :key="item.value" :label="item.name" :value="item.code"/>
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <yq-table page-name="merchantPurchaseStock" stripe :data="dataList" height="100%">
          <yq-table-column label="订单时间" prop="orderTime" width="200" align="center"/>
          <yq-table-column align="center" prop="departCode" label="部门" min-width="150" show-overflow-tooltip>
            <template v-slot="{row}">
              11111
<!--              <dictionary :dictionaries="departList" :disabled="true" v-model="row.clientDepartCode"-->
<!--                          dict-code="departCode" dict-name="departName"/>-->
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="仓库" prop="stockId" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-for="(item, i) in stockList" :key="i"><span
                  v-if="item.id === row.clientStockId">{{ item.stockName }}</span></span>
            </template>
          </yq-table-column>
          <yq-table-column  align="center" label="业务人员" prop="clientEmployeeName" min-width="150px"
                           show-overflow-tooltip/>
          <yq-table-column align="center" prop="status" label="订单状态" width="160rem">
            <template v-slot="{row}">
              <span v-for="(item, i) in statusList" :key="i">
                <el-tag @click="toAlert(row)" :type="item.type" v-if="item.code === (row.status).toString()">{{ item.name }}</el-tag>
              </span>
            </template>
          </yq-table-column>
          <yq-table-column label="操作" prop="options" align="center" min-width="120rem" :required="true">
            <template v-slot="{row}">
              <table-button v-if="row.status < 10" :disabled="row.status > 0"
                            @click="$routerUtil.push(`/supplyChain/stock/purchase/detail?id=${row.id}`)">编辑
              </table-button>
              <table-button v-else @click="$routerUtil.push(`/supplyChain/stock/purchase/detail?id=${row.id}`)">查看
              </table-button>
              <table-button :disabled="row.status > 0" message="是否确认删除此数据" type="delete" el-type="danger">
                删除
              </table-button>
            </template>
          </yq-table-column>
        </yq-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import merchantPurchaseApi from "@/api/main/supplyChain/merchantPurchaseApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import {ElMessageBox} from "element-plus";
import YqTable from "@/components/h5/column/YqTable.vue";
import YqTableColumn from "@/components/h5/column/YqTableColumn.vue";
export default {
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader, DateRange, Dictionary, YqTable, YqTableColumn
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      stockList: [],
      departList: [],
      statusList: [],
    };
  },
  created() {
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    commonApi.queryAllStock().then(list => this.stockList = list);
    commonApi.queryDepartList().then(list => this.departList = list);
    merchantPurchaseApi.getAllStatus().then(list => {
      // 给list加上显示的样式
      for (let i = 0; i < list.length; i++) {
        // 完成
        if (list[i].code === '99') {
          list[i].type = "success";
        } else if (list[i].code === '0') {
          // 草稿
          list[i].type = "info";
        } else if (list[i].code === '10') {
          // 待确认
          list[i].type = "primary";
        } else if (list[i].code === '20') {
          // 已确认
          list[i].type = "warning";
        }else if (list[i].code === '11') {
          // 已确认
          list[i].type = "warning";
        }else if (list[i].code === '12') {
          // 已确认
          list[i].type = "warning";
        }else if (list[i].code === '13') {
          // 已确认
          list[i].type = "warning";
        }else if (list[i].code === '15') {
          // 已确认
          list[i].type = "warning";
        }
      }
      this.statusList = list
    });
  },
  methods: {
    toAlert(row){
      if(row.status !== 13){
        return;
      }
      commonApi.findApprovalReason(row.id).then(reasonMsg =>{
        if(reasonMsg === "" || reasonMsg === null){
          this.$message.error("无失败原因");
          return;
        }
        ElMessageBox.alert(reasonMsg, '失败原因', {
          confirmButtonText: '我已知晓',
        })
      })

    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      // 供应链采购订单
      merchantPurchaseApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>