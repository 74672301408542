<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header/>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="单据日期">
        <el-date-picker style="width: 100%"
                        :disabled="headInfo.status !== 0"
                        v-model="headInfo.createdTime"
                        type="datetime"
                        placeholder="选择日期时间"
        />
      </el-descriptions-item>
<!--      <el-descriptions-item label="盘点单类型">-->
<!--        <el-select v-model="headInfo.inventoryType" style="width: 100%">-->
<!--          <el-option v-for="item in inventoryType" :key="item.id" :label="item.label" :value="item.value"/>-->
<!--        </el-select>-->
<!--      </el-descriptions-item>-->
      <el-descriptions-item label="所属部门">
        <dictionary :disabled="headInfo.status !== 0" v-model="headInfo.departCode" @change-data="changeDepart" :dictionaries="departList"
                    dict-code="departCode"
                    dict-name="departName"/>
      </el-descriptions-item>
      <el-descriptions-item label="仓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;库">
        <el-select :disabled="headInfo.status !== 0" v-model="headInfo.stockId" style="width: 100%">
          <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="盘点人员">
        {{ headInfo.createdName }}
      </el-descriptions-item>
      <el-descriptions-item label="入库类型">
        <el-select :disabled="headInfo.status !== 0" v-model="headInfo.inCode" style="width: 100%">
          <el-option v-for="item in inList" :key="item.id" :label="item.name" :value="item.inoutCode"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="出库类型">
        <el-select :disabled="headInfo.status !== 0" v-model="headInfo.outCode" style="width: 100%">
          <el-option v-for="item in outList" :key="item.id" :label="item.name" :value="item.inoutCode"/>
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item label="备注" :span="2">
        <el-input :disabled="headInfo.status !== 0" v-model="headInfo.remark" type="textarea" :rows="1" class="el-input-text"/>
      </el-descriptions-item>
    </el-descriptions>
    <div style="display: flex; justify-content: flex-end;margin-bottom: 5px" v-if="headInfo.status === 0">
      <div style="width: 400px">
        <product-list-select :stock-type="true" :is-depart="true" :is-stock="true" :stock-id="headInfo.stockId" :query-list-api="queryListApi" :selected-product-list="headInfo.detailList" @select="selectProduct" @clear="deleteBatch"/>
      </div>
    </div>

    <list-page>
      <table-list>
        <el-table stripe :data="headInfo.detailList" height="100%" ref="finalTable">
          <el-table-column type="selection" width="55"/>
          <el-table-column align="center" type="index" label="序号" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="150px" show-overflow-tooltip/>
          <el-table-column align="center" prop="skuUnitName" label="基本单位" width="120px"/>
          <el-table-column align="center" prop="skuCode" label="商品条码" min-width="150px"/>
          <el-table-column v-if="headInfo.id" align="center" label="盘点类型" min-width="100px">
            <template v-slot="{row}">
              <el-tag type="primary" v-if="row.changeType === 1">{{ row.codeName }}</el-tag>
              <el-tag type="success" v-if="row.changeType === 0">正常</el-tag>
              <el-tag type="danger" v-if="row.changeType === -1">{{ row.codeName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="skuQty" label="库存数量" min-width="150px"/>
          <el-table-column align="center" label="盘点数量" width="200">
            <template v-slot="{row}">
              <el-input-number :disabled="headInfo.status !== 0" v-model="row.changeQty" min="0" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="变动数量">
            <template v-slot="{row}">
              <span v-if="row.changeQty - row.skuQty > 0" style="font-weight: bolder">+</span>
              <span>{{row.changeQty - row.skuQty}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="buyPrice" label="成本单价">
            <template v-slot="{row}">
              <el-input disabled v-model="row.buyPrice" type="number"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="成本金额" min-width="150px">
            <template v-slot="{row}">
              <span v-if="row.changeQty - row.skuQty > 0" style="font-weight: bolder; color: #6e0404">+</span>
              <span v-else style="font-weight: bolder;  color: #6e0404">-</span>
              <money disabled :value="Math.abs(row.changeQty - row.skuQty) * row.buyPrice"/>￥
            </template>
          </el-table-column>
          <el-table-column align="center" label="备注" min-width="200px">
            <template v-slot="{row}">
              <el-input v-model="row.remark" :disabled="headInfo.status !== 0" type="textarea" :rows="1" class="el-input-text"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" v-if="headInfo.status === 0">
            <template v-slot="{row,$index}">
              <table-button  type="danger" el-type="danger" @click="deletedetailList(row,$index)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>

    </list-page>
    <form-page :show-page="false">
      <page-button :disabled="headInfo.status !== 0" type="write" el-type="success" @click="saveDraft">保存草稿</page-button>
      <page-button :disabled="headInfo.status !== 0" type="write" el-type="primary" @click="saveSubmit">提交入库</page-button>
    </form-page>
  </div>
</template>
<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import elementElMessage from "@/util/elementElMessage";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import commonApi from "@/api/commonApi";
import Money from "@/components/h5/Money.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader";
import config from "@/config/config";
import stockInventoryApi from "@/api/main/stock/stockInventoryApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import stockInoutTypeApi from "@/api/main/stock/StockInoutTypeApi";
export default {
  computed: {
  },
  components: {
    FormPage,
    TableButton, PageButton,
    ListPage, TableList, ProductListSelect, Money, Dictionary, PageHeader
  },
  watch:{
    'headInfo.detailList':{
      handler(val){
        console.log(val);
      }
    }
  },
  data() {
    return {
      searchInfo: {},
      inventoryType: [],
      showSaveProduct: false,
      productList: [],  //该商户可以入库的所有商品
      skuList: [], //入库商品对应的所有sku
      stockList: [], //仓库列表
      stockProduct: {}, //入库商品信息
      headInfo: {
        remark: "", //备注
        createdName: "", //创建
        createdTime: new Date(), //时间
        stockId: "",  //选择仓库
        status: null,
        departCode: "",
        detailList: [], //需要入库的商品
        echoStockId: "",//回显仓库时使用的id
      },
      queryListApi: {},
      allUnits: [],
      // 是否开启仓库挂载部门功能
      stockDepartSwitch: false,
      // 部门
      departList: [],
      // 当前选中的商品
      currentProduct: {
        skuOriginalCode: "",
      },
      showOriginalDialog: false,
      skuOriginalList: [],
      inList: [],
      outList: [],
    };
  },
  created() {
    // 入库类型
    stockInoutTypeApi.queryAllTypeList(1).then(list => {
      // 这里不允许进行采购入库（ 采购入库有专属的逻辑信息 ）
      this.inList = list.filter(item => item.inoutCode !== "CGRK");
    });
    // 出库类型
    stockInoutTypeApi.queryAllTypeList(2).then(list => this.outList = list);
    this.inventoryType = config.inventory;
    // 查询单位
    commonApi.queryUnits().then(list => this.allUnits = list);

    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");

    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);

    this.queryListApi = stockInoutApi.queryProductAuthorizeList;

    if (this.$routerUtil.query.inoutStorage) {
      this.echoInoutProduct(this.$routerUtil.query.inoutStorage);
    } else {
      // 新建
      commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.headInfo.originalCodeType = setting.settingValue);
      util.getUserInfo().then(user => {
        this.headInfo.status = 0;
        this.headInfo.createdName = user.userName;
      })
      //获取仓库列表
      this.queryAllStockList();
    }

  },
  methods: {
    changeDepart(data) {
      // 变更部门时，清空仓库
      this.headInfo.stockId = "";
      // 根据code获得部门id
      let dep = this.findDepartByCode(data, this.departList);
      if (dep.id) {
        // 部门有效
        this.queryAllStockList2(dep.id)
      }
    },
    findDepartByCode(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].departCode) {
          return list[i];
        }
        if (list[i].children && list[i].children.length > 0) {
          let dep = this.findDepartByCode(code, list[i].children);
          if (dep.id) {
            /// 找到数据
            return dep;
          }
        }
      }
      return {};
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.headInfo.createdName = user.userName;
          this.headInfo.employeeId = user.id;
          this.headInfo.departCode = user.mainDepartCode;
          this.headInfo.customerId = "10000";
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2(user.mainDepartId)
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      offlineOrderApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 默认选中一个
        if (this.headInfo.echoStockId && this.headInfo.echoStockId !== '') {
          this.headInfo.stockId = this.headInfo.echoStockId;
          this.headInfo.echoStockId = "";
          return;
        }
        if (list.length > 0) {
          this.headInfo.stockId = list[0].id;
        }
      });
    },
    //商品信息回显
    echoInoutProduct(id) {
      //判断该页面是否有参数传入
      stockInoutApi.findByInOrderId(id).then(res => {
        for (let i = 0; i < res.detailList.length; i++) {
          if(res.detailList[i].changeType === 1){
            let idx = this.inList.findIndex(item => item.inoutCode === res.inCode);
            res.detailList[i].codeName = this.inList[idx].name;
            continue;
          }
          if(res.detailList[i].changeType === -1){
            let idx = this.outList.findIndex(item => item.inoutCode === res.outCode);
            res.detailList[i].codeName = this.outList[idx].name;
          }
        }
        this.headInfo = res;
        this.changeDepart(res.departCode);
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryProductList();
    },
    deleteBatch() {
      let rows = this.$refs.finalTable.getSelectionRows();
      for (let i = 0; i < rows.length; i++) {
        this.headInfo.detailList.splice(this.headInfo.detailList.indexOf(rows[i]), 1);
      }
    },
    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          // 部门
          this.headInfo.departId = this.stockList[i].bankupId;
          break;
        }
      }
    },
    deletedetailList(row, index) {
      this.headInfo.detailList.splice(index, 1);
    },
    // 处理包装数据
    handlerPackageData(){
      for (let i = 0; i < this.headInfo.detailList.length; i++) {

      }
    },
    saveDraft(){
      this.handlerPackageData();
      if (this.checkData()) {
        stockInventoryApi.saveDraft(this.headInfo).then(res => this.headInfo = res);
        this.$routerUtil.push("/stock/inventory");
      }
    },
    saveSubmit(){
      this.headInfo.status = 99;
      if (this.checkData()) {
        stockInventoryApi.saveSubmit(this.headInfo).then(res => {
          this.headInfo = res;
          this.$routerUtil.push("/stock/inventory");
        });
      }
    },
    checkData() {
      if (!this.headInfo.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      if (!this.headInfo.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (this.headInfo.detailList.length === 0) {
        elementElMessage.toShowMessage("盘点商品不能为空", "warning");
        return false;
      }
      return true;
    },
    // 商品组件选中商品后，回调显示
    selectProduct(productSkuList) {
      // 向detail中添加数据
      let arr = [...this.headInfo.detailList];
      console.log(arr)
      for (let i = 0; i < productSkuList.length; i++) {
        // 去重添加
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === productSkuList[i].id) {
            contains = true;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: productSkuList[i].id,
            // skuQty: productSkuList[i].qty,
            skuName: productSkuList[i].skuName,
            skuCode: productSkuList[i].skuCode,
            buyPrice: productSkuList[i].skuBuyPrice,
            salePrice: productSkuList[i].skuSalePrice,
            skuUnitId: productSkuList[i].skuUnitId,
            skuUnitName: productSkuList[i].skuUnitName,
            buyPricePackage: productSkuList[i].skuBuyPrice,
            salePricePackage: productSkuList[i].skuSalePrice,
            unitIdPackage: productSkuList[i].skuUnitId,
            skuQty: productSkuList[i].stockQty,
            changeQty: 1,
          });
        }
      }
      this.headInfo.detailList = arr;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description.scss";
@import "@/assets/css/el-tables";
.head-sty {
  display: flex;
  justify-content: space-between;
}
.el-input-text{
  :deep(textarea){
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>