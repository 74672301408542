<template>
  <!-- -->
  <div>
    <el-tooltip
        effect="dark"
        content="列配置"
        placement="top"
    >
      <div style="display: flex;align-items: center;justify-content: center">
        <table-button style="line-height: 10px" @click="showTableSetting = true">
          <el-icon size="17" color="#409EFF">
            <Setting/>
          </el-icon>
        </table-button>
      </div>
    </el-tooltip>
    <el-dialog title="列配置" width="700px" v-model="showTableSetting" :append-to-body="true">
      <dialog-table>
        <el-table :tree-props="treeProps" row-key="id" :data="settingList" border height="100%">
          <!--          <el-table-column align="center" type="index" label="序号" width="60px"/>-->
          <el-table-column align="center" label="列名" prop="name" width="180px"/>
          <el-table-column align="center" label="配置" min-width="200px">
            <template v-slot="{row}">
              <el-tooltip
                  v-if="row.isChange"
                  effect="light"
                  content="该字段禁止自定义配置！"
                  placement="left-start"
              >
                <el-switch active-text="开启" inactive-text="关闭" inline-prompt v-model="row.isChecked" style="--el-switch-on-color: #606266;"
                           :disabled="row.isChange"/>
              </el-tooltip>
              <el-switch v-else active-text="开启" inactive-text="关闭" inline-prompt v-model="row.isChecked" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                         :disabled="row.isChange" @change="sendData"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="向上" width="80px">
            <template v-slot="{row}">
              <div style="display: flex; justify-content: center; align-items: center" @click="up(row.id)">
                <el-icon size="20" color="#409EFF">
                  <Top/>
                </el-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="向下" width="80px">
            <template v-slot="{row}">
              <div style="display: flex; justify-content: center; align-items: center" @click="down(row.id)">
                <el-icon size="20" color="#409EFF">
                  <Bottom/>
                </el-icon>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
    </el-dialog>
  </div>
</template>

<script>

import DialogTable from "@/components/h5/layout/DialogTable.vue";
import {Bottom, Setting, Top} from "@element-plus/icons-vue";

export default {
  props: {
    settingList: {default: () => []}
  },
  emits: ["setData"],
  watch: {
    settingList: {
      handler() {
        // 在这里执行您需要的操作
        this.setRowKey(this.settingList);
        this.sendData();
      },
      immediate: true // 如果需要在初始赋值时也执行操作，设置 immediate: true
    }
  },
  name: "",
  components: {
    Bottom,
    Top,
    Setting, DialogTable
  },
  data() {
    return {
      showTableSetting: false,
      treeProps: {checkStrictly: false}
    };
  },
  created() {
  },
  methods: {
    up(id) {
      let list = this.getListById(id, this.settingList);
      // 找到索引
      let index = list.findIndex(item => item.id === id);
      if (index > 0) {
        // 交换位置
        list[index].index = index - 1;
        list[index - 1].index = index;
      }
      this.dataSort(this.settingList);
      this.sendData();
    },
    down(id) {
      let list = this.getListById(id, this.settingList);
      // 找到索引
      let index = list.findIndex(item => item.id === id);
      if (index > 0) {
        // 交换位置
        list[index].index = index + 1;
        list[index + 1].index = index;
      }
      this.dataSort(this.settingList);
      this.sendData();
    },
    /**
     * 排序
     * @param list
     */
    dataSort(list) {
      let isSort = false;
      for (let i = 0; i < list.length; i++) {
        if (!isSort) {
          list.sort((a, b) => a.index - b.index);
        }
        if (list[i].children && list[i].children.length > 0) {
          this.dataSort(list[i].children);
        }
      }
      this.settingList.sort((a, b) => a.index - b.index);
    },
    // 根据id获取当前所在列表，进行排序
    getListById(id, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          return list;
        }
        if (list[i].children && list[i].children.length > 0) {
          let result = this.getListById(id, list[i].children);
          if (result && result.length > 0) {
            return result;
          }
        }
      }
    },
    /**
     * 返回排序对象
     */
    sendData() {
      // 处理对象
      let list = JSON.parse(JSON.stringify(this.settingList));
      // 排序
      list.sort((a, b) => a.index - b.index);
      this.$emit("setData", this.handlerObj(list));
    },
    handlerObj(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.length > 0) {
          list[i].children = this.handlerObj(list[i].children);
        }
        if (!list[i].isChecked) {
          list.splice(i, 1);
          i--;
        }
      }
      return list;
    },
    /**
     * 设置唯一id
     * @param list
     */
    setRowKey(list) {
      for (let i = 0; i < list.length; i++) {
        list[i].id = (Math.random() * 100000000).toString();
        this.setWordWidth(list[i]);
        if (list[i].children && list[i].children.length > 0) {
          this.setRowKey(list[i].children);
        }
      }
    },
    /**
     * 设置动态宽度
     * @param data
     */
    setWordWidth(data) {
      if (data.width) {
        return;
      }
      if (data.name.length === 2) {
        data.width = 80;
      } else if(data.name.length > 2 && data.name.length <= 8){
        data.width = 115;
      }else if(data.name.length > 8){
        data.width = 150;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-table__row--level-1) {
  background-color: #DCDFE6 !important;
}

:deep(.el-table__row--level-2) {
  background-color: #C0C4CC !important;
}
</style>

<!-- isChange: 是否可以动态显示隐藏 -->
<!-- isChecked: 是否显示 -->
<!-- index: 排序 -->
<!-- isLevel: 几级表头 == 多级表头，需要在children中自己添加信息 -->

<!-- 使用规则：传入一个配置的tableSetting，接收返回的setData -->
<!-- 如果是多级表头，并且动态添加，需要通过ref手动调用setRowKey，否则id可能赋值失败，需要通过手动调用sendData传递初始化数据 -->

<!--tableSetting: [-->
<!--{prop: "skuCode", name: "商品编号", isLevel: 1, isChange: true, isChecked: true, index: 1},-->
<!--{prop: "skuName", name: "商品名称", isLevel: 1, isChange: true, isChecked: true, index: 2},-->
<!--{prop: "skuStandard", name: "规格", isLevel: 1, isChange: false, isChecked: true, index: 3},-->
<!--{prop: "skuType", name: "型号", isLevel: 1, isChange: false, isChecked: true, index: 4},-->
<!--{prop: "barCode", name: "条码", isLevel: 1, isChange: false, isChecked: true, index: 5},-->
<!--{prop: "unitName", name: "基本单位", isLevel: 1, isChange: false, isChecked: true, index: 6},-->
<!--{prop: "initialQty", name: "期初库存", isLevel: 1, isChange: true, isChecked: true, index: 7},-->
<!--{prop: "", name: "入库", isLevel: 3, isChange: false, isChecked: true, index: 8, children: []},-->
<!--{prop: "", name: "出库", isLevel: 3, isChange: false, isChecked: true, index: 9, children: []},-->
<!--{prop: "endQty", name: "期末库存", isLevel: 1, isChange: true, isChecked: true, index: 10},-->
<!--],-->

<!-- 模板 -->
<!--<el-table-column v-for="(item,idx) in columnList" :key="idx" :label="item.name" show-overflow-tooltip align="center"-->
<!--                 :prop="item.isLevel === 1 ? item.prop : ''" :width="item.width">-->
<!--<el-table-column v-if="item.isLevel >= 2" v-for="(itemCld2, idx2) in item.children" :key="idx2" show-overflow-tooltip-->
<!--                 align="center"-->
<!--                 :label="itemCld2.name" :prop="item.isLevel === 2 ? itemCld2.prop : ''" :width="itemCld2.width">-->
<!--  <el-table-column v-if="item.isLevel >= 3" v-for="(itemCld3, idx3) in itemCld2.children" :key="idx3"-->
<!--                   show-overflow-tooltip align="center"-->
<!--                   :label="itemCld3.name" :prop="item.isLevel === 3 ? itemCld3.prop : ''" :width="itemCld3.width"/>-->
<!--</el-table-column>-->
<!--</el-table-column>-->
