import axios from "../../../util/axios";

export default {
    /** 查询左侧已配置的单据信息 */
    queryMainMenu: () => axios.get({url: "/basedata/system/approval/queryMainMenu"}),
    /** 查询详细的多级审批 */
    queryDetail: (code) => axios.get({url: "/basedata/system/approval/queryDetail", data: {code}}),
    /** 保存审批配置 */
    saveApprovalUser: (data) => axios.post({url: "/basedata/system/approval/saveApprovalUser", data: data}),
    /** 批量保存审批主配置 */
    saveBatch: (data) => axios.post({url: "/basedata/system/approval/saveBatch", data: data}),
    /** 删除一条人员等配置信息 */
    deleteUser: (id) => axios.del({url: "/basedata/system/approval/deleteUser", data: {id}}),
}