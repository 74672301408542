<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header>
          <template #default>
          </template>
        </page-header>
        <div>
          <el-descriptions :column="3" size="small" border>
            <el-descriptions-item label="单据日期">
              <el-date-picker style="width: 100%"
                              :disabled="isDisabled"
                              v-model="dataDetail.createdTime"
                              type="date"
                              placeholder="选择日期时间"
              />
            </el-descriptions-item>
            <el-descriptions-item label="单据编号">
              <span>{{ dataDetail.id ? dataDetail.id : '未保存' }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="经办人">
              <employee-select :disabled="isDisabled" v-model="dataDetail.employeeId" @selectOne="setEmployee"/>
            </el-descriptions-item>
            <el-descriptions-item label="备注" :span="2">
              <el-input :disabled="isDisabled" v-model="dataDetail.remark" type="textarea" :rows="1"
                        class="el-input-text"/>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div>
          <div v-if="!isDisabled" style="display: flex; justify-content: flex-end;margin-bottom: 12px">
            <div style="width: 400px">
              <product-list-select :selected-product-list="dataDetail.outList" @select="selectOutProduct"/>
            </div>
          </div>
        </div>
        <!-- 出库的信息（ 从库存中选取信息 ） -->
        <el-table stripe :data="dataDetail.outList" border ref="finalTable">
          <el-table-column align="center" type="index" label="序号" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="120px" show-overflow-tooltip/>

          <el-table-column align="center" label="基本单位" width="120px" prop="skuUnitName"/>

          <el-table-column align="center" prop="skuCode" label="商品编码" min-width="120px"/>

          <el-table-column align="center" label="出库仓库" width="180px">
            <template v-slot="{row}">
              <el-select :disabled="isDisabled" v-model="row.stockId" placeholder="请选择出库仓库" @change="selectOutStock">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column align="center" label="出库数量" width="130px">
            <template v-slot="{row}">
              <el-tooltip v-if="row.maxCount === 0" content="该仓库中此商品数量为0" placement="top" effect="light">
                <table-button el-type="danger">0</table-button>
              </el-tooltip>
              <el-input type="number" v-else :disabled="isDisabled" min="0" v-model="row.outNum" :max="row.maxCount">
                <template #prefix>
                  <el-tooltip :content="'此仓库该商品数量为：' + row.maxCount" placement="top" effect="light">
                    <el-icon>
                      <InfoFilled/>
                    </el-icon>
                  </el-tooltip>
                </template>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column align="center" width="80px" label="操作" fixed="right">
            <template v-slot="{row,$index}">
              <table-button type="danger" message="您确认要删除当前行数据吗？" el-type="danger" :disabled="isDisabled" @click="deleteRow(false,$index)">
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>

        <div>
          <div v-if="!isDisabled" style="display: flex; justify-content: flex-end;margin: 12px 0">
            <div style="width: 400px">
              <product-list-select :stock-type="true" :is-depart="true" :selected-product-list="dataDetail.detailList"
                                   @select="selectInProduct"/>
            </div>
          </div>
        </div>
        <!-- 入库的信息（ 拆卸的商品信息，按溯源码添加入库的数量 ） -->
        <el-table stripe :data="dataDetail.inList" border ref="finalTable">
          <el-table-column align="center" type="index" label="序号" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="150px" show-overflow-tooltip/>

          <el-table-column align="center" label="基本单位" width="120px" prop="skuUnitName"/>

          <el-table-column align="center" prop="skuCode" label="商品编码" min-width="150px"/>

          <el-table-column align="center" label="入库仓库" min-width="80px">
            <template v-slot="{row}">
              <el-select :disabled="isDisabled" v-model="row.stockId" placeholder="请选择入库仓库">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column align="center" label="目标商品" min-width="80px">
            <template v-slot="{row,$index}">
              <el-select v-model="row.originalSkuId" :disabled="isDisabled" placeholder="请选择目标商品" @change="countChange(row,$index)">
                <el-option v-for="(item,idx) in dataDetail.outList" :key="idx" :label="item.skuName"
                           :value="item.skuId" :disabled="item.outNum === 0">
                  <el-tooltip :content="item.outNum === 0 ? '该商品数量为0无法选择' : '总数量为：' + item.outNum"
                              placement="top" effect="light">
                    <div>{{ item.skuName }}</div>
                  </el-tooltip>
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column align="center" label="数量" width="130">
            <template v-slot="{row,$index}">
              <el-tooltip v-if="!row.originalSkuId" content="请选择目标拆卸商品" placement="top" effect="light">
                <table-button el-type="danger">0</table-button>
              </el-tooltip>
              <el-input type="number" @change="changeMax($index)" v-if="row.originalSkuId && !originalSwitch" :disabled="isDisabled" min="0" v-model="row.inNum"></el-input>
              <span v-if="row.originalSkuId && originalSwitch">{{ row.inNum }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" :width="originalSwitch ? '150px' : '80px'" label="操作" fixed="right">
            <template v-slot="{row,$index}">
              <order-original-code v-if="originalSwitch" :in-code="true" :original-code-type="originalCodeType"
                                   :disabled="isDisabled" @addOriginalCode="addOriginalCode2List($index, $event)"
                                   :code-list="row.originalCodeList" :sku-name="row.skuName"/>
              <table-button type="danger" el-type="danger" message="您确认要删除当前行数据吗？" v-if="!isDisabled" @click="deleteRow(true,$index)">
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <page-button :disabled="isDisabled" type="write" el-type="success" @click="saveSubmit(true)">保存草稿
        </page-button>
        <page-button :disabled="isDisabled" type="write" el-type="primary" @click="saveSubmit(false)">保存单据
        </page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import Money from "@/components/h5/Money.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import DialogTable from "@/components/h5/layout/DialogTable";
import PageHeader from "@/components/h5/layout/PageHeader";
import ImportComponent from "@/components/h5/ImportComponent.vue";
import assembleApi from "@/api/main/stock/assembleApi";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import stockApi from "@/api/main/stock/stockApi";
import stockProductApi from "@/api/main/stock/stockProductApi";
import {InfoFilled} from "@element-plus/icons-vue";
import commonApi from "@/api/commonApi";
import OrderOriginalCode from "@/views/main/supplyChain/merchantOrder/components/OrderOriginalCode.vue";
export default {
  name: "",
  components: {
    InfoFilled,
    FormPage,
    TableButton, PageButton, EmployeeSelect,
    ListPage, TableList, ProductListSelect, Money, Dictionary, DialogTable, PageHeader, ImportComponent, OrderOriginalCode
  },
  data() {
    return {
      // 控制是否禁用
      isDisabled: false,
      // 数据
      dataDetail: {
        outList: [], // 出库信息
        inList: [], // 入库信息
        details: [], // 最终上传和获取到的详情数据信息
      },
      // 仓库列表
      stockList: [],
      // 是否开启了溯源码功能
      originalSwitch: false,
      // 溯源码类型
      originalCodeType: "",
    };
  },
  created() {
    // 查询配置是否开启溯源码功能
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    // 查询溯源码类型
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.originalCodeType = setting.settingValue);
    // 查询仓库列表
    stockApi.queryListByNotCondition().then(res => this.stockList = res);
    // 判断是有id传入
    if(this.$routerUtil.query.id){
      this.findById(this.$routerUtil.query.id);
    }
  },
  methods: {
    changeMax(idx){
      let count = 0;
      let data = this.dataDetail.inList[idx];
      let outNum = Number(this.dataDetail.outList.find(item => item.skuId === data.originalSkuId).outNum);
      for (let i = 0; i < this.dataDetail.inList.length; i++) {
        let item = this.dataDetail.inList[i];
        if(i === Number(idx)){
          continue;
        }
        if(data.originalSkuId === item.originalSkuId){
          count += Number(item.inNum);
        }
      }
      let total = count + Number(data.inNum);
      if(total > outNum){
        this.$message.error("拆卸数量不能大于原出库数量！");
        this.dataDetail.inList[idx].inNum = outNum - count;
      }
    },
    // 单据回显
    findById(id){
      assembleApi.findById(id).then(data => {
        this.dataDetail = data;
        // 拆分入库和出库类型
        this.dataDetail.inList = data.details.filter(item => item.type === 2);
        this.dataDetail.outList = data.details.filter(item => item.type === 1);
        if(data.status === 99){
          this.isDisabled = true;
        }else{
          // 草稿需要重新计算最大的数量信息
          this.selectOutStock();
        }
      })
    },
    // 出库数量修改了
    countChange(row,idx){
      // let skuId = row.originalSkuId;
      // // 原数据中出库的数量信息
      // let outNum = Number(this.dataDetail.outList.find(item => item.skuId === skuId).outNum);
      // // 获取所有准备入库的信息
      // let count = 0;
      // for (let i = 0; i < this.dataDetail.inList.length; i++) {
      //   if(this.dataDetail.inList[i].skuId === row.skuId){
      //     continue;
      //   }
      //   if(this.dataDetail.inList[i].originalSkuId === skuId){
      //     count += Number(this.dataDetail.inList[i].inNum);
      //   }
      // }
      // let total = count + Number(row.inNum);
      // if(outNum === total){
      //   // 修改当前的数量信息
      //   row.maxCount = 0;
      // }else if(outNum > total){
      //   row.maxCount = outNum - count;
      // }
      // if(idx !== -1){
      //   this.dataDetail.inList[idx] = row;
      // }
    },
    // 添加溯源码
    addOriginalCode2List(idx,list){
      let arr = [...this.dataDetail.inList];
      arr[idx].originalCodeList = list;
      arr[idx].inNum = list.length;
      this.headInfo.inList = arr;
    },
    // 删除当前行数据
    deleteRow(isIn,idx){
      if(isIn){
        this.dataDetail.inList.splice(idx, 1);
      }else{
        this.dataDetail.outList.splice(idx, 1);
      }
    },
    // 检查并处理保存的参数信息
    checkAndHandlerParams() {
      // 开启了溯源码，判断实际数量是否有与溯源码数量不统一的
      if(this.originalSwitch){
        for (let i = 0; i < this.dataDetail.inList.length; i++) {
          // 添加溯源码类型
          this.dataDetail.inList[i].originalCodeType = this.originalCodeType;
          if(this.dataDetail.inList[i].inNum !== this.dataDetail.inList[i].originalCodeList.length){
            this.$message.error(`入库商品：${this.dataDetail.inList[i].skuName}的入库数量与溯源码数量不统一！`);
            return;
          }
        }
      }
      // 清空details的信息，防止重复添加
      this.dataDetail.details = [];
      // 将出入库的单据整合到一起去
      this.dataDetail.details.pushAll(this.dataDetail.outList);
      this.dataDetail.details.pushAll(this.dataDetail.inList);
      // 判断参数信息 - 是否有详情未选择仓库信息的
      let msg = '商品：';
      let isMsg = false;
      for (let i = 0; i < this.dataDetail.details.length; i++) {
        if (!this.dataDetail.details[i]) {
          isMsg = true;
          msg += this.dataDetail.details[i].skuName + '、';
        }
      }
      if (isMsg) {
        this.$message.error(msg + '未选择仓库');
        return false;
      }
      return true;
    },
    // 保存提交
    saveSubmit(isDraft) {
      if (!this.checkAndHandlerParams()) {
        return;
      }
      if (isDraft) {
        assembleApi.saveDraft(this.dataDetail);
      } else {
        assembleApi.saveSubmit(this.dataDetail);
      }
    },
    // 选择出库商品
    selectOutProduct(data) {
      // 向detail中添加数据
      let arr = [...this.dataDetail.outList];
      for (let i = 0; i < data.length; i++) {
        // 去重添加
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === data[i].id) {
            contains = true;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: data[i].id,
            outNum: 0,
            maxCount: 0,
            skuName: data[i].skuName,
            skuCode: data[i].skuCode,
            salePrice: data[i].skuSalePrice,
            skuUnitId: data[i].skuUnitId,
            skuUnitName: data[i].skuUnitName,
            type: 1
          });
        }
      }
      this.dataDetail.outList = arr;
    },
    // 选择入库商品
    selectInProduct(data) {
      // 向detail中添加数据
      let arr = [...this.dataDetail.inList];
      for (let i = 0; i < data.length; i++) {
        // 去重添加
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === data[i].id) {
            contains = true;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: data[i].id,
            inNum: 0,
            maxCount: 0,
            skuName: data[i].skuName,
            skuCode: data[i].skuCode,
            salePrice: data[i].skuSalePrice,
            skuUnitId: data[i].skuUnitId,
            skuUnitName: data[i].skuUnitName,
            type: 2
          });
        }
      }
      this.dataDetail.inList = arr;
    },
    // 设值职员
    setEmployee(data) {
      this.dataDetail.employeeId = data.id;
      this.dataDetail.employeeName = data.employeeName;
    },
    // 选择出库仓库时，需要计算仓库的最大数量
    async selectOutStock() {
      let list = [];
      for (let i = 0; i < this.dataDetail.outList.length; i++) {
        list.push({
          skuId: this.dataDetail.outList[i].skuId,
          stockId: this.dataDetail.outList[i].stockId,
        })
      }
      const result = await stockProductApi.queryStockQty(list);
      // 计算最大出库数量
      this.calcMaxOutCount(result);
    },
    // 计算最大出库数量
    calcMaxOutCount(result) {
      // 将result转为map
      let map = new Map();
      for (let i = 0; i < result.length; i++) {
        map.set(result[i].skuId + result[i].stockId, result[i].stockQty);
      }
      // 添加到出库的信息中去
      for (let i = 0; i < this.dataDetail.outList.length; i++) {
        if (result.length === 0) {
          this.dataDetail.outList[i].maxCount = 0;
        } else {
          this.dataDetail.outList[i].maxCount = map.get(this.dataDetail.outList[i].skuId + this.dataDetail.outList[i].stockId);
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>