<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <el-tree ref="groupTree" node-key="id" :data="approvals"
                 :props="{ children: 'children', label: 'name' }" @node-click="clickDoc" default-expand-all
                 :expand-on-click-node="false">
          <template v-slot="{ node, data }">
            <span class="custom-tree-node">
              <span class="node-name">
                <el-tooltip
                    effect="light"
                    :content="node.label"
                    placement="left-start"
                >
                  {{ node.label }}
                </el-tooltip>
              </span>
            </span>
          </template>
        </el-tree>
      </template>
      <template #default>
        <table-list>
          <el-table stripe border :data="details" height="100%"  id="dragTable">
            <el-table-column type="index" label="序号" width="65" align="center"/>
            <el-table-column align="center" prop="name" label="审批名称" width="150" show-overflow-tooltip/>
            <el-table-column align="center" label="审批等级" width="150" show-overflow-tooltip>
              <template v-slot="{row}">
                <span v-if="row.level === 1">一级审批</span>
                <span v-if="row.level === 2">二级审批</span>
                <span v-if="row.level === 3">三级审批</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否启用" width="150" show-overflow-tooltip>
              <template v-slot="{row}">
                {{ row.isEnabled === 0 ? '禁用' : '启用' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="审批人" min-width="100">
              <template v-slot="{row}">
                <table-button el-type="primary" @click="toShowApproval(row, true)">审批人员配置</table-button>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="抄送人" min-width="100" show-overflow-tooltip>
              <template v-slot="{row}">
                <table-button el-type="primary" @click="toShowApproval(row, false)">抄送人员配置</table-button>
              </template>
            </el-table-column>
          </el-table>
        </table-list>
      </template>
    </separate-page>

    <el-dialog :title="approvalTitle ? '审批人员配置' : '抄送人员配置'" v-model="showApproval">
      <dialog-table>
        <el-table stripe border :data="handlerAL" height="100%">
          <el-table-column type="index" label="序号" width="65" align="center">
            <template #header>
              <a href="javascript:" style="display: flex;justify-content: center" @click="showUser = true">
                <el-icon size="20" color="#409EFF"><Plus /></el-icon>
              </a>
            </template>
          </el-table-column>
          <el-table-column align="center" label="角色名称" min-width="150" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.roleName ? row.roleName : '/' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="职员名称" min-width="150" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.employeeName ? row.employeeName : '/' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="100">
            <template v-slot="{row}">
              <table-button message="您确认删除吗？" el-type="danger" type="write" @click="deleteRow(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
    </el-dialog>

    <el-dialog title="人员配置" width="30%" v-model="showUser" @closed="() => this.selectUser = []">
      <dialog-table>
        <el-table stripe border row-key="id" :data="handlerUserList" height="100%" ref="multipleTable" @row-click="clickRow" @selection-change="userChange">
          <el-table-column type="selection" width="40" align="center" />
          <el-table-column align="center" label="角色/职员" min-width="150" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.employeeName ? row.employeeName : row.roleName }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="类型" min-width="150" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.employeeName ? '职员' : '角色' }}
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <div style="display: flex; justify-content: flex-end;margin-top: 10px">
        <page-button el-type="info" @click="showUser = false">取消</page-button>
        <page-button el-type="primary" @click="saveUser">保存</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tableButton from "@/components/h5/button/TableButton.vue";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import approvalApi from "@/api/main/sys/approvalApi";
import TableList from "@/components/h5/layout/TableList.vue";
import TableSetting from "@/components/h5/column/TableSetting.vue";
import XyInputTag from "@/components/h5/inputTag/XyInputTag.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import Sortable from "sortablejs";
import {Plus} from "@element-plus/icons-vue";
import commonApi from "@/api/commonApi";
import PageButton from "@/components/h5/button/PageButton.vue";
export default {
  name: "",
  components: {
    Plus,
    TableList,
    TableButton,
    SeparatePage,
    TableSetting,
    tableButton,
    XyInputTag,
    DialogTable,
    PageButton
  },
  data() {
    return {
      leftData:{},
      approvals: [],
      details: [],
      // 配置项
      approvalTitle: false,
      showApproval: false,

      acList: [],

      // 添加职员或者角色
      showUser: false,
      rowData: {}, // 要配置的行数据
      userList: [], // 职员和角色

      userId: "",

      selectUser: [], // 本次选择的职员或者角色信息
    };
  },
  computed: {
    handlerAL(){
      let type = this.approvalTitle ? 1 : 2;
      return this.acList.filter(item => item.type === type);
    },
    handlerUserList(){
      let type = this.approvalTitle ? 1 : 2;
      // 判断userList中是否在acList中存在，如果存在则过滤掉
      return this.userList.filter(item => !this.acList.some(item2 => item2.type === type && (item2.roleId === item.id || item2.employeeId === item.id)));
    },
  },
  created() {
    this.init();
    // 查询所有的角色和职员
    Promise.all([commonApi.queryAllRoles(), commonApi.queryAllEmployee()]).then(list => {
      this.userList.pushAll(list[0]);
      this.userList.pushAll(list[1]);
    })
  },
  methods: {
    deleteRow(row){
      approvalApi.deleteUser(row.id).then(() => {
        this.acList.splice(this.acList.findIndex(item => item.id === row.id),1);
      });
    },
    saveUser(){
      approvalApi.saveApprovalUser(this.selectUser).then(() => {
        this.clickDoc(this.leftData).then(() => {
          this.acList = this.details.find(item => item.code === this.rowData.code).acList;
        });
        this.showUser = false;
      });
    },
    userChange(list){
      if(list.length === 0) {
        return;
      }
      this.selectUser = [];
      for (let i = 0; i < list.length; i++) {
        this.selectUser.push({
          approvalConfigId: this.rowData.id,
          type: this.approvalTitle ? 1 : 2,
          roleId: list[i].roleName ? list[i].id : '',
          employeeId: list[i].employeeName ? list[i].id : '',
        })
      }
    },
    toShowApproval(row, isApproval){
      this.approvalTitle = isApproval;
      this.acList = row.acList;
      this.showApproval = true;
      this.rowData = row;
    },
    /**
     * 初始化查询左侧已配置单据
     */
    init() {
      approvalApi.queryMainMenu().then(list => {
        this.approvals = [{name: "全部单据", children: list}];
      })
    },
    /**
     * 点击单据
     * @param row 单据
     */
    clickDoc(row) {
      if (!row || !row.code) return;
      // this.initDragTable();
      this.leftData = row;
      return approvalApi.queryDetail(row.code).then(list => this.details = list);
    },
    // 让页面table可以拖动
    initDragTable() {
      this.$nextTick().then(() => {
        const el = document.querySelector('#dragTable table tbody')
        new Sortable(el, {
          sort: true,
          ghostClass: 'sortable-ghost',
          onEnd: (e) => {
            let tableData = [...this.details];
            const targetRow = tableData.splice(e.oldIndex, 1)[0]
            tableData.splice(e.newIndex, 0, targetRow);
            // 重新渲染
            this.details = [];
            this.$nextTick().then(() => {
              // 按索引进行处理，然后保存
              this.details = this.handlerIndex(tableData);
            });
          },
        })
      })
    },
    // 处理审批等级信息
    handlerIndex(list){
      for (let i = 0; i < list.length; i++) {
        list[i].level = i + 1;
      }
      // 保存到数据库中
      approvalApi.saveBatch(list);
      return list;
    },
    clickRow(data) {
      // debugger;
      // data.checked = !data.checked
      // this.$refs.multipleTable.toggleRowSelection(data, data.checked);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
@import "@/assets/css/el-tables";
</style>