<template>
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <page-button el-type="primary" type="print" @click="exportData">导出</page-button>
        <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
      </template>
    </page-header>
    <list-page>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>

          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.categoryCode" :dictionaries="categoryList" dict-code="dictCode"
                        dict-name="dictName" :place-holder="'请选择商品分类'"
                        @clear-data="clearCode" style="width: 100%"/>
          </div>
          <el-input ref="orderNumberInput" v-model="searchInfo.skuName" placeholder="请输入商品名称"
                    clearable @change="resetSearch"/>

        </template>
        <template #moreCondition>
          <el-select v-if="readAccess !== '1'" v-model="batchSearchInfo.stockId" multiple collapse-tags
                     collapse-tags-tooltip clearable
                     placeholder="请选择仓库" style="width: 240px">
            <el-option
                v-for="item in stockList"
                :key="item.id"
                :label="item.stockName"
                :value="item.id"
            />
          </el-select>

          <el-checkbox v-model="isSelectEmpty" label="不显示出入库记录为0的商品" size="large"/>

        </template>
      </search-condition>

      <table-list>
        <el-table stripe :data="dataList" height="100%"  show-summary :summary-method="getSummaries" :stripe="true">
          <el-table-column align="center" label="序号" type="index" width="60px" fixed="left">
            <template #header>
              <table-setting2 ref="tableSetting" @set-data="getColumnData" :setting-list="tableSetting"/>
            </template>
          </el-table-column>

          <el-table-column v-for="(item,idx) in columnList" :key="idx" :label="item.name" show-overflow-tooltip
                           align="center"
                           :prop="item.prop" :min-width="item.width">
            <el-table-column v-if="item.children && item.children.length > 0" v-for="(itemCld2, idx2) in item.children" :key="idx2"
                             show-overflow-tooltip align="center"
                             :label="itemCld2.name" :prop="itemCld2.prop"
                             :min-width="itemCld2.width">
              <el-table-column v-if="itemCld2.children && itemCld2.children.length > 0" v-for="(itemCld3, idx3) in itemCld2.children" :key="idx3"
                               show-overflow-tooltip align="center"
                               :label="itemCld3.name" :prop="itemCld3.prop"
                               :min-width="itemCld3.width" />
            </el-table-column>
          </el-table-column>

          <el-table-column label="操作" :align="'center'" width="100px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="toShowDetail(row)">查看明细</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>

      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false"></form-page>
    </list-page>
  </div>
</template>
<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import stockStatisticApi from "@/api/main/stock/stockStatisticApi";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import util from "@/util/util";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import {h} from "vue";
import {Bottom, Setting, Top} from "@element-plus/icons-vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import TableSetting2 from "@/components/h5/column/TableSetting2.vue";
import stockInoutTypeApi from "@/api/main/stock/StockInoutTypeApi";

export default {
  name: "",
  components: {
    Bottom,
    Top,
    Setting,
    TableButton, FormPage, TableList,
    DateRange, SearchCondition, DialogTable,
    PageHeader, PageButton, ListPage, Dictionary, TableSetting2
  },
  data() {
    return {
      dataList: [],
      searchInfo: {},
      isSelectEmpty: false,
      readAccess: null,
      batchSearchInfo: {},
      categoryList: {},
      stockList: [],
      columnList: [],
      statistic: {},
      tableSetting: [
        {prop: "skuCode", name: "商品编号", isChange: true, isChecked: true, index: 1},
        {prop: "skuName", name: "商品名称", isChange: true, isChecked: true, index: 2},
        {prop: "skuStandard", name: "规格", isChange: false, isChecked: true, index: 3},
        {prop: "skuType", name: "型号", isChange: false, isChecked: true, index: 4},
        {prop: "barCode", name: "条码", isChange: false, isChecked: true, index: 5},
        {prop: "unitName", name: "基本单位", isChange: false, isChecked: true, index: 6},
        {prop: "", name: "期初结存", isChange: false, isChecked: true, index: 7, children: []},
        {prop: "", name: "本期入库", isChange: false, isChecked: true, index: 8, children: []},
        {prop: "", name: "本期出库", isChange: false, isChecked: true, index: 9, children: []},
        {prop: "", name: "期末结存", isChange: false, isChecked: true, index: 10, children: []},
      ],
    };
  },
  created() {
    this.initSetting();
    // 时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 仓库
    orderStatisticApi.queryStockList().then(res => this.stockList = res);
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
  },
  methods: {
    getColumnData(data) {
      this.columnList = [...data];
      // 初始化查询
      this.handlerBatchSearch();
      // 因为是动态添加的二级表头，这里不判空，可能会导致二级表头参数没有获取到，后端抛异常
      if (this.searchInfo.inoutTypes !== "") {
        this.resetSearch();
      }
    },
    // 初始化配置
    initSetting() {
      stockInoutTypeApi.queryAllTypeList().then(list => {
        let inArr = [];
        let outArr = [];
        let inList = list.filter(item => item.type === 1);
        let outList = list.filter(item => item.type === 2);
        for (let i = 0; i < inList.length; i++) {
          inArr.push({
            name: inList[i].name,
            isChange: false,
            isChecked: true,
            index: i,
            inoutId: inList[i].id,
            inoutCode: inList[i].inoutCode,
            children: [
              {name: "数量", isChange: false, isChecked: true, index: 0, prop: "qty" + inList[i].inoutCode},
              {name: "金额", isChange: false, isChecked: true, index: 0, prop: "amount" + inList[i].inoutCode},
            ]
          })
          if(i === inList.length - 1){
            inArr.push({
              prop: "xjInStockCount",
              name: "小计数量",
              isChange: false,
              isChecked: true,
              index: i + 1,
            })
            inArr.push({
              prop: "xjInStockAmount",
              name: "小计金额",
              isChange: false,
              isChecked: true,
              index: i + 2,
            })
          }
        }
        for (let i = 0; i < outList.length; i++) {
          outArr.push({
            name: outList[i].name,
            isChange: false,
            isChecked: true,
            index: i,
            inoutId: outList[i].id,
            inoutCode: outList[i].inoutCode,
            children: [
              {name: "数量",isChange: false,isChecked: true,index: 0,children: [],prop: "qty" + outList[i].inoutCode},
              {name: "金额",isChange: false,isChecked: true,index: 0,children: [],prop: "amount" + outList[i].inoutCode},
            ]
          })
          if(i === outList.length - 1){
            outArr.push({
              prop: "xjOutStockCount",
              name: "小计数量",
              isChange: false,
              isChecked: true,
              index: i + 1,
              children: []
            })
            outArr.push({
              prop: "xjOutStockAmount",
              name: "小计金额",
              isChange: false,
              isChecked: true,
              index: i + 2,
              children: []
            })
          }
        }
        // 添加到配置中去
        for (let i = 0; i < this.tableSetting.length; i++) {
          if (this.tableSetting[i].name === "本期入库") {
            this.tableSetting[i].children.pushAll(inArr);
          }
          if (this.tableSetting[i].name === "本期出库") {
            this.tableSetting[i].children.pushAll(outArr);
          }
        }
        let idx = this.tableSetting.findIndex(item => item.name === "期初结存");
        this.tableSetting[idx].children = [
          {prop: "initialQty", name: "数量", isChange: false, isChecked: true, index: 0},
          {prop: "initialAmount", name: "金额", isChange: false, isChecked: true, index: 1, width: "120"},
        ];
        let idx2 = this.tableSetting.findIndex(item => item.name === "期末结存");
        this.tableSetting[idx2].children = [
          {prop: "endQty", name: "数量", isChange: false, isChecked: true, index: 0},
          {prop: "endAmount", name: "金额", isChange: false, isChecked: true, index: 1, width: "120"},
        ];
        this.$refs.tableSetting.setRowKey(this.tableSetting);
        this.$refs.tableSetting.sendData();
        // 初始化完毕进行查询
        this.resetSearch();
      })
    },
    toShowDetail(row) {
      // 查看明细
      this.handlerBatchSearch();
      // 设置商品名称
      const currentSearch = {
        ...this.searchInfo,
        skuName: row.skuName,
        skuId: row.skuId,
      };
      const currentBatch = {
        stockId: [],
        inoutType: [],
      }
      let arr = this.searchInfo.strCode.split(",");
      for (let i = 0; i < arr.length; i++) {
        if(!arr[i]){
          arr.splice(i , 1);
          i--;
        }
      }
      currentBatch.inoutType.pushAll(arr);
      currentBatch.stockId.push(row.stockId);
      this.$routerUtil.push({
        path: "/plat/recordDetail",
        query: {
          searchInfoStr: JSON.stringify(currentSearch),
          batchSearchInfoStr: JSON.stringify(currentBatch),
        },
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    exportData() {
      this.handlerBatchSearch();
      this.searchInfo.isSelectEmpty = this.isSelectEmpty;
      util.exportExcel("/order/stock/statistic/exportDataPurchaseSale", this.searchInfo)
    },
    queryList() {
      // 处理批量查询的数据
      this.handlerBatchSearch();
      this.searchInfo.isSelectEmpty = this.isSelectEmpty;
      stockStatisticApi.queryPurchaseSaleList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = this.handlerMap(res.records);
      });
      stockStatisticApi.queryPurchaseSaleStatistic(this.searchInfo).then(data => {
        this.statistic = {...data, ...data.purchaseMap};
      })
    },
    handlerMap(list) {
      for (let i = 0; i < list.length; i++) {
        list[i] = {...list[i], ...list[i].purchaseMap};
      }
      return list;
    },
    clearCode() {
      this.searchInfo.categoryCode = "";
    },
    handlerBatchSearch() {
      if (this.batchSearchInfo.stockId && this.batchSearchInfo.stockId.length > 0) {
        this.searchInfo.stockId = this.batchSearchInfo.stockId.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
      let str = '';
      let strCode = '';
      // 获取开启了的配置二级表头列
      for (let i = 0; i < this.columnList.length; i++) {
        if (this.columnList[i].children && this.columnList[i].children.length > 0) {
          for (let j = 0; j < this.columnList[i].children.length; j++) {
            if(this.columnList[i].children[j].inoutId || this.columnList[i].children[j].inoutCode){
              str += this.columnList[i].children[j].inoutId + ','
              strCode += this.columnList[i].children[j].inoutCode + ','
            }
          }
        }
      }
      this.searchInfo.inoutTypes = str.substring(0, str.length - 1);
      this.searchInfo.strCode = strCode.substring(0, str.length - 1);
    },
    getSummaries(param) {
      const {columns} = param;
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
        }
        if (this.statistic[column.property]) {
          sums[index] = h('div', [Number(this.statistic[column.property]).toFixed(2)])
        }
      })
      return sums;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
//:deep(.el-table__header) {
//  .el-table__cell {
//    padding: 2px 0 !important;
//    font-size: 13px !important;
//  }
//  //:deep(.el-table .el-table__cel){
//  //}
//}
//:deep(.el-table tr){
//  background-color: #f3f7fd;
//}

</style>
