<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>

          <div style="display: flex;align-items: center">
            <page-button el-type="primary" @click="resetSearch">查询</page-button>

            <el-dropdown v-if="originalSwitch" @command="handleCommand">
              <el-button type="primary">
                新建
                <el-icon class="el-icon--right">
                  <arrow-down/>:
                </el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="commonIn">普通入库</el-dropdown-item>
                  <el-dropdown-item command="specialIn">带溯源码入库</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <page-button v-else type="write" el-type="success" @click="toGo(false)">新建</page-button>
          </div>

        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
          <div style="display: inline-block; margin-right: 10px">
            <dictionary v-if="readAccess === 3" @clearData="clearDic" v-model="searchInfo.departCode"
                        :place-holder="'请选择部门'"
                        :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
        </template>
        <template #moreCondition>
          <div style="display: inline-block; margin-right: 10px">
            <dictionary v-model="searchInfo.inoutType" :place-holder="'请选择业务类型'"
                        :dictionaries="dictionaryList" :dict-code="'inoutCode'" :dict-name="'name'"/>
          </div>
          <el-select v-model="searchInfo.stockId" placeholder="请选择仓库" size="default" clearable>
            <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
          </el-select>
          <el-select v-model="searchInfo.status" placeholder="请选择单据状态" size="default" clearable>
            <el-option label="草稿" value="0"/>
            <el-option label="待审核" value="10"/>
            <el-option label="已完成" value="99"/>
          </el-select>
          <el-input v-model="searchInfo.searchKey" placeholder="请输入备注信息"/>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="inOrderList" ref="multipleTable" height="100%" show-summary
                  :summary-method="getSummary">
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="60px" fixed/>
          <el-table-column align="center" prop="id" label="订单编号" width="180px" fixed>
            <template v-slot="{row}">
              <table-button class="add" @click="viewDetail(row)">{{ row.id }}</table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdTime" label="单据日期" show-overflow-tooltip fixed/>
          <el-table-column align="center" label="业务类型" show-overflow-tooltip>
            <template v-slot="{row}">
              <!--              <echo-dic :info-list="dictionaryList" :value="row.inoutType"/>-->
              <dictionary :disabled="true" v-model="row.inoutType" :dict-code="'inoutCode'" :dict-name="'name'"
                          :dictionaries="dictionaryList"/>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stockName" label="仓库名称" show-overflow-tooltip/>
          <el-table-column align="center" prop="skuQty" label="数量"/>
          <el-table-column align="center" prop="totalAmount" label="金额"/>
          <el-table-column align="center" label="单据状态" show-overflow-tooltip width="100px">
            <template v-slot="{row}">
              <el-tag v-if="row.status === 0" type="warning">草稿</el-tag>
              <el-tag v-if="row.status === 5" type="warning">检修中</el-tag>
              <el-tag v-if="row.status === 10" type="primary">待审核</el-tag>
              <el-tag v-if="row.status === 20" type="primary">待确认</el-tag>
              <el-tag v-if="row.status === 99" type="success">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdName" label="创建人员" show-overflow-tooltip/>

          <el-table-column label="单据来源" show-overflow-tooltip>
            <template v-slot="{row}">
              <table-button class="add"
                            @click="findOriginal(row)">
                {{ row.inoutOrderId }}
              </table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip/>
          <el-table-column align="center" label="操作 " fixed="right">
            <template v-slot="{row}">
              <table-button type="write" :disabled="row.status !== 0" @click="deleteRow(row)" el-type="danger"
                            message="确定删除该条草稿吗？">删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>

    <el-dialog title="二维码生成" v-model="showGenericCode" width="30%">
      <el-form label-width="70px">
        <el-form-item label="生成数量">
          <el-input v-model="genericData.count" type="number"/>
        </el-form-item>
        <el-form-item label="选择商品">
          {{ genericData.skuName }}
          <product-list-select :small-btn="true" :show-search="false" @select="selectData"/>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: space-between">
        <div class="text-warn">
          <span>提示：</span>
          <p>1.后缀[_1]为商品码,同一批生成的商品只有一条商品码</p>
          <p>2.后缀[_2]为序列号,根据数量生成对应的序列号</p>
          <p>3.入库自动生成溯源码时,需要填写前缀+序号</p>
          <p>4.格式：序列号前14位-编号</p>
        </div>
        <div>
          <page-button @click="showGenericCode = false" el-type="info">取消</page-button>
          <page-button @click="genericCode" el-type="primary">生成</page-button>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import commonApi from "@/api/commonApi";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import {h} from "vue";
import stockInStorageApi from "@/api/main/stock/stockInStorageApi";
import util from "@/util/util";
import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import config from "@/config/config";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import {ArrowDown} from "@element-plus/icons-vue";

export default {
  components: {
    ArrowDown,
    Dictionary,
    PageButton,
    FormPage,
    TableButton,
    ListPage, SearchCondition, TableList, DateRange, PageHeader, ProductListSelect
  },
  data() {
    return {
      stockList: [], //仓库列表
      inOrderList: [], // 入库订单列表
      searchInfo: {},
      // 溯源码功能
      originalSwitch: false,
      departList: [],
      dictionaryList: [],
      dictionaryInoutList: [],
      bottomInfo: {},
      // 设置读的权限值
      readAccess: null,
      // 生成二维码
      showGenericCode: false,
      // 系统溯源码
      sysOriginalCode: "",
      // 生成信息
      genericData: {
        count: 1,
      },
    };
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 获取字典列表
    commonApi.queryAllTypeList(1).then(res => this.dictionaryList = res);
    commonApi.queryDepartList().then(res => this.departList = res);
    // 初始化，默认查询一个月内的数据
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.sysOriginalCode = setting.settingValue);
    // 获取仓库列表
    this.queryStockList();
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  methods: {
    handleCommand(cmd) {
      this.toGo(cmd === "commonIn");
    },
    genericCode() {
      if (!this.genericData.id) {
        this.$message.error("请选择商品");
        return;
      }
      // 生成二维码信息、返回一个zip文件
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = `${process.env.VUE_APP_BASE_URL}/order/base/getQrCode?count=${this.genericData.count}&skuId=${this.genericData.id}&skuName=${this.genericData.skuName}`;
      document.body.appendChild(link);
      link.click();
    },
    selectData(data) {
      if (data.length > 1) {
        this.$message.error("当前只允许选择一条商品");
        return;
      }
      this.genericData = {count: this.genericData.count, ...data[0]};
    },
    // 导出
    exportList() {
      util.exportExcel("/order/stock/inStorage/exportList", this.searchInfo);
    },
    // 跳转原单据
    findOriginal(row) {
      switch (row.inoutType) {
        case "BYRK":   // 报溢入库
          this.$routerUtil.push(`/stock/inventory/detail?inoutStorage=${row.inoutOrderId}`)
          break;
        case "DBRK":  // 调拨入库
          merchantOrderApi.findById(row.originalOrderId).then(res => {
            if (res && res.orderType === 11) {
              this.$routerUtil.push(`/stock/restock/original/detail?id=${row.inoutOrderId}`)
            } else {
              this.$routerUtil.push(`/stock/restock/detail?id=${row.inoutOrderId}`)
            }
          })
          break;
        case "XSTK":   // 销售退库
          this.$routerUtil.push(`/report/refund-order/detail?id=${row.inoutOrderId}&isFinish=2`);
          break;
        case "CGRK":   // 采购订单生成的采购入库
          this.$routerUtil.push(`/supplyChain/purchase/detail?id=${row.inoutOrderId}`);
          break;
      }
    },
    clearDic() {
      this.searchInfo.departCode = "";
    },
    //序号
    indexMethod(index) {
      return index + 1;
    },
    toGo(isTrue) {
      if (isTrue) {
        this.$routerUtil.push({path: `/stock/instock/detail-simple`});
      } else {
        if (this.originalSwitch && this.sysOriginalCode !== "MATERIAL") {
          // 溯源码
          this.$routerUtil.push("/stock/instock/detail-original");
        } else {
          this.$routerUtil.push({path: `/stock/instock/detail-simple`});
          // this.$routerUtil.push({path: `/stock/instock/detail-simple-prl`});
        }
      }
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },
    queryList() {
      stockInoutApi.queryList(this.searchInfo).then(res => {
        this.inOrderList = this.convert(res.records);
        this.searchInfo.totalCount = res.totalCount;
      })
      this.queryBottomList();
    },
    convert(list) {
      try {
        let configType = config.inoutType;
        for (let i = 0; i < list.length; i++) {
          let inoutType = Number(list[i].inoutType);
          if (!isNaN(inoutType)) {
            let data = configType.find(item => item.value === inoutType);
            list[i].inoutType = data.text;
          }
        }
        return list;
      } catch (e) {
        return list;
      }
    },
    queryBottomList() {
      let searchVo = this.searchInfo;
      searchVo.inoutStorageType = 1;
      stockInStorageApi.querySaleStatisticInfo(searchVo).then(res => this.bottomInfo = res);
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    viewDetail(row) {
      let path = "";
      debugger;
      if (this.originalSwitch && row.existCode === "2") {
        // 溯源码
        path = "/stock/instock/detail-original";
      } else {
        path = `/stock/instock/detail-simple`;
      }
      // let path = this.originalSwitch ? "/stock/instock/detail-original" : "/stock/instock/detail-simple";
      //  this.originalSwitch ? "/stock/instock/detail-original" : "/stock/instock/detail-simple-prl";
      this.$routerUtil.push({
        path,
        query: {
          inoutStorage: row.id,
        }
      });
    },
    deleteRow(row) {
      if (row.status !== 0) {
        this.$message.error("只能删除草稿！！！")
        return;
      }
      stockInoutApi.deleteRow(row.id);
    },
    getSummary(param) {
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }
        if (index === 5) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [Number(this.bottomInfo.totalQty).toFixed(2)])
        }

        if (index === 6) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [Number(this.bottomInfo.totalAmount).toFixed(2)])
        }
      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";

.add:hover {
  text-decoration: underline;
}

.text-warn {
  color: #727171;
  font-size: 13px;

  p {
    letter-spacing: 1px;
  }

  span {
    font-weight: bolder;
    color: black;
  }
}


:deep(.el-dropdown){
  span{
    font-size: 12px;
    margin-top: 3px;
  }
}
</style>