<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <page-button el-type="primary" type="print" @click="exportData">导出</page-button>
        <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
      </template>
    </page-header>
    <list-page>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>

          <el-select v-if="readAccess !== '1'" v-model="batchSearchInfo.stockId" multiple collapse-tags
                     collapse-tags-tooltip clearable
                     placeholder="请选择仓库" style="width: 240px">
            <el-option
                v-for="item in stockList"
                :key="item.id"
                :label="item.stockName"
                :value="item.id"
            />
          </el-select>
          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.categoryCode" :dictionaries="categoryList" dict-code="dictCode"
                        dict-name="dictName" :place-holder="'请选择商品分类'"
                        @clear-data="clearCode" style="width: 100%"/>
          </div>
        </template>
        <template #moreCondition>

          <el-select v-model="searchInfo.inoutStorageType" clearable placeholder="请选择出入库单据" style="width: 240px">
            <el-option :value="1" label="入库单"/>
            <el-option :value="2" label="出库单"/>
          </el-select>

          <el-select v-model="batchSearchInfo.inoutType" placeholder="请选择出入库类型" style="width: 240px" multiple collapse-tags collapse-tags-tooltip clearable>
            <el-option v-for="item in inoutList" :key="item.id" :value="item.inoutCode" :label="item.name"/>
          </el-select>
          <el-input v-model="searchInfo.skuName" placeholder="请输入商品名称" clearable @change="resetSearch"/>
          <el-input v-model="searchInfo.inOutId" placeholder="请输入出入库单据编号" clearable @change="resetSearch"/>
          <div style="display: inline-block; width: 240px;margin-right: 10px">
            <el-select v-model="searchInfo.supplierId" placeholder="请选择供应商" size="default" clearable style="width: 100%;">
              <el-option v-for="(item,idx) in supplierList" :key="idx" :label="item.supplierName" :value="item.id"/>
            </el-select>
          </div>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :row-key="id" :data="dataList" height="100%" @row-click="rowClick" show-summary :summary-method="getSummary">
          <el-table-column align="center" type="index" label="序号" width="60px" fixed/>
          <el-table-column align="center" prop="id" label="订单编号" width="200px" show-overflow-tooltip fixed/>
          <el-table-column align="center" prop="createdTime" label="单据日期" min-width="120px" show-overflow-tooltip fixed/>
          <el-table-column align="center" width="110" prop="dicTypeName" label="业务类型" show-overflow-tooltip/>
          <el-table-column align="center" width="110" prop="stockName" label="仓库名称" show-overflow-tooltip/>
          <el-table-column align="center" prop="supplierName" label="供应商" show-overflow-tooltip/>
          <el-table-column align="center" width="110" prop="skuName" label="商品名称" show-overflow-tooltip/>
          <el-table-column align="center" prop="skuQty" label="数量"/>
          <el-table-column align="center" label="单价" prop="averagePrice" show-overflow-tooltip min-width="120px"/>
          <el-table-column align="center" label="金额" prop="costTotal" show-overflow-tooltip min-width="120px"/>
          <el-table-column align="center" label="税率" prop="taxRate" show-overflow-tooltip min-width="120px"/>
          <el-table-column align="center" label="税额" prop="taxAmount" show-overflow-tooltip min-width="120px"/>
          <el-table-column align="center" label="价税合计" prop="totalAmountIncludeTax" show-overflow-tooltip min-width="120px"/>
          <el-table-column align="center" prop="createdName" label="制单人" show-overflow-tooltip/>
          <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip/>
          <el-table-column align="center" label="操作 " fixed="right">
            <template v-slot="{row}">
              <table-button type="read" @click="viewDetail(row)">查看详情</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList">
      </form-page>
    </list-page>

  </div>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import stockStatisticApi from "@/api/main/stock/stockStatisticApi";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import util from "@/util/util";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import stockInoutTypeApi from "@/api/main/stock/StockInoutTypeApi";
import {h} from "vue";

export default {
  name: "RecordDetail",
  components: {
    Dictionary, FormPage, DateRange, TableButton, PageHeader, PageButton, ListPage, TableList, SearchCondition
  },
  data() {
    return {
      // 条件查询
      searchInfo: {},
      // 批量条件查询
      batchSearchInfo: {},
      // 该详情列的商品名称
      productName: "全部",
      // 表格数据列表
      dataList: [],
      // 仓库列表
      stockList: [],
      // 商品分类列表字典
      categoryList: [],
      inoutList: [],
      // 设置读的权限值
      readAccess: null,
      supplierList: [],
      detailStatistic:{},
    };
  },
  'searchInfo.startTime': {
    handler() {
      this.resetSearch();
    }
  },
  created() {
    commonApi.querySupplierList().then(list => this.supplierList = list);
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 获取出入库类型信息
    stockInoutTypeApi.queryAllTypeList().then(list => this.inoutList = list);
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
    // 仓库
    orderStatisticApi.queryStockList().then(res => this.stockList = res);
    // 时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 获取页面跳转传参的数据
    if (this.$routerUtil.query.batchSearchInfoStr) {
      this.batchSearchInfo = JSON.parse(this.$routerUtil.query.batchSearchInfoStr);
      this.handlerBatchSearch();
    }
    if (this.$routerUtil.query.searchInfoStr) {
      this.searchInfo = JSON.parse(this.$routerUtil.query.searchInfoStr);
      this.resetSearch();
    }
  },
  methods: {
    rowClick(row,idx) {
      console.log(row);
    },
    // 导出
    exportData() {
      this.handlerBatchSearch();
      util.exportExcel("/order/stock/statistic/exportDetailList", this.searchInfo);
    },
    // 按条件查询
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    getSummary(params){
      const {columns} = params;
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
        }
        if (this.detailStatistic[column.property]) {
          sums[index] = h('div', [Number(this.detailStatistic[column.property])])
        }
      })
      return sums;
    },
    // 列表查询
    queryList() {
      this.handlerBatchSearch();
      stockStatisticApi.queryDetailList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
      // 查询明细
      stockStatisticApi.queryDetailStatistic(this.searchInfo).then(data => this.detailStatistic = data);
    },
    // 批量查询处理
    handlerBatchSearch() {
      if (this.batchSearchInfo.stockId && this.batchSearchInfo.stockId.length > 0) {
        this.searchInfo.stockId = this.batchSearchInfo.stockId.join(',');
      } else {
        this.searchInfo.stockId = "";
      }
      if (this.batchSearchInfo.inoutType && this.batchSearchInfo.inoutType.length > 0) {
        this.searchInfo.inoutType = this.batchSearchInfo.inoutType.join(',');
      } else {
        this.searchInfo.inoutType = "";
      }
    },
    // 查看详情
    viewDetail(row) {
      if (row.inoutstorageType === 1) {
        // 入库
        this.$routerUtil.push({path: "/stock/instock/detail-simple", query: {inoutStorage: row.id}})
      } else if (row.inoutstorageType === 2) {
        // 出库
        this.$routerUtil.push({path: "/stock/outstock/detail", query: {inoutStorage: row.id}})
      } else if (row.inoutstorageType === 3) {
        // 调拨
        this.$routerUtil.push({path: "/stock/outstock/detail-simple", query: {id: row.id}})
      } else if (row.inoutstorageType === 5) {
        // 盘点
        this.$routerUtil.push({path: "/stock/outstock/detail-simple", query: {id: row.id}})
      }
      return "";
    },
    // 移除分类编码
    clearCode() {
      this.searchInfo.categoryCode = "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>