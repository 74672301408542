<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <div style="display: flex; align-items: center">
            <page-button type="ex" el-type="success" @click="exportDefault">导出</page-button>
            <page-button type="read" @click="resetSearch" el-type="primary">查询</page-button>
          </div>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期" v-model:end-time="searchInfo.endTime" />

          <el-input v-model="searchInfo.searchKey" @change="resetSearch" clearable placeholder="请输入收费单编号"/>

          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @select-one="setCustomer"/>
          </div>
        </template>
        <template #moreCondition>
          <div style="display: inline-block; margin-right: 10px">
            <dictionary v-if="readAccess === '3'" v-model="searchInfo.departCode" :place-holder="'请选择部门'" @clearData="()=>searchInfo.departCode = {}" :dictionaries="departList"
                        :is-multiple="true"
                        @change-data="setDepartCode"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>

          <div v-if="readAccess !== '1'" class="customer-select" style="display: inline-block; margin-right: 10px; width: 240px">
            <employee-select :placeholder-tit="'请选择收款人'" @select-more="setPayee" :is-multiple="true" v-model="searchInfo.payeeId" :access="readAccess"/>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px;width: 240px">
            <el-select placeholder="请选择单据类型" v-model="searchInfo.isPay">
              <el-option label="收费" :value="1" />
              <el-option label="退费" :value="2" />
            </el-select>
          </div>
          <div class="customer-select" style="display: inline-block; margin-right: 10px;width: 240px">
            <el-select v-model="searchInfo.payTypeId" placeholder="请选择支付方式" clearable>
              <el-option v-for="(st, i) in payTypeList" :key="i + 'p'" :label="st.payName" :value="st.id"/>
            </el-select>
          </div>
          <el-input v-model="searchInfo.orderId" @change="resetSearch" clearable placeholder="请输入销售订单/退单编号"/>
          <div class="customer-select" style="display: inline-block; margin-right: 10px;width: 240px">
            <el-select v-model="batchInfo.paymentType" placeholder="请选择收费标签" clearable multiple :collapse-tags="true" :max-collapse-tags="1" :collapse-tags-tooltip="true">
              <el-option v-for="(st, i) in feeTagList" :key="i + 'p'" :label="st.label" :value="st.value"/>
            </el-select>
          </div>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" show-summary :summary-method="getSummary">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="单据类型" prop="payName" min-width="100px" fixed/>
          <el-table-column :align="'center'" label="收费日期" show-overflow-tooltip min-width="150px" prop="payTime"/>
          <el-table-column align="center" label="收费单号" show-overflow-tooltip min-width="120px" prop="id" />
          <el-table-column align="center" label="业务日期" show-overflow-tooltip min-width="150px" prop="orderCreatedTime" />
          <el-table-column align="center" label="关联业务单号" show-overflow-tooltip min-width="120px" prop="orderId" />
          <el-table-column align="center" label="部门" show-overflow-tooltip min-width="120px" prop="departName" />
          <el-table-column align="center" label="客户" show-overflow-tooltip min-width="120px" prop="customerName" />
          <el-table-column align="center" label="客户电话" show-overflow-tooltip min-width="120px" prop="customerPhone" />
          <el-table-column align="center" label="订单金额" show-overflow-tooltip min-width="120px" prop="amount" />
          <el-table-column align="center" label="收费金额" show-overflow-tooltip min-width="120px" prop="currentAmount" />
          <el-table-column align="center" label="支付方式" show-overflow-tooltip min-width="120px" prop="payTypeName" />
          <el-table-column align="center" label="支付流水号" show-overflow-tooltip min-width="120px" prop="outTradeNo" />
          <el-table-column align="center" label="收款人" show-overflow-tooltip min-width="120px" prop="payeeName" />
          <el-table-column align="center" label="收费标签" show-overflow-tooltip min-width="120px" prop="paymentTypeName" fixed="right" />
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
</template>

<script>

import DateRange from "@/components/h5/date/DateRange.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import EchoDic from "@/components/h5/EchoDic.vue";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import commonApi from "@/api/commonApi";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import config from "@/config/config";
import paymentApi from "@/api/main/order/paymentOrder/paymentApi";
import util from "@/util/util";
import {h} from "vue";

export default {
  name: "PaymentOrder",
  components: {
    EmployeeSelect,
    PayTypeSelect,
    EchoDic,
    Dictionary,
    FormPage,
    TableList,
    CustomerSelect,
    TableButton,
    PageButton,
    PageHeader,
    SearchCondition,
    ListPage,
    DateRange
  },
  data() {
    return {
      dataList: [],
      searchInfo: {},
      batchInfo: {},
      departList: [], // 部门
      payTypeList: [], // 支付方式
      feeTagList: [], // 收费标签
      readAccess: null,
      countBottom: {}, // 底部统计信息
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  created() {
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    systemPayApi.queryAllList().then(res=> this.payTypeList = res);
    commonApi.queryAllEmployee().then(res => this.employeeList = res);
    commonApi.queryDepartList().then(res => this.departList = res);
    // 初始化时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 收费标签
    this.feeTagList = config.feeTag;
  },
  methods: {
    exportDefault(){
      this.handleBatchSearch();
      util.exportExcel("/order/payment/exportList", this.searchInfo);
    },
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList(){
      this.handleBatchSearch();
      paymentApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
      // 查询底部的统计信息
      paymentApi.countBottom(this.searchInfo).then(res => this.countBottom = res);
    },
    setCustomer(data){
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    getSummary(param){
      const {columns} = param;
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return;
        }
        // 金额
        if (index === 10) {
          sums[index] = h('div', [Number(this.countBottom.totalPrice ? this.countBottom.totalPrice : 0).toFixed(2)])
        }
      })
      return sums
    },
    // 设置部门信息
    setDepartCode(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          arr.push(data[i][j]);
        }
      }
      this.batchInfo.departCodes = arr
    },
    // 设置收费人员信息
    setPayee(data) {
      this.batchInfo.payeeIds = data;
    },
    // 处理批量查询条件
    handleBatchSearch() {
      if (this.batchInfo.departCodes && this.batchInfo.departCodes.length > 0) {
        this.searchInfo.departCode = this.batchInfo.departCodes.join(',');
      } else {
        this.searchInfo.departCode = "";
      }
      if (this.batchInfo.payeeIds && this.batchInfo.payeeIds.length > 0) {
        this.searchInfo.payeeId = this.batchInfo.payeeIds.join(',');
      } else {
        this.searchInfo.payeeId = "";
      }
      if (this.batchInfo.paymentType && this.batchInfo.paymentType.length > 0) {
        this.searchInfo.paymentType = this.batchInfo.paymentType.join(',');
      } else {
        this.searchInfo.paymentType = "";
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
</style>