import axios from "../../../util/axios";

export default {
    // 保存组装拆卸但
    saveSubmit: (data) => axios.post({url: "/order/stock/assemble/saveSubmit", data: data}),

    // 保存组装拆卸但草稿
    saveDraft: (data) => axios.post({url: "/order/stock/assemble/saveDraft", data: data}),

    // 查询组装拆卸但列表
    queryList: (data) => axios.get({url: "/order/stock/assemble/queryList", data: data}),

    // 查询组装拆卸但详情
    findById: (id) => axios.get({url: "/order/stock/assemble/findById", data: {id: id}}),

    // 删除一条草稿
    deleteById: (id) => axios.del({url: "/order/stock/assemble/deleteById", data: {id: id}})
}