import axios from "../../../util/axios";

export default {
    /** 查询当前商户第三方配置 */
    findThirdConfig: () => axios.get({url: "/basedata/sync/database/findThirdConfig"}),
    /** 查询所有支持的erp系统版本 */
    queryAllVersions: () => axios.get({url: "/basedata/sync/database/queryAllVersions"}),
    /** 测试第三方系统连接情况，并返回数据库实例，如果是api对接，不返回数据库实例 */
    testLink: (erpInfo) => axios.post({url: "/basedata/sync/database/testLink", data: erpInfo}),
    /** 测试第三方系统连接情况，查询操作员，返回职员列表 */
    testOperatorLink: (erpInfo) => axios.post({url: "/basedata/sync/database/testOperatorLink", data: erpInfo}),
    /** 测试第三方系统连接情况，查询仓库，返回仓库列表 */
    testStockLink: (erpInfo) => axios.post({url: "/basedata/sync/database/testStockLink", data: erpInfo}),
    /** 保存第三方系统配置 */
    save: (erpInfo) => axios.post({url: "/basedata/sync/database/save", data: erpInfo}),
}