<template>
  <!-- 自定义字段显示与否，必须指定prop,不支持index类型 -->
  <el-table-column v-if="type === 'selection'" type="selection" :fixed="fixed" :align="align" :width="width"/>
  <el-table-column v-else-if="showPropInfo" :prop="prop" :label="label" :width="width" :fixed="fixed" :align="align" :min-width="minWidth" />

  <el-table-column v-else :label="displaySetting.label" ref="td" v-if="displaySetting.display" :type="type" :column-key="columnKey" :min-width="minWidth"  :sort-method="sortMethod" :sort-by="sortBy" :resizable="resizable"
                   :fixed="displaySetting.fixed" :width="displaySetting.width" :align="displaySetting.align" :index="displaySetting.index">
    <template v-slot="{row, $index}">
      <span v-show="false">{{columnShow(row)}}</span>
      <span v-if="!hasSelfSlot">{{row[prop]}}</span>
      <slot :row="row" :$index="$index" />
    </template>
    <template #header>
      <slot name="header"></slot>
    </template>
  </el-table-column>
</template>

<script>

import {getCurrentInstance} from "vue";

export default {
  props: {
    required: {default: false}, // 必需项，required的项不可以被隐藏
    defaultShow: {default: true}, // 是否默认显示，如果没有配置，就按照默认显示
    displayInSetting: {default: true}, // 是否在列表显示
    showPropInfo: {default: false}, // 使用el-table的默认显示
    // 以下是el-column的属性
    label: {default: ""}, // 根据label来进行去重
    prop: {default: "unknown"}, // 属性名，必填
    type: {default: ""},
    columnKey: {default: ""},
    width: {default: ""},
    minWidth: {default: ""},
    fixed: {default: false},
    sortMethod: {default: null},
    sortBy: {default: ""},
    resizable: {default: true},
    align: {default: "left"}
  },
  name: "",
  components: {
  },
  data() {
    return {
      display: true,
      hasSelfSlot: false, // 是否自定义slot
      displaySetting: { // 自定义的显示配置，只支持这几个
        label: "",
        fixed: false,
        align: "left",
        width: "",
        display: true,
      }
    };
  },
  created() {
    this.displaySetting = {
      label: this.label,
      fixed: this.fixed,
      align: this.align,
      width: this.width,
      display: true,
    }
    if (this.$parent.$parent.registerColumn) {
      this.$parent.$parent.registerColumn({
        label: this.label,
        props: this.prop,
        required: this.required,
        defaultShow: this.defaultShow,
        displayInSetting: this.displayInSetting,
        fixed: this.fixed,
        width: this.width,
        align: this.align,
      });
    }
    // 是否使用了slot
    const ins = getCurrentInstance();
    if (ins && ins.slots.default) {
      this.hasSelfSlot = true;
    }
  },
  methods: {
    // 控制column是否显示
    columnShow(data) {
      // this.display = data.displaySetting[this.prop].display;
      this.displaySetting = {
        ...data.displaySetting[this.prop],
        width: data.displaySetting[this.prop].width + "rem",
      };
      // console.log(this.displaySetting)
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>

</style>