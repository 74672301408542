<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border>
          <el-descriptions-item v-if="!dataDetail.id || userInfo.merchantId === dataDetail.supplierId" label="下游客户">
            <client-select :disabled="dataDetail.id" v-model="dataDetail.clientId" :merchant-name="dataDetail.clientName"/>
          </el-descriptions-item>
          <el-descriptions-item v-else label="供应商">
            {{dataDetail.supplierName}}
          </el-descriptions-item>
          <el-descriptions-item label="协议价名称">
            <el-input v-model="dataDetail.title"/>
          </el-descriptions-item>
          <el-descriptions-item label="有效期">
            <date-range v-model:start-time="dataDetail.startTime" v-model:end-time="dataDetail.endTime" :show-short-cut="false" style="width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="创建时间">
            {{dataDetail.createdTime}}
          </el-descriptions-item>
          <el-descriptions-item label="备注信息" :span="2">
            <el-input type="textarea" v-model="dataDetail.description"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right">
          <div style="width: 400px;display: inline-block;">
            <product-list-select :selected-product-list="dataDetail.detail" @select="selectProductList" @selectOne="selectProductSingle"/>
          </div>
        </div>
        <div style="height: calc(100% - 230px)">
          <el-table stripe :data="dataDetail.detail" height="100%">
            <el-table-column label="序号" type="index" width="60px" fixed/>
            <el-table-column label="商品名称" prop="skuName" min-width="150px" show-overflow-tooltip/>
            <el-table-column label="商品编码" prop="skuCode" min-width="150px" show-overflow-tooltip/>
            <el-table-column label="协议价" prop="price" width="100px">
              <template v-slot="{row}">
                <el-input v-model="row.price"/>
              </template>
            </el-table-column>
            <el-table-column v-if="userInfo.merchantId === dataDetail.supplierId" label="成本价" prop="price" width="100px">
              <template v-slot="{row}">
                <el-input v-model="row.buyPrice"/>
              </template>
            </el-table-column>
            <el-table-column label="原售价" prop="originalPrice" width="100px" :align="'right'">
              <template v-slot="{row}"><money :value="row.originalPrice"/></template>
            </el-table-column>
            <el-table-column label="单位" prop="unitName" width="100px" :align="'center'"/>
            <el-table-column label="操作" width="100px" fixed="right" :align="'center'">
              <template v-slot="{row, $index}">
                <table-button el-type="danger" @click="removeList($index)">删除</table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <page-button :disabled="!!dataDetail.supplierId && dataDetail.supplierId !== userInfo.merchantId" el-type="primary" type="write" @click="toSave">保存</page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import merchantDealPriceApi from "@/api/main/supplyChain/merchantDealPriceApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import Money from "@/components/h5/Money.vue";
import util from "@/util/util";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, DateRange, PageHeader, ProductListSelect, ClientSelect, Money
  },
  data() {
    return {
      dataDetail: {
        detail: [],
      },
      userInfo: {}
    };
  },
  created() {
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    }
    util.getUserInfo().then(user => this.userInfo = user);
  },
  methods: {
    findById(id) {
      merchantDealPriceApi.findById(id).then(data => this.dataDetail = data);
    },
    toSave() {
      if (!this.dataDetail.clientId) {
        this.$message.error("请选择客户");
        return;
      }
      if (!this.dataDetail.detail || this.dataDetail.detail.length === 0) {
        this.$message.error("请添加商品");
        return;
      }
      merchantDealPriceApi.save(this.dataDetail).then(data => {
        this.dataDetail = data;
      })
    },
    createPriceData(product) {
      return {
        skuId: product.id,
        skuName: product.skuName,
        skuCode: product.skuCode,
        price: product.skuSalePrice,
        buyPrice: product.skuBuyPrice,
        originalPrice: product.skuSalePrice,
        unitName: product.skuUnitName,
        unitId: product.skuUnitId,
      }
    },
    // 向列表中添加多个商品
    selectProductList(list) {
      for (let i = 0; i < list.length; i++) {
        this.selectProductSingle(list[i]);
      }
    },
    selectProductSingle(data) {
      // 如果没有，则添加
      let contains = false;
      for (let j = 0; j < this.dataDetail.detail.length; j++) {
        if (data.id === this.dataDetail.detail[j].skuId) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.detail.push(this.createPriceData(data));
      }
    },
    removeList(index) {
      this.dataDetail.detail = this.dataDetail.detail.remove(index);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>