<template>
  <!-- 房态状态 -->
  <div class="defaultViewStyle">
    <page-header>
      <span @click="changeType(null)" class="special-type" style="background-color: #fc6b03"><el-icon
          v-if="searchType === null"><el-icon-check/></el-icon>全部</span>
      <span @click="changeType(1)" class="special-type" style="background-color: #67C23A"><el-icon
          v-if="searchType === 1"><el-icon-check/></el-icon>净房</span>
      <span @click="changeType(2)" class="special-type" style="background-color: #409EFF"><el-icon
          v-if="searchType === 2"><el-icon-check/></el-icon>入住</span>
      <span @click="changeType(0)" class="special-type" style="background-color: #909399"><el-icon
          v-if="searchType === 0"><el-icon-check/></el-icon>脏房</span>
      <span @click="changeType(-1)" class="special-type" style="background-color: #F56C6C"><el-icon
          v-if="searchType === -1"><el-icon-check/></el-icon>预定</span>
    </page-header>

    <div class="top-content">
      <el-tabs
          v-model="roomType"
          type="border-card"
          @tab-click="handleRoomClick"
      >
        <el-tab-pane label="全部类型" name=""></el-tab-pane>
        <el-tab-pane v-for="item in roomList" :key="item.id" :label="item.typeName" :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="mid-content">
      <div class="left">
        <el-tabs v-model="floorType" :tab-position="'left'" type="border-card"
                 @tab-click="handleFloorClick">
          <el-tab-pane label="全部楼层" name=""></el-tab-pane>
          <el-tab-pane v-for="item in floorList" :key="item.id" :label="item.typeName" :name="item.id"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <!--        <div v-for="item in dataList" :key="item.id" class="room-item" :style="item.status === 0 ? '' ">-->
        <div class="room-item" @click="toDetail(item)" v-for="item in dataList" :key="item.id"
             :style="{'background-color': item.status === 0 ? '#909399' : item.status === 1 ? '#67C23A' : item.status === 2 ? '#409EFF' : '#F56C6C'}">
          <div>
            <span>房号：</span>
            <span>{{ item.name }}</span>
          </div>
          <div>
            <span>客房类型：</span>
            <span>{{ item.typeName }}</span>
          </div>
          <div>
            <span>楼层：</span>
            <span>{{ item.typeFloorName }}</span>
          </div>
          <hr style="width: 100%"/>
          <div>
            <span>客户名称：</span>
            <span>{{ item.subscribe.customerName ? item.subscribe.customerName : '无' }}</span>
          </div>
          <div>
            <span>手机号：</span>
            <span>{{ item.subscribe.customerPhone ? item.subscribe.customerPhone : '无' }}</span>
          </div>
          <div>
            <span>入住日期：</span>
            <span>{{ item.subscribe.startTime ? item.subscribe.startTime.split(" ")[0] : '无' }}</span>
          </div>
          <div>
            <span>退房时间：</span>
            <span>{{ item.subscribe.endTime ? item.subscribe.endTime.split(" ")[0] : '无' }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" v-model="showDetail" title="客房详情" width="850px" @closed="clearObj">
      <div class="room-detail">
        <h3 style="margin: 0">客房信息</h3>
        <div class="room-info">
          <div class="room-top">
            <div>
              <span>房间号：</span>
              <span>{{ detail.roomDetail.name }}</span>
            </div>
            <div>
              <span>所在楼层：</span>
              <span>{{ detail.roomDetail.typeFloorName }}</span>
            </div>
          </div>
          <div class="room-bottom">
            <div>
              <span>客房类型：</span>
              <span>{{ detail.roomDetail.typeName }}</span>
            </div>
            <div>
              <span>客房状态：</span>
              <span v-if="detail.roomDetail.status === 1">净房</span>
              <span v-if="detail.roomDetail.status === 2">入住</span>
              <span v-if="detail.roomDetail.status === 0">脏房</span>
              <span v-if="detail.roomDetail.status === -1">预订</span>
            </div>
          </div>
        </div>
        <h3 style="margin: 15px 0">入住登记</h3>
        <div class="room-info-check-in">
          <div style="display: inline-block;" class="customer-select">
            <el-input :disabled="detail.roomDetail.status !== 1" v-model="orderId" placeholder="请输入/扫描单号"/>
          </div>
          <div style="display: inline-block;" class="customer-select">
            <customer-select :disabled="detail.roomDetail.status !== 1" v-model="customerId" :name="customerName"
                             @selectOne="setCustomer"/>
          </div>
          <page-button @click="findDetail" el-type="primary">查询</page-button>
        </div>
        <div class="customer-info">
          <div class="customer-detail">
            <div>
              <div>
                <span>客户姓名：</span>
                <span>{{ detail.subscribe.customerName }}</span>
              </div>
              <div>
                <span>身份证号：</span>
                <span>{{ detail.subscribe.cardNo }}</span>
              </div>
            </div>

            <div>
              <div>
                <span>性别：</span>
                <span v-if="detail.subscribe.gender === 2">女</span>
                <span v-if="detail.subscribe.gender === 1">男</span>
                <span v-if="!detail.subscribe.gender">保密</span>
              </div>
              <div>
                <span>出生日期：</span>
                <span>{{ detail.subscribe.birthday ? detail.subscribe.birthday.split(" ")[0] : '未填写' }}</span>
              </div>
            </div>

            <div>
              <div>
                <span>客户电话：</span>
                <span>{{ detail.subscribe.customerPhone }}</span>
              </div>
              <div>
                <span>紧急电话：</span>
                <span>{{ detail.subscribe.emergencyContact }}</span>
              </div>
            </div>

            <div>
              <div>
                <span>家庭住址：</span>
                <span>{{ detail.subscribe.address }}</span>
              </div>
            </div>

          </div>
        </div>
        <div class="order-info">
          <div>
            <el-form-item label="订单金额：">
              <el-input :disabled="true" v-model="detail.subscribe.amount" style="width: 240px"/>
            </el-form-item>
            <el-form-item label="已收金额：">
              <el-input :disabled="true" v-model="detail.subscribe.amountCollect" style="width: 240px"/>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="未收金额：">
              <el-input :disabled="true" v-model="getAmountNotCollect" style="width: 240px"/>
            </el-form-item>
            <el-form-item label="本次收款：">
              <el-input :disabled="detail.roomDetail.status !== 1" v-model="detail.subscribe.amountShould"
                        style="width: 240px"/>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="入住日期：">
              <el-date-picker
                  v-model="detail.subscribe.startTime"
                  type="date"
                  @change="changeTime"
                  placeholder="选择入住日期"
                  size="default"
                  style="width: 240px"
                  :disabled="detail.roomDetail.status !== 1"
              />
            </el-form-item>
            <el-form-item label="退房日期：">
              <el-date-picker
                  v-model="detail.subscribe.endTime"
                  type="date"
                  placeholder="选择退房日期"
                  size="default"
                  style="width: 240px"
                  :disabled="detail.roomDetail.status !== 1"
              />
            </el-form-item>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end">
        <page-button el-type="primary" message="办理入住前，请确认已处理完相应收款？" @click="toCheckIn">入住
        </page-button>
        <page-button el-type="primary" @click="addContinueLive">续住</page-button>
        <page-button el-type="primary" message="办理退房前，请确认已处理完相应退款？" @click="toCheckOut">退房
        </page-button>
        <page-button el-type="primary" @click="toAdvance">收押金</page-button>
        <page-button el-type="primary" @click="toRefundAdvance">退押金</page-button>
        <page-button el-type="primary" @click="toPay">订单收款</page-button>
        <page-button el-type="primary" @click="showStatus = true">调整状态</page-button>
      </div>
    </el-dialog>

    <el-dialog v-model="showOrder" width="30%" height="310px" :close-on-click-modal="false" title="订单收款" @opened="dialogAutoFocus">
      <sale-balance :sale-info="currentOrder" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                    :is-pay="true" :is-batch-pay="true" :is-refresh-pay="isRefreshPay" ref="autoFocus"
                    @find-sale-status="toFindPayStatus" @change-pay="changePay" @close-page="showOrder = false"
                    @pay="payReceipt" @batch-submit="batchSubmit" v-loading.fullscreen.lock="fullscreenPayLoading">
        <div style="display: flex;align-items: center">
          <el-checkbox v-model="isAutoPrint" label="打印凭证" size="large"/>
        </div>
      </sale-balance>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" v-model="showAdvance" width="500px" height="360px"
               :title=" isPay ? isPaying2 ? '订单结算&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支付中...' : '押金结算' : '提交收款' "
               @opened="openBefore">
      <div style="height: 293px;overflow: auto">
        <el-form label-width="70px" style="">
          <div style="height: 250px">
            <el-form-item label="扫码支付">
              <div style="position: relative;width: 100%;overflow: hidden">
                <el-input v-model="dataDetail.qrCode" ref="inputRef" placeholder="支付码" style="width: 100%"
                          @change="pay" :disabled="dataDetail.surplusPayType === 2 || dataDetail.payType !== 2"/>
                <div v-if="dataDetail.payStatus === 20"
                     style="position: absolute;right: 0;top: 0;line-height: 40px;border-left: 1px solid #dcdfe6;width: 32px;text-align: center;">
                  <el-tooltip :disabled="isRefreshPay" content="支付中，请稍后刷新支付状态" effect="light">
                    <el-icon :class="isRefreshPay ? 'rotating-element' : ''">
                      <el-icon-refresh @click="toFindAdvanceStatus"/>
                    </el-icon>
                  </el-tooltip>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="客户名称">
              <div style="position: relative;width: 100%;overflow: hidden">
                <el-input v-model="dataDetail.customerName" disabled style="width: 100%"/>
              </div>
            </el-form-item>
            <el-form-item label="押金类型">
              <dictionary style="width: 100%" :disabled="dataDetail.status === 99" v-model="dataDetail.depositType"
                          :dictionaries="dictionaryList"/>
            </el-form-item>
            <el-form-item label="金额合计">
              <el-input v-model="dataDetail.amountPay"/>
            </el-form-item>
            <el-form-item :label="dataDetail.surplusPayType === 1 ? '支付方式' : '退款方式'">
              <pay-type-select v-model="dataDetail.payTypeId" @select-one="changeAdvancePay"/>
            </el-form-item>
          </div>
        </el-form>
        <div style="display: flex;justify-content: space-between">
          <el-checkbox v-model="isAutoPrint" label="打印押金凭证" size="large"/>
          <div>
            <page-button el-type="info" @click="showAdvance = false">取消</page-button>
            <page-button el-type="primary" v-loading.fullscreen.lock="fullscreenLoadingAdvance" @click="advancePay"
                         :loading="preparePay" :disabled="disableToClickPay">
              确认
            </page-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" v-model="showAdvanceList" width="50%" title="押金订单">
      <dialog-table>
        <el-table stripe :data="advanceList" height="100%" border>
          <el-table-column align="center" label="序号" type="index" width="60px"/>
          <el-table-column :align="'center'" label="单据时间" show-overflow-tooltip min-width="150px"
                           prop="createdTime"/>
          <el-table-column align="center" label="客户名称" show-overflow-tooltip min-width="100px" prop="customerName"/>
          <el-table-column align="center" label="部门" show-overflow-tooltip min-width="100px" prop="departName"/>
          <!--          <el-table-column align="center" label="金额" prop="amountPay" show-overflow-tooltip min-width="100px">-->
          <!--            <template v-slot="{row}">-->
          <!--              {{row.surplusPayType === 1 ? row.amountPay : - row.refundAmount}}-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column align="center" label="经销人员" show-overflow-tooltip min-width="100px" prop="employeeName"/>
          <el-table-column align="center" label="单据类型" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <el-tag :type="row.surplusPayType === 1 ? 'primary' : 'warning'">
                {{ row.surplusPayType === 1 ? '收押金' : '退押金' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="toShowRefund(row)"> 选中
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" v-model="showStatus" width="20%" title="客房状态">

        <el-form label-width="70px">
          <el-form-item label="当前状态">
            <span v-if="detail.roomDetail.status === 1">净房</span>
            <span v-if="detail.roomDetail.status === 2">入住</span>
            <span v-if="detail.roomDetail.status === 3">脏房</span>
            <span v-if="detail.roomDetail.status === -1">预订</span>
          </el-form-item>
          <el-form-item label="客房状态">
            <el-select v-model="roomStatus">
              <el-option :value="0" label="脏房"/>
              <el-option :value="1" label="净房"/>
              <el-option :value="2" label="入住"/>
              <el-option :value="-1" label="预定"/>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: flex-end">
          <page-button el-type="info" @click="showStatus = false">取消</page-button>
          <page-button el-type="primary" @click="toSure">确定</page-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import {Document, Position, School} from "@element-plus/icons-vue";
import houseTypeApi from "@/api/main/house/houseTypeApi";
import houseStatusApi from "@/api/main/house/houseStatusApi";
import houseSubscribeApi from "@/api/main/house/houseSubscribeApi";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import SaleBalance from "@/components/h5/sale/saleBalance.vue";
import Pay from "@/mixin/pay";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import Advance from "@/mixin/advance";
import Util from "@/util/util";
import config from "@/config/config";
import Dictionary from "@/components/h5/Dictionary.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import advanceApi from "@/api/main/advance/advanceApi";
import AdvanceApi from "@/api/main/advance/advanceApi";
import {ElButton, ElMessageBox} from "element-plus";
import houseDocApi from "@/api/main/house/houseDocApi";
import {h} from "vue";

export default {
  mixins: [Pay, Advance],
  name: "",
  components: {
    DialogTable, TableButton,
    Dictionary,
    PageButton, PayTypeSelect,
    SaleBalance,
    CustomerSelect,
    School,
    Document,
    Position,
    PageHeader,
  },
  data() {
    return {
      showStatus: false,
      roomStatus: null,
      imgUrl: require('@/assets/image/room-logo.png'),
      roomType: "",
      floorType: "",
      searchType: null,
      floorList: [],
      roomList: [],
      dataList: [],
      showDetail: false,
      // 客房详情
      detail: {
        roomDetail: {}, // 客房详情
        subscribe: {} // 预定信息
      },
      orderId: "",
      customerId: "",
      customerName: "",
      userInfo: {},
      advanceList: [],
      showAdvanceList: false,
      msgBox: null,
    };
  },
  computed: {
    getAmountNotCollect() {
      if (this.detail.subscribe.id) {
        return this.detail.subscribe.amount - this.detail.subscribe.amountCollect;
      } else {
        return null;
      }
    },
  },
  created() {
    // 获取用户信息
    Util.getUserInfo().then(res => this.userInfo = res);
    // 查询所有的楼层
    houseTypeApi.queryAllList({searchType: 0}).then(list => this.floorList = list);
    // 客房类型
    houseTypeApi.queryAllList({searchType: 1}).then(list => this.roomList = list);
    // 查询
    this.queryList();
  },
  methods: {
    /**
     * 改变房间状态
     */
    toSure() {
      houseDocApi.updateStatus(this.detail.roomDetail.id, this.roomStatus).then(() =>{
        this.$message.success("修改成功！");
        this.showDetail = false;
        this.showStatus = false;
        this.queryList();
      });
    },
    /**
     * 房间续住
     */
    addContinueLive() {
      if(this.detail.roomDetail.status !== 2){
        this.$message.error("当前客房尚未入住，无法续住！");
        return;
      }
      if (!this.detail.subscribe.id) {
        this.$message.error("请先获取订单信息！");
        return;
      }
      // this指向问题
      let _this = this;
      function handleClick() {
        _this.$messageBox.close();
        _this.$routerUtil.push(`/house/document?name=${_this.detail.roomDetail.name}`);
      }
      if (!this.detail.roomDetail.contProductId) {
        ElMessageBox({
          title: '提示',
          message: h('p', null, [
            h('span', null, '客房尚未绑定续住商品，请'),
            h('a', {style: 'color: #409EFF',href: 'javascript:',onClick: handleClick}, '前往绑定！'),
          ]),
          showCancelButton: true,
          confirmButtonText: '前往订单',
          cancelButtonText: '取消',
        }).then(() => {
          this.$routerUtil.push(`/report/offline-order/detail?customerId=${this.detail.subscribe.customerId}&productId=${this.detail.roomDetail.contProductId}&subId=${this.detail.subscribe.id}`);
        })
        return;
      }
      this.$routerUtil.push(`/report/offline-order/detail?customerId=${this.detail.subscribe.customerId}&productId=${this.detail.roomDetail.contProductId}&subId=${this.detail.subscribe.id}`)
    },
    /**
     * 入住
     */
    toCheckIn() {
      if (!this.detail.subscribe.id) {
        this.$message.error("请先获取订单信息！");
        return;
      }
      houseStatusApi.checkIn(this.detail.subscribe.id, this.detail.roomDetail.id).then(() => {
        houseSubscribeApi.save(this.detail.subscribe);
        this.queryList();
        this.showDetail = false;
      });
    },
    /**
     * 退房
     */
    toCheckOut() {
      houseStatusApi.checkOut(this.detail.roomDetail.id).then(() => {
        this.$message.success("退房成功！");
        this.queryList();
        this.showDetail = false;
      });
    },
    advancePay() {
      if (this.dataDetail.surplusPayType === 2) {
        // 退款
        this.refund();
      } else {
        // 收款
        this.pay();
      }
    },
    toShowRefund(row) {
      AdvanceApi.findById(row.id).then(data => {
        // 重置
        this.dataDetail = {};
        // 赋值
        this.dataDetail = data;
        if (!data.refundAmount || data.refundAmount === 0) {
          this.dataDetail.refundAmount = data.amountPay;
        }
        this.dataDetail.paymentType = 5;
        this.dataDetail.surplusPayType = 2;
        if (data.surplusPayType === 1) {
          this.dataDetail.status = 0;
        }
        this.showAdvanceList = false;
        this.showAdvance = true;
      })
    },
    // 押金退款
    toRefundAdvance() {
      if (!this.detail.subscribe.id) {
        this.$message.error("请先选择预定信息！");
        return;
      }
      // 查询押金详情
      advanceApi.queryByCustomerId(this.detail.subscribe.customerId).then(list => {
        if (list.length === 0) {
          this.$message.error("当前客户暂无押金缴纳信息！");
          return;
        }
        this.advanceList = list;
        this.showAdvanceList = true;
      })
    },
    // 押金
    toAdvance() {
      if (!this.detail.subscribe.id) {
        this.$message.error("请先选择预定信息！");
        return;
      }
      // 重置
      this.dataDetail = {};
      // 押金
      this.dataDetail.surplusPay = 2;
      // 押金收款
      this.dataDetail.surplusPayType = 1;
      // 默认金额2000
      this.dataDetail.amountPay = 2000;
      // 获取押金参数-部门
      this.dataDetail.departCode = this.detail.subscribe.departCode;
      // 客户名称
      this.dataDetail.customerName = this.detail.subscribe.customerName;
      // 客户id
      this.dataDetail.customerId = this.detail.subscribe.customerId;
      // 职员默认自己
      this.dataDetail.employeeId = this.userInfo.id;
      // 单据时间
      this.dataDetail.advanceTime = new Date();
      // 默认支付方式
      this.dataDetail.payTypeId = config.defaultPay[1];
      this.dataDetail.payType = 2;
      // 展开
      this.showAdvance = true;
    },
    toPay() {
      if (!this.detail.subscribe.orderId) {
        this.$message.error("请选择支付订单信息！");
        return;
      }
      if (this.detail.subscribe.amountShould === 0) {
        this.$message.error("订单收款金额为0！");
        return;
      }
      this.batchPay({id: this.detail.subscribe.orderId});
    },
    updateRoomData() {
      this.findDetail();
    },
    clearObj() {
      this.orderId = "";
      this.customerId = "";
      this.detail.subscribe = {};
      this.detail.roomDetail = {};
    },
    setCustomer(data, isFind = true) {
      this.customerId = data.id;
      this.customerName = data.customerName;
      if (this.customerId && isFind) {
        this.findDetail();
      }
    },
    changeTime() {
      let dateArr = this.detail.subscribe.startTime.format("yyyy-MM-dd").split("-");
      this.detail.subscribe.endTime = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]) + 28);
    },
    // 查询订单详情信息
    findDetail(isCheck = true) {
      if (this.detail.roomDetail.status !== 1 && isCheck) {
        this.$message.error("该客房状态禁止查询！");
        return;
      }
      houseSubscribeApi.findByCondition(this.orderId, this.customerId).then(data => {
        this.detail.subscribe = data;
        if(!isCheck){
          this.setCustomer(data, false);
        }
      }).catch(() => {
        this.detail.subscribe = {};
      });
    },
    toDetail(item) {
      this.showDetail = true;
      this.detail.roomDetail = item;
      if (item.status === 2) {
        // 根据
        houseSubscribeApi.findById(item.subscribeId).then(data => {
          this.orderId = data.orderId;
          this.findDetail(false);
        });
      }
    },
    changeType(val) {
      this.searchType = val;
      this.queryList();
    },
    handleRoomClick(key) {
      this.roomType = key.props.name;
      this.queryList();
    },
    handleFloorClick(key) {
      this.floorType = key.props.name;
      this.queryList();
    },
    queryList() {
      houseStatusApi.queryList({
        dataId: this.floorType,
        searchKey: this.roomType,
        searchType: this.searchType
      }).then(list => {
        this.dataList = this.handler(list);
      })
    },
    handler(list) {
      for (let i = 0; i < list.length; i++) {
        if (!list[i].subscribe) {
          list[i].subscribe = {};
        }
      }
      return list;
    },
  }
};
</script>

<style lang="scss" scoped>



.customer-select {
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}

:deep(.el-radio-group) {
  flex-wrap: nowrap !important;
}

:deep(.el-dialog .el-dialog__body) {
  padding: 25px !important;
}

.room-detail {

  margin: 0 !important;
  font-size: 17px;

  h3 {
    letter-spacing: 2px;
  }

  .room-info {

    margin-top: 10px;
    border-bottom: 1px solid #a19d9d;

    span {
      font-size: 15px;
    }

    .room-top {
      display: flex;
      flex-direction: row;

      > div:nth-child(1) {
        width: 55%;
      }
    }

    .room-bottom {
      display: flex;
      flex-direction: row;
      margin: 10px 0 15px 0;

      > div:nth-child(1) {
        width: 55%;
      }
    }

  }

  .room-info-check-in {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .el-input {
      margin-right: 10px;
    }

    :deep(.el-button) {
      padding: 6px 25px;
    }
  }

  .customer-info {
    border: 1px solid #e1e0e0;
    margin: 5px 0 15px 0;
    border-radius: 5px;
    padding: 15px;

    .customer-detail {
      width: 100%;
      margin: 0 auto;
      line-height: 40px;
      font-size: 15px;

      > div {
        display: flex;
        flex-direction: row;

        > div:nth-child(1) {
          width: 55%;
        }
      }
    }
  }

  .order-info {
    margin-top: 15px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      :deep(.el-input__wrapper) {
        width: 100%;
      }

      :deep(.el-form-item__label) {
        font-size: 14px;
      }
    }
  }
}

:deep(.left .el-tabs__header) {
  //writing-mode: vertical-rl;
  //text-orientation: upright;
}

.left {
  height: 100%;
}

:deep(.el-tabs) {
  box-sizing: border-box;
}

.right {
  height: 100%;
  //margin: 10px;
  width: 100%;
  overflow: scroll;

  .room-item {
    //box-sizing: border-box;
    //margin: 10px;
    //width: 150px;
    //height: 115px;
    //background-color: red;
    //display: flex;
    //flex-direction: column;
    ////align-items: center;
    //justify-content: flex-start;
    ////line-height: 35px;
    //border-radius: 10px;
    //box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    //position: relative;


    box-sizing: border-box;
    margin: 10px;
    width: 280px;
    //width: 18rem;
    /* height: 115px; */
    background-color: #67C23A;
    display: inline-block;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 4px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    padding: 10px;
    line-height: 23px;
    //padding: 0.5rem;
    //line-height: 1.4rem;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .mask {
      position: absolute;
      //opacity: 0;
      width: 100%;
      height: 0;
      overflow: hidden;
      border-radius: 9px;
      transition: all 0.5s;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .mask-det {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 12px;

        a {
          font-size: 10px;
          color: #409EFF;
          text-decoration: none;
        }
      }
    }

    span {
      color: white;
      font-size: 13px;
      text-align: center;
      font-weight: 600;
      display: block;
    }

    img {
      width: 40px;
    }

    .word {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 95%;
    }
  }

  //.room-item:nth-child(5){
  //  background-color: #409EFF;
  //}

  .room-item:hover > .mask {
    height: 100%;
    transition: all 0.3s;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

:deep(.el-tabs__item){
  font-size: 12px !important;
}
:deep(.el-tabs){
  --el-tabs-header-height: 30px !important;
}
:deep(.el-tabs--left .el-tabs__item.is-left){
  justify-content: center !important;
}
:deep(.left .el-tabs__item) {
  text-align: center;
  width: 35px;
  //width: 25px;
  //writing-mode: vertical-rl;
  //text-orientation: upright;
  height: auto;
  padding: 10px 10px !important;
}

.mid-content {
  display: flex;
  flex-direction: row;
  height: calc(100% - 100px);

  :deep(.el-tabs__nav){
    white-space: pre-wrap !important;
  }


}


.top-content {
  margin-left: 35px;

  :deep(.el-tabs) {
    height: auto !important;
  }
}

:deep(.el-tabs__content) {
  display: none !important;
}

:deep(.el-tabs__header) {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

:deep(.el-tabs__nav-scroll) {
  background-color: #FFF;
}

:deep(.el-tabs__item.is-active) {
  background-color: #409EFF !important;
  color: #FFF !important;
}

.special-type {
  margin-left: 10px;
  user-select: none;
  background: #409eff;
  color: #FFF;
  padding: 4px 10px;
  border-radius: 4px;
}
</style>